import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";

export const getAllActiveCities = createAsyncThunk(
  "City/FindAllActiveCity",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_URL}/City/FindAllActiveCity`
    );
    return response.data?.Data;
  }
);
