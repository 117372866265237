import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../toast/Toasts";

export const emailVerification = createAsyncThunk(
  "UserLogin/EmailOtpApprove",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserLogin/EmailOtpApprove`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error email approve:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
