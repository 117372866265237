import React from "react";
import ARROW_DOWN from "../../assets/images/arrow-down.png";
import { formatIndianNumber } from "../../_helpers/helper";

interface FlightPriceProps {
  route: any;
  sortedPriceList: any;
  flightSearchDetails: any;
  openFlightRate: boolean;
  routeIndex: number;
  setOpenFlightRate: (openFlightRate: boolean) => void;
  priceDetails: any;
  handleRadioButtonChange: (id: string) => void;
  handleBookNowClick: (priceDetails: any, route: any) => void;
  handleSelectFlight?: (
    priceDetails: any,
    route: any,
    type: string,
    index: number
  ) => void;
  type?: string;
  index?: number;
  isSelected?: boolean;
}

const FlightPrice = ({
  sortedPriceList,
  flightSearchDetails,
  openFlightRate,
  priceDetails,
  routeIndex,
  route,
  handleRadioButtonChange,
  setOpenFlightRate,
  handleBookNowClick,
  handleSelectFlight,
  type,
  index,
  isSelected,
}: FlightPriceProps) => {
  return (
    <div className="route-price-right">
      <div className="route-price-col">
        {sortedPriceList?.map((tp: any, index: number) => {
          const totalAdultPrice =
            (tp?.fd?.ADULT?.fC?.TF || 0) * flightSearchDetails.ADULT;
          const totalChildPrice =
            (tp?.fd?.CHILD?.fC?.TF || 0) * flightSearchDetails.CHILD;
          const totalInfantPrice =
            (tp?.fd?.INFANT?.fC?.TF || 0) * flightSearchDetails.INFANT;
          const totalPrice =
            totalAdultPrice + totalChildPrice + totalInfantPrice;

          const RefundableType =
            tp?.fd?.ADULT?.rT === 1
              ? "Refundable"
              : tp?.fd?.ADULT?.rT === 2
              ? "Partial Refundable"
              : "Non Refundable";

          /* CHNAGE IN RADIO BUTTON */
          return (
            <div
              className={`route-price ${
                index > 2 && !openFlightRate ? "d-none" : ""
              }`}
              key={tp.id}
            >
              <input
                type="radio"
                value={priceDetails}
                checked={priceDetails?.id === tp.id}
                name={`radio_${routeIndex}`}
                onChange={() => handleRadioButtonChange(tp?.id)}
              />
              <label htmlFor=""></label>₹{formatIndianNumber(totalPrice)}
              <a href="">
                <span>{tp?.fareIdentifier}</span>{" "}
                {tp?.fd?.ADULT?.cc ? tp?.fd?.ADULT?.cc + ", " : ""}
                {tp?.fd?.ADULT?.mI ? "Free Meal, " : ""} {RefundableType}
              </a>
            </div>
          );
        })}
        {sortedPriceList?.length > 3 && (
          <div className="expand-toggle">
            <a
              className={`${openFlightRate ? "up-arrow" : "down-arrow"} `}
              onClick={() => {
                setOpenFlightRate(!openFlightRate);
              }}
            >
              <img src={ARROW_DOWN} alt="" />
            </a>
          </div>
        )}
      </div>
      {type === "multicity" ? (
        <div className="flight-btn">
          <a
            className="fill-btn"
            onClick={() =>
              handleSelectFlight &&
              handleSelectFlight(priceDetails, route, type, index || 0)
            }
          >
            {isSelected ? "Selected" : "Select"}
          </a>
        </div>
      ) : (
        <div className="flight-btn">
          <a
            className="fill-btn"
            onClick={() => handleBookNowClick(priceDetails, route)}
          >
            Book Now
          </a>
        </div>
      )}
    </div>
  );
};

export default FlightPrice;
