import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  generateObjectFromUrl,
  generatePayloadFromUrl,
  getAirportNameByCityCode,
  getCityNameByCityCode,
  getCurrentFlightSearchFromLocalStorage,
  getMinPriceObject,
  updatedRecentSearches,
} from "../../_helpers/helper";
import FlightSearchSection from "../../components/search/FlightSearchSection";
import PopularItems from "../../components/layout/footer/PopularItems";
import FlightsFilter from "./FlightFilter";
import { getAllFlights, setSortTypeAsync } from "./services/flight.services";
import { setMulticityActiveIndex } from "./flightSlice";
import { useLocation } from "react-router";
import { setFlightSearchDetails } from "../home/homeSlice";
import { FilterSkeleton } from "../../components/skeletons/Skeletons";
import { getAllFlightPlaces } from "../home/services/home.services";
import FilterLoading from "../../components/spinner/FilterLoading";
import ReturnFlightsFooter from "./ReturnFlightsFooter";
import MulticityFlightsFooter from "./MulticityFlightsFooter";
import FlightListingPart from "./FlightListingPart";
import { flushSync } from "react-dom";

const FlightListing = () => {
  const location = useLocation();
  const [selectedOnward, setSelectedOnward] = useState<any>();
  const [selectedReturn, setSelectedReturn] = useState<any>();
  const [selectedMultiCity, setSelectedMultiCity] = useState<any>([]);

  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const filteredOnwardFlights: any = useAppSelector(
    (state) => state.flight.filteredOnwardFlights
  );

  const filteredReturnFlights: any = useAppSelector(
    (state) => state.flight.filteredReturnFlights
  );

  const filteredMulticityFlights: any = useAppSelector(
    (state) => state.flight.filteredMulticityFlights
  );

  const comboSortType: any = useAppSelector(
    (state) => state.flight.comboSortType
  );
  const multicityActiveIndex: any = useAppSelector(
    (state) => state.flight.multicityActiveIndex
  );
  const filterLoading: any = useAppSelector(
    (state) => state.flight.filterLoading
  );

  const loading = useAppSelector((state) => state.flight.loading);

  const totalOAdultPrice =
    (selectedOnward?.priceDetails?.fd?.ADULT?.fC?.TF || 0) *
    flightSearchDetails.ADULT;
  const totalOChildPrice =
    (selectedOnward?.priceDetails?.fd?.CHILD?.fC?.TF || 0) *
    flightSearchDetails.CHILD;
  const totalOInfantPrice =
    (selectedOnward?.priceDetails?.fd?.INFANT?.fC?.TF || 0) *
    flightSearchDetails.INFANT;
  const totalOPrice = totalOAdultPrice + totalOChildPrice + totalOInfantPrice;

  const totalRAdultPrice =
    (selectedReturn?.priceDetails?.fd?.ADULT?.fC?.TF || 0) *
    flightSearchDetails.ADULT;
  const totalRChildPrice =
    (selectedReturn?.priceDetails?.fd?.CHILD?.fC?.TF || 0) *
    flightSearchDetails.CHILD;
  const totalRInfantPrice =
    (selectedReturn?.priceDetails?.fd?.INFANT?.fC?.TF || 0) *
    flightSearchDetails.INFANT;
  const totalRPrice = totalRAdultPrice + totalRChildPrice + totalRInfantPrice;

  const grandMulticityTotal = selectedMultiCity?.reduce(
    (acc: any, flight: any) => {
      const totalAdultPrice =
        (flight?.priceDetails?.fd?.ADULT?.fC?.TF || 0) *
        flightSearchDetails.ADULT;
      const totalChildPrice =
        (flight?.priceDetails?.fd?.CHILD?.fC?.TF || 0) *
        flightSearchDetails.CHILD;
      const totalInfantPrice =
        (flight?.priceDetails?.fd?.INFANT?.fC?.TF || 0) *
        flightSearchDetails.INFANT;
      const totalPrice = totalAdultPrice + totalChildPrice + totalInfantPrice;
      return acc + totalPrice;
    },
    0
  );

  const dispatch = useAppDispatch();

  const onSearchLocation = useCallback(
    async (value: string) => {
      if (value?.length > 0) {
        const response = await dispatch(getAllFlightPlaces(value));
        if (response.meta.requestStatus === "fulfilled") {
          return response.payload;
        }
      }
      return [];
    },
    [dispatch]
  );

  const checkAndFetchLocation = useCallback(
    async (value: string, options: any[] = [], type: string) => {
      const existingOption =
        Array.isArray(options) &&
        options?.find((option) => option.flightplace_code === value);

      if (!existingOption) {
        const newOptions = await onSearchLocation(value);
        if (newOptions.length > 0) {
          dispatch(
            setFlightSearchDetails({
              ...flightSearchDetails,
              [`${type}Options`]: newOptions,
            })
          );
          return newOptions;
        }
      }
      return options;
    },
    [dispatch, flightSearchDetails, onSearchLocation]
  );

  useEffect(() => {
    const flightSearchPayload = generatePayloadFromUrl(location.search);
    const flightSearchQuery = generateObjectFromUrl(
      location.search,
      flightSearchDetails
    );

    // Trigger the flight search with the payload
    dispatch(getAllFlights(flightSearchPayload));

    const fetchOptionsAndSearchFlights = async () => {
      if (flightSearchQuery.triptype === "Multicity") {
        // Handle Multicity scenario
        const updatedMulticities = await Promise.all(
          (flightSearchQuery?.multicities || []).map(
            async (city: any, index: number) => {
              const updatedFromOptions = await checkAndFetchLocation(
                city.from,
                getCurrentFlightSearchFromLocalStorage(
                  `multicities.${index}.fromOptions`
                ),
                `from`
              );

              const updatedToOptions = await checkAndFetchLocation(
                city.to,
                getCurrentFlightSearchFromLocalStorage(
                  `multicities.${index}.toOptions`
                ),
                `to`
              );

              const fromCity = await getCityNameByCityCode(
                city.from,
                updatedFromOptions
              );
              const toCity = await getCityNameByCityCode(
                city.to,
                updatedToOptions
              );

              const fromFlightPlace = await getAirportNameByCityCode(
                city.from,
                updatedFromOptions
              );
              const toFlightPlace = await getAirportNameByCityCode(
                city.to,
                updatedToOptions
              );

              return {
                ...city,
                fromCity,
                toCity,
                fromFlightPlace,
                toFlightPlace,
                fromOptions: updatedFromOptions,
                toOptions: updatedToOptions,
              };
            }
          )
        );

        let flightDetails = JSON.parse(
          localStorage.getItem("flightDetails") || "{}"
        );

        // Update flight details in localStorage
        flightDetails = {
          ...flightDetails,
          lastFlightSearch: {
            triptype: flightSearchQuery.triptype,
            multicities: updatedMulticities,
            ADULT: flightSearchQuery.ADULT,
            CHILD: flightSearchQuery.CHILD,
            INFANT: flightSearchQuery.INFANT,
            cabinClass: flightSearchQuery.cabinClass,
            specialfare: flightSearchQuery.specialfare,
          },
        };

        localStorage.setItem("flightDetails", JSON.stringify(flightDetails));

        dispatch(
          setFlightSearchDetails({
            ...flightSearchDetails,
            ...flightSearchQuery,
            multicities: updatedMulticities,
          })
        );
      } else {
        // Handle One Way and Round Trip scenarios
        const updatedFromOptions = await checkAndFetchLocation(
          flightSearchQuery.from,
          getCurrentFlightSearchFromLocalStorage("fromOptions"),
          "from"
        );
        const updatedToOptions = await checkAndFetchLocation(
          flightSearchQuery.to,
          getCurrentFlightSearchFromLocalStorage("toOptions"),
          "to"
        );

        const fromCity = await getCityNameByCityCode(
          flightSearchQuery.from,
          updatedFromOptions
        );
        const toCity = await getCityNameByCityCode(
          flightSearchQuery.to,
          updatedToOptions
        );

        const fromFlightPlace = await getAirportNameByCityCode(
          flightSearchQuery.from,
          updatedFromOptions
        );
        const toFlightPlace = await getAirportNameByCityCode(
          flightSearchQuery.to,
          updatedToOptions
        );

        const currentFlightSearch = {
          ...flightSearchDetails,
          from: flightSearchQuery.from,
          to: flightSearchQuery.to,
          departureDate: flightSearchQuery?.departureDate,
          returnDate: flightSearchQuery?.returnDate || null,
          triptype: flightSearchQuery.triptype,
          ADULT: flightSearchQuery.ADULT,
          CHILD: flightSearchQuery.CHILD,
          INFANT: flightSearchQuery.INFANT,
          cabinClass: flightSearchQuery.cabinClass,
          specialfare: flightSearchQuery.specialfare,
          fromCity: fromCity,
          toCity: toCity,
          fromFlightPlace: fromFlightPlace,
          toFlightPlace: toFlightPlace,
          fromOptions: updatedFromOptions,
          toOptions: updatedToOptions,
        };

        const fromSearch = {
          city_name: fromCity,
          flightplace_code: flightSearchQuery.from,
          flightplace_name: fromFlightPlace,
        };

        const toSearch = {
          city_name: toCity,
          flightplace_code: flightSearchQuery.to,
          flightplace_name: toFlightPlace,
        };

        const flightRecentSearchesFrom = await updatedRecentSearches(
          "flightRecentSearchesFrom",
          fromSearch
        );
        const flightRecentSearchesTo = await updatedRecentSearches(
          "flightRecentSearchesTo",
          toSearch
        );

        let flightDetails = JSON.parse(
          localStorage.getItem("flightDetails") || "{}"
        );

        flightDetails = {
          ...flightDetails,
          lastFlightSearch: currentFlightSearch,
          flightRecentSearchesFrom: flightRecentSearchesFrom,
          flightRecentSearchesTo: flightRecentSearchesTo,
        };

        localStorage.setItem("flightDetails", JSON.stringify(flightDetails));

        dispatch(
          setFlightSearchDetails({
            ...flightSearchDetails,
            fromOptions: updatedFromOptions,
            toOptions: updatedToOptions,
            ...flightSearchQuery,
          })
        );
      }
    };

    fetchOptionsAndSearchFlights();
  }, [location.search]);

  useEffect(() => {
    const route = filteredOnwardFlights && filteredOnwardFlights[0];
    const priceDetails = getMinPriceObject(route?.totalPriceList);
    setSelectedOnward({ route: route, priceDetails: priceDetails });
  }, [filteredOnwardFlights]);

  useEffect(() => {
    const route = filteredReturnFlights && filteredReturnFlights[0];
    const priceDetails = getMinPriceObject(route?.totalPriceList);
    setSelectedReturn({ route: route, priceDetails: priceDetails });
  }, [filteredReturnFlights]);

  useEffect(() => {
    const selectedMulticityRoutes = filteredMulticityFlights?.map(
      (flights: any[], index: number) => {
        const route = flights && flights[0];
        const priceDetails = getMinPriceObject(route?.totalPriceList);
        return { route, priceDetails };
      }
    );

    setSelectedMultiCity(selectedMulticityRoutes);
  }, [filteredMulticityFlights]);

  const handleChange = (event: any, type?: string, index?: number) => {
    const value = event.target.value;
    const selectedValue = JSON.parse(value);
    dispatch(setSortTypeAsync({ type, selectedValue, index }));
  };

  const handleSelectFlight = (
    priceDetails: any,
    route: any,
    type: string,
    index?: number
  ) => {
    if (type === "onward") {
      setSelectedOnward({
        priceDetails,
        route,
      });
    } else if (type === "return") {
      setSelectedReturn({
        priceDetails,
        route,
      });
    } else if (type === "multicity") {
      if (index !== undefined && index >= 0) {
        const updatedMultiCity = [...selectedMultiCity];

        if (index < updatedMultiCity.length) {
          updatedMultiCity[index] = { priceDetails, route };
        } else {
          updatedMultiCity.push({ priceDetails, route });
        }

        setSelectedMultiCity(updatedMultiCity);
      }
    }
  };

  const handleBookNowClick = () => {
    let flightDetails = JSON.parse(
      localStorage.getItem("flightDetails") || "{}"
    );

    flightDetails = {
      ...flightDetails,
      lastFlightSearchUrl: window.location.href,
    };
    localStorage.setItem("flightDetails", JSON.stringify(flightDetails));

    const priceIds = [
      selectedOnward?.priceDetails?.id,
      selectedReturn?.priceDetails?.id,
    ];

    const baseUrl = window.location.origin;
    const newTabUrl = `${baseUrl}/flights/reviewbooking?priceIds=${priceIds?.join(
      ","
    )}`;
    window.open(newTabUrl, "_blank");
  };

  const handleMulticityBookNowClick = () => {
    if (selectedMultiCity?.length - 1 <= multicityActiveIndex) {
      let flightDetails = JSON.parse(
        localStorage.getItem("flightDetails") || "{}"
      );

      flightDetails = {
        ...flightDetails,
        lastFlightSearchUrl: window.location.href,
      };
      localStorage.setItem("flightDetails", JSON.stringify(flightDetails));

      const priceIds = selectedMultiCity?.map((e: any) => e?.priceDetails?.id);

      const baseUrl = window.location.origin;
      const newTabUrl = `${baseUrl}/flights/reviewbooking?priceIds=${priceIds?.join(
        ","
      )}`;
      window.open(newTabUrl, "_blank");
    } else {
      dispatch(setMulticityActiveIndex(multicityActiveIndex + 1));
    }
  };

  return (
    <>
      {filterLoading && <FilterLoading />}
      <div className="listing-page-top">
        <div className="container">
          <FlightSearchSection />
        </div>
      </div>
      <div className={`listing-page`}>
        <div className="container">
          <div className="row">
            {!loading ? <FlightsFilter /> : <FilterSkeleton />}
            <FlightListingPart
              selectedMultiCity={selectedMultiCity}
              handleChange={handleChange}
              selectedOnward={selectedOnward}
              handleSelectFlight={handleSelectFlight}
              selectedReturn={selectedReturn}
            />
          </div>
        </div>
      </div>
      <PopularItems />
      {!loading &&
        filteredReturnFlights?.length > 0 &&
        selectedOnward?.route &&
        selectedReturn?.route && (
          <ReturnFlightsFooter
            selectedOnward={selectedOnward}
            selectedReturn={selectedReturn}
            totalOPrice={totalOPrice}
            totalRPrice={totalRPrice}
            handleBookNowClick={handleBookNowClick}
          />
        )}
      {!loading &&
        filteredMulticityFlights?.length > 0 &&
        selectedMultiCity.every(
          (flightDetails: any) => flightDetails?.route
        ) && (
          <MulticityFlightsFooter
            selectedMultiCity={selectedMultiCity}
            grandMulticityTotal={grandMulticityTotal}
            handleMulticityBookNowClick={handleMulticityBookNowClick}
          />
        )}
    </>
  );
};

export default FlightListing;
