import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../toast/Toasts";

export const createUser = createAsyncThunk(
  "User/InsertUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUser = createAsyncThunk(
  "User/UpdateUser",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateUser`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
