import { createSlice } from "@reduxjs/toolkit";
import { IStateState } from "./stateModel";
import { getAllActiveStates } from "./services/state.services";

const initialState: IStateState = {
  loading: false,
  error: "",
  StateList: [],
  StateID: null,
};

const userSlice = createSlice({
  name: "state",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllActiveStates.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllActiveStates.fulfilled, (state, action) => {
      state.loading = true;
      state.StateList = action.payload;
    });
    builder.addCase(getAllActiveStates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default userSlice.reducer;
