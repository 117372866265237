import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";

export const getAllActiveStates = createAsyncThunk(
  "State/FindAllActiveState",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_URL}/State/FindAllActiveState`
    );
    return response.data?.Data;
  }
);
