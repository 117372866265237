import React from "react";
import { Link } from "react-router-dom";
import BAG from "../../assets/images/upcoming-trip-bag.png";
import INDIGO from "../../assets/images/INDIGO.png";
import { useAppSelector } from "../../app/hooks";
import { getFlightImage } from "../../_helpers/helper";
import moment from "moment";

const UpcomingTrips = () => {
  const MytripsList = useAppSelector((state) => state.mytrips.MytripsList);
  return (
    <div
      className="tab-pane fade active show"
      id="pills-flight1"
      role="tabpanel"
      aria-labelledby="pills-flight-tab1"
    >
      {MytripsList && MytripsList.upcommings?.length > 0 ? (
        MytripsList.upcommings.map((item: any, index: any) => (
          <div
            className="route-timing triproute-timing flex-wrap gap-0 align-items-start"
            key={item.booking_no}
          >
            <div className="flight-choose w-25">
              <div className="fight-name">
                {/* need to change image here */}
                <img
                  src={getFlightImage(item?.airline_code) || INDIGO}
                  alt="airline"
                />
                <span>
                  <h5>{item?.airline_name}</h5>
                  <p>Booking ID - {item.booking_no}</p>
                  <strong className="trip-complete-btn">UpComing</strong>
                </span>
              </div>
            </div>
            <div className="route-strating w-25">
              <h4>{item?.from_place}</h4>
              {moment(item?.from_date_time, "MMM DD YYYY hh:mma").format(
                "ddd, DD MMMM’YY | HH:mm"
              )}
              <p>
                {/* <strong>{item?.from_place}, </strong> */}
                {item?.from_airport}
              </p>
            </div>
            <div className="route-strating route-arrival w-25">
              <h4>{item?.to_place}</h4>
              {moment(item?.to_date_time, "MMM DD YYYY hh:mma").format(
                "ddd, DD MMMM’YY | HH:mm"
              )}
              <p>
                {/* <strong>{item?.to_place}, </strong> */}
                {item?.to_airport}
              </p>
            </div>
            <div className="btn-trip-complte w-25">
              <Link
                to={`/flights/trip-details?bookingId=${item?.booking_no}`}
                target="_blank"
                className="fill-btn"
              >
                View Booking
              </Link>
              <div className="btn-trip-complte-invoice">
                <Link to="" className="btn-text">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 2V6C14 6.53043 14.2107 7.03914 14.5858 7.41421C14.9609 7.78929 15.4696 8 16 8H20M12 18V12M12 18L9 15M12 18L15 15M15 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7L15 2Z"
                      stroke="#08A34E"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Download Invoice
                </Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="upcoming-trips">
          <img src={BAG} alt="upcoming-bag" />
          <h3>Looks empty, you've no upcoming bookings.</h3>
          <p>When you book a trip, you will se your itinerary here.</p>
          <Link to={"/"} className="fill-btn">
            Plan a Trip
          </Link>
        </div>
      )}
    </div>
  );
};

export default UpcomingTrips;
