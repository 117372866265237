import { useEffect } from "react";
import AirPlane from "../../assets/images/airplane.png";
import HOTEL1 from "../../assets/images/hotel-1.png";
import Beach from "../../assets/images/beach.png";
import BANNER from "../../assets/images/BIG_SMILE_BANNER.jpg";
import BUS from "../../assets/images/travel_bus.png";
import FlightSearchSection from "../search/FlightSearchSection";
import { useLocation, useNavigate } from "react-router";

const Banner = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return () => {
      document.querySelector(".main-header")?.classList.remove("new-header");
      document.querySelector(".navbar-banner-tab")?.classList.remove("show");
    };
  }, []);

  return (
    <form className="banner-section">
      <div className="banner-tabbing">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                location.pathname === "/flights" || location.pathname === "/"
                  ? "active"
                  : ""
              }`}
              id="pills-flight-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-flight"
              type="button"
              role="tab"
              aria-controls="pills-flight"
              aria-selected="true"
              onClick={() => navigate("/flights")}
            >
              <img src={AirPlane} alt="" />
              Flight
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                location.pathname === "/hotels" ? "active" : ""
              }`}
              id="pills-flight-tab"
              // id="pills-hotel-tab"
              data-bs-toggle="pill"
              // data-bs-target="#pills-hotel"
              data-bs-target="#pills-flight"
              type="button"
              role="tab"
              aria-controls="pills-hotel"
              aria-selected="false"
              onClick={() => navigate("/hotels")}
            >
              <img src={HOTEL1} alt="" />
              Hotel
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                location.pathname === "/holidaypackages" ? "active" : ""
              }`}
              // id="pills-package-tab"
              id="pills-flight-tab"
              data-bs-toggle="pill"
              // data-bs-target="#pills-package"
              data-bs-target="#pills-flight"
              type="button"
              role="tab"
              aria-controls="pills-package"
              aria-selected="false"
              onClick={() => navigate("/holidaypackages")}
            >
              <img src={Beach} alt="" />
              Holiday Packages
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${
                location.pathname === "/buses" ? "active" : ""
              }`}
              // id="pills-package-tab"
              id="pills-flight-tab"
              data-bs-toggle="pill"
              // data-bs-target="#pills-package"
              data-bs-target="#pills-flight"
              type="button"
              role="tab"
              aria-controls="pills-buses"
              aria-selected="false"
              onClick={() => navigate("/buses")}
            >
              <img src={BUS} alt="" />
              Buses
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-flight"
            role="tabpanel"
            aria-labelledby="pills-flight-tab"
          >
            {location.pathname === "/flights" ? (
              <FlightSearchSection />
            ) : location.pathname === "/hotels" ? (
              <h4 className="text-center">Hotel Work in progress</h4>
            ) : location.pathname === "/holidaypackages" ? (
              <h4 className="text-center">Holiday Packages Work in progress</h4>
            ) : location.pathname === "/buses" ? (
              <h4 className="text-center">Buses Work in progress</h4>
            ) : (
              <FlightSearchSection />
            )}
            {/* <FlightSearchSection /> */}
          </div>
        </div>
      </div>
      <div className="banner-pic">
        <img src={BANNER} alt="" />
      </div>
    </form>
  );
};

export default Banner;
