import { createSlice } from "@reduxjs/toolkit";
import { ICountryState } from "./countryModel";
import { getAllActiveCountries } from "./services/country.services";

const initialState: ICountryState = {
  loading: false,
  error: "",
  CountryList: [],
  CountryID: null,
};

const userSlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllActiveCountries.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllActiveCountries.fulfilled, (state, action) => {
      state.loading = true;
      state.CountryList = action.payload;
    });
    builder.addCase(getAllActiveCountries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default userSlice.reducer;
