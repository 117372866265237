import { useEffect } from "react";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import PLANE from "../../assets/images/plane.png";
import PHONE from "../../assets/images/phone.png";
import MAIL from "../../assets/images/mail.png";
import { getBookingDetails } from "./services/mytrips.services";
import {
  formatIndianNumber,
  formatMinToHRAndMin,
  getFlightImage,
} from "../../_helpers/helper";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../components/spinner/Loading";
import useWindowSize from "../../_hooks/useWindowSize";

const TestBookingDetails = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const loading = useAppSelector((state) => state?.mytrips?.loading);
  const location = useLocation();

  const BookingDetails = useAppSelector(
    (state) => state?.mytrips?.BookinDetails
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const bookingId = params.get("bookingId");
    dispatch(
      getBookingDetails({
        bookingId: bookingId,
        requirePaxPricing: true,
      })
    );
  }, []);

  return (
    <>
      <Loading loading={loading} backgroundColor="white" />
      {!loading && (
        <div className="Booking-page">
          <div className="container">
            <h3>Booking Details</h3>
            <div className="booking-confirmationbar">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="flight-indcator-id trip-details-flight-indicator">
                    <h5 className="m-0">
                      Booking ID:{" "}
                      <strong>
                        {BookingDetails && BookingDetails?.order?.bookingId}
                      </strong>
                    </h5>
                    <p>
                      <strong>Booked on: </strong>
                      {BookingDetails &&
                        moment(BookingDetails?.order?.createdOn).format(
                          "DD/MM/YYYY | hh:mm:ss A"
                        )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-7 col-sm-12">
                <div className="booking-page-right travel-detail-new">
                  <h3>Flight Details</h3>
                  <div className="fligt-confirm">
                    <div className="booking-timing">
                      <div className="booking-flight-half">
                        {BookingDetails &&
                          BookingDetails?.itemInfos?.AIR?.tripInfos?.map(
                            (tripInfo: any, tripIndex: any) => {
                              return (
                                <div
                                  key={tripIndex}
                                  className={`${
                                    tripIndex + 1 <
                                    BookingDetails?.itemInfos?.AIR?.tripInfos
                                      ?.length
                                      ? "flight-review-card"
                                      : ""
                                  }`}
                                >
                                  <h4>
                                    {tripInfo?.sI[0]?.da?.city} →{" "}
                                    {
                                      tripInfo?.sI[tripInfo?.sI?.length - 1]?.aa
                                        ?.city
                                    }
                                  </h4>
                                  {tripInfo?.sI?.map(
                                    (item: any, index: any) => (
                                      <div
                                        className="booking-timimg-main"
                                        key={index}
                                      >
                                        <h5>Flight {index + 1}</h5>
                                        <div className="route-timing border-0 p-0">
                                          <div className="flight-choose">
                                            <div className="fight-name">
                                              <img
                                                src={getFlightImage(
                                                  item?.fD?.aI?.code
                                                )}
                                                // src={getFlightImage(
                                                //   item?.fD?.aI?.name
                                                // )}
                                                alt=""
                                              />
                                              <span>
                                                <h5>{item?.fD?.aI?.name}</h5>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="route-strating">
                                            {moment(item?.dt).format("HH:mm")}
                                            <p>
                                              <strong>
                                                {item?.da?.code},{" "}
                                              </strong>
                                              {item?.da?.name}
                                            </p>
                                          </div>
                                          <div className="cunsume-time">
                                            <p>
                                              Non Stop |{" "}
                                              <strong>
                                                {formatMinToHRAndMin(
                                                  item?.duration
                                                )}
                                              </strong>
                                            </p>
                                            <div className="cunsume-pic">
                                              <img src={PLANE} alt="plane" />
                                            </div>
                                            <p>
                                              {" "}
                                              {moment(item?.dt).format(
                                                "dddd, DD MMM’YY"
                                              )}
                                            </p>
                                          </div>
                                          <div className="route-strating route-arrival">
                                            {moment(item?.at).format("HH:mm")}
                                            <p>
                                              <strong>
                                                {item?.aa?.code},{" "}
                                              </strong>
                                              {item?.aa?.name}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="mobile-timimg">
                                          <div className="cunsume-time">
                                            <p>
                                              Non Stop |{" "}
                                              <strong>
                                                {formatMinToHRAndMin(
                                                  item?.duration
                                                )}
                                              </strong>
                                            </p>
                                            <p>
                                              {moment(item?.dt).format(
                                                "dddd, DD MMM’YY"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="travel-contact">
                    <div className="traveller-list">
                      <h4>Traveller Details</h4>
                      {BookingDetails &&
                        BookingDetails?.itemInfos?.AIR?.travellerInfos?.map(
                          (traveller: any, index: any) => (
                            <div className="sidebar-content" key={index}>
                              <span>
                                {`${index + 1}. ${traveller?.ti} ${
                                  traveller?.fN
                                } ${traveller?.lN}`}
                              </span>
                              <div className="sidebar-left" />
                              <div className="sidebar-right">
                                <span>{traveller?.pt}</span>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                    <div className="traveller-contact-detail traveller-list">
                      <h4>Contact Detail</h4>
                      {BookingDetails &&
                        BookingDetails?.order?.deliveryInfo?.emails?.map(
                          (e: any) => (
                            <Link to={`mailto:${e}`}>
                              <img src={MAIL} alt="e-mail" />
                              {e}
                            </Link>
                          )
                        )}
                      {BookingDetails &&
                        BookingDetails?.order?.deliveryInfo?.contacts?.map(
                          (e: any) => (
                            <Link to={`tel:++91 ${e}`}>
                              <img src={PHONE} alt="phone" />
                              {e}
                            </Link>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-12">
                <h3>Fare Details</h3>
                <div
                  className="listing-sidebar booking-sidebar border-0"
                  style={{ top: width <= 991 ? 0 : 80 }}
                >
                  <div className="sidebar-content ">
                    <div className="sidebar-left">
                      <h5>Base Fair</h5>
                    </div>
                    <div className="sidebar-right">
                      <h5>
                        {BookingDetails &&
                          `₹${formatIndianNumber(
                            BookingDetails?.itemInfos?.AIR?.totalPriceInfo
                              ?.totalFareDetail?.fC?.BF
                          )}`}
                      </h5>
                    </div>
                  </div>
                  <div className="sidebar-content ">
                    <div className="sidebar-left">
                      <h5>Taxes & Fees</h5>
                    </div>
                    <div className="sidebar-right">
                      <h5>
                        {BookingDetails &&
                          `₹${formatIndianNumber(
                            BookingDetails?.itemInfos?.AIR?.totalPriceInfo
                              ?.totalFareDetail?.fC?.TAF
                          )}`}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="sidebar-content siderbar-total"
                    style={{
                      borderTop: "1px dashed rgb(0 0 0 / 20%)",
                      padding: "10px 0",
                    }}
                  >
                    <div className="sidebar-left">
                      <h5>
                        <strong>Grand Total</strong>
                      </h5>
                    </div>
                    <div className="sidebar-right">
                      <h5>
                        <strong>
                          {BookingDetails &&
                            `₹${formatIndianNumber(
                              BookingDetails?.itemInfos?.AIR?.totalPriceInfo
                                ?.totalFareDetail?.fC?.TF
                            )}`}
                        </strong>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TestBookingDetails;
