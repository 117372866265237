import HeaderLogo from "../../../assets/images/big smile logo-01.png";
import USERPNG from "../../../assets/images/user.png";
import USER2PNG from "../../../assets/images/user-2.png";
import USERMOBILE from "../../../assets/images/user-mobile.png";
import TRAVEL from "../../../assets/images/travel.png";
import PAYMENT from "../../../assets/images/payment.png";
import WALLET from "../../../assets/images/wallet.png";
import { useAppSelector } from "../../../app/hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AirPlane from "../../../assets/images/airplane.png";
import HOTEL1 from "../../../assets/images/hotel-1.png";
import Beach from "../../../assets/images/beach.png";
import BUS from "../../../assets/images/travel_bus.png";
import { STICKYHEADERPATHS } from "../../../_constants/constant";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useAppSelector((state) => state.login.userDetails);

  return (
    <header className={`main-header ${STICKYHEADERPATHS?.includes(location.pathname) ? "position-fixed" : "" }`}>
      <div className="container position-relative">
        <a className="logo" href="/">
          <img src={HeaderLogo} alt="" height={51}/>
        </a>

        <div className="navbar-banner-tab">
          <ul
            className="nav nav-pills mb-3"
            id="pills-tab"
            role="tablist"
            style={{ height: 51 }}
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  location.pathname === "/flights" ||
                  location.pathname === "/" ||
                  location?.pathname?.split("/")?.filter(Boolean)[0] ===
                    "flights"
                    ? "active"
                    : ""
                }`}
                id="pills-flight-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-flight"
                type="button"
                role="tab"
                aria-controls="pills-flight"
                aria-selected="true"
                onClick={() => navigate("/flights")}
              >
                <img src={AirPlane} alt="" />
                Flight
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  location.pathname === "/hotels" ||
                  location?.pathname?.split("/")?.filter(Boolean)[0] ===
                    "hotels"
                    ? "active"
                    : ""
                }`}
                id="pills-flight-tab"
                // id="pills-hotel-tab"
                data-bs-toggle="pill"
                // data-bs-target="#pills-hotel"
                data-bs-target="#pills-flight"
                type="button"
                role="tab"
                aria-controls="pills-hotel"
                aria-selected="false"
                onClick={() => navigate("/hotels")}
              >
                <img src={HOTEL1} alt="" />
                Hotel
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  location.pathname === "/holidaypackages" ||
                  location?.pathname?.split("/")?.filter(Boolean)[0] ===
                    "holidaypackages"
                    ? "active"
                    : ""
                }`}
                // id="pills-package-tab"
                id="pills-flight-tab"
                data-bs-toggle="pill"
                // data-bs-target="#pills-package"
                data-bs-target="#pills-flight"
                type="button"
                role="tab"
                aria-controls="pills-package"
                aria-selected="false"
                onClick={() => navigate("/holidaypackages")}
              >
                <img src={Beach} alt="" />
                Holiday Packages
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  location.pathname === "/buses" ||
                  location?.pathname?.split("/")?.filter(Boolean)[0] === "buses"
                    ? "active"
                    : ""
                }`}
                // id="pills-package-tab"
                id="pills-flight-tab"
                data-bs-toggle="pill"
                // data-bs-target="#pills-package"
                data-bs-target="#pills-flight"
                type="button"
                role="tab"
                aria-controls="pills-buses"
                aria-selected="false"
                onClick={() => navigate("/buses")}
              >
                <img src={BUS} alt="" />
                Buses
              </button>
            </li>
          </ul>
        </div>

        {userDetails?.firstName ? (
          <button className="fill-btn btn-right">
            <img src={USERPNG} alt="" />
            {"Hi, " + userDetails?.firstName}
          </button>
        ) : (
          <button
            className="fill-btn"
            data-bs-toggle="modal"
            data-bs-target="#loginModal"
          >
            <img src={USERPNG} alt="" />
            {"login or Signup"}
          </button>
        )}
        <div className="menu">
          <ul>
            <li>
              <h6>Hey {userDetails?.firstName}</h6>
              <p className="light">Get exclusive deals & Manage your trips</p>
            </li>
            <li>
              <Link to="/my-profile">
                <div className="menu-pic">
                  <img src={USER2PNG} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">My profile</p>
                  <p className="light">
                    Manage your profile, traveller details,login details and
                    pasword
                  </p>
                </div>
              </Link>
            </li>
            <li>
              <Link to={"/flights/mytrips"}>
                <div className="menu-pic">
                  <img src={TRAVEL} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">My Trip</p>
                  <p className="light">
                    See booking details,prin e-ticket,Cancel booking,modify
                    booking check refund status& more
                  </p>
                </div>
              </Link>
            </li>
            <li>
              <a href="javascript:void(0)">
                <div className="menu-pic">
                  <img src={WALLET} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">My Wallet</p>
                  <p className="light">
                    Use your wallet money to avail even greater discount
                  </p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <div className="menu-pic">
                  <img src={PAYMENT} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">Make a payment</p>
                  <p className="light">Complete your pending payment here</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
        {userDetails?.firstName ? (
          <a className="Mobile-size btn-mobile-right">
            <img src={USERMOBILE} alt="" />
            {"Hi, " + userDetails?.firstName}
          </a>
        ) : (
          <a
            className="Mobile-size"
            data-bs-toggle="modal"
            data-bs-target="#loginModal"
            href="javascript:void(0)"
          >
            <img src={USERMOBILE} alt="" />
            {"login or Signup"}
          </a>
        )}
        <div className="mobile-menu">
          <ul>
            <li>
              <h6>Hey {userDetails?.firstName}</h6>
              <p className="light">Get exclusive deals & Manage your trips</p>
            </li>
            <li>
              <Link to="/profile">
                <div className="menu-pic">
                  <img src={USER2PNG} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">My profile</p>
                  <p className="light">
                    Manage your profile, traveller details,login details and
                    pasword
                  </p>
                </div>
              </Link>
            </li>
            <li>
              <Link to={"/flights/mytrips"}>
                <div className="menu-pic">
                  <img src={TRAVEL} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">My Trip</p>
                  <p className="light">
                    See booking details,prin e-ticket,Cancel booking,modify
                    booking check refund status& more
                  </p>
                </div>
              </Link>
            </li>
            <li>
              <a href="javascript:void(0)">
                <div className="menu-pic">
                  <img src={WALLET} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">My Wallet</p>
                  <p className="light">
                    Use your wallet money to avail even greater discount
                  </p>
                </div>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <div className="menu-pic">
                  <img src={PAYMENT} alt="" />
                </div>
                <div className="menu-content">
                  <p className="bold">Make a payment</p>
                  <p className="light">Complete your pending payment here</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
