import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loading from "../../components/spinner/Loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getLocalItem } from "../../_helpers/helper";
import { MdOutlineEdit } from "react-icons/md";
import {
  getBillingDetails,
  updateUserBillingDetails,
} from "./services/myprofile.services";

const BillingDetails = () => {
  const dispatch = useAppDispatch();
  const billingLoading = useAppSelector(
    (state) => state.profile.billingLoading
  );
  const billingData = useAppSelector((state) => state.profile.billingData);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CityList = useAppSelector((state) => state.city.CityList);
  const closeBtnRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getBillingDetails({ user_guid: getLocalItem("user_guid") }));
  }, []);

  const validationSchema = Yup.object({
    billing_pan_card_no: Yup.string()
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Invalid PAN card number")
      .required("PAN card number is required"),
    billing_pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, "Invalid pincode")
      .required("Pincode is required"),
    billing_gst_no: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid GST number"
      )
      .required("GST number is required"),
  });

  const formik = useFormik({
    initialValues: {
      billing_address: billingData?.billing_address || "",
      billing_state_id: billingData?.billing_state_id || "",
      billing_city_id: billingData?.billing_city_id || "",
      billing_pan_card_no: billingData?.billing_pan_card_no || "",
      billing_pincode: billingData?.billing_pincode || "",
      billing_gst_no: billingData?.billing_gst_no || "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const payload = {
        billing_city_id: values?.billing_city_id
          ? +values?.billing_city_id
          : null,
        billing_state_id: values?.billing_state_id
          ? +values?.billing_state_id
          : null,
        billing_address: values?.billing_address ? values?.billing_address : "",
        billing_pan_card_no: values?.billing_pan_card_no
          ? values?.billing_pan_card_no
          : "",
        billing_pincode: values?.billing_pincode ? values?.billing_pincode : "",
        billing_gst_no: values?.billing_gst_no ? values?.billing_gst_no : "",
        user_guid: getLocalItem("user_guid"),
        isactive: true,
      };
      const response = await dispatch(updateUserBillingDetails(payload));
      if (response.meta.requestStatus === "fulfilled") {
        setSubmitting(false);
        resetForm();
        if (closeBtnRef.current) {
          closeBtnRef.current.click();
        }
        dispatch(getBillingDetails({ user_guid: getLocalItem("user_guid") }));
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className="position-relative">
      <Loading loading={billingLoading} />
      <div className="profile-heading">
        <div className="prfile-name">
          <h2>Billing Information</h2>
          <p>
            As per government directive Billing address is compulsory for all
            bookings.
          </p>
        </div>
        <form className="add-travel">
          <a
            className="travel-btn"
            data-bs-toggle="modal"
            data-bs-target="#billingform"
            href="javascript:void(0)"
          >
            <MdOutlineEdit /> Edit
          </a>
          <div
            className="modal fade"
            id="billingform"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="edit-profile"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fs-5" id="edit-profile">
                    Edit Billing Details
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body travel-form-detail">
                  <div className="travel-form">
                    <form>
                      <div className="field-set">
                        <div className="field">
                          <label
                            className={
                              formik.touched.billing_gst_no &&
                              formik.errors.billing_gst_no
                                ? "error-label"
                                : ""
                            }
                          >
                            GST No <code>*</code>
                          </label>
                          <input
                            type="text"
                            name="billing_gst_no"
                            value={formik.values.billing_gst_no}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.billing_gst_no &&
                              formik.errors.billing_gst_no
                                ? "error-border"
                                : ""
                            }
                          />
                          {formik.touched.billing_gst_no &&
                          formik.errors.billing_gst_no &&
                          typeof formik.errors.billing_gst_no === "string" ? (
                            <div className="error-label">
                              {formik.errors.billing_gst_no}
                            </div>
                          ) : null}
                        </div>
                        <div className="field">
                          <label
                            className={
                              formik.touched.billing_pan_card_no &&
                              formik.errors.billing_pan_card_no
                                ? "error-label"
                                : ""
                            }
                          >
                            Pancard No <code>*</code>
                          </label>
                          <input
                            type="text"
                            name="billing_pan_card_no"
                            value={formik.values.billing_pan_card_no}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.billing_pan_card_no &&
                              formik.errors.billing_pan_card_no
                                ? "error-border"
                                : ""
                            }
                          />
                          {formik.touched.billing_pan_card_no &&
                          formik.errors.billing_pan_card_no &&
                          typeof formik.errors.billing_pan_card_no ===
                            "string" ? (
                            <div className="error-label">
                              {formik.errors.billing_pan_card_no}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="field-set">
                        <div className="field">
                          <label
                          // className={
                          //   formik.touched.billing_address &&
                          //   formik.errors.billing_address
                          //     ? "error-label"
                          //     : ""
                          // }
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            name="billing_address"
                            value={formik.values.billing_address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // className={
                            //   formik.touched.billing_address &&
                            //   formik.errors.billing_address
                            //     ? "error-border"
                            //     : ""
                            // }
                          />
                          {/* {formik.touched.billing_address &&
                          formik.errors.billing_address &&
                          typeof formik.errors.billing_address === "string" ? (
                            <div className="error-label">
                              {formik.errors.billing_address}
                            </div>
                          ) : null} */}
                        </div>
                        <div className="field">
                          <label
                          // className={
                          //   formik.touched.billing_city_id &&
                          //   formik.errors.billing_city_id
                          //     ? "error-label"
                          //     : ""
                          // }
                          >
                            City
                          </label>
                          <select
                            name="billing_city_id"
                            value={formik.values.billing_city_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // className={
                            //   formik.touched.billing_city_id &&
                            //   formik.errors.billing_city_id
                            //     ? "error-border"
                            //     : ""
                            // }
                          >
                            <option value="">Select City</option>
                            {CityList?.map((e) => (
                              <option key={e?.id} value={e?.id}>
                                {e?.city_name}
                              </option>
                            ))}
                          </select>
                          {/* {formik.touched.billing_city_id &&
                          formik.errors.billing_city_id &&
                          typeof formik.errors.billing_city_id === "string" ? (
                            <div className="error-label">
                              {formik.errors.billing_city_id}
                            </div>
                          ) : null} */}
                        </div>
                      </div>
                      <div className="field-set">
                        <div className="field">
                          <label
                          // className={
                          //   formik.touched.billing_state_id &&
                          //   formik.errors.billing_state_id
                          //     ? "error-label"
                          //     : ""
                          // }
                          >
                            State
                          </label>
                          <select
                            name="billing_state_id"
                            value={formik.values.billing_state_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            // className={
                            //   formik.touched.billing_state_id &&
                            //   formik.errors.billing_state_id
                            //     ? "error-border"
                            //     : ""
                            // }
                          >
                            <option value="">Select State</option>
                            {StateList?.map((e) => (
                              <option key={e?.id} value={e?.id}>
                                {e?.state_name}
                              </option>
                            ))}
                          </select>
                          {/* {formik.touched.billing_state_id &&
                          formik.errors.billing_state_id &&
                          typeof formik.errors.billing_state_id === "string" ? (
                            <div className="error-label">
                              {formik.errors.billing_state_id}
                            </div>
                          ) : null} */}
                        </div>
                        <div className="field">
                          <label
                            className={
                              formik.touched.billing_pincode &&
                              formik.errors.billing_pincode
                                ? "error-label"
                                : ""
                            }
                          >
                            Pincode <code>*</code>
                          </label>
                          <input
                            type="text"
                            name="billing_pincode"
                            value={formik.values.billing_pincode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.billing_pincode &&
                              formik.errors.billing_pincode
                                ? "error-border"
                                : ""
                            }
                          />
                          {formik.touched.billing_pincode &&
                          formik.errors.billing_pincode &&
                          typeof formik.errors.billing_pincode === "string" ? (
                            <div className="error-label">
                              {formik.errors.billing_pincode}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="travel-detail-btns mt-2">
                    <a
                      className="disable"
                      href="javascript:void(0)"
                      data-bs-dismiss="modal"
                      ref={closeBtnRef}
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={(event) => {
                        event.preventDefault();
                        formik.handleSubmit();
                      }}
                    >
                      Save
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="travel-table">
        <table>
          <tbody>
            <tr>
              <td className="w-40">GST No</td>
              <td>
                {billingData?.billing_gst_no ? (
                  <span>{billingData?.billing_gst_no}</span>
                ) : (
                  <span
                    className="profile-add-span"
                    data-bs-toggle="modal"
                    data-bs-target="#billingform"
                  >
                    + Add
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>Pancard No</td>
              <td>
                {billingData?.billing_pan_card_no ? (
                  <span>{billingData?.billing_pan_card_no}</span>
                ) : (
                  <span
                    className="profile-add-span"
                    data-bs-toggle="modal"
                    data-bs-target="#billingform"
                  >
                    + Add
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                {billingData?.billing_address ? (
                  <span>{billingData?.billing_address}</span>
                ) : (
                  <span
                    className="profile-add-span"
                    data-bs-toggle="modal"
                    data-bs-target="#billingform"
                  >
                    + Add
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>City</td>
              <td>
                {billingData?.billing_city_name ? (
                  <span>{billingData?.billing_city_name}</span>
                ) : (
                  <span
                    className="profile-add-span"
                    data-bs-toggle="modal"
                    data-bs-target="#billingform"
                  >
                    + Add
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>State</td>
              <td>
                {billingData?.billing_state_name ? (
                  <span>{billingData?.billing_state_name}</span>
                ) : (
                  <span
                    className="profile-add-span"
                    data-bs-toggle="modal"
                    data-bs-target="#billingform"
                  >
                    + Add
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>Pincode</td>
              <td>
                {billingData?.billing_pincode ? (
                  <span>{billingData?.billing_pincode}</span>
                ) : (
                  <span
                    className="profile-add-span"
                    data-bs-toggle="modal"
                    data-bs-target="#billingform"
                  >
                    + Add
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BillingDetails;
