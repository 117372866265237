import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getLocalItem } from "../../_helpers/helper";
import {
  emailVerificationMail,
  getLoginDetailsByID,
  updateUserEmail,
} from "./services/myprofile.services";
import Loading from "../../components/spinner/Loading";
import { VscVerifiedFilled } from "react-icons/vsc";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaEdit } from "react-icons/fa";

const LoginDetails = () => {
  const dispatch = useAppDispatch();
  const loginData = useAppSelector((state) => state.profile.loginData);
  const loginLoading = useAppSelector((state) => state.profile.loginLoading);
  const emailverificationLoading = useAppSelector(
    (state) => state.profile.emailverificationLoading
  );
  const closeBtnRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getLoginDetailsByID({ user_guid: getLocalItem("user_guid") }));
  }, []);

  const formik = useFormik({
    initialValues: {
      email_id: loginData?.email_id || "",
    },
    validationSchema: Yup.object({
      email_id: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const payload = {
        email_id: values?.email_id ? values?.email_id : "",
        user_guid: getLocalItem("user_guid"),
      };
      const response = await dispatch(updateUserEmail(payload));
      if (response.meta.requestStatus === "fulfilled") {
        setSubmitting(false);
        resetForm();
        if (closeBtnRef.current) {
          closeBtnRef.current.click();
        }
        dispatch(getLoginDetailsByID({ user_guid: getLocalItem("user_guid") }));
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className="position-relative">
      <Loading loading={loginLoading} />
      <div className="profile-heading">
        <div className="prfile-name">
          <h2>Login Details</h2>
          <p>Manage your email address and mobile number</p>
        </div>
        <form className="add-travel">
          <div
            className="modal fade"
            id="emailform"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="edit-profile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              style={{ maxWidth: 450 }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fs-5" id="edit-profile">
                    Add Your Email ID
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body travel-form-detail">
                  <div className="travel-form">
                    <form>
                      <div className="field w-100">
                        <label
                          className={
                            formik.touched.email_id && formik.errors.email_id
                              ? "error-label"
                              : ""
                          }
                        >
                          Email ID <code>*</code>
                        </label>
                        <input
                          type="email"
                          name="email_id"
                          value={formik.values.email_id}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.touched.email_id && formik.errors.email_id
                              ? "error-border"
                              : ""
                          }
                        />
                        {formik.touched.email_id &&
                        formik.errors.email_id &&
                        typeof formik.errors.email_id === "string" ? (
                          <div className="error-label">
                            {formik.errors.email_id}
                          </div>
                        ) : null}
                      </div>
                    </form>
                  </div>
                  <div className="travel-detail-btns mt-4">
                    <a
                      className="disable"
                      href="javascript:void(0)"
                      data-bs-dismiss="modal"
                      ref={closeBtnRef}
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() => formik.handleSubmit()}
                    >
                      Save
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="travel-table">
        <table>
          <tbody>
            <tr>
              <td className="w-40">Mobile Number</td>
              <td>
                <div className="profile-email-wrapper">
                  <span>{loginData?.mobile_no}</span>
                  <span className="email-verified">
                    <VscVerifiedFilled /> Verified
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Email ID</td>
              <td>
                {loginData?.email_id ? (
                  <div className="profile-email-wrapper">
                    <span className="loggedin-email">
                      {loginData?.email_id}{" "}
                      <FaEdit
                        className="edit-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#emailform"
                      />
                    </span>
                    {loginData?.is_email_verified ? (
                      <span className="email-verified">
                        <VscVerifiedFilled /> Verified
                      </span>
                    ) : (
                      <>
                        {!emailverificationLoading ? (
                          <a
                            href="javascript:void(0)"
                            className="verify-email"
                            onClick={() => {
                              dispatch(
                                emailVerificationMail({
                                  user_guid: getLocalItem("user_guid"),
                                })
                              );
                            }}
                          >
                            click here to verify
                          </a>
                        ) : (
                          <span>email sending...</span>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <span
                    className="profile-add-span"
                    data-bs-toggle="modal"
                    data-bs-target="#emailform"
                  >
                    + Add your Email ID
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoginDetails;
