import Loading from "../../components/spinner/Loading";
import DEVICE from "../../assets/images/device.png";
import MOBILE from "../../assets/images/mobile.png";
import { capitalizeFirstLetter, getDeviceId } from "../../_helpers/helper";
import { useAppSelector } from "../../app/hooks";
import moment from "moment";

const LoggedinDevicesCard = () => {
  const LoggedInDeviceList = useAppSelector(
    (state) => state.profile.LoggedInDeviceList
  );
  const loggedInDevicesLoading = useAppSelector(
    (state) => state.profile.loggedInDevicesLoading
  );

  const device_id = getDeviceId();
  return (
    <div className="position-relative">
      <Loading loading={loggedInDevicesLoading} />
      <div className="profile-heading">
        <div className="prfile-name">
          <h2>Logged In Devices</h2>
          <p>Check all the devices where your account is logged in</p>
        </div>
      </div>
      <table>
        <tbody>
          {LoggedInDeviceList?.map((e: any) => (
            <tr>
              <td>
                <div>
                  <p>
                    <img
                      src={e?.device_type === "desktop" ? DEVICE : MOBILE}
                      alt=""
                    />
                  </p>
                  <p>
                    <span>
                      {capitalizeFirstLetter(e?.device_type)}{" "}
                      {e?.browser_version ? "Web" : "App"}
                    </span>
                    <span className="color-da">
                      {device_id === e?.device_id ? " (Current Device)" : ""}
                    </span>
                    <br />
                    {e?.browser_version && (
                      <>
                        {e?.browser_version} <br />
                      </>
                    )}
                    Logged in since{" "}
                    {moment(e?.activity_date).format("h:mm A, Do MMM YY")}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LoggedinDevicesCard;
