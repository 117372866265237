import React from "react";
import {
  getFieldError,
  getSelectedItems,
  isFormikErrorsArray,
} from "../../_helpers/helper";
import CONTACT from "../../assets/images/contact.png";
import { FieldArray, FormikProps } from "formik";
import { useAppSelector } from "../../app/hooks";
import { IChild, IReviewBookingDetails } from "./reviewbookingModel";
import { INITIALS } from "../../_constants/constant";
import moment from "moment";

interface ChildFormProps {
  formik: FormikProps<IReviewBookingDetails>;
  isPIDRequired: Boolean;
  isPPEDRequired: Boolean;
  isPNRequired: Boolean;
  isCDOBRRequired: Boolean;
  handleCheckboxChange: (
    type: "adults" | "children" | "infants",
    flightDetailsKey: "ADULT" | "CHILD" | "INFANT",
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const ChildForm = ({
  formik,
  handleCheckboxChange,
  isPIDRequired,
  isPPEDRequired,
  isPNRequired,
  isCDOBRRequired,
}: ChildFormProps) => {
  const flightReviewDetails = useAppSelector(
    (state) => state.reviewbooking.flightReviewDetails
  );

  const reviewBookingDetails = useAppSelector(
    (state) => state.reviewbooking.reviewBookingDetails
  );

  const CountryList = useAppSelector((state) => state.country.CountryList);

  const paxDetails = flightReviewDetails?.searchQuery?.paxInfo;

  const CHILD = paxDetails?.CHILD || 0;
  return (
    <div className="adult-form">
      <div className="traveller-details-title">
        <p className="m-0">
          <img src={CONTACT} /> CHILD (2-12 Yrs)
          <span className="m-0 tarveller-error-message">
            {formik.errors?.childrenLimit}
          </span>
        </p>
        <strong>
          {getSelectedItems(formik.values?.children)?.length}/{CHILD} added
        </strong>
      </div>
      <span className="note">
        <strong>Important: </strong>Enter name as mentioned on your passport or
        Government approved IDs.
      </span>
      <FieldArray name="children">
        {({ push, remove }) => (
          <>
            {formik.values.children.map((child, index) => (
              <div className="child-form" key={index}>
                <div>
                  <input
                    className="form-check"
                    type="checkbox"
                    name={`children[${index}].isSelected`}
                    checked={formik.values.children[index].isSelected}
                    onChange={(e) =>
                      handleCheckboxChange("children", "CHILD", index, e)
                    }
                  />
                  <h5>
                    {reviewBookingDetails?.children[index]?.first_name
                      ? `${formik.values.children[index]?.first_name} ${formik.values.children[index]?.last_name}`
                      : `Child ${
                          index -
                          (getSelectedItems(
                            reviewBookingDetails?.children,
                            "first_name"
                          )?.length -
                            1)
                        }`}
                  </h5>
                </div>
                {formik.values.children[index].isSelected && (
                  <div className="form">
                    <div className="field-set align-end">
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.initial &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "initial"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          Initial <code>*</code>
                        </label>
                        <select
                          name={`children[${index}].initial`}
                          value={formik.values.children[index].initial}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.initial &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "initial"
                            )
                              ? "error-border py-0"
                              : "py-0"
                          }
                        >
                          <option value="" hidden selected>
                            Select Initial
                          </option>
                          {INITIALS?.map((e: string) => (
                            <option value={e}>{e}</option>
                          ))}
                        </select>
                        {isFormikErrorsArray<IChild>(formik.errors.children) &&
                          isFormikErrorsArray<IChild>(
                            formik.touched.children
                          ) &&
                          formik.touched.children[index]?.initial &&
                          getFieldError<IChild>(
                            formik.errors.children,
                            index,
                            "initial"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "initial"
                              )}
                            </div>
                          )}
                      </div>
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.first_name &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "first_name"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          First Name <code>*</code>
                        </label>
                        <input
                          type="text"
                          name={`children[${index}].first_name`}
                          onChange={formik.handleChange}
                          value={formik.values.children[index].first_name}
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.first_name &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "first_name"
                            )
                              ? "error-border"
                              : ""
                          }
                        />
                        {isFormikErrorsArray<IChild>(formik.errors.children) &&
                          isFormikErrorsArray<IChild>(
                            formik.touched.children
                          ) &&
                          formik.touched.children[index]?.first_name &&
                          getFieldError<IChild>(
                            formik.errors.children,
                            index,
                            "first_name"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "first_name"
                              )}
                            </div>
                          )}
                      </div>
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.last_name &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "last_name"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          Last Name <code>*</code>
                        </label>
                        <input
                          type="text"
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.last_name &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "last_name"
                            )
                              ? "error-border"
                              : ""
                          }
                          name={`children[${index}].last_name`}
                          onChange={formik.handleChange}
                          value={formik.values.children[index].last_name}
                        />
                        {isFormikErrorsArray<IChild>(formik.errors.children) &&
                          isFormikErrorsArray<IChild>(
                            formik.touched.children
                          ) &&
                          formik.touched.children[index]?.last_name &&
                          getFieldError<IChild>(
                            formik.errors.children,
                            index,
                            "last_name"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "last_name"
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="field-set">
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.date_of_birth &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "date_of_birth"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          Date of Birth {isCDOBRRequired && <code>*</code>}
                        </label>
                        <input
                          type="date"
                          name={`children[${index}].date_of_birth`}
                          max={moment()
                            .subtract(2, "years")
                            .format("YYYY-MM-DD")}
                          min={moment()
                            .subtract(12, "years")
                            .format("YYYY-MM-DD")}
                          onChange={formik.handleChange}
                          value={formik.values.children[index].date_of_birth}
                          className={
                            isFormikErrorsArray<IChild>(
                              formik.errors.children
                            ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.date_of_birth &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "date_of_birth"
                            )
                              ? "error-border"
                              : ""
                          }
                        />
                        {isFormikErrorsArray<IChild>(formik.errors.children) &&
                          isFormikErrorsArray<IChild>(
                            formik.touched.children
                          ) &&
                          formik.touched.children[index]?.date_of_birth &&
                          getFieldError<IChild>(
                            formik.errors.children,
                            index,
                            "date_of_birth"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "date_of_birth"
                              )}
                            </div>
                          )}
                      </div>
                      {isPNRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]?.passport_no &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "passport_no"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Passport No {isPNRequired && <code>*</code>}
                          </label>
                          <input
                            type="text"
                            name={`children[${index}].passport_no`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.children[index].passport_no}
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]?.passport_no &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "passport_no"
                              )
                                ? "error-border"
                                : ""
                            }
                          />
                          {isFormikErrorsArray<IChild>(
                            formik.errors.children
                          ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.passport_no &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "passport_no"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IChild>(
                                  formik.errors.children,
                                  index,
                                  "passport_no"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                      {isPNRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]
                                ?.issue_country_code &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "issue_country_code"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Issue Country {isPNRequired && <code>*</code>}
                          </label>
                          <select
                            name={`children[${index}].issue_country_code`}
                            value={
                              formik.values.children[index].issue_country_code
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]
                                ?.issue_country_code &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "issue_country_code"
                              )
                                ? "error-border py-0"
                                : "py-0"
                            }
                          >
                            <option value="" hidden selected>
                              Select Country
                            </option>
                            {CountryList?.map((e) => (
                              <option
                                key={e?.country_code}
                                value={e?.country_code}
                              >
                                {e?.country_name}
                              </option>
                            ))}
                          </select>
                          {isFormikErrorsArray<IChild>(
                            formik.errors.children
                          ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]
                              ?.issue_country_code &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "issue_country_code"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IChild>(
                                  formik.errors.children,
                                  index,
                                  "issue_country_code"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                    </div>
                    <div className="field-set mb-3">
                      {isPIDRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]?.issue_date &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "issue_date"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Passport Issue Date{" "}
                            {isPIDRequired && <code>*</code>}
                          </label>
                          <input
                            type="date"
                            name={`children[${index}].issue_date`}
                            onChange={formik.handleChange}
                            max={moment().format("YYYY-MM-DD")}
                            value={formik.values.children[index].issue_date}
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]?.issue_date &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "issue_date"
                              )
                                ? "error-border"
                                : ""
                            }
                          />
                          {isFormikErrorsArray<IChild>(
                            formik.errors.children
                          ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.issue_date &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "issue_date"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IChild>(
                                  formik.errors.children,
                                  index,
                                  "issue_date"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                      {isPPEDRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]?.expire_date &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "expire_date"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Passport Expiry Date{" "}
                            {isPPEDRequired && <code>*</code>}
                          </label>
                          <input
                            type="date"
                            name={`children[${index}].expire_date`}
                            onChange={formik.handleChange}
                            min={moment().add(6, "months").format("YYYY-MM-DD")}
                            value={formik.values.children[index].expire_date}
                            className={
                              isFormikErrorsArray<IChild>(
                                formik.errors.children
                              ) &&
                              isFormikErrorsArray<IChild>(
                                formik.touched.children
                              ) &&
                              formik.touched.children[index]?.expire_date &&
                              getFieldError<IChild>(
                                formik.errors.children,
                                index,
                                "expire_date"
                              )
                                ? "error-border"
                                : ""
                            }
                          />
                          {isFormikErrorsArray<IChild>(
                            formik.errors.children
                          ) &&
                            isFormikErrorsArray<IChild>(
                              formik.touched.children
                            ) &&
                            formik.touched.children[index]?.expire_date &&
                            getFieldError<IChild>(
                              formik.errors.children,
                              index,
                              "expire_date"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IChild>(
                                  formik.errors.children,
                                  index,
                                  "expire_date"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                      <div className="fields"></div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <a
              className="add-more"
              href="javascript:void(0)"
              onClick={() => {
                if (
                  CHILD > getSelectedItems(formik?.values?.children)?.length
                ) {
                  push({
                    isSelected: true,
                    first_name: "",
                    last_name: "",
                    initial: "",
                    gender: "",
                    date_of_birth: "",
                    // wheelchair: false,
                  });
                } else {
                  formik.setFieldError(
                    "childrenLimit",
                    `You have already selected ${CHILD} CHILD. Remove before adding a new one.`
                  );
                }
              }}
            >
              + Add New Child
            </a>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default ChildForm;
