import { createSlice } from "@reduxjs/toolkit";
import { IUserState } from "./userModel";
import { createUser, updateUser } from "./services/user.services";

const initialState: IUserState = {
  loading: false,
  error: "",
  UserList: [],
  UserID: null,
  UserDetails: {
    country_id: null,
    state_id: null,
    city_id: null,
    nationality_id: null,
    gender: null,
    first_name: "",
    last_name: "",
    date_of_birth: "",
    mobile_no: "",
    email_id: "",
    passport_no: "",
    passport_expiry: "",
    pan_card_no: "",
    billing_details: "",
    billing_address: "",
    pincode: "",
    gst_no: "",
    isactive: true,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
// export const { clearUserDetails, setUserID, clearUserID } = userSlice.actions;
export default userSlice.reducer;
