import { createSlice } from "@reduxjs/toolkit";
import { ICityState } from "./cityModel";
import { getAllActiveCities } from "./services/city.services";

const initialState: ICityState = {
  loading: false,
  error: "",
  CityList: [],
  CityID: null,
};

const userSlice = createSlice({
  name: "city",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllActiveCities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllActiveCities.fulfilled, (state, action) => {
      state.loading = true;
      state.CityList = action.payload;
    });
    builder.addCase(getAllActiveCities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default userSlice.reducer;
