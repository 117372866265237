import React from "react";
import Banner from "../../components/Home/Banner";
import ExclusiveOfferSection from "./ExclusiveOfferSection";
import DestinationSection from "./DestinationSection";
import WeekendDeals from "./WeekendDeals";
import BookSection from "./BookSection";
import PopularItems from "../../components/layout/footer/PopularItems";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Banner />
      <ExclusiveOfferSection />
      <DestinationSection />
      <WeekendDeals />
      <BookSection />
      <PopularItems />
      <Helmet>
        <style>{`
          body {
            background: white;
          }
        `}</style>
      </Helmet>
    </>
  );
};

export default Home;
