import React from "react";

const OfferCard = ({ offer }: { offer: any }) => {
  return (
    <div className={`offer-card ${offer?.backgroundClass || ""}`}>
      <div className="offer-card-text">
        <span className={offer?.spanClass || "default-span"}>
          {offer?.description}
        </span>
        <h3>{offer?.title}</h3>
        <p className="cupon-code">
          User code <br />
          <span>{offer?.code}</span>
        </p>
      </div>
      <div className="offer-card-pic">
        <img src={offer?.image} alt={offer?.title} />
      </div>
    </div>
  );
};

export default OfferCard;
