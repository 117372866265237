import React from "react";
import {
  getFieldError,
  getSelectedItems,
  isFormikErrorsArray,
} from "../../_helpers/helper";
import CONTACT from "../../assets/images/contact.png";
import { FieldArray, FormikProps } from "formik";
import { useAppSelector } from "../../app/hooks";
import { IAdult, IReviewBookingDetails } from "./reviewbookingModel";
import { INITIALS } from "../../_constants/constant";
import moment from "moment";

interface AdultFormProps {
  formik: FormikProps<IReviewBookingDetails>;
  isPIDRequired: Boolean;
  isPPEDRequired: Boolean;
  isPNRequired: Boolean;
  isADOBRRequired: Boolean;
  handleCheckboxChange: (
    type: "adults" | "children" | "infants",
    flightDetailsKey: "ADULT" | "CHILD" | "INFANT",
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const AdultForm = ({
  formik,
  handleCheckboxChange,
  isPIDRequired,
  isPPEDRequired,
  isPNRequired,
  isADOBRRequired,
}: AdultFormProps) => {
  const flightReviewDetails = useAppSelector(
    (state) => state.reviewbooking.flightReviewDetails
  );

  const reviewBookingDetails = useAppSelector(
    (state) => state.reviewbooking.reviewBookingDetails
  );

  const CountryList = useAppSelector((state) => state.country.CountryList);
  const paxDetails = flightReviewDetails?.searchQuery?.paxInfo;

  const ADULT = paxDetails?.ADULT || 0;

  return (
    <div className="adult-form">
      <div className="traveller-details-title">
        <p className="m-0">
          <img src={CONTACT} /> ADULT (12 Yrs+)
          <span className="m-0 tarveller-error-message">
            {formik.errors?.adultsLimit}
          </span>
        </p>
        <strong>
          {getSelectedItems(formik.values?.adults)?.length}/{ADULT} added
        </strong>
      </div>
      <span className="note">
        <strong>Important:</strong> Enter name as mentioned on your passport or
        Government approved IDs.
      </span>
      <FieldArray name="adults">
        {({ push, remove }) => (
          <>
            {formik.values.adults.map((adult, index) => (
              <div className="child-form">
                <input
                  className="form-check"
                  type="checkbox"
                  name={`adults[${index}].isSelected`}
                  checked={formik.values.adults[index].isSelected}
                  onChange={(e) =>
                    handleCheckboxChange("adults", "ADULT", index, e)
                  }
                />
                <h5>
                  {reviewBookingDetails?.adults[index]?.first_name
                    ? `${formik.values.adults[index]?.first_name} ${formik.values.adults[index]?.last_name}`
                    : `Adult ${
                        index -
                        (getSelectedItems(
                          reviewBookingDetails?.adults,
                          "first_name"
                        )?.length -
                          1)
                      }`}
                </h5>
                {formik.values.adults[index].isSelected && (
                  <div className="form">
                    <div className="field-set align-end">
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.initial &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "initial"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          Initial <code>*</code>
                        </label>
                        <select
                          name={`adults[${index}].initial`}
                          value={formik.values.adults[index].initial}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.initial &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "initial"
                            )
                              ? "error-border py-0"
                              : "py-0"
                          }
                        >
                          <option value="" hidden selected>
                            Select Initial
                          </option>
                          {INITIALS?.map((e: string) => (
                            <option value={e}>{e}</option>
                          ))}
                        </select>
                        {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                          isFormikErrorsArray<IAdult>(formik.touched.adults) &&
                          formik.touched.adults[index]?.initial &&
                          getFieldError<IAdult>(
                            formik.errors.adults,
                            index,
                            "initial"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "initial"
                              )}
                            </div>
                          )}
                      </div>
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.first_name &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "first_name"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          First Name <code>*</code>
                        </label>
                        <input
                          type="text"
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.first_name &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "first_name"
                            )
                              ? "error-border"
                              : ""
                          }
                          name={`adults[${index}].first_name`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.adults[index].first_name}
                        />
                        {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                          isFormikErrorsArray<IAdult>(formik.touched.adults) &&
                          formik.touched.adults[index]?.first_name &&
                          getFieldError<IAdult>(
                            formik.errors.adults,
                            index,
                            "first_name"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "first_name"
                              )}
                            </div>
                          )}
                      </div>
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.last_name &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "last_name"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          Last Name <code>*</code>
                        </label>
                        <input
                          type="text"
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.last_name &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "last_name"
                            )
                              ? "error-border"
                              : ""
                          }
                          name={`adults[${index}].last_name`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.adults[index].last_name}
                        />
                        {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                          isFormikErrorsArray<IAdult>(formik.touched.adults) &&
                          formik.touched.adults[index]?.last_name &&
                          getFieldError<IAdult>(
                            formik.errors.adults,
                            index,
                            "last_name"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "last_name"
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="field-set">
                      <div className="fields">
                        <label
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.date_of_birth &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "date_of_birth"
                            )
                              ? "error-label"
                              : ""
                          }
                        >
                          Date of Birth {isADOBRRequired && <code>*</code>}
                        </label>
                        <input
                          type="date"
                          name={`adults[${index}].date_of_birth`}
                          onChange={formik.handleChange}
                          max={moment()
                            .subtract(12, "years")
                            .format("YYYY-MM-DD")}
                          value={formik.values.adults[index].date_of_birth}
                          className={
                            isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.date_of_birth &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "date_of_birth"
                            )
                              ? "error-border"
                              : ""
                          }
                        />
                        {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                          isFormikErrorsArray<IAdult>(formik.touched.adults) &&
                          formik.touched.adults[index]?.date_of_birth &&
                          getFieldError<IAdult>(
                            formik.errors.adults,
                            index,
                            "date_of_birth"
                          ) && (
                            <div className="error-label">
                              {getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "date_of_birth"
                              )}
                            </div>
                          )}
                      </div>
                      {isPNRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]?.passport_no &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "passport_no"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Passport No {isPNRequired && <code>*</code>}
                          </label>
                          <input
                            type="text"
                            name={`adults[${index}].passport_no`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.adults[index].passport_no}
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]?.passport_no &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "passport_no"
                              )
                                ? "error-border"
                                : ""
                            }
                          />
                          {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.passport_no &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "passport_no"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IAdult>(
                                  formik.errors.adults,
                                  index,
                                  "passport_no"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                      {isPNRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]
                                ?.issue_country_code &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "issue_country_code"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Issue Country {isPNRequired && <code>*</code>}
                          </label>
                          <select
                            name={`adults[${index}].issue_country_code`}
                            value={
                              formik.values.adults[index].issue_country_code
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]
                                ?.issue_country_code &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "issue_country_code"
                              )
                                ? "error-border py-0"
                                : "py-0"
                            }
                          >
                            <option value="" hidden selected>
                              Select Country
                            </option>
                            {CountryList?.map((e) => (
                              <option
                                key={e?.country_code}
                                value={e?.country_code}
                              >
                                {e?.country_name}
                              </option>
                            ))}
                          </select>
                          {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.issue_country_code &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "issue_country_code"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IAdult>(
                                  formik.errors.adults,
                                  index,
                                  "issue_country_code"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                    </div>
                    <div className="field-set mb-3">
                      {isPIDRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]?.issue_date &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "issue_date"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Passport Issue Date{" "}
                            {isPIDRequired && <code>*</code>}
                          </label>
                          <input
                            type="date"
                            name={`adults[${index}].issue_date`}
                            onChange={formik.handleChange}
                            max={moment().format("YYYY-MM-DD")}
                            value={formik.values.adults[index].issue_date}
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]?.issue_date &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "issue_date"
                              )
                                ? "error-border"
                                : ""
                            }
                          />
                          {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.issue_date &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "issue_date"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IAdult>(
                                  formik.errors.adults,
                                  index,
                                  "issue_date"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                      {isPPEDRequired ? (
                        <div className="fields">
                          <label
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]?.expire_date &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "expire_date"
                              )
                                ? "error-label"
                                : ""
                            }
                          >
                            Passport Expiry Date{" "}
                            {isPPEDRequired && <code>*</code>}
                          </label>
                          <input
                            type="date"
                            name={`adults[${index}].expire_date`}
                            onChange={formik.handleChange}
                            min={moment().add(6, "months").format("YYYY-MM-DD")}
                            value={formik.values.adults[index].expire_date}
                            className={
                              isFormikErrorsArray<IAdult>(
                                formik.errors.adults
                              ) &&
                              isFormikErrorsArray<IAdult>(
                                formik.touched.adults
                              ) &&
                              formik.touched.adults[index]?.expire_date &&
                              getFieldError<IAdult>(
                                formik.errors.adults,
                                index,
                                "expire_date"
                              )
                                ? "error-border"
                                : ""
                            }
                          />
                          {isFormikErrorsArray<IAdult>(formik.errors.adults) &&
                            isFormikErrorsArray<IAdult>(
                              formik.touched.adults
                            ) &&
                            formik.touched.adults[index]?.expire_date &&
                            getFieldError<IAdult>(
                              formik.errors.adults,
                              index,
                              "expire_date"
                            ) && (
                              <div className="error-label">
                                {getFieldError<IAdult>(
                                  formik.errors.adults,
                                  index,
                                  "expire_date"
                                )}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="fields"></div>
                      )}
                      <div className="fields"></div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <a
              className="add-more"
              href="javascript:void(0)"
              onClick={() => {
                if (ADULT > getSelectedItems(formik?.values?.adults)?.length) {
                  push({
                    first_name: "",
                    last_name: "",
                    initial: "",
                    date_of_birth: "",
                    passport_no: "",
                    issue_country_code: "",
                    issue_date: "",
                    expire_date: "",
                    isSelected: true,
                  });
                } else {
                  formik.setFieldError(
                    "adultsLimit",
                    `You have already selected ${ADULT} ADULT. Remove before adding a new one.`
                  );
                }
              }}
            >
              + Add New Adult
            </a>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default AdultForm;
