import moment from "moment";
import INDIGO from "../assets/images/INDIGO.png";
import AIRASIA from "../assets/images/AIRASIA.png";
import AIRINDIA from "../assets/images/AIRINDIA.png";
import AIRINIDAEXPRESS from "../assets/images/AIRINIDAEXPRESS.png";
import VISTARA from "../assets/images/VISTARA.png";
import SPICEJET from "../assets/images/SPICEJET.png";
import DEFAULT_FLIGHT from "../assets/images/default-airline.png";
import { v4 as uuidv4 } from "uuid";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { FormikErrors } from "formik";
import {
  CABBINCLASSARRAY,
  FARETYPEARRAY,
  TRIPTYPEARRAY,
} from "../_constants/constant";
import { useFlightTimes } from "../_hooks/useFlightTimes";

export const flightImages = [
  { name: "Indigo", image: INDIGO },
  { name: "AirAsia India", image: AIRASIA },
  { name: "SpiceJet", image: SPICEJET },
  { name: "AI Express", image: AIRINIDAEXPRESS },
  { name: "Air India", image: AIRINDIA },
  { name: "Vistara", image: VISTARA },
];

// this is the function for the get difference in Hours and Minutes
export const getDiffInHRAndMin = (departureTime: any, arrivalTime: any) => {
  let duration;

  const departureMoment = moment(departureTime);
  let arrivalMoment = moment(arrivalTime);

  if (arrivalMoment.isAfter(departureMoment)) {
    duration = moment.duration(arrivalMoment.diff(departureMoment));
  } else {
    // If arrival time is before departure time, add 1 day to arrival time
    arrivalMoment = arrivalMoment.add(1, "day");
    duration = moment.duration(arrivalMoment.diff(departureMoment));
  }

  // Calculate hours and minutes
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return `${hours} h ${minutes} min`;
};

// this is the function for convert min to Hours and minutes
export const formatMinToHRAndMin = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}h ${formattedMinutes}min`;
};

// this is the function for get arrival days message
export const getArrivalDayMessage = (departureTime: any, arrivalTime: any) => {
  const departureMoment = moment(departureTime);
  const arrivalMoment = moment(arrivalTime);

  const departureDate = departureMoment.startOf("day");
  const arrivalDate = arrivalMoment.startOf("day");

  const dayDifference = arrivalDate.diff(departureDate, "days");

  if (dayDifference > 0) {
    return `Flight Arrives after ${dayDifference} Day(s) `;
  } else {
    return "";
  }
};

export const getFlightImage = (flightCode: string) => {
  // const flight = flightImages?.find((f: any) => f.name === flightName);
  // return flight?.image || DEFAULT_FLIGHT;
  return `https://static.tripjack.com/img/airlineLogo/v1/${flightCode}.png`;
};

const getKeyOrValue = (
  array: any[],
  keyName: string,
  valueName: string,
  input: string
) => {
  const foundObj = array.find(
    (item: any) => item[keyName] === input || item[valueName] === input
  );
  if (!foundObj) return "";
  return foundObj[keyName] === input ? foundObj[valueName] : foundObj[keyName];
};

export const getTripType = (input: string) => {
  return getKeyOrValue(TRIPTYPEARRAY, "key", "type", input);
};

export const getCabinClass = (input: string) => {
  return getKeyOrValue(CABBINCLASSARRAY, "key", "class", input);
};

export const getFareType = (input: string) => {
  return getKeyOrValue(FARETYPEARRAY, "key", "type", input);
};

export const getCityNameByCityCode = (
  flightplace_code: any,
  flightPlaces: any
) => {
  const flightPlace: any[] = flightPlaces?.filter(
    (e: any) => e?.flightplace_code === flightplace_code
  );
  const city_name = flightPlace[0]?.city_name;
  return city_name;
};

export const getAirportNameByCityCode = (
  flightplace_code: any,
  flightPlaces: any
) => {
  const flightPlace: any[] = flightPlaces?.filter(
    (e: any) => e?.flightplace_code === flightplace_code
  );
  const flightplace_name = flightPlace[0]?.flightplace_name;
  return flightplace_name;
};

export const calculateTotalJourneyDuration = (flightSegments: any) => {
  let totalDuration = 0;

  flightSegments.forEach((segment: any, index: number) => {
    totalDuration += segment.duration || 0;
    if (index < flightSegments.length - 1) {
      totalDuration += flightSegments[index].cT || 0;
    }
  });
  return totalDuration;
};

const getMinPrice = (priceList: any[] = []) => {
  return Math.min(...priceList?.map((item) => item.fd.ADULT.fC.TF));
};

export const delay = (ms: any) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const filterFlights = (flights: any = [], filter: any) => {
  let updatedFlights = [...flights];

  if (filter?.stop && filter?.stop?.length > 0) {
    updatedFlights = updatedFlights.filter((flight) => {
      const stops = flight?.sI?.length - 1;
      return (
        filter.stop.includes(stops) || (stops >= 2 && filter?.stop?.includes(2))
      );
    });
  }

  if (filter?.airline && filter?.airline?.length > 0) {
    updatedFlights = updatedFlights.filter((flight) => {
      return flight.sI.some((segment: any) =>
        filter?.airline?.includes(segment.fD.aI.name)
      );
    });
  }

  if (filter?.departureTime && filter?.departureTime?.length > 0) {
    updatedFlights = updatedFlights.filter((flight) => {
      const hour = moment(flight.sI[0]?.dt).hour();
      return filter?.departureTime.some((timeRange: any) => {
        switch (timeRange) {
          case "before6am":
            return hour < 6;
          case "6am-12pm":
            return hour >= 6 && hour < 12;
          case "12pm-6pm":
            return hour >= 12 && hour < 18;
          case "after6pm":
            return hour >= 18;
          default:
            return true;
        }
      });
    });
  }

  if (filter?.arrivalTime && filter?.arrivalTime?.length > 0) {
    updatedFlights = updatedFlights.filter((flight) => {
      const hour = moment(flight.sI[flight?.sI?.length - 1]?.at).hour();
      return filter?.arrivalTime.some((timeRange: any) => {
        switch (timeRange) {
          case "before6am":
            return hour < 6;
          case "6am-12pm":
            return hour >= 6 && hour < 12;
          case "12pm-6pm":
            return hour >= 12 && hour < 18;
          case "after6pm":
            return hour >= 18;
          default:
            return true;
        }
      });
    });
  }
  return updatedFlights;
};

export const filterComboFlights = (
  groupedFlights: any[] = [],
  comboFilters: any[] = []
) => {
  return groupedFlights
    .map((group: any) => {
      return group.filter((flight: any, index: number) => {
        const filter = comboFilters[index] || {};
        const totalStops = flight.sI.length - 1;

        if (filter.stop?.length && !filter.stop.includes(totalStops)) {
          return false;
        }

        if (filter.airline && filter.airline.length > 0) {
          const hasValidAirline = flight.sI.some((segment: any) =>
            filter.airline.includes(segment.fD.aI.name)
          );
          if (!hasValidAirline) return false;
        }

        if (filter.departureTime && filter.departureTime.length > 0) {
          const departureHour = moment(flight.sI[0]?.dt).hour();
          const isDepartureValid = filter.departureTime.some(
            (timeRange: any) => {
              switch (timeRange) {
                case "before6am":
                  return departureHour < 6;
                case "6am-12pm":
                  return departureHour >= 6 && departureHour < 12;
                case "12pm-6pm":
                  return departureHour >= 12 && departureHour < 18;
                case "after6pm":
                  return departureHour >= 18;
                default:
                  return true;
              }
            }
          );
          if (!isDepartureValid) return false;
        }

        if (filter.arrivalTime && filter.arrivalTime.length > 0) {
          const arrivalHour = moment(
            flight.sI[flight.sI.length - 1]?.at
          ).hour();
          const isArrivalValid = filter.arrivalTime.some((timeRange: any) => {
            switch (timeRange) {
              case "before6am":
                return arrivalHour < 6;
              case "6am-12pm":
                return arrivalHour >= 6 && arrivalHour < 12;
              case "12pm-6pm":
                return arrivalHour >= 12 && arrivalHour < 18;
              case "after6pm":
                return arrivalHour >= 18;
              default:
                return true;
            }
          });
          if (!isArrivalValid) return false;
        }

        return true;
      });
    })
    .filter((group) =>
      group.length >=
      ((groupedFlights?.length > 0 && groupedFlights[0]?.length) || 1)
        ? true
        : false
    );
};

export const sortFlights = (flightsToSort: any = [], sortType: any) => {
  if (!sortType || !sortType.field || !sortType.order) {
    return flightsToSort;
  }
  const { field, order } = sortType;
  return flightsToSort.sort((a: any, b: any) => {
    let comparison = 0;

    if (field === "price") {
      const priceA = getMinPrice(a.totalPriceList);
      const priceB = getMinPrice(b.totalPriceList);
      comparison = priceA - priceB;
    } else if (field === "duration") {
      const durationA = calculateTotalJourneyDuration(a.sI);
      const durationB = calculateTotalJourneyDuration(b.sI);
      comparison = durationA - durationB;
    } else if (field === "departure") {
      const departureA: any = moment(a.sI[0].dt);
      const departureB: any = moment(b.sI[0].dt);
      comparison = departureA - departureB;
    } else if (field === "arrival") {
      const arrivalA: any = moment(a.sI[a.sI.length - 1].at);
      const arrivalB: any = moment(b.sI[b.sI.length - 1].at);
      comparison = arrivalA - arrivalB;
    }

    return order === "asc" ? comparison : -comparison;
  });
};

export const sortComboFlights = (
  groupedFlights: any[] = [],
  comboSortType: any = {},
  activeIndex: number
) => {
  if (!comboSortType || !comboSortType.field || !comboSortType.order) {
    return groupedFlights;
  }

  const { field, order } = comboSortType;

  // Sort the groups based on the flight at the specified activeIndex
  return [...groupedFlights].sort((groupA, groupB) => {
    const flightA = groupA[activeIndex] || groupA[0];
    const flightB = groupB[activeIndex] || groupB[0];

    return compareFlights(flightA, flightB, field, order);
  });
};

// Helper function to compare two flights
const compareFlights = (a: any, b: any, field: any, order: any) => {
  let comparison = 0;

  if (field === "price") {
    const priceA = getMinPrice(a.totalPriceList);
    const priceB = getMinPrice(b.totalPriceList);
    comparison = priceA - priceB;
  } else if (field === "duration") {
    const durationA = calculateTotalJourneyDuration(a.sI);
    const durationB = calculateTotalJourneyDuration(b.sI);
    comparison = durationA - durationB;
  } else if (field === "departure") {
    const departureA: any = moment(a.sI[0].dt);
    const departureB: any = moment(b.sI[0].dt);
    comparison = departureA - departureB;
  } else if (field === "arrival") {
    const arrivalA: any = moment(a.sI[a.sI.length - 1].at);
    const arrivalB: any = moment(b.sI[b.sI.length - 1].at);
    comparison = arrivalA - arrivalB;
  }

  return order === "asc" ? comparison : -comparison;
};

export const getMinPriceObject = (flights: any) => {
  return flights?.reduce((min: any, flight: any) => {
    const totalPrice = flight.fd.ADULT.fC.TF;
    return totalPrice < min.fd.ADULT.fC.TF ? flight : min;
  }, flights[0]);
};

export const getDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("device_id", deviceId);
  }
  return deviceId;
};

export const getDeviceType = () => {
  if (isMobile) return "mobile";
  if (isTablet) return "tablet";
  return "desktop";
};

export const getBrowserName = () => {
  return browserName || "";
};

export const getSessionId = () => {
  let sessionId = sessionStorage.getItem("session_id");
  if (!sessionId) {
    sessionId = uuidv4();
    sessionStorage.setItem("session_id", sessionId);
  }
  return sessionId;
};

export const getLocalItem = (localKey: string) => {
  return localStorage.getItem(localKey);
};

export const getSelectedItems = (
  array: any[] = [],
  selectionProperty = "isSelected"
) => {
  return array?.filter((item) => item[selectionProperty]) ?? [];
};

export const getFieldError = <T>(
  errors: FormikErrors<T>[] | undefined,
  index: number,
  field: keyof T
) => {
  if (!errors || !errors[index]) return undefined;
  const error = errors[index][field];
  return typeof error === "string" ? error : undefined;
};

export const isFormikErrorsArray = <T>(
  errors: any
): errors is FormikErrors<T>[] => {
  return Array.isArray(errors);
};

export const formatTraveller = (traveller: any, type: string) => ({
  ti: traveller.initial || "",
  fN: traveller.first_name,
  lN: traveller.last_name || "",
  pt: type.toUpperCase(),
  ...(traveller.date_of_birth && {
    dob: traveller.date_of_birth,
  }),
  ...(traveller.issue_country_code && {
    pNat: traveller.issue_country_code || "",
  }),
  ...(traveller.passport_no && {
    pNum: traveller.passport_no || "",
  }),
  ...(traveller.expire_date && {
    eD: traveller.expire_date || "",
  }),
  ...(traveller.issue_date && {
    pid: traveller.issue_date || "",
  }),
});

export const formatBookingData = (tripJackResponse: any) => {
  const { order, itemInfos, gstInfo, searchType } = tripJackResponse;

  const fd =
    itemInfos?.AIR?.tripInfos[0] &&
    itemInfos?.AIR?.tripInfos[0]?.sI[0]?.bI?.tI[0]?.fd;

  const RefundableType =
    fd?.rT === 1
      ? "Refundable"
      : fd?.rT === 2
      ? "Partial Refundable"
      : "Non Refundable";

  const flight_class = fd?.cc;

  const user_guid = getLocalItem("user_guid");

  const bookingData: any = {
    user_guid: user_guid,
    booking_no: order?.bookingId,
    booking_status: "Confirmed",
    booking_date: order?.createdOn,
    amount: order?.amount,
    email_id: order?.deliveryInfo?.emails[0],
    mobile_no: order?.deliveryInfo?.contacts[0],
    alt_email_id: order?.deliveryInfo?.emails[1] || "",
    alt_mobile_no: order?.deliveryInfo?.contacts[1] || "",
    gst_number: gstInfo?.gstNumber,
    gst_email: gstInfo?.email,
    gst_registred_name: gstInfo?.registeredName,
    gst_mobile_no: gstInfo?.mobile,
    gst_address: gstInfo?.address,
    base_fare: itemInfos?.AIR?.totalPriceInfo?.totalFareDetail?.fC?.BF,
    airline_gst:
      itemInfos?.AIR?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.AGST,
    total_airline_tax: itemInfos?.AIR?.totalPriceInfo?.totalFareDetail?.fC?.TAF,
    other_taxes: itemInfos?.AIR?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.OT,
    yr: itemInfos?.AIR?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.YR,
    oc: itemInfos?.AIR?.totalPriceInfo?.totalFareDetail?.afC?.TAF?.OC,
    net_price: itemInfos?.AIR?.totalPriceInfo?.totalFareDetail?.fC?.NF,
    fare_rules: RefundableType,
    flight_details: [],
    passenger_details: [],
  };

  itemInfos?.AIR?.tripInfos?.forEach((tripInfo: any) => {
    tripInfo?.sI?.forEach((segment: any) => {
      bookingData?.flight_details?.push({
        airline_name: segment?.fD?.aI?.name,
        airline_code: segment?.fD?.aI?.code,
        flight_no: segment?.fD?.fN,
        flight_class,
        flight_type: searchType || "ONEWAY",
        departure_date_time: segment?.dt,
        departure_place: segment?.da?.city,
        departure_airport: segment?.da?.name,
        departure_terminal: segment?.da?.terminal,
        arrival_date_time: segment?.at,
        arrival_place: segment?.aa?.city,
        arrival_airport: segment?.aa?.name,
        arrival_terminal: segment?.aa?.terminal,
        duration: `${Math?.floor(segment?.duration / 60)}h ${
          segment?.duration % 60
        }m`,
        baggage_information: `${segment?.bI?.tI[0]?.fd?.bI?.iB}, ${segment?.bI?.tI[0]?.fd?.bI?.cB}`,
        stop_details:
          segment?.stops === 0 ? "Non-stop" : segment?.stops + "Stop",
      });
    });
  });

  itemInfos?.AIR?.travellerInfos?.forEach((traveller: any) => {
    bookingData?.passenger_details?.push({
      initial: traveller?.ti,
      first_name: traveller?.fN,
      last_name: traveller?.lN,
      gender:
        traveller?.ti === "Ms" || traveller?.ti === "Mrs" ? "Female" : "Male",
      pax_type: traveller?.pt,
      date_of_birth: traveller?.dob,
      passport_no: traveller?.pNum,
      expiry_date: traveller?.eD,
      issue_country_code: traveller?.pNat,
      // need to add one more field here for the passport issue date and also if possible then change issue country to country code
      issue_date: traveller?.pid,
      status: "Confirmed",
    });
  });

  return bookingData;
};

export const formatTempBookingData = (tripJackResponse: any) => {
  const {
    bookingId,
    totalPriceInfo,
    tripInfos,
    searchQuery,
    gstInfo,
    deliveryInfo,
    travellerInfos,
  } = tripJackResponse;

  const fd = tripInfos?.[0]?.totalPriceList?.[0]?.fd?.ADULT;
  const RefundableType =
    fd?.rT === 1
      ? "Refundable"
      : fd?.rT === 2
      ? "Partial Refundable"
      : "Non Refundable";

  const flight_class = fd?.cc;
  const user_guid = getLocalItem("user_guid");

  const bookingData: any = {
    booking_no: bookingId,
    booking_status: "Pending",
    amount: totalPriceInfo?.totalFareDetail?.fC?.NF,
    base_fare: totalPriceInfo?.totalFareDetail?.fC?.BF,
    other_taxes: totalPriceInfo?.totalFareDetail?.afC?.TAF?.OT,
    airline_gst: totalPriceInfo.totalFareDetail?.afC?.TAF?.AGST,
    yr: totalPriceInfo.totalFareDetail?.afC?.TAF?.YR,
    oc: totalPriceInfo.totalFareDetail?.afC?.TAF?.OC,
    total_airline_tax: totalPriceInfo?.totalFareDetail?.fC?.TAF,
    net_price: totalPriceInfo?.totalFareDetail?.fC?.NF,
    fare_rules: RefundableType,
    user_guid: user_guid,
    booking_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
    email_id: deliveryInfo?.emails[0],
    mobile_no: deliveryInfo?.contacts[0],
    alt_email_id: deliveryInfo?.emails[1] || "",
    alt_mobile_no: deliveryInfo?.contacts[1] || "",
    gst_number: gstInfo?.gstNumber,
    gst_email: gstInfo?.email,
    gst_registred_name: gstInfo?.registeredName,
    gst_mobile_no: gstInfo?.mobile,
    gst_address: gstInfo?.address,
    flight_details: [],
    passenger_details: [],
  };

  tripInfos?.forEach((tripInfo: any) => {
    tripInfo?.sI?.forEach((segment: any) => {
      bookingData.flight_details.push({
        airline_name: segment?.fD?.aI?.name,
        airline_code: segment?.fD?.aI?.code,
        flight_no: segment?.fD?.fN,
        flight_class,
        flight_type: searchQuery?.searchType || "ONEWAY",
        departure_date_time: segment?.dt,
        departure_place: segment?.da?.city,
        departure_airport: segment?.da?.name,
        departure_terminal: segment?.da?.terminal,
        arrival_date_time: segment?.at,
        arrival_place: segment?.aa?.city,
        arrival_airport: segment?.aa?.name,
        arrival_terminal: segment?.aa?.terminal,
        duration: `${Math.floor(segment?.duration / 60)}h ${
          segment?.duration % 60
        }m`,
        stop_details:
          segment?.stops === 0 ? "Non-stop" : `${segment?.stops} Stop`,
      });
    });
  });

  travellerInfos?.forEach((traveller: any) => {
    bookingData?.passenger_details?.push({
      initial: traveller?.ti,
      first_name: traveller?.fN,
      last_name: traveller?.lN,
      gender:
        traveller?.ti === "Ms" || traveller?.ti === "Mrs" ? "Female" : "Male",
      pax_type: traveller?.pt,
      date_of_birth: traveller?.dob,
      passport_no: traveller?.pNum,
      expiry_date: traveller?.eD,
      issue_country_code: traveller?.pNat,
      // need to add one more field here for the passport issue date and also if possible then change issue country to country code
      issue_date: traveller?.pid,
      status: "Pending",
    });
  });

  return bookingData;
};

export const parseQueryParams = (search: string) => {
  const queryParams: any = new URLSearchParams(search);
  const params: any = {};
  for (const [key, value] of queryParams.entries()) {
    params[key] = value;
  }
  return params;
};
export const generatePayloadFromUrl = (url: any) => {
  const queryParams = parseQueryParams(url);

  const itinerary = queryParams.itinerary.split("_");
  const routes = itinerary.map((route: any) => {
    const [from, to, date] = route.split("-");
    return {
      fromCityOrAirport: { code: from },
      toCityOrAirport: { code: to },
      travelDate: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"),
    };
  });

  const [adultCount, childCount, infantCount] = queryParams.paxType
    .split("_")
    .map((pax: any) => parseInt(pax.split("-")[1], 10));

  const payload = {
    searchQuery: {
      cabinClass: getCabinClass(queryParams.cabinClass)?.toUpperCase(),
      paxInfo: {
        ADULT: `${adultCount}`,
        CHILD: `${childCount}`,
        INFANT: `${infantCount}`,
      },
      routeInfos: routes,
      searchModifiers: {
        pft:
          getFareType(queryParams.pft) === "Regular"
            ? ""
            : getFareType(queryParams.pft)?.toUpperCase()?.replace(" ", "_"),
        isDirectFlight: false,
        isConnectingFlight: false,
      },
    },
  };
  return payload;
};

export const generateObjectFromUrl = (
  search: string,
  flightSearchDetails: any
) => {
  const queryParams = parseQueryParams(search);

  let multicities: any[] = [];

  const [adultCount, childCount, infantCount] = queryParams.paxType
    .split("_")
    .map((pax: any) => parseInt(pax.split("-")[1], 10));

  if (queryParams.triptype === "M") {
    // Handle Multicity trip type
    const itinerarySegments = queryParams?.itinerary?.split("_");

    multicities = itinerarySegments.map((segment: string) => {
      const [from, to, departureDate] = segment.split("-");
      return {
        from: from || "BLR",
        to: to || "PNQ",
        departureDate:
          moment(departureDate, "DD/MM/YYYY").toISOString() ||
          moment().toISOString(),
      };
    });

    return {
      triptype: getTripType(queryParams.triptype) || "One Way",
      ...(multicities.length > 0 && { multicities }),
      from: flightSearchDetails?.from || "BLR",
      to: flightSearchDetails?.to || "PNQ",
      departureDate:
        moment(
          flightSearchDetails?.departureDate,
          "DD/MM/YYYY"
        ).toISOString() || moment().toISOString(),
      returnDate: flightSearchDetails?.returnDate
        ? moment(flightSearchDetails?.returnDate, "DD/MM/YYYY").toISOString()
        : moment(
            flightSearchDetails?.departureDate,
            "DD/MM/YYYY"
          ).toISOString(),
      ADULT: adultCount || 1,
      CHILD: childCount || 0,
      INFANT: infantCount || 0,
      cabinClass: getCabinClass(queryParams.cabinClass) || "Economy",
      specialfare: getFareType(queryParams.pft) || "Regular",
    };
  } else {
    const itinerary = queryParams?.itinerary?.split("_")[0];
    const [from, to, departureDate] = itinerary?.split("-");

    const returnSegment =
      queryParams.triptype === "R" ? queryParams.itinerary.split("_")[1] : null;
    const returnDate = returnSegment ? returnSegment.split("-")[2] : null;

    return {
      from: from || "BLR",
      triptype: getTripType(queryParams.triptype) || "One Way",
      to: to || "PNQ",
      multicities: flightSearchDetails?.multicities,
      departureDate:
        moment(departureDate, "DD/MM/YYYY").toISOString() ||
        moment().toISOString(),
      returnDate: returnDate
        ? moment(returnDate, "DD/MM/YYYY").toISOString()
        : moment(departureDate, "DD/MM/YYYY").toISOString(),
      ADULT: adultCount || 1,
      CHILD: childCount || 0,
      INFANT: infantCount || 0,
      cabinClass: getCabinClass(queryParams.cabinClass) || "Economy",
      specialfare: getFareType(queryParams.pft) || "Regular",
    };
  }
};

export function formatIndianNumber(number: number): string {
  if (number === null || number === undefined) return "";
  let integerPart = Math.round(number).toString();

  let lastThreeDigits = integerPart.slice(-3);
  let otherDigits = integerPart.slice(0, -3);

  if (otherDigits !== "") {
    lastThreeDigits = "," + lastThreeDigits;
  }

  let formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",");

  return formattedOtherDigits + lastThreeDigits;
}

export function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getRecentSearchLocations = (key: string) => {
  const flightDetails = JSON.parse(
    localStorage.getItem("flightDetails") || "{}"
  );
  if (!flightDetails[key]) {
    flightDetails[key] = [];
  }

  return flightDetails[key];
};

export const getCurrentFlightSearchFromLocalStorage = (key: string) => {
  const flightDetails = JSON.parse(
    localStorage.getItem("flightDetails") || "{}"
  );

  if (!flightDetails["lastFlightSearch"]) {
    flightDetails["lastFlightSearch"] = {};
  }

  // Use the key as a path to access nested properties
  const keys = key.split(".");
  let result = flightDetails["lastFlightSearch"];

  for (let k of keys) {
    result = result ? result[k] : null;
  }

  return result || "";
};

export const updatedRecentSearches = (key: string, newSearch: any) => {
  const recentSearches = getRecentSearchLocations(key);

  const duplicateIndex = recentSearches.findIndex(
    (search: any) => search.flightplace_code === newSearch.flightplace_code
  );

  if (duplicateIndex === -1) {
    recentSearches.unshift(newSearch);
    if (recentSearches.length > 5) recentSearches.pop();
  }

  return recentSearches;
};

export const filterOutRecentSearches = (
  fromOptions: any[],
  recentFromOptions: any[]
) => {
  return fromOptions.filter(
    (fromOption) =>
      !recentFromOptions.some(
        (recentOption) =>
          recentOption.flightplace_code === fromOption.flightplace_code
      )
  );
};

export const getFlightTimes = (flights: any[] = []) => {
  return flights?.map((flight: any) => useFlightTimes(flight?.route || {}));
};

export const groupComboFlights = (comboFlights: any[] = []) => {
  const groupedFlights: any[] = [];
  let currentGroup: any[] = [];

  comboFlights.forEach((combo) => {
    let currentRoute: any = null;

    combo.sI.forEach((flight: any) => {
      if (flight.sN === 0) {
        // If there's an existing route, push it to the current group
        if (currentRoute) {
          currentGroup.push(currentRoute);
        }
        // Clone the combo object and reset the sI array for the new route
        currentRoute = { ...combo, sI: [flight] };
      } else if (currentRoute) {
        // Add flights to the current route
        currentRoute.sI.push(flight);
      }
    });

    // Add the final route to the group
    if (currentRoute) {
      currentGroup.push(currentRoute);
    }

    // After processing one combo, add the group to the final result
    groupedFlights.push(currentGroup);
    currentGroup = [];
  });

  return groupedFlights;
};

export const generateFilterOptions = (
  flights: any,
  flightSearchDetails: any
) => {
  const stopGroups: any = {};
  const airlineGroups: any = {};

  flights?.forEach((flight: any) => {
    const stops = flight?.sI?.length - 1;
    const airlineName = flight?.sI[0]?.fD?.aI?.name;

    const minPrice = flight?.totalPriceList?.reduce((min: any, price: any) => {
      const totalAdultPrice =
        (price?.fd?.ADULT?.fC?.TF || 0) * flightSearchDetails.ADULT;
      const totalChildPrice =
        (price?.fd?.CHILD?.fC?.TF || 0) * flightSearchDetails.CHILD;
      const totalInfantPrice =
        (price?.fd?.INFANT?.fC?.TF || 0) * flightSearchDetails.INFANT;
      const totalPrice = totalAdultPrice + totalChildPrice + totalInfantPrice;
      return Math.min(min, totalPrice);
    }, Infinity);

    if (stopGroups[stops]) {
      stopGroups[stops].count += 1;
      stopGroups[stops].minPrice = Math.min(
        stopGroups[stops].minPrice,
        minPrice
      );
    } else {
      stopGroups[stops] = { count: 1, minPrice };
    }
    if (airlineGroups[airlineName]) {
      airlineGroups[airlineName].count += 1;
      airlineGroups[airlineName].minPrice = Math.min(
        airlineGroups[airlineName].minPrice,
        minPrice
      );
    } else {
      airlineGroups[airlineName] = { count: 1, minPrice };
    }
  });

  const stopFilterOptions = Object.keys(stopGroups).reduce(
    (acc: any, stops: any) => {
      const { count, minPrice } = stopGroups[stops];
      let label;
      let value;

      if (stops === "0") {
        label = `Direct (${count})`;
        value = 0;
      } else if (stops === "1") {
        label = `1 stop (${count})`;
        value = 1;
      } else {
        const existing: any = acc.find((option: any) => option.value === 2);

        if (existing) {
          existing.count += count;
          existing.minPrice = Math.min(existing.minPrice, minPrice);
        } else {
          acc.push({
            label: `2+ stops (${count})`,
            count: count,
            minPrice: minPrice,
            value: 2,
          });
        }

        return acc;
      }

      acc.push({ label, count, minPrice, value });
      return acc;
    },
    []
  );

  const twoPlusStops = stopFilterOptions.find(
    (option: any) => option.value === 2
  );
  if (twoPlusStops) {
    twoPlusStops.label = `2+ stops (${twoPlusStops.count})`;
  }

  const airlineFilterOptions = Object.keys(airlineGroups).map((airlineName) => {
    const { count, minPrice } = airlineGroups[airlineName];
    const label = `${airlineName} (${count})`;
    return { label, count, minPrice, value: airlineName };
  });

  return { stopFilterOptions, airlineFilterOptions };
};

export const generateComboFilterOptions = (
  groupedFlights: any[],
  flightSearchDetails: any
) => {
  const filterOptions: any[] = [];
  const routeCount = Math.max(
    ...groupedFlights.map((flightGroup) => flightGroup.length)
  );

  for (let routeIndex = 0; routeIndex < routeCount; routeIndex++) {
    const stopGroups: any = {};
    const airlineGroups: any = {};

    groupedFlights.forEach((flightGroup) => {
      const route = flightGroup[routeIndex];

      if (route) {
        const stops = route?.sI?.length - 1;
        const airlineName = route?.sI[0]?.fD?.aI?.name;

        const minPrice =
          route?.totalPriceList?.reduce((min: any, price: any) => {
            const totalAdultPrice =
              (price?.fd?.ADULT?.fC?.TF || 0) * flightSearchDetails.ADULT;
            const totalChildPrice =
              (price?.fd?.CHILD?.fC?.TF || 0) * flightSearchDetails.CHILD;
            const totalInfantPrice =
              (price?.fd?.INFANT?.fC?.TF || 0) * flightSearchDetails.INFANT;
            const totalPrice =
              totalAdultPrice + totalChildPrice + totalInfantPrice;
            return Math.min(min, totalPrice);
          }, Infinity) || 0;

        if (stopGroups[stops]) {
          stopGroups[stops].count += 1;
          stopGroups[stops].minPrice = Math.min(
            stopGroups[stops].minPrice,
            minPrice
          );
        } else {
          stopGroups[stops] = { count: 1, minPrice };
        }
        if (airlineGroups[airlineName]) {
          airlineGroups[airlineName].count += 1;
          airlineGroups[airlineName].minPrice = Math.min(
            airlineGroups[airlineName].minPrice,
            minPrice
          );
        } else {
          airlineGroups[airlineName] = { count: 1, minPrice };
        }
      }
    });

    const stopFilterOptions = Object.keys(stopGroups).reduce(
      (acc: any, stops: any) => {
        const { count, minPrice } = stopGroups[stops];
        let label;
        let value;

        if (stops === "0") {
          label = `Direct (${count})`;
          value = 0;
        } else if (stops === "1") {
          label = `1 stop (${count})`;
          value = 1;
        } else {
          const existing: any = acc.find((option: any) => option.value === 2);

          if (existing) {
            existing.count += count;
            existing.minPrice = Math.min(existing.minPrice, minPrice);
          } else {
            acc.push({
              label: `2+ stops (${count})`,
              count: count,
              minPrice: minPrice,
              value: 2,
            });
          }

          return acc;
        }

        acc.push({ label, count, minPrice, value });
        return acc;
      },
      []
    );

    const twoPlusStops = stopFilterOptions.find(
      (option: any) => option.value === 2
    );
    if (twoPlusStops) {
      twoPlusStops.label = `2+ stops (${twoPlusStops.count})`;
    }

    const airlineFilterOptions = Object.keys(airlineGroups).map(
      (airlineName) => {
        const { count, minPrice } = airlineGroups[airlineName];
        const label = `${airlineName} (${count})`;
        return { label, count, minPrice, value: airlineName };
      }
    );

    filterOptions.push({ stopFilterOptions, airlineFilterOptions });
  }

  return filterOptions;
};

export const getDepartureAndArivalCityByRoute = (groupedRoutes: any[]) => {
  return groupedRoutes
    .map((route) => {
      if (!route) return null;
      const departureCode = route?.sI[0]?.da?.code || "";
      const arrivalCode = route?.sI[route?.sI?.length - 1]?.aa?.code || "";
      const departureCity = route?.sI[0]?.da?.city || "";
      const arrivalCity = route?.sI[route?.sI?.length - 1]?.aa?.city || "";

      return {
        departureCode,
        arrivalCode,
        departureCity,
        arrivalCity,
      };
    })
    .filter(Boolean);
};
