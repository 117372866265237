import React from "react";
import moment from "moment";
import {
  getArrivalDayMessage,
  getDiffInHRAndMin,
  getFlightImage,
} from "../../_helpers/helper";
import PLANE from "../../assets/images/plane.png";

interface ComboFlightRouteProps {
  route: any;
}

const ComboFlightRoute = ({ route }: ComboFlightRouteProps) => {
  const flightNamesArray = route?.sI?.map((sI: any) => sI?.fD?.aI?.name) || [];
  const uniqueFlightNames = Array.from(new Set(flightNamesArray));
  const flightCodesArray = route?.sI?.map((sI: any) => sI?.fD?.aI?.code) || [];
  const uniqueFlightCodes = Array.from(new Set(flightCodesArray));

  const startTime = moment(route?.sI[0]?.dt);
  const endTime = moment(route?.sI[route?.sI?.length - 1]?.at);

  const flightDetails = route?.sI?.reduce(
    (acc: string[], sI: any, index: number, array: any[]) => {
      const flightNo = sI?.fD?.fN;
      const flightCode = sI?.fD?.aI?.code;

      // Add flight details if not already added
      if (!acc.some((detail) => detail.includes(flightNo))) {
        acc.push(`${flightCode} - ${flightNo}`);
      }

      // Always add the last item
      if (
        index === array.length - 1 &&
        !acc[acc.length - 1].includes(flightNo)
      ) {
        acc.push(`${flightCode} - ${flightNo}`);
      }

      return acc;
    },
    []
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "3%",
          marginBottom: 10,
          borderBottom: "1px solid lightgray",
        }}
      >
        <div className="flight-flex" style={{ width: "40%" }}>
          <div className="fight-name combo-fight-name">
            {uniqueFlightCodes?.length > 1 ? (
              <div style={{ height: 40, width: 40, display: "flex" }}>
                {uniqueFlightCodes?.map((code: any, index: number) => {
                  if (index < 2) {
                    return (
                      <img
                        src={getFlightImage(code)}
                        alt=""
                        style={{
                          height: 20,
                          width: 20,
                          marginBottom: index === 0 ? 20 : 0,
                          marginTop: index === 0 ? 0 : 20,
                        }}
                      />
                    );
                  }
                })}
              </div>
            ) : (
              uniqueFlightCodes?.map((code: any) => {
                return <img src={getFlightImage(code)} alt="" />;
              })
            )}
            <span>
              <h5>{uniqueFlightNames.join(", ")}</h5>
              <p>{flightDetails.join(", ")}</p>
            </span>
          </div>
        </div>
        <div className="" style={{ flex: 1, width: "57%" }}>
          <div
            className="route-timing"
            style={{
              justifyContent: "space-between",
            }}
          >
            <div className="route-strating">
              <p>{route?.sI[0]?.da?.city}</p>
              {moment(startTime)?.format("HH:mm")}
              <p>{moment(startTime)?.format("MMM DD")}</p>
            </div>
            <div className="cunsume-time">
              <span>
                {route?.sI?.length - 1 === 0
                  ? "Non-Stop"
                  : `${route?.sI?.length - 1} Stop`}
              </span>
              <div className="cunsume-pic">
                <img src={PLANE} alt="" />
              </div>
              <p>{getDiffInHRAndMin(startTime, endTime)}</p>
            </div>
            <div className="route-strating">
              <p>{route?.sI[route?.sI?.length - 1]?.aa?.city}</p>
              {moment(endTime)?.format("HH:mm")}
              <p>{moment(endTime)?.format("MMM DD")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComboFlightRoute;
