import React from "react";

const BookCard = ({ feature }: { feature: any }) => {
  return (
    <div className="book-card">
      <div className="book-card-top">
        <h4>
          {feature.title.split(" ").map((line: any, i: number) => (
            <React.Fragment key={i}>
              {line} <br />
            </React.Fragment>
          ))}
        </h4>
        <img src={feature.image} alt={feature.title} />
      </div>
      <p>{feature.description}</p>
    </div>
  );
};

export default BookCard;
