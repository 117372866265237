import React from "react";

const DestinationCard = ({ destination }: { destination: any }) => {
  return (
    <div className="p-2">
      <div className="Destinations-card">
        <div className="Destinations-card-pic">
          <img src={destination?.image} alt={destination?.name} />
        </div>
        <div className="Destinations-card-body">
          <p>{destination?.propertiesCount} Properties</p>
          <h4>{destination?.name}</h4>
        </div>
      </div>
    </div>
  );
};

export default DestinationCard;
