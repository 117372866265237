import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL, TRIPJACK_API_URL } from "../../../app/config";
import { ErrorToast } from "../../../toast/Toasts";
import { DUMMY_REVIEWFLIGHTDETAILS } from "../../../_constants/constant";

export const getGroupwiseTravellerByUserID = createAsyncThunk(
  "TravellersInformation/FindBirthdateByUserID",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TravellersInformation/FindBirthdateByUserID`,
        payload
      );
      const formatedResponse = {
        adults: response.data?.Data.AdultDataView?.map((e: any) => ({
          ...e,
          first_name: e?.first_name,
          last_name: e?.last_name,
          gender: e?.gender ? `${e?.gender}` : "",
          mobile_no: e?.mobile_no,
          email_id: e?.email_id,
          isSelected: false,
          initial: e?.initial,
          date_of_birth: e?.date_of_birth,
          passport_no: e?.passport_no,
          issue_country_code: e?.issue_country_code,
          issue_date: e?.issue_date,
          expire_date: e?.expire_date,
        })),
        children: response.data?.Data.ChildDateView?.map((e: any) => ({
          ...e,
          first_name: e?.first_name,
          last_name: e?.last_name,
          gender: e?.gender ? `${e?.gender}` : "",
          isSelected: false,
          initial: e?.initial,
          date_of_birth: e?.date_of_birth,
          passport_no: e?.passport_no,
          issue_country_code: e?.issue_country_code,
          issue_date: e?.issue_date,
          expire_date: e?.expire_date,
        })),
        infants: response.data?.Data.InfantDataView?.map((e: any) => ({
          ...e,
          first_name: e?.first_name,
          last_name: e?.last_name,
          gender: e?.gender ? `${e?.gender}` : "",
          date_of_birth: e?.date_of_birth,
          isSelected: false,
          initial: e?.initial,
          passport_no: e?.passport_no,
          issue_country_code: e?.issue_country_code,
          issue_date: e?.issue_date,
          expire_date: e?.expire_date,
        })),
      };
      return formatedResponse;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error get travellers by user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getReviewFlightBooking = createAsyncThunk(
  "Flight/ReviewFlight",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${TRIPJACK_API_URL}/fms/v1/review`,
        payload
      );
      // return DUMMY_REVIEWFLIGHTDETAILS;
      return response.data;
    } catch (error: any) {
      ErrorToast(
        (error?.response?.data?.errors[0] &&
          error?.response?.data?.errors[0]?.message) ||
          "Something went wrong!"
      );
      return Promise.reject({
        message:
          (error?.response?.data?.errors[0] &&
            error?.response?.data?.errors[0]?.message) ||
          "Something went wrong!",
      });
    }
  }
);

export const confirmFlightBooking = createAsyncThunk(
  "Flight/ConfirmBooking",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${TRIPJACK_API_URL}/oms/v1/air/book`,
        payload
      );
      return response.data;
    } catch (error: any) {
      ErrorToast(
        (error?.response?.data?.errors[0] &&
          error?.response?.data?.errors[0]?.message) ||
          "Something went wrong!"
      );
      return Promise.reject({
        message:
          (error?.response?.data?.errors[0] &&
            error?.response?.data?.errors[0]?.message) ||
          "Something went wrong!",
      });
    }
  }
);

export const getConfirmFlightBooking = createAsyncThunk(
  "Flight/getConfirmFlightBooking",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${TRIPJACK_API_URL}/oms/v1/booking-details`,
        payload
      );
      return response.data;
    } catch (error: any) {
      ErrorToast(
        (error?.response?.data?.errors[0] &&
          error?.response?.data?.errors[0]?.message) ||
          "Something went wrong!"
      );
      return Promise.reject({
        message:
          (error?.response?.data?.errors[0] &&
            error?.response?.data?.errors[0]?.message) ||
          "Something went wrong!",
      });
    }
  }
);

export const saveFlightBooking = createAsyncThunk(
  "FlightBooking/InsertFlightBooking",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightBooking/InsertFlightBooking`,
        payload
      );

      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error insert booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const saveTempFlightBooking = createAsyncThunk(
  "FlightBooking/InsertTempFlightBooking",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightBooking/InsertTempFlightBooking`,
        payload
      );

      return response.data?.Data;
    } catch (error: any) {
      console.error("Error insert tempbooking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const cretePaymentOrder = createAsyncThunk(
  "RazorpayGateway/CreateOrder",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RazorpayGateway/CreateOrder`,
        payload
      );

      return response.data;
    } catch (error: any) {
      console.error("Payment Order Created failed:", error);
      return Promise.reject({
        message:
          error?.response?.data?.Details || "Payment Order Created failed",
      });
    }
  }
);

export const verifyPayment = createAsyncThunk(
  "RazorpayGateway/verify",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RazorpayGateway/verify`,
        payload
      );

      return response.data;
    } catch (error: any) {
      console.error("Payment verification failed:", error);
      return Promise.reject({
        message:
          error?.response?.data?.Details || "Payment verification failed",
      });
    }
  }
);
