import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../toast/Toasts";
import { setUserDetails } from "../../login/loginSlice";

export const getUserByID = createAsyncThunk(
  "User/FindByIDUser",
  async (payload: any, { dispatch }) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDUser`,
        payload
      );
      dispatch(
        setUserDetails({
          firstName: response.data?.Data?.first_name,
          lastName: response.data?.Data?.last_name,
        })
      );
      localStorage.setItem("first_name", response.data?.Data?.first_name);
      localStorage.setItem("last_name", response.data?.Data?.last_name);
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error get user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getLoginDetailsByID = createAsyncThunk(
  "User/FindByEmailDataByUserGUID",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByEmailDataByUserGUID`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error get login details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "User/UpdateUser",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateUser`,
        payload
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUserEmail = createAsyncThunk(
  "User/UpdateEmailID",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmailID`,
        payload
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const emailVerificationMail = createAsyncThunk(
  "UserLogin/EmailVerification",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserLogin/EmailVerification`,
        payload
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error email verification:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBillingDetails = createAsyncThunk(
  "User/FindByBillingInformationUserGUID",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByBillingInformationUserGUID`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error email verification:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUserBillingDetails = createAsyncThunk(
  "User/UpdateBillingInformation",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateBillingInformation`,
        payload
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTravellersByUserID = createAsyncThunk(
  "TravellersInformation/FindByUserIDTravellersInformation",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TravellersInformation/FindByUserIDTravellersInformation`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTraveller = createAsyncThunk(
  "TravellersInformation/InsertTravellersInformation",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TravellersInformation/InsertTravellersInformation`,
        payload
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTraveller = createAsyncThunk(
  "TravellersInformation/UpdateTravellersInformation",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TravellersInformation/UpdateTravellersInformation`,
        payload
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTraveller = createAsyncThunk(
  "TravellersInformation/DeleteTravellersInformation",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TravellersInformation/DeleteTravellersInformation`,
        payload
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getbyIDTraveller = createAsyncThunk(
  "TravellersInformation/FindByIDTravellersInformation",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TravellersInformation/FindByIDTravellersInformation`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadUserImage = createAsyncThunk(
  "User/UserImageUpdate",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UserImageUpdate`,
        payload,
        {
          headers: { "content-type": "multipart/form-data" },
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserImageByUserID = createAsyncThunk(
  "User/FindByProfileImageGUID",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByProfileImageGUID`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error update email:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllLoggedDevices = createAsyncThunk(
  "LoginActivity/LoginList",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LoginActivity/LoginList`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error get Logged in devices:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
