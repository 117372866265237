import React from "react";
import moment from "moment";
import { formatIndianNumber, getFlightImage } from "../../_helpers/helper";
import PLANE from "../../assets/images/plane.png";

const ReturnFlightsFooter = ({
  selectedOnward,
  selectedReturn,
  totalOPrice,
  totalRPrice,
  handleBookNowClick,
}: any) => {
  return (
    <div className="footer-fix">
      {selectedOnward?.route && (
        <div className="flight-mobile">
          <div className="fight-name">
            <img
              src={getFlightImage(
                selectedOnward?.route?.sI[0]?.fD?.aI?.code
                // selectedOnward?.route?.sI[0]?.fD?.aI?.name
              )}
              alt=""
            />
            <span>
              <h5>
                {selectedOnward?.route?.sI?.map(
                  (sI: any, index: number, array: any[]) => {
                    const name = sI?.fD?.aI?.name;
                    const isLastItem = index === array.length - 1;

                    // Check if the name is repeated
                    const isRepeated = array.some(
                      (s: any, i: number) =>
                        i !== index && s?.fD?.aI?.name === name
                    );

                    // Render the name if it's the first occurrence, the last item, or if it's repeated
                    if (
                      !isRepeated ||
                      isLastItem ||
                      array.lastIndexOf(name) === index
                    ) {
                      return `${name}${!isLastItem ? ", " : ""}`;
                    }
                    return null;
                  }
                )}
              </h5>
              <p>
                {selectedOnward?.route?.sI?.map(
                  (sI: any, index: number, array: any[]) => {
                    const flightNo = sI?.fD?.fN;
                    const flightCode = sI?.fD?.aI?.code;
                    const isLastItem = index === array.length - 1;

                    // Check if the name is repeated
                    const isRepeated = array.some(
                      (s: any, i: number) =>
                        i !== index && s?.fD?.fN === flightNo
                    );

                    // Render the name if it's the first occurrence, the last item, or if it's repeated
                    if (
                      !isRepeated ||
                      isLastItem ||
                      array.lastIndexOf(flightNo) === index
                    ) {
                      return `${flightCode} - ${flightNo}${
                        !isLastItem ? ", " : ""
                      }`;
                    }
                    return null;
                  }
                )}
              </p>
            </span>
          </div>
          <div className="route-timing">
            <div className="route-strating">
              {moment(moment(selectedOnward?.route?.sI[0]?.dt))?.format(
                "HH:mm"
              )}
              <p>{selectedOnward?.route?.sI[0]?.da?.code}</p>
            </div>
            <div className="cunsume-time">
              <div className="cunsume-pic">
                <img src={PLANE} alt="" />
              </div>
            </div>
            <div className="route-strating">
              {moment(
                moment(
                  selectedOnward?.route?.sI[
                    selectedOnward?.route?.sI?.length - 1
                  ]?.at
                )
              )?.format("HH:mm")}
              <p>
                {
                  selectedOnward?.route?.sI[
                    selectedOnward?.route?.sI?.length - 1
                  ]?.aa?.code
                }
              </p>
            </div>
          </div>
          <div className="route-price">₹ {formatIndianNumber(totalOPrice)}</div>
        </div>
      )}
      |
      {selectedReturn?.route && (
        <div className="flight-mobile">
          <div className="fight-name">
            <img
              src={getFlightImage(
                selectedReturn?.route?.sI[0]?.fD?.aI?.code
                // selectedReturn?.route?.sI[0]?.fD?.aI?.name
              )}
              alt=""
            />
            <span>
              <h5>
                {selectedReturn?.route?.sI?.map(
                  (sI: any, index: number, array: any[]) => {
                    const name = sI?.fD?.aI?.name;
                    const isLastItem = index === array.length - 1;

                    // Check if the name is repeated
                    const isRepeated = array.some(
                      (s: any, i: number) =>
                        i !== index && s?.fD?.aI?.name === name
                    );

                    // Render the name if it's the first occurrence, the last item, or if it's repeated
                    if (
                      !isRepeated ||
                      isLastItem ||
                      array.lastIndexOf(name) === index
                    ) {
                      return `${name}${!isLastItem ? ", " : ""}`;
                    }
                    return null;
                  }
                )}
              </h5>
              <p>
                {selectedReturn?.route?.sI?.map(
                  (sI: any, index: number, array: any[]) => {
                    const flightNo = sI?.fD?.fN;
                    const flightCode = sI?.fD?.aI?.code;
                    const isLastItem = index === array.length - 1;

                    // Check if the name is repeated
                    const isRepeated = array.some(
                      (s: any, i: number) =>
                        i !== index && s?.fD?.fN === flightNo
                    );

                    // Render the name if it's the first occurrence, the last item, or if it's repeated
                    if (
                      !isRepeated ||
                      isLastItem ||
                      array.lastIndexOf(flightNo) === index
                    ) {
                      return `${flightCode} - ${flightNo}${
                        !isLastItem ? ", " : ""
                      }`;
                    }
                    return null;
                  }
                )}
              </p>
            </span>
          </div>
          <div className="route-timing">
            <div className="route-strating">
              {moment(moment(selectedReturn?.route?.sI[0]?.dt))?.format(
                "HH:mm"
              )}
              <p>{selectedReturn?.route?.sI[0]?.da?.code}</p>
            </div>
            <div className="cunsume-time">
              <div className="cunsume-pic">
                <img src={PLANE} alt="" />
              </div>
            </div>
            <div className="route-strating">
              {moment(
                moment(
                  selectedReturn?.route?.sI[
                    selectedReturn?.route?.sI?.length - 1
                  ]?.at
                )
              )?.format("HH:mm")}
              <p>
                {
                  selectedReturn?.route?.sI[
                    selectedReturn?.route?.sI?.length - 1
                  ]?.aa?.code
                }
              </p>
            </div>
          </div>
          <div className="route-price">₹ {formatIndianNumber(totalRPrice)}</div>
        </div>
      )}
      |
      {selectedOnward?.route && selectedReturn?.route && (
        <div className="footer-last">
          <div className="price">
            ₹ {formatIndianNumber(totalOPrice + totalRPrice)}
          </div>
          <a className="fill-btn" onClick={() => handleBookNowClick()}>
            Book Now
          </a>
        </div>
      )}
    </div>
  );
};

export default ReturnFlightsFooter;
