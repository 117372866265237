import SUN from "../assets/images/sun.png";
import CLOUD_SUN from "../assets/images/cloud-sun.png";
import SUN_MOON from "../assets/images/sun-moon.png";
import SUN_SET from "../assets/images/SUN_SET.jpeg";

export const CABBINCLASSARRAY = [
  { class: "Economy", key: "E" },
  { class: "Premium", key: "P" },
  { class: "Business", key: "B" },
];

export const TRIPTYPEARRAY = [
  { type: "One Way", key: "O" },
  { type: "Round trip", key: "R" },
  { type: "Multicity", key: "M" },
];

export const FARETYPEARRAY = [
  { type: "Regular", key: "REG" },
  { type: "Student", key: "STU" },
  { type: "Senior Citizen", key: "SC" },
  // "Armed Forces",
  // "Doctor and Nurses",
];

export const INFANTARRAY = [0, 1, 2, 3, 4, 5, 6];

export const CHILDARRAY = [0, 1, 2, 3, 4, 5, 6];

export const ADULTARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export const filterTiming = [
  { label: "Before 6AM", image: SUN_MOON, value: "before6am" },
  { label: "06AM - 12PM", image: CLOUD_SUN, value: "6am-12pm" },
  { label: "12PM - 6PM", image: SUN, value: "12pm-6pm" },
  { label: "After 6PM", image: SUN_SET, value: "after6pm" },
];

export const sortingOptions = [
  { field: "price", order: "asc", label: "Price (Lowest)" },
  { field: "price", order: "desc", label: "Price (Highest)" },
  { field: "duration", order: "asc", label: "Duration (Lowest)" },
  { field: "duration", order: "desc", label: "Duration (Highest)" },
  { field: "departure", order: "asc", label: "Departure (Lowest)" },
  { field: "departure", order: "desc", label: "Departure (Highest)" },
  { field: "arrival", order: "asc", label: "Arrival (Lowest)" },
  { field: "arrival", order: "desc", label: "Arrival (Highest)" },
];

export const countryCodes = [
  { name: "United States", code: "+1" },
  { name: "Canada", code: "+1" },
  { name: "United Kingdom", code: "+44" },
  { name: "Australia", code: "+61" },
  { name: "India", code: "+91" },
  { name: "Germany", code: "+49" },
  { name: "France", code: "+33" },
  { name: "China", code: "+86" },
  { name: "Japan", code: "+81" },
  { name: "South Korea", code: "+82" },
];

export const STICKYHEADERPATHS = [
  "/",
  "/flights",
  "/hotels",
  "/holidaypackages",
  "/buses",
];

export const INITIALS = ["Mr", "Mrs", "Master", "Ms"];

export const DUMMY_REVIEWFLIGHTDETAILS = {
  tripInfos: [
    {
      sI: [
        {
          id: "160",
          fD: {
            aI: {
              code: "AI",
              name: "Air India",
              isLcc: false,
            },
            fN: "836",
            eT: "321",
          },
          stops: 0,
          duration: 100,
          cT: 1360,
          da: {
            code: "AMD",
            name: "Sardar Vallabh Bhai Patel Intl Arpt",
            cityCode: "AMD",
            city: "Ahmedabad",
            country: "India",
            countryCode: "IN",
            terminal: "Terminal 1",
          },
          aa: {
            code: "DEL",
            name: "Delhi Indira Gandhi Intl",
            cityCode: "DEL",
            city: "Delhi",
            country: "India",
            countryCode: "IN",
            terminal: "Terminal 3",
          },
          dt: "2024-09-15T18:40",
          at: "2024-09-15T20:20",
          iand: false,
          isRs: false,
          sN: 0,
          ssrInfo: {
            MEAL: [
              {
                code: "VGML",
                desc: "Vegan Veg Meal",
              },
              {
                code: "AVML",
                desc: "Hindu Veg Meal",
              },
              {
                code: "VJML",
                desc: "Jain Veg Meal",
              },
              {
                code: "VLML",
                desc: "Lacto-Ovo Veg Meal",
              },
              {
                code: "FPML",
                desc: "Fruit Platter Meal",
              },
              {
                code: "MOML",
                desc: "Moslem Meal",
              },
              {
                code: "HNML",
                desc: "Hindu Non-Veg Meal",
              },
              {
                code: "SFML",
                desc: "Sea Food Meal",
              },
              {
                code: "DBML",
                desc: "Diabetic Meal",
              },
              {
                code: "NLML",
                desc: "Low Lactose Meal",
              },
              {
                code: "CHML",
                desc: "Child Meal",
              },
              {
                code: "BBML",
                desc: "Baby Meal",
              },
              {
                code: "BLML",
                desc: "Bland Meal",
              },
              {
                code: "GFML",
                desc: "Gluten Intolerant Meal",
              },
              {
                code: "KSML",
                desc: "Kosher Meal",
              },
              {
                code: "LCML",
                desc: "Low Calorie Meal",
              },
              {
                code: "LFML",
                desc: "Low Fat Meal",
              },
              {
                code: "LSML",
                desc: "ow Salt Meal",
              },
              {
                code: "RVML",
                desc: "Vegetarian Raw Meal",
              },
              {
                code: "VOML",
                desc: "Vegetarian Oriental Meal",
              },
              {
                code: "PFML",
                desc: "Peanut Free Meal",
              },
              {
                code: "ORML",
                desc: "Oriental Meal",
              },
              {
                code: "NSML",
                desc: "No Salt Meal",
              },
              {
                code: "PRML",
                desc: "Low Purine Meal",
              },
              {
                code: "LPML",
                desc: "Low Protein Meal",
              },
              {
                code: "HFML",
                desc: "High Fiber Meal",
              },
              {
                code: "NFML",
                desc: "No Fish Meal",
              },
            ],
          },
          ac: [],
        },
        {
          id: "161",
          fD: {
            aI: {
              code: "AI",
              name: "Air India",
              isLcc: false,
            },
            fN: "853",
            eT: "32N",
          },
          stops: 0,
          duration: 135,
          da: {
            code: "DEL",
            name: "Delhi Indira Gandhi Intl",
            cityCode: "DEL",
            city: "Delhi",
            country: "India",
            countryCode: "IN",
            terminal: "Terminal 3",
          },
          aa: {
            code: "PNQ",
            name: "Lohegaon Arpt",
            cityCode: "PNQ",
            city: "Pune",
            country: "India",
            countryCode: "IN",
          },
          dt: "2024-09-16T19:00",
          at: "2024-09-16T21:15",
          iand: false,
          isRs: false,
          sN: 1,
          ssrInfo: {
            MEAL: [
              {
                code: "VGML",
                desc: "Vegan Veg Meal",
              },
              {
                code: "AVML",
                desc: "Hindu Veg Meal",
              },
              {
                code: "VJML",
                desc: "Jain Veg Meal",
              },
              {
                code: "VLML",
                desc: "Lacto-Ovo Veg Meal",
              },
              {
                code: "FPML",
                desc: "Fruit Platter Meal",
              },
              {
                code: "MOML",
                desc: "Moslem Meal",
              },
              {
                code: "HNML",
                desc: "Hindu Non-Veg Meal",
              },
              {
                code: "SFML",
                desc: "Sea Food Meal",
              },
              {
                code: "DBML",
                desc: "Diabetic Meal",
              },
              {
                code: "NLML",
                desc: "Low Lactose Meal",
              },
              {
                code: "CHML",
                desc: "Child Meal",
              },
              {
                code: "BBML",
                desc: "Baby Meal",
              },
              {
                code: "BLML",
                desc: "Bland Meal",
              },
              {
                code: "GFML",
                desc: "Gluten Intolerant Meal",
              },
              {
                code: "KSML",
                desc: "Kosher Meal",
              },
              {
                code: "LCML",
                desc: "Low Calorie Meal",
              },
              {
                code: "LFML",
                desc: "Low Fat Meal",
              },
              {
                code: "LSML",
                desc: "ow Salt Meal",
              },
              {
                code: "RVML",
                desc: "Vegetarian Raw Meal",
              },
              {
                code: "VOML",
                desc: "Vegetarian Oriental Meal",
              },
              {
                code: "PFML",
                desc: "Peanut Free Meal",
              },
              {
                code: "ORML",
                desc: "Oriental Meal",
              },
              {
                code: "NSML",
                desc: "No Salt Meal",
              },
              {
                code: "PRML",
                desc: "Low Purine Meal",
              },
              {
                code: "LPML",
                desc: "Low Protein Meal",
              },
              {
                code: "HFML",
                desc: "High Fiber Meal",
              },
              {
                code: "NFML",
                desc: "No Fish Meal",
              },
            ],
          },
          ac: [],
        },
      ],
      totalPriceList: [
        {
          fd: {
            ADULT: {
              fC: {
                NF: 9418,
                TAF: 1519,
                TF: 9418,
                BF: 7899,
              },
              afC: {
                TAF: {
                  AGST: 412,
                  YR: 340,
                  OT: 767,
                },
              },
              bI: {
                iB: "25 Kg (01 Piece only)",
                cB: "7 Kg",
              },
              rT: 1,
              cc: "ECONOMY",
              cB: "G",
              fB: "GU1YXTII",
              mI: false,
            },
          },
          fareIdentifier: "PUBLISHED",
          id: "R15-2-10-5458623861_0AMDDELAI836DELPNQAI853~12747267169406988",
          messages: [],
          pc: {
            code: "AI",
            name: "Air India",
            isLcc: false,
          },
          fareRuleInformation: {
            fr: {},
            tfr: {
              NO_SHOW: [
                {
                  policyInfo:
                    "Non refundable (Only statutory taxes will be refunded)",
                  st: "0",
                  et: "8760",
                },
              ],
              DATECHANGE: [
                {
                  amount: 3000,
                  policyInfo:
                    "__nls__Changes permitted 02 Hrs before scheduled departure__nls__Change Penalty : INR 3,000/- or basic fare whichever is lower + Fare Difference",
                  fcs: {
                    ARFT: 0,
                    ARF: 3000,
                  },
                  st: "4",
                  et: "8760",
                },
              ],
              CANCELLATION: [
                {
                  amount: 3000,
                  policyInfo:
                    "__nls__Cancellation permitted 02 Hrs before scheduled departure__nls__Cancellation Penalty : INR 3,000/- or basic fare whichever is lower",
                  fcs: {
                    ACF: 3000,
                    ACFT: 0,
                  },
                  st: "4",
                  et: "8760",
                },
              ],
              SEAT_CHARGEABLE: [
                {
                  policyInfo: "",
                  st: "0",
                  et: "8760",
                },
              ],
            },
          },
        },
      ],
    },
  ],
  alerts: [
    {
      oldFare: 7577,
      newFare: 9418,
      type: "FAREALERT",
    },
  ],
  searchQuery: {
    routeInfos: [
      {
        fromCityOrAirport: {
          code: "AMD",
          name: "Sardar Vallabh Bhai Patel Intl Arpt",
          cityCode: "AMD",
          city: "Ahmedabad",
          country: "India",
          countryCode: "IN",
        },
        toCityOrAirport: {
          code: "PNQ",
          name: "Lohegaon Arpt",
          cityCode: "PNQ",
          city: "Pune",
          country: "India",
          countryCode: "IN",
        },
        travelDate: "2024-09-15",
      },
    ],
    cabinClass: "ECONOMY",
    paxInfo: {
      ADULT: 1,
      CHILD: 0,
      INFANT: 0,
    },
    requestId: "5458623861",
    searchType: "ONEWAY",
    searchModifiers: {
      isDirectFlight: false,
      isConnectingFlight: false,
      pft: "REGULAR",
      pfts: ["REGULAR"],
    },
    isDomestic: true,
  },
  bookingId: "TJS103301098683",
  totalPriceInfo: {
    totalFareDetail: {
      fC: {
        NF: 9418,
        TAF: 1519,
        TF: 9418,
        BF: 7899,
      },
      afC: {
        TAF: {
          AGST: 412,
          YR: 340,
          OT: 767,
        },
      },
    },
  },
  status: {
    success: true,
    httpStatus: 200,
  },
  conditions: {
    ffas: ["AI"],
    isa: true,
    dob: {
      adobr: false,
      cdobr: false,
      idobr: true,
    },
    iecr: false,
    dc: {
      ida: false,
      idm: false,
    },
    ipa: false,
    addOns: {
      isbpa: false,
    },
    isBA: true,
    st: 240,
    sct: "2024-09-16T12:37:33.190",
    gst: {
      gstappl: true,
      igm: false,
    },
  },
};
