import React from "react";
import BOOKING from "../../assets/images/booking.png";
import LOWPRICE from "../../assets/images/low-price.png";
import EXISTINGDEAL from "../../assets/images/exting-deal.png";
import SUPPORT from "../../assets/images/support.png";
import BookCard from "./BookCard";

const bookingFeatures = [
  {
    title: "Easy Booking",
    image: BOOKING,
    description:
      "We offer easy and convenient flight bookings with attractive offers.",
  },
  {
    title: "Lowest Price",
    image: LOWPRICE,
    description:
      "We ensure low rates on hotel reservation, holiday packages and on flight tickets.",
  },
  {
    title: "Exciting Deals",
    image: EXISTINGDEAL,
    description:
      "Enjoy exciting deals on flights, hotels, buses, car rental and tour packages.",
  },
  {
    title: "24/7 Support",
    image: SUPPORT,
    description:
      "Get assistance 24/7 on any kind of travel related query. We are happy to assist you.",
  },
];

const BookSection = () => {
  return (
    <div className="book-section">
      <div className="container">
        <h2>Why book with us?</h2>
        <div className="row">
          {bookingFeatures.map((feature, index) => (
            <div key={index} className="col-md-3 col-sm-6">
              <BookCard feature={feature} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BookSection;
