import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";

export const getAllActiveCountries = createAsyncThunk(
  "Country/FindAllActiveCountry",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_URL}/Country/FindAllActiveCountry`
    );
    return response.data?.Data;
  }
);
