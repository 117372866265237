import React from "react";
import Layout from "../components/layout/Layout";
import { Routes, Route as RouteItem } from "react-router-dom";
import Home from "../pages/home/Home";
import FlightListing from "../pages/flights/FlightListing";
import MyProfile from "../pages/myprofile/MyProfile";
import VerifyEmail from "../pages/verifyemail/VerifyEmail";
import ReviewBookingNew from "../pages/reviewbooking/ReviewBookingNew";
import TripDetails from "../pages/mytrips/TripDetails";
import MyTrips from "../pages/mytrips/MyTrips";
import ConfirmedTripDetails from "../pages/mytrips/ConfirmedTripDetails";

const MainRouter = () => {
  return (
    <Routes>
      <RouteItem path="/" element={<Layout />}>
        <RouteItem index={true} element={<Home />} />
        <RouteItem path={"flights"} element={<Home />} />
        <RouteItem path={"hotels"} element={<Home />} />
        <RouteItem path={"holidaypackages"} element={<Home />} />
        <RouteItem path={"buses"} element={<Home />} />
        <RouteItem path="flights/search" element={<FlightListing />} />
        <RouteItem path="my-profile" element={<MyProfile />} />
        <RouteItem
          path="flights/reviewbooking"
          element={<ReviewBookingNew />}
        />
        <RouteItem path="flights/mytrips" element={<MyTrips />} />
        <RouteItem path="flights/trip-details" element={<TripDetails />} />
        <RouteItem
          path="flights/confirmed-trip-details"
          element={<ConfirmedTripDetails />}
        />
      </RouteItem>
      <RouteItem path="verify-email" element={<VerifyEmail />} />
    </Routes>
  );
};

export default MainRouter;
