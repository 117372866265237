import React, { useEffect } from "react";
import { Link, Element, scrollSpy } from "react-scroll";
import USER2 from "../../assets/images/user-2.svg";
import LOGIN from "../../assets/images/LOGIN.svg";
import USERS from "../../assets/images/users.svg";
import DEVICES from "../../assets/images/devices.svg";
import LOGOUT from "../../assets/images/logout.svg";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ProfileCard from "./MyProfileCard";
import LoginDetails from "./LoginDetails";
import BillingDetails from "./BillingDetails";
import { getAllActiveNationalities } from "../nationality/services/nationality.services";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import LoggedinDevicesCard from "./LoggedinDevicesCard";
import { useNavigate } from "react-router";
import { setUserDetails } from "../login/loginSlice";
import Travellers from "./Travellers";
import { getAllActiveCountries } from "../country/services/country.services";
import {
  getAllLoggedDevices,
  getTravellersByUserID,
} from "./services/myprofile.services";
import { getDeviceId, getLocalItem } from "../../_helpers/helper";
import ImageUploadComponent from "./ImageUpload";
import { userLogout } from "../login/services/login.services";
import useWindowSize from "../../_hooks/useWindowSize";

const Profile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.profile.userData);
  const progressPercentage = 75;
  const { width } = useWindowSize();

  useEffect(() => {
    scrollSpy.update();
    dispatch(getAllActiveNationalities());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());

    // getDeviceDetails();
    // dispatch(
    //   getTravellersByUserID({
    //     user_guid: "72647F6F-BD6E-4A7A-95CA-5118E8EF5DA0",
    //   })
    // );
    dispatch(getTravellersByUserID({ user_guid: getLocalItem("user_guid") }));
    dispatch(getAllLoggedDevices({ user_guid: getLocalItem("user_guid") }));
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("last_name");
    localStorage.removeItem("user_guid");
    localStorage.removeItem("mobile_no");
    localStorage.removeItem("first_name");
    dispatch(setUserDetails({ firstName: "", lastName: "" }));
    dispatch(userLogout({ device_id: getDeviceId() }));
    navigate("/");
  };

  return (
    <div className="profile-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div
              className="prifile-deta"
              style={{ top: width <= 991 ? 0 : 80 }}
            >
              {/* <div className="profile-upload"> */}
              {/* <input type="file" />
                <span>{`${userData?.first_name?.charAt(0) || ""}${
                  userData?.last_name?.charAt(0) || ""
                }`}</span> */}
              <ImageUploadComponent />
              {/* </div> */}
              <h5>{`${userData?.first_name || ""} ${
                userData?.last_name || ""
              }`}</h5>
              <p>personal profile</p>
              <Link
                activeClass={"active"}
                to="profile-detail"
                spy={true}
                offset={-115}
              >
                <img src={USER2} alt="" />
                Profile
              </Link>
              <Link
                to="login-detail"
                activeClass={"active"}
                spy={true}
                offset={-115}
              >
                <img src={LOGIN} alt="" />
                Login Details
              </Link>
              <Link
                to="billing-detail"
                activeClass={"active"}
                spy={true}
                offset={-115}
              >
                <img src={LOGIN} alt="" />
                Billing Information
              </Link>
              <Link
                to="save-travel"
                activeClass={"active"}
                spy={true}
                offset={-115}
              >
                <img src={USERS} alt="" />
                Save travellers
              </Link>
              <Link
                to="logged-device"
                activeClass={"active"}
                spy={true}
                offset={-115}
              >
                <img src={DEVICES} alt="" />
                logged in device
              </Link>
              <a href="Javascript:void(0)" onClick={() => handleLogout()}>
                <img src={LOGOUT} alt="" /> Logout
              </a>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            {/* <div className="profile-process">
              <div className="process">
                <h6>
                  Complete your profile <span>75%</span>
                </h6>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progressPercentage}%` }}
                    aria-valuenow={progressPercentage}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <p>
                  Get the best out of big smile trip by adding the remaining
                  details!
                </p>
              </div>
            </div> */}
            <Element
              name="profile-detail"
              className="profile-detail mt-0"
              data-id={1}
            >
              <ProfileCard />
            </Element>
            <Element className="profile-detail" data-id={2} name="login-detail">
              <LoginDetails />
            </Element>
            <Element
              className="profile-detail"
              data-id={2}
              name="billing-detail"
            >
              <BillingDetails />
            </Element>
            <Element className="profile-detail" data-id={3} name="save-travel">
              <Travellers />
            </Element>
            <Element
              className="profile-detail"
              data-id={4}
              name="logged-device"
            >
              <LoggedinDevicesCard />
            </Element>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
