import React, { useState } from "react";
import { useLocation } from "react-router";
import { useAppDispatch } from "../../app/hooks";
import { FieldArrayRenderProps, FormikProps } from "formik";
import PLAN_TAKEOFF from "../../assets/images/plane-takeoff.jpg";
import PLANE_LANDING from "../../assets/images/plane-landing.png";
import CALENDER_DAYS from "../../assets/images/calendar-days.png";
import { Select } from "antd";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import SEARCH from "../../assets/images/search.png";
import { ErrorToast } from "../../toast/Toasts";

interface IMTripSearch {
  formik: FormikProps<any>;
  index: number;
  onSearchLocation: (e: string, options: string) => void;
  setIsMultiOpen: (e: boolean) => void;
  arrayHelpers: FieldArrayRenderProps;
}

const MTripSearch = ({
  formik,
  index,
  onSearchLocation,
  setIsMultiOpen,
  arrayHelpers,
}: IMTripSearch) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const isShowAddButton =
    formik.values?.multicities?.length - 1 === index && index > 0 && index < 5;

  const isShowDeleteButton =
    formik.values?.multicities?.length - 1 === index && index > 1;

  const [dateFocused, setDateFocused] = useState(false);

  const isBannerSearch =
    location.pathname === "/" || location.pathname === "/flights";

  return (
    <div className="tabcontent-ow tabiingcontent">
      <div className="row">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <div className="place-field">
            <span className="field-top">From</span>
            <span className="field-top field-right">To</span>
            <div
              className="place-field-dropdown place-field-1"
              style={{
                padding: isBannerSearch ? "14px 0" : "7px 0",
                justifyContent: "start",
              }}
            >
              <div className="place-field-pic">
                <img src={PLAN_TAKEOFF} alt="" />
              </div>
              <div className="select-div h-0">
                <Select
                  className={`select-control ${
                    formik.values?.multicities[index]?.from
                      ? "opacity-1"
                      : "opacity-0"
                  } `}
                  style={{
                    zIndex: 1,
                  }}
                  placeholder="Select City"
                  onChange={(value) => {
                    if (formik.values?.multicities[index]?.to !== value) {
                      formik.setFieldValue(`multicities[${index}].from`, value);
                    } else {
                      ErrorToast("From & To airports cannot be the same");
                    }
                  }}
                  showSearch
                  onSearch={(e) =>
                    onSearchLocation(e, `multicities[${index}].fromOptions`)
                  }
                  filterOption={false}
                  value={formik.values?.multicities[index]?.from}
                >
                  {formik.values.multicities[index]?.fromOptions?.map(
                    (e: any) => (
                      <option value={e?.flightplace_code} label={e?.city_name}>
                        <div className="location-field-option">
                          <div className="option-field">
                            <p className="city-option">{e?.city_name}</p>
                            <span className="code-option m-0">
                              {e.flightplace_code}
                            </span>
                          </div>
                          <p className="m-0 airport-name-option">
                            {e.flightplace_name}
                          </p>
                        </div>
                      </option>
                    )
                  )}
                </Select>
                <h3
                  className={`m-0 select-city-label ${
                    formik.values?.multicities[index]?.from
                      ? "opacity-0"
                      : "opacity-1"
                  } `}
                >
                  Select City
                </h3>
              </div>
            </div>
            <div
              className="place-field-dropdown place-field-2"
              style={{
                padding: isBannerSearch ? "14px 0" : "7px 0",
              }}
            >
              <div className="place-field-pic">
                <img src={PLANE_LANDING} alt="" />
              </div>
              <div className="select-div h-0">
                <Select
                  className={`select-control ${
                    formik.values?.multicities[index]?.to
                      ? "opacity-1"
                      : "opacity-0"
                  } `}
                  style={{
                    zIndex: 1,
                  }}
                  onChange={(value) => {
                    if (
                      formik.values?.multicities[index]?.from !== value &&
                      formik.values?.multicities[index + 1]?.to !== value
                    ) {
                      formik.setFieldValue(`multicities[${index}].to`, value);
                      if (index + 1 < formik.values?.multicities?.length) {
                        formik.setFieldValue(
                          `multicities[${index + 1}].from`,
                          value
                        );
                        formik.setFieldValue(
                          `multicities[${index + 1}].fromOptions`,
                          formik.values?.multicities[index]?.toOptions
                        );
                      }
                    } else {
                      ErrorToast("From & To airports cannot be the same");
                    }
                  }}
                  placeholder="Select City"
                  showSearch
                  onSearch={(e) =>
                    onSearchLocation(e, `multicities[${index}].toOptions`)
                  }
                  filterOption={false}
                  value={formik.values?.multicities[index]?.to}
                >
                  {formik.values.multicities[index]?.toOptions?.map(
                    (e: any) => (
                      <option value={e?.flightplace_code} label={e?.city_name}>
                        <div className="location-field-option">
                          <div className="option-field">
                            <p className="city-option">{e?.city_name}</p>
                            <span className="code-option m-0">
                              {e.flightplace_code}
                            </span>
                          </div>
                          <p className="m-0 airport-name-option">
                            {e.flightplace_name}
                          </p>
                        </div>
                      </option>
                    )
                  )}
                </Select>
                <h3
                  className={`m-0 select-city-label ${
                    formik.values?.multicities[index]?.to
                      ? "opacity-0"
                      : "opacity-1"
                  } `}
                >
                  Select City
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-lg-5 col-md-12 col-sm-12 ${
            isBannerSearch ? "" : "list-top-right"
          }`}
        >
          <div className="place-field position-relative gap-2">
            <span className="field-top">Departure</span>
            <div className="place-field-dropdown place-field-3">
              <div className="place-field-pic">
                <img src={CALENDER_DAYS} alt="" />
              </div>
              <div className="custom-select">
                <button
                  className={`select-button ${
                    isBannerSearch ? "min-h-52" : "min-h-39"
                  }`}
                  onClick={(e: any) => e.preventDefault()}
                >
                  <span className="selected-value">
                    <div className={`place-field-text formattedDate`}>
                      <h3>
                        {formik.values.multicities[index]?.departureDate
                          ? moment(
                              formik.values.multicities[index]?.departureDate
                            ).format("DD MMM'YY")
                          : ""}
                      </h3>
                      <p>
                        {formik.values.multicities[index]?.departureDate
                          ? moment(
                              formik.values.multicities[index]?.departureDate
                            ).format("dddd")
                          : "Select Date"}
                      </p>
                    </div>
                  </span>
                </button>
                <SingleDatePicker
                  id={`multicities[${index}].departureDate`}
                  date={
                    formik.values.multicities[index]?.departureDate
                      ? moment(formik.values.multicities[index]?.departureDate)
                      : null
                  }
                  onDateChange={(date) => {
                    formik.setFieldValue(
                      `multicities[${index}].departureDate`,
                      date?.toISOString()
                    );
                    formik.values.multicities.forEach(
                      (city: any, idx: number) => {
                        if (
                          idx > index &&
                          date &&
                          moment(city.departureDate).isBefore(date)
                        ) {
                          formik.setFieldValue(
                            `multicities[${idx}].departureDate`,
                            null
                          );
                        }
                      }
                    );
                  }}
                  renderMonthElement={({
                    month,
                    onMonthSelect,
                    onYearSelect,
                  }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <select
                        style={{
                          border: "1px solid lightgray",
                        }}
                        value={month.month()}
                        onChange={(e) => onMonthSelect(month, e.target.value)}
                      >
                        {moment.months().map((label, value) => (
                          <option value={value} key={`month-${value}`}>
                            {label}
                          </option>
                        ))}
                      </select>
                      <select
                        style={{
                          border: "1px solid lightgray",
                        }}
                        value={month.year()}
                        onChange={(e) => onYearSelect(month, e.target.value)}
                      >
                        {Array.from(new Array(100), (e, i) => (
                          <option
                            value={i + moment().year() - 50}
                            key={`year-${i}`}
                          >
                            {i + moment().year() - 50}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  displayFormat={"DD/MM/YYYY"}
                  focused={dateFocused}
                  openDirection="down"
                  anchorDirection="left"
                  numberOfMonths={1}
                  onFocusChange={({ focused }) => {
                    setDateFocused(focused);
                  }}
                  hideKeyboardShortcutsPanel={true}
                  isOutsideRange={(day) =>
                    day.isBefore(
                      moment(
                        formik.values?.multicities[index - 1]?.departureDate ||
                          new Date()
                      ),
                      "day"
                    )
                  }
                />
              </div>
            </div>
            <div className="place-field-dropdown place-field-4">
              <div className="add-remove-btn">
                {isShowAddButton && (
                  <button
                    type="button"
                    className="fill-btn"
                    onClick={() =>
                      arrayHelpers.push({
                        from: formik.values?.multicities[index]?.to,
                        fromOptions:
                          formik.values?.multicities[index]?.toOptions,
                        toOptions:
                          formik.values?.multicities[index]?.fromOptions,
                        to: "",
                        departureDate:
                          formik.values?.multicities[index]?.departureDate,
                      })
                    }
                  >
                    Add City
                  </button>
                )}
                {isShowDeleteButton && (
                  <button
                    type="button"
                    className="btn-close position-relative top-0 end-0 z-index-0"
                    onClick={() => arrayHelpers.remove(index)}
                  />
                )}
              </div>
            </div>
          </div>
          {!isBannerSearch &&
            index + 1 === formik.values?.multicities?.length && (
              <a
                onClick={() => {
                  setIsMultiOpen(false);
                  const newFormKey = (formik.values.formKey || 0) + 1;
                  formik.setFieldValue("formKey", newFormKey);
                  formik.handleSubmit();
                }}
                className="fill-btn"
              >
                <img src={SEARCH} alt="" />
              </a>
            )}
        </div>
      </div>
    </div>
  );
};

export default MTripSearch;
