import React, { useEffect, useRef } from "react";
import SLIDERS_HORIZONTAL from "../../assets/images/sliders-horizontal.png";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { filterTiming } from "../../_constants/constant";
import {
  setComboActiveIndex,
  setComboFilters,
  setFilter,
  // setFilterLoading,
  setMulticityFilter,
  setReturnFilter,
} from "./flightSlice";
import {
  generateComboFilterOptions,
  generateFilterOptions,
  getCurrentFlightSearchFromLocalStorage,
  getDepartureAndArivalCityByRoute,
  groupComboFlights,
} from "../../_helpers/helper";
import useWindowSize from "../../_hooks/useWindowSize";
import { setFilterAsync } from "./services/flight.services";

const Checkbox = ({
  label,
  checked,
  onChange,
  id,
}: {
  label: string;
  checked: any;
  onChange: () => void;
  id: string;
}) => (
  <div className="sidebar-field">
    <input type="checkbox" checked={checked} onChange={onChange} id={id} />
    <label htmlFor={id}>{label}</label>
  </div>
);

const FilterSection = ({
  title,
  options,
  selectedOptions,
  handleChange,
  type,
}: {
  title: string;
  options: any;
  selectedOptions: any;
  handleChange: (value: any) => void;
  type?: string;
}) => (
  <>
    {type === "multicity" && (
      <h5 className="multicity-filter-label">{title}</h5>
    )}
    <div
    //  className="sidebar-content"
    >
      <div className="sidebar-left">
        <table className="w-100">
          <tr>
            <td>{type !== "multicity" && <h5>{title}</h5>}</td>
            <td className="text-end">
              {type !== "multicity" && <h5>From</h5>}
            </td>
          </tr>
          {options?.map((option: any, index: number) => (
            <tr key={index}>
              <td>
                <Checkbox
                  key={index}
                  label={option.label}
                  checked={selectedOptions?.includes(option?.value)}
                  onChange={() => handleChange(option?.value)}
                  id={`${title.toLowerCase()}-${index}`}
                />
              </td>
              <td style={{ textAlign: "right", verticalAlign: "baseline" }}>
                <span key={index}>₹{option.minPrice}</span>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  </>
);

const FlightsFilter = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const flightData: any = useAppSelector((state) => state.flight.flightData);
  const onwardFilters: any = useAppSelector(
    (state) => state.flight.onwardFilters
  );
  const returnFilters: any = useAppSelector(
    (state) => state.flight.returnFilters
  );
  const multicityFilters: any = useAppSelector(
    (state) => state.flight.multicityFilters
  );
  const comboFilters: any = useAppSelector(
    (state) => state.flight.comboFilters
  );
  const multicityActiveIndex: any = useAppSelector(
    (state) => state.flight.multicityActiveIndex
  );
  const comboActiveIndex: any = useAppSelector(
    (state) => state.flight.comboActiveIndex
  );
  const flightSearchDetails: any = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const multicities = getCurrentFlightSearchFromLocalStorage("multicities");

  const tripInfos = flightData?.searchResult?.tripInfos || {};

  const onwardFlights = flightData?.searchResult?.tripInfos?.ONWARD;
  const returnFlights = flightData?.searchResult?.tripInfos?.RETURN;
  const comboFlights = flightData?.searchResult?.tripInfos?.COMBO || [];
  const groupedFlights = groupComboFlights(comboFlights);
  const multiCityFlights = Object.values(
    flightData?.searchResult?.tripInfos || {}
  );

  const combocities = getDepartureAndArivalCityByRoute(
    (groupedFlights?.length > 0 && groupedFlights[0]) || []
  );

  const {
    stopFilterOptions: onwardStopFilterOptions,
    airlineFilterOptions: onwardAirlineFilterOptions,
  } = generateFilterOptions(onwardFlights, flightSearchDetails);
  const comboFilterOptions = generateComboFilterOptions(
    groupedFlights,
    flightSearchDetails
  );
  const {
    stopFilterOptions: returnStopFilterOptions,
    airlineFilterOptions: returnAirlineFilterOptions,
  } = generateFilterOptions(returnFlights, flightSearchDetails);

  const handleOptionChange = (
    option: any,
    selectedOptions: any = [],
    filterKey: any,
    type?: string,
    index?: number
  ) => {
    dispatch(
      setFilterAsync({
        option,
        selectedOptions,
        filterKey,
        type,
        index,
        returnFilters,
        multicityFilters,
        comboFilters,
        onwardFilters,
      })
    );
  };

  return (
    <div
      className="col-lg-3 col-md-5 col-sm-12"
      style={{ zIndex: 2, top: width <= 991 ? 0 : 80 }}
    >
      <div className="filter-toggle">
        <h5>
          Popular Filters
          <img src={SLIDERS_HORIZONTAL} alt="" />
        </h5>
      </div>
      <div className="filter-overlay">
        <div className="listing-sidebar">
          <span className="close-btn"></span>
          <div className="sidebar-title pb-2">
            <h5>Popular Filters</h5>
            <div className="makeFlex gap8 hrtlCenter mt-2 hide-scrollbar overflow-x-auto">
              {combocities?.map((flight: any, index) => (
                <div className="fareCardItem filter-fareCardItem" key={index}>
                  <input
                    type="radio"
                    name="flight"
                    value={index}
                    checked={index === comboActiveIndex}
                    onChange={() => dispatch(setComboActiveIndex(index))}
                  />
                  <span
                    style={{
                      background:
                        index === comboActiveIndex ? "#cdffe4" : undefined,
                      borderColor:
                        index === comboActiveIndex ? "#08a34e" : undefined,
                    }}
                  ></span>
                  <label
                    htmlFor=""
                    style={{
                      color: index === comboActiveIndex ? "#08a34e" : undefined,
                    }}
                  >
                    {`${flight?.departureCode} - ${flight?.arrivalCode}`}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {/* need to change if proper validation is not working */}
          {tripInfos[`${multicityActiveIndex}`] &&
          tripInfos[`${multicityActiveIndex}`]?.length > 0 ? (
            <FilterSection
              title={`Stops from ${multicities[multicityActiveIndex]?.fromCity}`}
              options={
                generateFilterOptions(
                  multiCityFlights[multicityActiveIndex] || [],
                  flightSearchDetails
                )?.stopFilterOptions
              }
              selectedOptions={multicityFilters[multicityActiveIndex]?.stop}
              handleChange={(stops) =>
                handleOptionChange(
                  stops,
                  multicityFilters[multicityActiveIndex]?.stop,
                  "stop",
                  "multicity",
                  multicityActiveIndex
                )
              }
              type="multicity"
            />
          ) : comboFlights?.length > 0 ? (
            <FilterSection
              title={`Stops from ${combocities[comboActiveIndex]?.departureCity}`}
              options={
                comboFilterOptions[comboActiveIndex]?.stopFilterOptions || []
              }
              selectedOptions={comboFilters[comboActiveIndex]?.stop}
              handleChange={(stops) =>
                handleOptionChange(
                  stops,
                  comboFilters[comboActiveIndex]?.stop,
                  "stop",
                  "combo",
                  comboActiveIndex
                )
              }
            />
          ) : (
            <FilterSection
              title={returnFlights ? "Onward Stops" : "Stops"}
              options={onwardStopFilterOptions}
              selectedOptions={onwardFilters?.stop}
              handleChange={(stops) =>
                handleOptionChange(stops, onwardFilters?.stop, "stop")
              }
            />
          )}
          {returnFlights && (
            <FilterSection
              title="Return Stops"
              options={returnStopFilterOptions}
              selectedOptions={returnFilters?.stop}
              handleChange={(stops) =>
                handleOptionChange(stops, returnFilters?.stop, "stop", "return")
              }
            />
          )}
          {tripInfos[`${multicityActiveIndex}`] &&
          tripInfos[`${multicityActiveIndex}`]?.length > 0 ? (
            <FilterSection
              title={`Airlines from ${multicities[multicityActiveIndex]?.fromCity}`}
              options={
                generateFilterOptions(
                  multiCityFlights[multicityActiveIndex] || [],
                  flightSearchDetails
                )?.airlineFilterOptions
              }
              selectedOptions={multicityFilters[multicityActiveIndex]?.airline}
              handleChange={(airline) =>
                handleOptionChange(
                  airline,
                  multicityFilters[multicityActiveIndex]?.airline,
                  "airline",
                  "multicity",
                  multicityActiveIndex
                )
              }
              type="multicity"
            />
          ) : comboFlights?.length > 0 ? (
            <FilterSection
              title={`Airlines from ${combocities[comboActiveIndex]?.departureCity}`}
              options={
                comboFilterOptions[comboActiveIndex]?.airlineFilterOptions || []
              }
              selectedOptions={comboFilters[comboActiveIndex]?.airline}
              handleChange={(airline) =>
                handleOptionChange(
                  airline,
                  comboFilters[comboActiveIndex]?.airline,
                  "airline",
                  "combo",
                  comboActiveIndex
                )
              }
            />
          ) : (
            <FilterSection
              title={returnFlights ? "Onward Airlines" : "Airlines"}
              options={onwardAirlineFilterOptions}
              selectedOptions={onwardFilters?.airline}
              handleChange={(airline) =>
                handleOptionChange(airline, onwardFilters?.airline, "airline")
              }
            />
          )}
          {returnFlights && (
            <FilterSection
              title="Return Airlines"
              options={returnAirlineFilterOptions}
              selectedOptions={returnFilters?.airline}
              handleChange={(airline) =>
                handleOptionChange(
                  airline,
                  returnFilters?.airline,
                  "airline",
                  "return"
                )
              }
            />
          )}
          {tripInfos[`${multicityActiveIndex}`] &&
          tripInfos[`${multicityActiveIndex}`]?.length > 0 ? (
            <div className="sidebar-timing">
              <h5>
                Departure from {multicities[multicityActiveIndex]?.fromCity}
                {/* {getCurrentFlightSearchFromLocalStorage("fromCity")} */}
              </h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={multicityFilters[
                        multicityActiveIndex
                      ]?.departureTime?.includes(time?.value)}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          multicityFilters[multicityActiveIndex]?.departureTime,
                          "departureTime",
                          "multicity",
                          multicityActiveIndex
                        )
                      }
                      id={`departure-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : comboFlights?.length > 0 ? (
            <div className="sidebar-timing">
              <h5>
                Departure from {combocities[comboActiveIndex]?.departureCity}
              </h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={comboFilters[
                        comboActiveIndex
                      ]?.departureTime?.includes(time?.value)}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          comboFilters[comboActiveIndex]?.departureTime,
                          "departureTime",
                          "combo",
                          comboActiveIndex
                        )
                      }
                      id={`departure-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="sidebar-timing">
              <h5>
                Departure from{" "}
                {getCurrentFlightSearchFromLocalStorage("fromCity")}
              </h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={onwardFilters?.departureTime?.includes(
                        time?.value
                      )}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          onwardFilters?.departureTime,
                          "departureTime"
                        )
                      }
                      id={`departure-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {tripInfos[`${multicityActiveIndex}`] &&
          tripInfos[`${multicityActiveIndex}`]?.length > 0 ? (
            <div className="sidebar-timing">
              <h5>Arrival at {multicities[multicityActiveIndex]?.toCity}</h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={multicityFilters[
                        multicityActiveIndex
                      ]?.arrivalTime?.includes(time?.value)}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          multicityFilters[multicityActiveIndex]?.arrivalTime,
                          "arrivalTime",
                          "multicity",
                          multicityActiveIndex
                        )
                      }
                      id={`arrival-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : comboFlights?.length > 0 ? (
            <div className="sidebar-timing">
              <h5>Arrival at {combocities[comboActiveIndex]?.arrivalCity}</h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={comboFilters[
                        comboActiveIndex
                      ]?.arrivalTime?.includes(time?.value)}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          comboFilters[comboActiveIndex]?.arrivalTime,
                          "arrivalTime",
                          "combo",
                          comboActiveIndex
                        )
                      }
                      id={`arrival-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="sidebar-timing">
              <h5>
                Arrival at {getCurrentFlightSearchFromLocalStorage("toCity")}
              </h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={onwardFilters?.arrivalTime?.includes(
                        time?.value
                      )}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          onwardFilters?.arrivalTime,
                          "arrivalTime"
                        )
                      }
                      id={`arrival-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {returnFlights && (
            <div className="sidebar-timing">
              <h5>
                Departure from{" "}
                {getCurrentFlightSearchFromLocalStorage("toCity")}
              </h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={returnFilters?.departureTime?.includes(
                        time?.value
                      )}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          returnFilters?.departureTime,
                          "departureTime",
                          "return"
                        )
                      }
                      id={`departure-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {returnFlights && (
            <div className="sidebar-timing">
              <h5>
                Arrival at {getCurrentFlightSearchFromLocalStorage("fromCity")}
              </h5>
              <div className="sider-bar-depar">
                {filterTiming?.map((time) => (
                  <div className="timimg-departure" key={time.value}>
                    <input
                      type="checkbox"
                      checked={returnFilters?.arrivalTime?.includes(
                        time?.value
                      )}
                      onChange={() =>
                        handleOptionChange(
                          time.value,
                          returnFilters?.arrivalTime,
                          "arrivalTime",
                          "return"
                        )
                      }
                      id={`arrival-${time.value}`}
                    />
                    <div className="timing-radio">
                      <img
                        src={time?.image}
                        alt=""
                        className="mix-blend-multiply"
                      />
                      <span>({time?.label})</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FlightsFilter;
