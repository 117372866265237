import React, { useEffect, ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getLocalItem } from "../../_helpers/helper";
import { MdOutlineEdit } from "react-icons/md";
import {
  getUserImageByUserID,
  uploadUserImage,
} from "./services/myprofile.services";
import Loading from "../../components/spinner/Loading";
import { ErrorToast } from "../../toast/Toasts";

const ImageUploadComponent: React.FC = () => {
  const userData = useAppSelector((state) => state.profile.userData);
  const imageData = useAppSelector((state) => state.profile.imageData);
  const imageUploadLoading = useAppSelector(
    (state) => state.profile.imageUploadLoading
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserImageByUserID({ user_guid: getLocalItem("user_guid") }));
  }, []);

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const user_guid = getLocalItem("user_guid");
    const maxSize = 1 * 1024 * 1024;
    if (file && file.size > maxSize) {
      return ErrorToast("File size should not be more than 1 MB");
    }
    if (file && user_guid) {
      const formData = new FormData();
      formData.append("profile_photo", file);
      formData.append("user_guid", user_guid);

      const response = await dispatch(uploadUserImage(formData));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(
          getUserImageByUserID({ user_guid: getLocalItem("user_guid") })
        );
      }
    }
  };

  const handleEditClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput?.click();
  };

  return (
    <div
      className="profile-upload"
      style={{
        background: `url(${imageData?.profile_photo})`,
      }}
    >
      <Loading loading={imageUploadLoading} />
      {!imageData?.profile_photo && (
        <span>{`${userData?.first_name?.charAt(0) || ""}${
          userData?.last_name?.charAt(0) || ""
        }`}</span>
      )}
      <input
        id="fileInput"
        type="file"
        className="file-input"
        onChange={handleImageChange}
      />
      <MdOutlineEdit onClick={handleEditClick} className="edit-button" />
    </div>
  );
};

export default ImageUploadComponent;
