import { createSlice } from "@reduxjs/toolkit";
import {
  deleteTraveller,
  emailVerificationMail,
  getAllLoggedDevices,
  getBillingDetails,
  getbyIDTraveller,
  getLoginDetailsByID,
  getTravellersByUserID,
  getUserByID,
  getUserImageByUserID,
  insertTraveller,
  updateTraveller,
  updateUserBillingDetails,
  updateUserEmail,
  updateUserInfo,
  uploadUserImage,
} from "./services/myprofile.services";
interface IProfile {
  loading: boolean;
  error: string;
  userData: any;
  loginData: any;
  LoggedInDeviceList: any;
  loginLoading: boolean;
  imageUploadLoading: boolean;
  loggedInDevicesLoading: boolean;
  billingData: any;
  imageData: any;
  travellerData: any;
  travellerGUID: number | null;
  billingLoading: boolean;
  travellerList: any[];
  travellersLoading: boolean;
  emailverificationLoading: boolean;
}

const initialState: IProfile = {
  loading: false,
  loginLoading: false,
  billingLoading: false,
  imageUploadLoading: false,
  loggedInDevicesLoading: false,
  travellersLoading: false,
  emailverificationLoading: false,
  error: "",
  userData: {},
  imageData: {},
  loginData: {},
  billingData: {},
  LoggedInDeviceList: [],
  travellerData: {
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    email_id: "",
    mobile_no: "",
    passport_no: "",
    expire_date: "",
    issue_country: "",
    pan_card_no: "",
  },
  travellerGUID: null,
  travellerList: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setTravellerDetail(state, action) {
      state.travellerData = action.payload;
    },
    clearTravellerDetail(state) {
      state.travellerData = initialState.travellerData;
    },
    setTravellerGUID(state, action) {
      state.travellerGUID = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getUserByID.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    });
    builder.addCase(getUserByID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.userData = initialState.userData;
    });
    builder.addCase(getLoginDetailsByID.pending, (state) => {
      state.loginLoading = true;
      state.error = "";
    });
    builder.addCase(getLoginDetailsByID.fulfilled, (state, action) => {
      state.loginLoading = false;
      state.loginData = action.payload;
    });
    builder.addCase(getLoginDetailsByID.rejected, (state, action) => {
      state.loginLoading = false;
      state.error = action.error.message || "Something went wrong";
      state.loginData = initialState.loginData;
    });
    builder.addCase(getBillingDetails.pending, (state) => {
      state.billingLoading = true;
      state.error = "";
    });
    builder.addCase(getBillingDetails.fulfilled, (state, action) => {
      state.billingLoading = false;
      state.billingData = action.payload;
    });
    builder.addCase(getBillingDetails.rejected, (state, action) => {
      state.billingLoading = false;
      state.error = action.error.message || "Something went wrong";
      state.billingData = initialState.billingData;
    });
    builder.addCase(updateUserInfo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateUserInfo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateUserEmail.pending, (state) => {
      state.loginLoading = true;
      state.error = "";
    });
    builder.addCase(updateUserEmail.fulfilled, (state) => {
      state.loginLoading = false;
    });
    builder.addCase(updateUserEmail.rejected, (state, action) => {
      state.loginLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateUserBillingDetails.pending, (state) => {
      state.billingLoading = true;
      state.error = "";
    });
    builder.addCase(updateUserBillingDetails.fulfilled, (state) => {
      state.billingLoading = false;
    });
    builder.addCase(updateUserBillingDetails.rejected, (state, action) => {
      state.billingLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(emailVerificationMail.pending, (state) => {
      state.emailverificationLoading = true;
      state.error = "";
    });
    builder.addCase(emailVerificationMail.fulfilled, (state) => {
      state.emailverificationLoading = false;
    });
    builder.addCase(emailVerificationMail.rejected, (state, action) => {
      state.emailverificationLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTravellersByUserID.pending, (state) => {
      state.travellersLoading = true;
      state.error = "";
    });
    builder.addCase(getTravellersByUserID.fulfilled, (state, action) => {
      state.travellersLoading = false;
      state.travellerList = action.payload;
    });
    builder.addCase(getTravellersByUserID.rejected, (state, action) => {
      state.travellersLoading = false;
      state.error = action.error.message || "Something went wrong";
      state.travellerList = initialState.travellerList;
    });
    builder.addCase(insertTraveller.pending, (state) => {
      state.travellersLoading = true;
      state.error = "";
    });
    builder.addCase(insertTraveller.fulfilled, (state, action) => {
      state.travellersLoading = false;
    });
    builder.addCase(insertTraveller.rejected, (state, action) => {
      state.travellersLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTraveller.pending, (state) => {
      state.travellersLoading = true;
      state.error = "";
    });
    builder.addCase(updateTraveller.fulfilled, (state, action) => {
      state.travellersLoading = false;
    });
    builder.addCase(updateTraveller.rejected, (state, action) => {
      state.travellersLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTraveller.pending, (state) => {
      state.travellersLoading = true;
      state.error = "";
    });
    builder.addCase(deleteTraveller.fulfilled, (state, action) => {
      state.travellersLoading = false;
    });
    builder.addCase(deleteTraveller.rejected, (state, action) => {
      state.travellersLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getbyIDTraveller.pending, (state) => {
      state.travellersLoading = true;
      state.error = "";
    });
    builder.addCase(getbyIDTraveller.fulfilled, (state, action) => {
      state.travellersLoading = false;
      state.travellerData = action.payload;
    });
    builder.addCase(getbyIDTraveller.rejected, (state, action) => {
      state.travellersLoading = false;
      state.error = action.error.message || "Something went wrong";
      state.travellerData = initialState.travellerData;
    });
    builder.addCase(uploadUserImage.pending, (state) => {
      state.imageUploadLoading = true;
      state.error = "";
    });
    builder.addCase(uploadUserImage.fulfilled, (state) => {
      state.imageUploadLoading = false;
    });
    builder.addCase(uploadUserImage.rejected, (state, action) => {
      state.imageUploadLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getUserImageByUserID.pending, (state) => {
      state.imageUploadLoading = true;
      state.error = "";
    });
    builder.addCase(getUserImageByUserID.fulfilled, (state, action) => {
      state.imageUploadLoading = false;
      state.imageData = action.payload;
    });
    builder.addCase(getUserImageByUserID.rejected, (state, action) => {
      state.imageUploadLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllLoggedDevices.pending, (state) => {
      state.loggedInDevicesLoading = true;
      state.error = "";
    });
    builder.addCase(getAllLoggedDevices.fulfilled, (state, action) => {
      state.loggedInDevicesLoading = false;
      state.LoggedInDeviceList = action.payload;
    });
    builder.addCase(getAllLoggedDevices.rejected, (state, action) => {
      state.loggedInDevicesLoading = false;
      state.LoggedInDeviceList = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setTravellerDetail, clearTravellerDetail, setTravellerGUID } =
  profileSlice.actions;

export default profileSlice.reducer;
