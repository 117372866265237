import { createSlice } from "@reduxjs/toolkit";
import {
  getAllFlights,
  setFilterAsync,
  setSortTypeAsync,
} from "./services/flight.services";
import {
  sortComboFlights,
  filterComboFlights,
  filterFlights,
  groupComboFlights,
  sortFlights,
} from "../../_helpers/helper";

const initialState: any = {
  loading: false,
  filterLoading: false,
  error: "",
  flightData: {},
  filteredOnwardFlights: [],
  onwardFilters: {
    stop: [],
    airline: [],
    departureTime: [],
    arrivalTime: [],
  },
  onwardSortType: {
    field: "price",
    order: "asc",
  },
  returnFilters: {
    stop: [],
    airline: [],
    departureTime: [],
    arrivalTime: [],
  },
  returnSortType: {
    field: "price",
    order: "asc",
  },
  multicityFilters: [],
  multicitySortTypes: [],
  filteredMulticityFlights: [],
  comboFilters: [],
  comboSortType: {
    field: "price",
    order: "asc",
  },
  filteredComboFlights: [],
  multicityActiveIndex: 0,
  comboActiveIndex: 0,
};

const flightSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {
    setFilter(state, action) {
      state.onwardFilters = { ...state.onwardFilters, ...action.payload };
      const filteredOnwardFlights = filterFlights(
        state.flightData?.searchResult?.tripInfos?.ONWARD,
        state.onwardFilters
      );
      const sortedFilteredFlights = sortFlights(
        filteredOnwardFlights,
        state.onwardSortType
      );
      state.filteredOnwardFlights = sortedFilteredFlights;
    },
    setSortType(state, action) {
      state.onwardSortType = action.payload;
      state.filteredOnwardFlights = sortFlights(
        [...state.filteredOnwardFlights],
        state.onwardSortType
      );
    },
    setReturnFilter(state, action) {
      state.returnFilters = { ...state.returnFilters, ...action.payload };
      const filteredReturnFlights = filterFlights(
        state.flightData?.searchResult?.tripInfos?.RETURN,
        state.returnFilters
      );
      const sortedFilteredFlights = sortFlights(
        filteredReturnFlights,
        state.returnSortType
      );
      state.filteredReturnFlights = sortedFilteredFlights;
    },
    setReturnSortType(state, action) {
      state.returnSortType = action.payload;
      state.filteredReturnFlights = sortFlights(
        [...state.filteredReturnFlights],
        state.returnSortType
      );
    },
    setMulticityFilter(state, action) {
      state.multicityFilters = action.payload;
      state.filteredMulticityFlights = (
        Object.values(
          state.flightData?.searchResult?.tripInfos || {}
        ) as any[][]
      ).map((flightList: any[], index: number) => {
        const filteredFlights = filterFlights(
          flightList,
          state.multicityFilters[index]
        );
        return sortFlights(filteredFlights, state.multicitySortTypes[index]);
      });
    },
    setMulticitySortType(state, action) {
      const { sortType, index } = action.payload;
      state.multicitySortTypes[index] = sortType;
      state.filteredMulticityFlights[index] = sortFlights(
        filterFlights(
          state.flightData?.searchResult?.tripInfos[index],
          state.multicityFilters[index]
        ),
        sortType
      );
    },
    setMulticityActiveIndex(state, action) {
      state.multicityActiveIndex = action.payload;
    },
    setComboActiveIndex(state, action) {
      state.comboActiveIndex = action.payload;
    },
    setFilterLoading(state, action) {
      state.filterLoading = action.payload;
    },

    setComboFilters(state, action) {
      // state.filterLoading = true;
      state.comboFilters = action.payload;

      const comboFlights =
        state.flightData?.searchResult?.tripInfos?.COMBO || [];

      const groupedFlights = groupComboFlights(comboFlights);

      const filteredComboFlights = filterComboFlights(
        groupedFlights,
        state.comboFilters
      );

      const sortedFilteredComboFlights = sortComboFlights(
        filteredComboFlights,
        state.comboSortType,
        state.comboActiveIndex
      );
      state.filteredComboFlights = sortedFilteredComboFlights;

      // setTimeout(() => {
      //   state.filterLoading = false;
      // }, 500);
    },

    setComboSortType(state, action) {
      state.comboSortType = action.payload;
      state.filteredComboFlights = sortComboFlights(
        [...state.filteredComboFlights],
        state.comboSortType,
        state.comboActiveIndex
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFlights.pending, (state) => {
      state.loading = true;
      state.onwardFilters = initialState.onwardFilters;
      state.onwardSortType = initialState.onwardSortType;
      state.returnFilters = initialState.returnFilters;
      state.returnSortType = initialState.returnSortType;
      state.multicityFilters = initialState.multicityFilters;
      state.multicitySortTypes = initialState.multicitySortTypes;
      state.flightData = {};
      state.filteredOnwardFlights = [];
      state.filteredReturnFlights = [];
      state.filteredMulticityFlights = [];
      state.filteredComboFlights = [];
    });
    builder.addCase(getAllFlights.fulfilled, (state, action) => {
      state.loading = false;
      state.flightData = action.payload;

      const onwardFlights = action.payload?.searchResult?.tripInfos?.ONWARD;
      const returnFlights = action.payload?.searchResult?.tripInfos?.RETURN;
      const comboFlights = action.payload?.searchResult?.tripInfos?.COMBO || [];
      const multicityFlights = action.payload?.searchResult?.tripInfos?.["0"];

      const groupedFlights = groupComboFlights(comboFlights);

      while (
        state.comboFilters.length <
          (groupedFlights?.length > 0 && groupedFlights[0]?.length) ||
        0
      ) {
        state.comboFilters.push({
          stop: [],
          airline: [],
          departureTime: [],
          arrivalTime: [],
        });
      }

      state.filteredOnwardFlights = onwardFlights
        ? sortFlights(onwardFlights, state.onwardSortType)
        : [];
      state.filteredReturnFlights = returnFlights
        ? sortFlights(returnFlights, state.returnSortType)
        : [];

      state.filteredMulticityFlights = multicityFlights
        ? (
            Object.values(
              action.payload?.searchResult?.tripInfos || {}
            ) as any[][]
          ).map((flightList: any[], index: number) => {
            if (!state.multicityFilters[index]) {
              state.multicityFilters.push({
                stop: [],
                airline: [],
                departureTime: [],
                arrivalTime: [],
              });
            }

            if (!state.multicitySortTypes[index]) {
              state.multicitySortTypes.push({
                field: "price",
                order: "asc",
              });
            }

            return sortFlights(
              flightList,
              state.multicitySortTypes[index] ||
                initialState.multicitySortTypes[0]
            );
          })
        : [];

      state.filteredComboFlights = groupedFlights
        ? sortComboFlights(
            groupedFlights,
            state.comboSortType,
            state.comboActiveIndex
          )
        : [];

      state.error = "";
    });
    builder.addCase(getAllFlights.rejected, (state, action) => {
      state.loading = false;
      state.flightData = {};
      state.filteredOnwardFlights = [];
      state.filteredReturnFlights = [];
      state.filteredMulticityFlights = [];
      state.filteredComboFlights = [];

      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(setSortTypeAsync.pending, (state) => {
      state.filterLoading = true;
    });
    builder.addCase(setSortTypeAsync.fulfilled, (state) => {
      state.filterLoading = false;
    });
    builder.addCase(setSortTypeAsync.rejected, (state) => {
      state.filterLoading = false;
    });
    builder.addCase(setFilterAsync.pending, (state) => {
      state.filterLoading = true;
    });
    builder.addCase(setFilterAsync.fulfilled, (state) => {
      state.filterLoading = false;
    });
    builder.addCase(setFilterAsync.rejected, (state) => {
      state.filterLoading = false;
    });
  },
});

export const {
  setFilter,
  setSortType,
  setReturnSortType,
  setReturnFilter,
  setMulticityFilter,
  setMulticitySortType,
  setMulticityActiveIndex,
  setComboActiveIndex,
  setComboFilters,
  setComboSortType,
  setFilterLoading,
} = flightSlice.actions;
export default flightSlice.reducer;
