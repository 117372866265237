import { createSlice } from "@reduxjs/toolkit";
import { getAllFlightPlaces } from "./services/home.services";
import moment from "moment";

const initialState = {
  loading: false,
  fromLoading: false,
  toLoading: false,
  error: "",
  flightPlaces: [],
  flightSearchDetails: {
    formKey: 0,
    from: "BLR",
    fromOptions: [
      {
        city_name: "Bengaluru",
        flightplace_code: "BLR",
        flightplace_name: "Bengaluru Intl Arpt",
      },
    ],
    triptype: "One Way",
    to: "PNQ",
    toOptions: [
      {
        city_name: "Pune",
        flightplace_code: "PNQ",
        flightplace_name: "Lohegaon Arpt",
      },
    ],
    departureDate: moment().toISOString(),
    returnDate: moment().toISOString(),
    ADULT: 1,
    CHILD: 0,
    INFANT: 0,
    cabinClass: "Economy",
    specialfare: "Regular",
    traveller: null,
    multicities: [
      {
        from: "BLR",
        to: "PNQ",
        fromCity: "Bengaluru",
        toCity: "Pune",
        departureDate: moment().toISOString(),
        fromOptions: [
          {
            city_name: "Bengaluru",
            flightplace_code: "BLR",
            flightplace_name: "Bengaluru Intl Arpt",
          },
        ],
        toOptions: [
          {
            city_name: "Pune",
            flightplace_code: "PNQ",
            flightplace_name: "Lohegaon Arpt",
          },
        ],
      },
      {
        from: "PNQ",
        to: "DEL",
        fromCity: "Pune",
        toCity: "Delhi",
        departureDate: moment().add(1, "day").toISOString(),
        fromOptions: [
          {
            city_name: "Pune",
            flightplace_code: "PNQ",
            flightplace_name: "Lohegaon Arpt",
          },
        ],
        toOptions: [
          {
            city_name: "Delhi",
            flightplace_code: "DEL",
            flightplace_name: "Delhi Indira Gandhi Intl",
          },
        ],
      },
    ],
  },
};

const packageListSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setFlightSearchDetails: (state, action) => {
      state.flightSearchDetails = action.payload;
    },
    clearFlightSearchDetails: (state) => {
      state.flightSearchDetails = initialState.flightSearchDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFlightPlaces.pending, (state) => {
      state.fromLoading = true;
    });
    builder.addCase(getAllFlightPlaces.fulfilled, (state, action) => {
      state.fromLoading = false;
      state.flightPlaces = action.payload;
      state.error = "";
    });
    builder.addCase(getAllFlightPlaces.rejected, (state, action) => {
      state.fromLoading = false;
      state.flightPlaces = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setFlightSearchDetails, clearFlightSearchDetails } =
  packageListSlice.actions;
export default packageListSlice.reducer;
