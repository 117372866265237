import React, { useRef } from "react";
import Loading from "../../components/spinner/Loading";
import USER from "../../assets/images/user.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { getLocalItem } from "../../_helpers/helper";
import {
  deleteTraveller,
  getTravellersByUserID,
  insertTraveller,
  updateTraveller,
} from "./services/myprofile.services";
import {
  clearTravellerDetail,
  setTravellerDetail,
  setTravellerGUID,
} from "./myprofileSlice";
import { INITIALS } from "../../_constants/constant";

const Travellers = () => {
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const travellerList = useAppSelector((state) => state.profile.travellerList);
  const travellerData = useAppSelector((state) => state.profile.travellerData);
  const travellerGUID = useAppSelector((state) => state.profile.travellerGUID);
  const travellersLoading = useAppSelector(
    (state) => state.profile.travellersLoading
  );
  const dispatch = useAppDispatch();
  const closeBtnRef = useRef<any>(null);
  const deleteCloseBtnRef = useRef<any>(null);

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    date_of_birth: Yup.date().required("Required"),
    // gender: Yup.string().required("Required"),
    initial: Yup.string().required("Required"),
    email_id: Yup.string().email("Invalid email address").nullable(),
    // .required("Email is required"),
    mobile_no: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .nullable(),
    // .required("Mobile number is required"),
    passport_no: Yup.string()
      .matches(/^[A-Z]{1}[0-9]{7}$/, "Invalid passport number")
      .nullable(),
    pan_card_no: Yup.string()
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Invalid PAN card number")
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      first_name: travellerData?.first_name || "",
      last_name: travellerData?.last_name || "",
      date_of_birth: travellerData?.date_of_birth || "",
      gender: travellerData?.gender || "",
      initial: travellerData?.initial || "",
      email_id: travellerData?.email_id || "",
      mobile_no: travellerData?.mobile_no || "",
      passport_no: travellerData?.passport_no || "",
      expire_date: travellerData?.expire_date || "",
      issue_date: travellerData?.issue_date || "",
      issue_country: travellerData?.issue_country || "",
      pan_card_no: travellerData?.pan_card_no || "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const payload = {
        gender: values?.initial
          ? values?.initial === "Ms" || values?.initial === "Mrs"
            ? 2
            : 1
          : 1,
        initial: values?.initial ? values?.initial : "",
        first_name: values?.first_name ? values?.first_name : "",
        last_name: values?.last_name ? values?.last_name : "",
        email_id: values?.email_id ? values?.email_id : "",
        mobile_no: values?.mobile_no ? `${values?.mobile_no}` : "",
        issue_country: values?.issue_country
          ? `${values?.issue_country}`
          : null,
        passport_no: values?.passport_no ? values?.passport_no : "",
        pan_card_no: values?.pan_card_no ? values?.pan_card_no : "",
        date_of_birth: values?.date_of_birth
          ? moment(values?.date_of_birth)?.format("YYYY-MM-DD")
          : null,
        expire_date: values?.expire_date
          ? moment(values?.expire_date)?.format("YYYY-MM-DD")
          : null,
        issue_date: values?.issue_date
          ? moment(values?.issue_date)?.format("YYYY-MM-DD")
          : null,
        user_guid: getLocalItem("user_guid"),
        isactive: true,
      };
      if (travellerData?.ID) {
        const editPayload = {
          ...payload,
          traveller_information_guid: travellerData?.traveller_information_guid,
        };
        const response = await dispatch(updateTraveller(editPayload));
        if (response.meta.requestStatus === "fulfilled") {
          setSubmitting(false);
          resetForm();
          if (closeBtnRef.current) {
            closeBtnRef.current.click();
          }
          dispatch(
            getTravellersByUserID({ user_guid: getLocalItem("user_guid") })
          );
        }
      } else {
        const response = await dispatch(insertTraveller(payload));
        if (response.meta.requestStatus === "fulfilled") {
          setSubmitting(false);
          resetForm();
          if (closeBtnRef.current) {
            closeBtnRef.current.click();
          }
          dispatch(
            getTravellersByUserID({ user_guid: getLocalItem("user_guid") })
          );
        }
      }
    },
    enableReinitialize: true,
  });

  const handleDeleteTraveller = async () => {
    if (travellerGUID) {
      const response = await dispatch(
        deleteTraveller({ traveller_information_guid: travellerGUID })
      );
      if (response.meta.requestStatus === "fulfilled") {
        if (deleteCloseBtnRef.current) {
          deleteCloseBtnRef.current.click();
        }
        dispatch(
          getTravellersByUserID({ user_guid: getLocalItem("user_guid") })
        );
      }
    }
  };

  return (
    <div className="position-relative">
      <Loading loading={travellersLoading} />
      <div className="profile-heading">
        <div className="prfile-name">
          <h2>Save Traveller(s)</h2>
          <p>You have {travellerList?.length} traveller(s)</p>
        </div>
        <form className="add-travel">
          <a
            className="travel-btn"
            href="javascript:void(0)"
            data-bs-toggle="modal"
            data-bs-target="#travellerform"
          >
            + Add Traveller
          </a>
          <span className="overlay" />
          <div
            className="modal fade"
            id="travellerform"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="traveller-form"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fs-5" id="traveller-form">
                    {!travellerData?.ID ? "Add" : "Edit"} Traveller's info
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => dispatch(clearTravellerDetail())}
                  ></button>
                </div>
                <div className="modal-body travel-form-detail">
                  <div className="travel-form-detail">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="travel-form basic-info">
                          <h5>Basic Information</h5>
                          <p>
                            Please check if Initial, Name and date of birth
                            match Govt. ID such as Passport.
                          </p>
                          <form>
                            <div className="field-set">
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.initial &&
                                    formik.errors.initial
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Initial <code>*</code>
                                </label>
                                <select
                                  name="initial"
                                  value={formik.values.initial}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.initial &&
                                    formik.errors.initial
                                      ? "error-border"
                                      : ""
                                  }
                                >
                                  <option value="">Select Initial</option>
                                  {INITIALS?.map((e: string) => (
                                    <option value={e}>{e}</option>
                                  ))}
                                </select>
                                {formik.touched.initial &&
                                formik.errors.initial &&
                                formik.errors.initial &&
                                typeof formik.errors.initial === "string" ? (
                                  <div className="error-label">
                                    {formik.errors.initial}
                                  </div>
                                ) : null}
                              </div>
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.first_name &&
                                    formik.errors.first_name
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  First Name <code>*</code>
                                </label>
                                <input
                                  type="text"
                                  name="first_name"
                                  value={formik.values.first_name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.first_name &&
                                    formik.errors.first_name
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.first_name &&
                                formik.errors.first_name &&
                                typeof formik.errors.first_name === "string" ? (
                                  <div className="error-label">
                                    {formik.errors.first_name}
                                  </div>
                                ) : null}
                              </div>
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.last_name &&
                                    formik.errors.last_name
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Last Name <code>*</code>
                                </label>
                                <input
                                  type="text"
                                  name="last_name"
                                  value={formik.values.last_name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.last_name &&
                                    formik.errors.last_name
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.last_name &&
                                formik.errors.last_name &&
                                typeof formik.errors.last_name === "string" ? (
                                  <div className="error-label">
                                    {formik.errors.last_name}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="field-set">
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.date_of_birth &&
                                    formik.errors.date_of_birth
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Birthday <code>*</code>
                                </label>
                                <input
                                  type="date"
                                  name="date_of_birth"
                                  max={moment().format("YYYY-MM-DD")}
                                  value={formik.values.date_of_birth}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.date_of_birth &&
                                    formik.errors.date_of_birth
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.date_of_birth &&
                                formik.errors.date_of_birth &&
                                typeof formik.errors.date_of_birth ===
                                  "string" ? (
                                  <div className="error-label">
                                    {formik.errors.date_of_birth}
                                  </div>
                                ) : null}
                              </div>
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.pan_card_no &&
                                    formik.errors.pan_card_no
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Pancard No
                                </label>
                                <input
                                  type="text"
                                  name="pan_card_no"
                                  value={formik.values.pan_card_no}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.pan_card_no &&
                                    formik.errors.pan_card_no
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.pan_card_no &&
                                formik.errors.pan_card_no &&
                                typeof formik.errors.pan_card_no ===
                                  "string" ? (
                                  <div className="error-label">
                                    {formik.errors.pan_card_no}
                                  </div>
                                ) : null}
                              </div>
                              {/* <div className="field">
                                <label
                                  className={
                                    formik.touched.gender &&
                                    formik.errors.gender
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Gender
                                </label>
                                <select
                                  name="gender"
                                  value={formik.values.gender}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.gender &&
                                    formik.errors.gender
                                      ? "error-border"
                                      : ""
                                  }
                                >
                                  <option value="">Select Gender</option>
                                  <option value={1}>Male</option>
                                  <option value={2}>Female</option>
                                </select>
                                {formik.touched.gender &&
                                formik.errors.gender &&
                                formik.errors.gender &&
                                typeof formik.errors.gender === "string" ? (
                                  <div className="error-label">
                                    {formik.errors.gender}
                                  </div>
                                ) : null}
                              </div> */}
                            </div>
                            <div className="field-set">
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.email_id &&
                                    formik.errors.email_id
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Email ID
                                </label>
                                <input
                                  type="email"
                                  name="email_id"
                                  value={formik.values.email_id}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.email_id &&
                                    formik.errors.email_id
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.email_id &&
                                formik.errors.email_id &&
                                typeof formik.errors.email_id === "string" ? (
                                  <div className="error-label">
                                    {formik.errors.email_id}
                                  </div>
                                ) : null}
                              </div>
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.mobile_no &&
                                    formik.errors.mobile_no
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Mobile No
                                </label>
                                <input
                                  type="number"
                                  name="mobile_no"
                                  value={formik.values.mobile_no}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.mobile_no &&
                                    formik.errors.mobile_no
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.mobile_no &&
                                formik.errors.mobile_no &&
                                typeof formik.errors.mobile_no === "string" ? (
                                  <div className="error-label">
                                    {formik.errors.mobile_no}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="field-set">
                              {/* <div className="field">
                                <label
                                  className={
                                    formik.touched.pan_card_no &&
                                    formik.errors.pan_card_no
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Pancard No
                                </label>
                                <input
                                  type="text"
                                  name="pan_card_no"
                                  value={formik.values.pan_card_no}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.pan_card_no &&
                                    formik.errors.pan_card_no
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.pan_card_no &&
                                formik.errors.pan_card_no &&
                                typeof formik.errors.pan_card_no ===
                                  "string" ? (
                                  <div className="error-label">
                                    {formik.errors.pan_card_no}
                                  </div>
                                ) : null}
                              </div> */}
                            </div>
                          </form>
                        </div>
                        <hr />
                        <div className="travel-form passport-info">
                          <h5>Passport Details</h5>
                          <p>Mandatory for International Travel</p>
                          <form>
                            <div className="field-set">
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.passport_no &&
                                    formik.errors.passport_no
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Passport No
                                </label>
                                <input
                                  type="text"
                                  name="passport_no"
                                  value={formik.values.passport_no}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.passport_no &&
                                    formik.errors.passport_no
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.passport_no &&
                                formik.errors.passport_no &&
                                typeof formik.errors.passport_no ===
                                  "string" ? (
                                  <div className="error-label">
                                    {formik.errors.passport_no}
                                  </div>
                                ) : null}
                              </div>
                              <div className="field">
                                <label
                                // className={
                                //   formik.touched.issue_country &&
                                //   formik.errors.issue_country
                                //     ? "error-label"
                                //     : ""
                                // }
                                >
                                  Issue Country
                                </label>
                                <select
                                  name="issue_country"
                                  value={formik.values.issue_country}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  // className={
                                  //   formik.touched.issue_country &&
                                  //   formik.errors.issue_country
                                  //     ? "error-border"
                                  //     : ""
                                  // }
                                >
                                  <option value="">Select Country</option>
                                  {CountryList?.map((e) => (
                                    <option key={e?.id} value={e?.id}>
                                      {e?.country_name}
                                    </option>
                                  ))}
                                </select>
                                {/* {formik.touched.issue_country &&
                          formik.errors.issue_country &&
                          typeof formik.errors.issue_country === "string" ? (
                            <div className="error-label">
                              {formik.errors.issue_country}
                            </div>
                          ) : null} */}
                              </div>
                            </div>
                            <div className="field-set">
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.issue_date &&
                                    formik.errors.issue_date
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Issue Date
                                </label>
                                <input
                                  type="date"
                                  name="issue_date"
                                  max={moment().format("YYYY-MM-DD")}
                                  value={formik.values.issue_date}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.issue_date &&
                                    formik.errors.issue_date
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.issue_date &&
                                formik.errors.issue_date &&
                                typeof formik.errors.issue_date === "string" ? (
                                  <div className="error-label">
                                    {formik.errors.issue_date}
                                  </div>
                                ) : null}
                              </div>
                              <div className="field">
                                <label
                                  className={
                                    formik.touched.expire_date &&
                                    formik.errors.expire_date
                                      ? "error-label"
                                      : ""
                                  }
                                >
                                  Expiry Date
                                </label>
                                <input
                                  type="date"
                                  name="expire_date"
                                  min={moment()
                                    .add(6, "months")
                                    .format("YYYY-MM-DD")}
                                  value={formik.values.expire_date}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.touched.expire_date &&
                                    formik.errors.expire_date
                                      ? "error-border"
                                      : ""
                                  }
                                />
                                {formik.touched.expire_date &&
                                formik.errors.expire_date &&
                                typeof formik.errors.expire_date ===
                                  "string" ? (
                                  <div className="error-label">
                                    {formik.errors.expire_date}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      ></div>
                      <div className="travel-detail-btns mt-2">
                        <a
                          className="disable"
                          href="javascript:void(0)"
                          data-bs-dismiss="modal"
                          ref={closeBtnRef}
                          onClick={() => dispatch(clearTravellerDetail())}
                        >
                          cancel
                        </a>
                        <a
                          href="javascript:void(0)"
                          onClick={() => formik.handleSubmit()}
                        >
                          save
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div
          className="modal fade"
          id="travellerdelete"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="edit-profile"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: 350 }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fs-5" id="edit-profile">
                  Delete Traveller
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body travel-form-detail">
                <div className="travel-form">
                  <p>Are you want to sure to delete it?</p>
                </div>
                <div className="travel-detail-btns mt-4">
                  <a
                    className="disable"
                    href="javascript:void(0)"
                    data-bs-dismiss="modal"
                    ref={deleteCloseBtnRef}
                  >
                    Cancel
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={() => handleDeleteTraveller()}
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {travellerList?.length > 0 && (
        <div className="travel-table">
          <table>
            <tbody>
              {travellerList?.map((traveller, index) => {
                return (
                  <tr>
                    <td>
                      <img src={USER} alt="" />
                      <span>
                        {traveller?.initial || ""} {traveller?.first_name || ""}{" "}
                        {traveller?.last_name + " " || ""}
                      </span>
                      (
                      {/* {traveller?.gender === 1
                        ? "Male"
                        : traveller?.gender === 2
                        ? "Female"
                        : ""}
                      {", "} */}
                      {traveller?.date_of_birth
                        ? moment().diff(
                            moment(traveller?.date_of_birth, "YYYY-MM-DD"),
                            "years"
                          ) + " years"
                        : ""}
                      )
                    </td>
                    <td>{traveller?.mobile_no || ""} </td>
                    <td className="traveller-icon-wrapper">
                      <span>
                        <FaRegEdit
                          className="me-2 traveller-icon"
                          data-bs-toggle="modal"
                          data-bs-target="#travellerform"
                          onClick={() => {
                            dispatch(setTravellerDetail(traveller));
                          }}
                        />
                        <RiDeleteBin6Line
                          className="traveller-icon red-color"
                          data-bs-toggle="modal"
                          data-bs-target="#travellerdelete"
                          onClick={() => {
                            dispatch(
                              setTravellerGUID(
                                traveller?.traveller_information_guid
                              )
                            );
                          }}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Travellers;
