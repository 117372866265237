import React, { useEffect } from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { Outlet, useLocation } from "react-router";
import Login from "../../pages/login/Login";
import { STICKYHEADERPATHS } from "../../_constants/constant";

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (STICKYHEADERPATHS?.includes(location.pathname)) {
        if (window.scrollY > 100) {
          document.querySelector(".main-header")?.classList.add("new-header");
          document.querySelector(".navbar-banner-tab")?.classList.add("show");
        } else {
          document
            .querySelector(".main-header")
            ?.classList.remove("new-header");
          document.querySelector(".root")?.classList.remove("m-65");
          document
            .querySelector(".navbar-banner-tab")
            ?.classList.remove("show");
        }
      } else {
        if (window.scrollY > 65) {
          document.querySelector(".main-header")?.classList.add("new-header");
          document.querySelector(".root")?.classList.add("m-65");
        } else {
          document
            .querySelector(".main-header")
            ?.classList.remove("new-header");
          document.querySelector(".root")?.classList.remove("m-65");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  useEffect(() => {
    if (!STICKYHEADERPATHS?.includes(location.pathname)) {
      document.querySelector(".navbar-banner-tab")?.classList.add("show");
    } else {
      if (window.scrollY < 65) {
        document.querySelector(".navbar-banner-tab")?.classList.remove("show");
      } else {
        document.querySelector(".navbar-banner-tab")?.classList.add("show");
      }
    }

    return () => {
      document.querySelector(".navbar-banner-tab")?.classList.remove("show");
    };
  }, [location.pathname]);

  return (
    <>
      <Login />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
