import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../toast/Toasts";

export const userLogin = createAsyncThunk(
  "UserLogin/UserLogin",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserLogin/UserLogin`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Login user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const userLogout = createAsyncThunk(
  "LoginActivity/Logout",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/LoginActivity/Logout`,
        payload
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Logout user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateOTP = createAsyncThunk(
  "UserLogin/OtpGenerate",
  async (mobile: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/UserLogin/OtpGenerate`,
        { mobile_no: mobile }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error generating OTP:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
