import { createSlice } from "@reduxjs/toolkit";
import { generateOTP, userLogin, userLogout } from "./services/login.services";

const initialState = {
  loading: false,
  error: "",
  data: null,
  userDetails: {
    firstName: localStorage.getItem("first_name") || "",
    lastName: localStorage.getItem("last_name") || "",
    mobileNo: localStorage.getItem("mobile_no") || "",
    userGuid: localStorage.getItem("user_guid") || "",
  },
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUserDetails(state, action) {
      state.userDetails = {
        ...state.userDetails,
        ...action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(generateOTP.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(generateOTP.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(generateOTP.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(userLogout.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userLogout.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails.firstName = action.payload?.first_name || "";
      state.userDetails.lastName = action.payload?.last_name || "";
      state.userDetails.mobileNo = action.payload?.mobile_no || "";
      state.userDetails.userGuid = action.payload?.user_guid || "";

      // Update local storage with user details
      localStorage.setItem("first_name", state.userDetails.firstName);
      localStorage.setItem("last_name", state.userDetails.lastName);
      localStorage.setItem("mobile_no", state.userDetails.mobileNo);
      localStorage.setItem("user_guid", state.userDetails.userGuid);
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setUserDetails } = loginSlice.actions;

export default loginSlice.reducer;
