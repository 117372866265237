import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { MdOutlineEdit } from "react-icons/md";
import { getLocalItem } from "../../_helpers/helper";
import { getUserByID, updateUserInfo } from "./services/myprofile.services";
import { useFormik } from "formik";
import moment from "moment";
import Loading from "../../components/spinner/Loading";
import * as Yup from "yup";

const ProfileCard = () => {
  const userData = useAppSelector((state) => state.profile.userData);
  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );
  const CityList = useAppSelector((state) => state.city.CityList);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.profile.loading);
  const closeBtnRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getUserByID({ user_guid: getLocalItem("user_guid") }));
  }, []);

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    date_of_birth: Yup.date().required("Required"),
    gender: Yup.string().required("Required"),
    // city_id: Yup.string().required("Required"),
    // nationality_id: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: userData?.first_name || "",
      last_name: userData?.last_name || "",
      date_of_birth: userData?.date_of_birth
        ? moment(userData?.date_of_birth, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
      gender: userData?.gender || "",
      city_id: userData?.city_id || "",
      nationality_id: userData?.nationality_id || "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const payload = {
        city_id: values?.city_id ? +values?.city_id : null,
        nationality_id: values?.nationality_id ? +values?.nationality_id : null,
        gender: values?.gender ? values?.gender : "",
        first_name: values?.first_name ? values?.first_name : "",
        last_name: values?.last_name ? values?.last_name : "",
        date_of_birth: values?.date_of_birth
          ? moment(values?.date_of_birth)?.format("YYYY-MM-DD")
          : "",
        user_guid: getLocalItem("user_guid"),
        isactive: true,
      };
      const response = await dispatch(updateUserInfo(payload));
      if (response.meta.requestStatus === "fulfilled") {
        setSubmitting(false);
        resetForm();
        if (closeBtnRef.current) {
          closeBtnRef.current.click();
        }
        dispatch(getUserByID({ user_guid: getLocalItem("user_guid") }));
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className="position-relative">
      <Loading loading={loading} />
      <div className="profile-heading">
        <div className="prfile-name">
          <h2>Profile</h2>
          <p>Basic info, for a faster booking experience</p>
        </div>
        <form className="add-travel">
          <a
            className="travel-btn"
            data-bs-toggle="modal"
            data-bs-target="#profileform"
            href="javascript:void(0)"
          >
            <MdOutlineEdit /> Edit
          </a>
          <div
            className="modal fade"
            id="profileform"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="edit-profile"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title fs-5" id="edit-profile">
                    Edit Profile
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body travel-form-detail">
                  <div className="travel-form">
                    <form>
                      <div className="field-set">
                        <div className="field">
                          <label
                            className={
                              formik.touched.first_name &&
                              formik.errors.first_name
                                ? "error-label"
                                : ""
                            }
                          >
                            First Name <code>*</code>
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.first_name &&
                              formik.errors.first_name
                                ? "error-border"
                                : ""
                            }
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name &&
                          typeof formik.errors.first_name === "string" ? (
                            <div className="error-label">
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="field">
                          <label
                            className={
                              formik.touched.last_name &&
                              formik.errors.last_name
                                ? "error-label"
                                : ""
                            }
                          >
                            Last Name <code>*</code>
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.last_name &&
                              formik.errors.last_name
                                ? "error-border"
                                : ""
                            }
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name &&
                          typeof formik.errors.last_name === "string" ? (
                            <div className="error-label">
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="field-set">
                        <div className="field">
                          <label
                            className={
                              formik.touched.date_of_birth &&
                              formik.errors.date_of_birth
                                ? "error-label"
                                : ""
                            }
                          >
                            Birthday <code>*</code>
                          </label>
                          <input
                            type="date"
                            name="date_of_birth"
                            max={moment().format("YYYY-MM-DD")}
                            value={formik.values.date_of_birth}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.date_of_birth &&
                              formik.errors.date_of_birth
                                ? "error-border"
                                : ""
                            }
                          />
                          {formik.touched.date_of_birth &&
                          formik.errors.date_of_birth &&
                          typeof formik.errors.date_of_birth === "string" ? (
                            <div className="error-label">
                              {formik.errors.date_of_birth}
                            </div>
                          ) : null}
                        </div>
                        <div className="field">
                          <label
                            className={
                              formik.touched.gender && formik.errors.gender
                                ? "error-label"
                                : ""
                            }
                          >
                            Gender <code>*</code>
                          </label>
                          <select
                            name="gender"
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.touched.gender && formik.errors.gender
                                ? "error-border"
                                : ""
                            }
                          >
                            <option value="">Select Gender</option>
                            <option value={1}>Male</option>
                            <option value={2}>Female</option>
                          </select>
                          {formik.touched.gender &&
                          formik.errors.gender &&
                          formik.errors.gender &&
                          typeof formik.errors.gender === "string" ? (
                            <div className="error-label">
                              {formik.errors.gender}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="field-set">
                        <div className="field">
                          <label>Nationality</label>
                          <select
                            name="nationality_id"
                            value={formik.values.nationality_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Nationality</option>
                            {NationalityList?.map((e) => (
                              <option key={e?.id} value={e?.id}>
                                {e?.nationality_name}
                              </option>
                            ))}
                          </select>
                          {/* {formik.touched.nationality_id &&
                          typeof formik.errors.nationality_id === "string" ? (
                            <div className="error">
                              {formik.errors.nationality_id}
                            </div>
                          ) : null} */}
                        </div>
                        <div className="field">
                          <label>City</label>
                          <select
                            name="city_id"
                            value={formik.values.city_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select City</option>
                            {CityList?.map((e) => (
                              <option key={e?.id} value={e?.id}>
                                {e?.city_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="travel-detail-btns mt-2">
                    <a
                      className="disable"
                      href="javascript:void(0)"
                      data-bs-dismiss="modal"
                      ref={closeBtnRef}
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() => formik.handleSubmit()}
                    >
                      Save
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <table>
        <tbody>
          <tr>
            <td className="w-40">First Name</td>
            <td>
              {userData?.first_name ? (
                <span>{userData?.first_name || ""}</span>
              ) : (
                <span
                  className="profile-add-span"
                  data-bs-toggle="modal"
                  data-bs-target="#profileform"
                >
                  + Add
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>
              {userData?.last_name ? (
                <span>{userData?.last_name || ""}</span>
              ) : (
                <span
                  className="profile-add-span"
                  data-bs-toggle="modal"
                  data-bs-target="#profileform"
                >
                  + Add
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>Date of Birth</td>
            <td>
              {userData?.date_of_birth ? (
                <span>{userData?.date_of_birth}</span>
              ) : (
                <span
                  className="profile-add-span"
                  data-bs-toggle="modal"
                  data-bs-target="#profileform"
                >
                  + Add
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>Gender</td>
            <td>
              {userData?.gender ? (
                <span>
                  {userData?.gender === 1
                    ? "Male"
                    : userData?.gender === 2
                    ? "Female"
                    : ""}
                </span>
              ) : (
                <span
                  className="profile-add-span"
                  data-bs-toggle="modal"
                  data-bs-target="#profileform"
                >
                  + Add
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>Nationality</td>
            <td>
              {userData?.nationality_name ? (
                <span>{userData?.nationality_name}</span>
              ) : (
                <span
                  className="profile-add-span"
                  data-bs-toggle="modal"
                  data-bs-target="#profileform"
                >
                  + Add
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              {userData?.city_name ? (
                <span>{userData?.city_name}</span>
              ) : (
                <span
                  className="profile-add-span"
                  data-bs-toggle="modal"
                  data-bs-target="#profileform"
                >
                  + Add
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProfileCard;
