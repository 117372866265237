import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllMyTrips, getBookingDetails } from "./services/mytrips.services";
import { IMyTripInitial } from "./mytripsModel";

const initialState: IMyTripInitial = {
  loading: false,
  error: "",
  MytripsList: {
    upcommings: [],
    cancelled: [],
    completed: [],
  },
  BookinDetails: null,
};

const myTripsListSlice = createSlice({
  name: "mytrips",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllMyTrips.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMyTrips.fulfilled, (state, action) => {
      state.loading = false;
      state.MytripsList = action.payload;
      state.error = "";
    });
    builder.addCase(getAllMyTrips.rejected, (state, action) => {
      state.loading = false;
      state.MytripsList = initialState.MytripsList;
      state.error = action.error.message || "Something went wrong";
    });

    // Booking Details API CALL oms/v1/booking-details

    builder.addCase(getBookingDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBookingDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.BookinDetails = action.payload;
      state.error = "";
    });
    builder.addCase(getBookingDetails.rejected, (state, action) => {
      state.loading = false;
      state.BookinDetails = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {} = myTripsListSlice.actions;
export default myTripsListSlice.reducer;
