import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";

export const getAllActiveNationalities = createAsyncThunk(
  "Nationality/FindAllActiveNationality",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_URL}/Nationality/FindAllActiveNationality`
    );
    return response.data?.Data;
  }
);
