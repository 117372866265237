import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { emailVerification } from "./services/verifyemail.services";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [verified, setVerified] = useState(false);
  const loading = useAppSelector((state) => state.verifyemail.loading);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const guid = queryParams.get("guid");
    const code = queryParams.get("code");

    if (guid && code) {
      verifyEmail(guid, code);
    }
  }, [location]);

  const verifyEmail = async (guid: string, code: string) => {
    const response = await dispatch(
      emailVerification({
        user_guid: guid,
        email_code: code,
      })
    );

    if (response.meta.requestStatus === "fulfilled") {
      setVerified(true);
    }
  };

  return (
    <div>
      <h3 className="text-center mt-5">
        {loading
          ? "Verifying your email..."
          : verified
          ? "Email Verified Successfully"
          : "Authentication Failed"}
      </h3>
      {verified && (
        <div className="d-flex-center">
          <button className="fill-btn" onClick={() => navigate("/")}>
            Go to Home
          </button>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
