import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { TRIPJACK_API_URL } from "../../../app/config";
import {
  setComboFilters,
  setComboSortType,
  setFilter,
  setFilterLoading,
  setMulticityFilter,
  setMulticitySortType,
  setReturnFilter,
  setReturnSortType,
  setSortType,
} from "../flightSlice";

export const getAllFlights = createAsyncThunk(
  "Flight/FindAllFlight",
  async (payload: any) => {
    const response = await axiosApiInstance.post(
      `${TRIPJACK_API_URL}/fms/v1/air-search-all`,
      payload
    );
    return response.data;
  }
);

export const setSortTypeAsync = createAsyncThunk(
  "flight/setSortTypeAsync",
  async (
    payload: { type?: string; selectedValue: any; index?: number },
    { dispatch }
  ) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 0));

      const { type, selectedValue, index } = payload;

      if (type === "return") {
        dispatch(setReturnSortType(selectedValue));
      } else if (type === "multicity") {
        dispatch(setMulticitySortType({ sortType: selectedValue, index }));
      } else if (type === "combo") {
        dispatch(setComboSortType(selectedValue));
      } else {
        dispatch(setSortType(selectedValue));
      }
    } catch (error) {
      console.error("Error setting sort type:", error);
    }
  }
);

export const setFilterAsync = createAsyncThunk(
  "flight/setFilterAsync",
  async (payload: any, { dispatch }) => {
    const {
      option,
      selectedOptions,
      filterKey,
      type,
      index,
      returnFilters,
      multicityFilters,
      comboFilters,
      onwardFilters,
    } = payload;
    try {
      const updatedSelectedOptions =
        Array.isArray(selectedOptions) && selectedOptions.includes(option)
          ? selectedOptions.filter((item) => item !== option)
          : [...selectedOptions, option];

      await new Promise((resolve) => setTimeout(resolve, 0));

      switch (type) {
        case "return":
          dispatch(
            setReturnFilter({
              ...returnFilters,
              [filterKey]: updatedSelectedOptions,
            })
          );
          break;
        case "multicity":
          if (typeof index === "number") {
            const updatedMulticityFilters = [...multicityFilters];
            updatedMulticityFilters[index] = {
              ...updatedMulticityFilters[index],
              [filterKey]: updatedSelectedOptions,
            };
            dispatch(setMulticityFilter(updatedMulticityFilters));
          }
          break;
        case "combo":
          if (typeof index === "number") {
            const updatedComboFilters = [...comboFilters];
            updatedComboFilters[index] = {
              ...updatedComboFilters[index],
              [filterKey]: updatedSelectedOptions,
            };
            dispatch(setComboFilters(updatedComboFilters));
          }
          break;
        case "onward":
        default:
          dispatch(
            setFilter({ ...onwardFilters, [filterKey]: updatedSelectedOptions })
          );
          break;
      }
    } catch (error) {
      console.error("Error in filter update:", error);
    }
  }
);
