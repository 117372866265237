import { toast, ToastOptions } from "react-toastify";

type ToastMessage = string;

const SUCCESS_OPTIONS: ToastOptions = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ERROR_OPTIONS: ToastOptions = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const SuccessToast = (message: ToastMessage) => {
  toast.success(message, SUCCESS_OPTIONS);
};

export const ErrorToast = (message: ToastMessage) => {
  toast.error(message, ERROR_OPTIONS);
};
