import React from "react";

const WeekendCard = ({ deal }: { deal: any }) => {
  return (
    <div className="weekend-card p-2">
      <div className="weekend-card-pic">
        <img src={deal?.image} alt={deal?.name} />
      </div>
      <p className="review">{deal?.review}</p>
      <h5>
        <a href="#">{deal?.name}</a>
      </h5>
      <span className="location">{deal?.location}</span>
      <span className="price">
        {deal?.price} <span className="old-price">{deal?.oldPrice}</span>
      </span>
      <p>
        for {deal?.nights} nights <br />
        {deal?.perNight} per night <br />
        includes taxes & fees
      </p>
      {deal?.memberPrice ? (
        <span className="discount available">Member Price available</span>
      ) : (
        <span className="discount">{deal?.discount}</span>
      )}
    </div>
  );
};

export default WeekendCard;
