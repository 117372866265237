import { useEffect, useState } from "react";
import USER from "../../assets/images/user-2.png";
import SEARCH from "../../assets/images/search.png";
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikProvider,
  useFormik,
} from "formik";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllFlightPlaces } from "../../pages/home/services/home.services";
import { setFlightSearchDetails } from "../../pages/home/homeSlice";
import { FARETYPEARRAY, TRIPTYPEARRAY } from "../../_constants/constant";
import {
  filterOutRecentSearches,
  getAirportNameByCityCode,
  getCabinClass,
  getCityNameByCityCode,
  getCurrentFlightSearchFromLocalStorage,
  getFareType,
  getRecentSearchLocations,
  getTripType,
  updatedRecentSearches,
} from "../../_helpers/helper";
import TravellerSearchPopup from "./TravellerSearchPopup";
import ORTripSearch from "./ORTripSearch";
import MTripSearch from "./MTripSearch";

const FlightSearchSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMultiOpen, setIsMultiOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [recentFromOptions, setRecentFromOptions] = useState([]);
  const [recentToOptions, setRecentToOptions] = useState([]);

  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const isBannerSearch =
    location.pathname === "/" || location.pathname === "/flights";

  const onSearchLocation = async (value: string, type: string) => {
    if (value?.length > 0) {
      const response = await dispatch(getAllFlightPlaces(value));
      if (response.meta.requestStatus === "fulfilled") {
        formik.setFieldValue(type, response.payload);
      }
    }
  };

  useEffect(() => {
    setRecentFromOptions(getRecentSearchLocations("flightRecentSearchesFrom"));
    setRecentToOptions(getRecentSearchLocations("flightRecentSearchesTo"));
  }, [localStorage.getItem("flightDetails")]);

  const formik = useFormik({
    initialValues: flightSearchDetails,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      dispatch(setFlightSearchDetails(values));
      let itinerary = "";
      let multicities: any = [];
      const formattedDeparture = moment(values.departureDate).format(
        "DD/MM/YYYY"
      );
      const formattedReturn =
        values.triptype === "Round trip"
          ? moment(values.returnDate).format("DD/MM/YYYY")
          : "";

      if (values.triptype === "Multicity") {
        // Handle Multi City
        for (const city of values.multicities) {
          const formattedDeparture = moment(city.departureDate).format(
            "DD/MM/YYYY"
          );

          // Append to the itinerary string
          itinerary += `${city.from}-${city.to}-${formattedDeparture}`;
          if (
            values.multicities.indexOf(city) <
            values.multicities.length - 1
          ) {
            itinerary += "_";
          }

          // Fetch city and airport names
          const fromCity = await getCityNameByCityCode(
            city.from,
            city.fromOptions
          );
          const toCity = await getCityNameByCityCode(city.to, city.toOptions);
          const fromFlightPlace = await getAirportNameByCityCode(
            city.from,
            city.fromOptions
          );
          const toFlightPlace = await getAirportNameByCityCode(
            city.to,
            city.toOptions
          );

          // Push the values into the multicities array
          multicities.push({
            from: city.from,
            to: city.to,
            fromCity: fromCity,
            fromFlightPlace: fromFlightPlace,
            toFlightPlace: toFlightPlace,
            fromOptions: city.fromOptions,
            toOptions: city.toOptions,
            toCity: toCity,
          });
        }
      } else {
        // Handle One Way or Round Trip
        itinerary =
          values.triptype === "Round trip"
            ? `${values.from}-${values.to}-${formattedDeparture}_${values.to}-${values.from}-${formattedReturn}`
            : `${values.from}-${values.to}-${formattedDeparture}`;
      }

      const paxType = `A-${values.ADULT}_C-${values.CHILD}_I-${values.INFANT}`;

      const queryString = `itinerary=${itinerary}&triptype=${getTripType(
        values?.triptype
      )}&paxType=${paxType}&cabinClass=${getCabinClass(
        values?.cabinClass
      )}&pft=${getFareType(values?.specialfare)}&formKey=${values.formKey + 1}`;

      const fromCity = await getCityNameByCityCode(
        values.from,
        values.fromOptions
      );
      const toCity = await getCityNameByCityCode(values.to, values.toOptions);
      const fromFlightPlace = await getAirportNameByCityCode(
        values.from,
        values.fromOptions
      );
      const toFlightPlace = await getAirportNameByCityCode(
        values.to,
        values.toOptions
      );

      const currentFlightSearch = {
        from: values.from,
        to: values.to,
        fromCity: fromCity,
        fromFlightPlace: fromFlightPlace,
        toFlightPlace: toFlightPlace,
        fromOptions: values.fromOptions,
        toOptions: values.toOptions,
        toCity: toCity,
        departureDate: formattedDeparture,
        returnDate: formattedReturn || null,
        multicities: multicities?.length > 0 ? multicities : values.multicities,
        triptype: values.triptype,
        ADULT: values.ADULT,
        CHILD: values.CHILD,
        INFANT: values.INFANT,
        cabinClass: values.cabinClass,
        specialfare: values.specialfare,
      };

      const fromSearch = {
        city_name: fromCity,
        flightplace_code: values.from,
        flightplace_name: fromFlightPlace,
      };

      const toSearch = {
        city_name: toCity,
        flightplace_code: values.to,
        flightplace_name: toFlightPlace,
      };

      const flightRecentSearchesFrom = updatedRecentSearches(
        "flightRecentSearchesFrom",
        fromSearch
      );
      const flightRecentSearchesTo = updatedRecentSearches(
        "flightRecentSearchesTo",
        toSearch
      );

      let flightDetails = JSON.parse(
        localStorage.getItem("flightDetails") || "{}"
      );

      flightDetails = {
        ...flightDetails,
        lastFlightSearch: currentFlightSearch,
        flightRecentSearchesFrom: flightRecentSearchesFrom,
        flightRecentSearchesTo: flightRecentSearchesTo,
      };

      localStorage.setItem("flightDetails", JSON.stringify(flightDetails));

      navigate(`/flights/search?${queryString}`);
    },
  });

  const multicities = getCurrentFlightSearchFromLocalStorage("multicities");

  const allCities =
    multicities &&
    multicities?.flatMap((city: any) => [city.fromCity, city.toCity]);

  const uniqueCities = Array.from(new Set(allCities?.slice(1, -1)));

  const viaCities = uniqueCities.join(", ");

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <FormikProvider value={formik}>
      <form>
        <div className="banner-tabbing-top">
          <div className="radio-tabbing">
            {TRIPTYPEARRAY.map((triptype) => (
              <div className="fareCardItem" key={triptype?.type}>
                <span
                  style={{
                    background:
                      formik.values.triptype === triptype?.type
                        ? "#cdffe4"
                        : undefined,
                    borderColor:
                      formik.values.triptype === triptype?.type
                        ? "#08a34e"
                        : undefined,
                  }}
                ></span>
                <label
                  onClick={() => {
                    formik.setFieldValue("triptype", triptype?.type);
                  }}
                  style={{
                    color:
                      formik.values.triptype === triptype?.type
                        ? "#08a34e"
                        : undefined,
                  }}
                >
                  {triptype?.type}
                </label>
              </div>
            ))}
          </div>
          <div className="select-section">
            <img src={USER} alt="" />
            <h5 onClick={handleButtonClick}>
              {formik.values.ADULT + formik.values.CHILD + formik.values.INFANT}{" "}
              Traveller, {formik.values.cabinClass}
            </h5>
            {isOpen && (
              <TravellerSearchPopup formik={formik} handleClose={handleClose} />
            )}
          </div>
        </div>
        {(formik.values.triptype === "One Way" ||
          formik.values.triptype === "Round trip") && (
          <ORTripSearch
            formik={formik}
            onSearchLocation={onSearchLocation}
            recentToOptions={recentToOptions}
            recentFromOptions={recentFromOptions}
          />
        )}
        {formik.values.triptype === "Multicity" && (
          <>
            {isBannerSearch ? (
              <FieldArray
                name="multicities"
                render={(arrayHelpers: FieldArrayRenderProps) => (
                  <div>
                    {formik.values.multicities?.map(
                      (city: any, index: number) => (
                        <MTripSearch
                          formik={formik}
                          arrayHelpers={arrayHelpers}
                          index={index}
                          onSearchLocation={onSearchLocation}
                          setIsMultiOpen={setIsMultiOpen}
                        />
                      )
                    )}
                  </div>
                )}
              />
            ) : (
              <>
                <div className="tabcontent-ow tabiingcontent">
                  <div className="row">
                    <div className="col-12 multicity-destinations-wrapper">
                      <div
                        className="multicity-destinations"
                        onClick={() => setIsMultiOpen(!isMultiOpen)}
                      >
                        <p className="multicity-label m-0">
                          {multicities[0]?.fromCity} to{" "}
                          {multicities[multicities?.length - 1]?.toCity}
                        </p>
                        <p className="m-0 multicity-via-label">
                          {multicities?.length > 1 && "Via "}{" "}
                          {/* Only show "Via" if there are intermediate stops */}
                          {viaCities && `${viaCities}`}
                          {/* You can add additional static text if needed */}
                        </p>
                      </div>
                      {!isMultiOpen && (
                        <a
                          onClick={() => {
                            const newFormKey = (formik.values.formKey || 0) + 1;
                            formik.setFieldValue("formKey", newFormKey);
                            formik.handleSubmit();
                          }}
                          className="fill-btn multicity-search-btn"
                        >
                          <img src={SEARCH} alt="" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                {isMultiOpen && (
                  <FieldArray
                    name="multicities"
                    render={(arrayHelpers: FieldArrayRenderProps) => (
                      <div>
                        {formik.values.multicities?.map(
                          (city: any, index: number) => (
                            <MTripSearch
                              formik={formik}
                              arrayHelpers={arrayHelpers}
                              index={index}
                              onSearchLocation={onSearchLocation}
                              setIsMultiOpen={setIsMultiOpen}
                            />
                          )
                        )}
                      </div>
                    )}
                  />
                )}
              </>
            )}
          </>
        )}
        <div className="extra-discount">
          <div className="select-fare">
            {isBannerSearch ? (
              <>
                <h5>Select a special fare</h5>
                <a>EXTRA SAVINGS</a>
              </>
            ) : (
              <h5>Fare Type</h5>
            )}
          </div>
          <div className="makeFlex gap8 hrtlCenter">
            {FARETYPEARRAY.map((specialfare: any) => (
              <div className="fareCardItem" key={specialfare?.type}>
                <input
                  type="radio"
                  name="specialfare"
                  value={specialfare?.type.toString()}
                  onChange={() =>
                    formik.setFieldValue("specialfare", specialfare?.type)
                  }
                />
                <span
                  style={{
                    background:
                      formik.values.specialfare === specialfare?.type
                        ? "#cdffe4"
                        : undefined,
                    borderColor:
                      formik.values.specialfare === specialfare?.type
                        ? "#08a34e"
                        : undefined,
                  }}
                ></span>
                <label
                  htmlFor=""
                  style={{
                    color:
                      formik.values.specialfare === specialfare?.type
                        ? "#08a34e"
                        : undefined,
                  }}
                >
                  {specialfare?.type}
                </label>
              </div>
            ))}
          </div>
        </div>
        {isBannerSearch && (
          <a
            onClick={() => {
              const newFormKey = (formik.values.formKey || 0) + 1;
              formik.setFieldValue("formKey", newFormKey);
              formik.handleSubmit();
            }}
            className="fill-btn"
          >
            <img src={SEARCH} alt="" />
            Search flights
          </a>
        )}
      </form>
    </FormikProvider>
  );
};

export default FlightSearchSection;
