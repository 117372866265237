import { configureStore } from "@reduxjs/toolkit";
import homeSlice from "../pages/home/homeSlice";
import flightSlice from "../pages/flights/flightSlice";
import userSlice from "../pages/user/userSlice";
import loginSlice from "../pages/login/loginSlice";
import nationalitySlice from "../pages/nationality/nationalitySlice";
import citySlice from "../pages/city/citySlice";
import myprofileSlice from "../pages/myprofile/myprofileSlice";
import verifyemailSlice from "../pages/verifyemail/verifyemailSlice";
import stateSlice from "../pages/state/stateSlice";
import countrySlice from "../pages/country/countrySlice";
import reviewbookingSlice from "../pages/reviewbooking/reviewbookingSlice";
import mytripsSlice from "../pages/mytrips/mytripsSlice";

const store = configureStore({
  reducer: {
    home: homeSlice,
    flight: flightSlice,
    user: userSlice,
    login: loginSlice,
    profile: myprofileSlice,
    nationality: nationalitySlice,
    city: citySlice,
    country: countrySlice,
    state: stateSlice,
    verifyemail: verifyemailSlice,
    reviewbooking: reviewbookingSlice,
    mytrips: mytripsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
