import MainRouter from "./_routers/Router";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const raw =
    '{\r\n    "Auth_Header": {\r\n        "UserId": "bigsmile",\r\n        "Password": "0351C2CD23F512166609580FB1CEDB13DB40B6A4",\r\n        "IP_Address": "192.168.1.32",\r\n        "Request_Id": "5500887959052",\r\n        "IMEI_Number": "2232323232323"\r\n    },\r\n	"Travel_Type":0,\r\n	"Booking_Type":0,\r\n	"TripInfo":[\r\n        {\r\n            "Origin":"BOM",\r\n            "Destination":"MAA",\r\n            "TravelDate":"09/29/2024",\r\n            "Trip_Id":0\r\n        }\r\n	],\r\n	"Adult_Count":"1",\r\n	"Child_Count":"0",\r\n	"Infant_Count":"0",\r\n	"Class_Of_Travel":"0",\r\n	"InventoryType":0,\r\n    "SrCitizen_Search":false,\r\n	"StudentFare_Search":false,\r\n	"DefenceFare_Search":false,\r\n    "Filtered_Airline": [\r\n        {\r\n        "Airline_Code": ""\r\n        }\r\n    ]\r\n    \r\n}';

  const requestOptions: any = {
    method: "POST",
    body: raw,
    redirect: "follow",
  };

  fetch(
    "http://uat.vickyonline.in/AirlineHost/AirAPIService.svc/JsonService/air_search",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  return (
    <>
      <ToastContainer />
      <MainRouter />
    </>
  );
}

export default App;
