import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  formatBookingData,
  formatIndianNumber,
  formatTempBookingData,
  formatTraveller,
  getLocalItem,
  getSelectedItems,
} from "../../_helpers/helper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useFormik, FormikProvider, FormikValues } from "formik";
import * as Yup from "yup";
import {
  IReviewBookingDetails,
  IReviewBookingKeys,
} from "./reviewbookingModel";
import {
  confirmFlightBooking,
  cretePaymentOrder,
  getConfirmFlightBooking,
  getGroupwiseTravellerByUserID,
  getReviewFlightBooking,
  saveFlightBooking,
  saveTempFlightBooking,
  verifyPayment,
} from "./services/reviewbooking.services";
import { ErrorToast } from "../../toast/Toasts";
import Loading from "../../components/spinner/Loading";
import ReviewFlightRouteCard from "./ReviewFlightRouteCard";
import ReviewPriceCard from "./ReviewPriceCard";
import IMPInfoCard from "./IMPInfoCard";
import AdultForm from "./AdultForm";
import ChildForm from "./ChildForm";
import InfantForm from "./InfantForm";
import ContactDetailsForm from "./ContactDetailsForm";
import { getAllActiveCountries } from "../country/services/country.services";
import { setFlightReviewDetails } from "./reviewbookingSlice";
import moment from "moment";

const ReviewBookingNew = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isAlertAvailabel, setIsAlertAvaialbel] = useState(false);
  const [sessionReady, setSessionReady] = useState(false);
  const reviewBookingDetails = useAppSelector(
    (state) => state.reviewbooking.reviewBookingDetails
  );
  const flightReviewDetails = useAppSelector(
    (state) => state.reviewbooking.flightReviewDetails
  );
  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );
  const loading = useAppSelector((state) => state.reviewbooking.loading);
  const bookingLoading = useAppSelector(
    (state) => state.reviewbooking.bookingLoading
  );
  const paymentLoading = useAppSelector(
    (state) => state.reviewbooking.paymentLoading
  );
  const travellerLoading = useAppSelector(
    (state) => state.reviewbooking.travellerLoading
  );
  const paxDetails = flightReviewDetails?.searchQuery?.paxInfo;
  const isDomestic = flightReviewDetails?.searchQuery?.isDomestic;
  const tripInfos = flightReviewDetails?.tripInfos;
  const condtions = flightReviewDetails?.conditions;
  const alerts = flightReviewDetails?.alerts && flightReviewDetails?.alerts[0];

  const isPIDRequired = condtions?.pcs?.pid;
  const isPPEDRequired = condtions?.pcs?.pped;
  const isPNRequired = condtions?.pcs?.pm;
  const isIDOBRRequired = condtions?.dob?.idobr;
  const isCDOBRRequired = condtions?.dob?.cdobr;
  const isADOBRRequired = condtions?.dob?.adobr;
  const isGSTRequired = condtions?.gst?.igm;
  const isGSTApplicable = condtions?.gst?.gstappl;

  useEffect(() => {
    dispatch(
      getGroupwiseTravellerByUserID({ user_guid: getLocalItem("user_guid") })
    );
    dispatch(getAllActiveCountries());
  }, []);

  useEffect(() => {
    setIsAlertAvaialbel(alerts ? true : false);
  }, [alerts]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const priceIds = params.get("priceIds")?.split(",") || [];

    const sessionData = sessionStorage.getItem("flightReviewDetails");
    const savedPriceIds = sessionStorage.getItem("priceIds");

    if (sessionData && savedPriceIds === priceIds.join(",")) {
      dispatch(setFlightReviewDetails(JSON.parse(sessionData)));
    } else {
      dispatch(
        getReviewFlightBooking({
          priceIds: priceIds,
        })
      ).then((response: any) => {
        if (response.payload) {
          sessionStorage.setItem(
            "flightReviewDetails",
            JSON.stringify(response.payload)
          );
          sessionStorage.setItem("priceIds", priceIds.join(","));

          const conditions = response.payload?.conditions;

          const sessionCreatedTime = moment(conditions?.sct).toISOString();
          const sessionDurationInSeconds = conditions?.st - 120;

          sessionStorage.setItem("sessionCreatedTime", sessionCreatedTime);
          sessionStorage.setItem(
            "sessionDurationInSeconds",
            sessionDurationInSeconds.toString()
          );

          setSessionReady(true);
        }
      });
    }
  }, [location.search, dispatch]);

  useEffect(() => {
    const sessionCreatedTime = sessionStorage.getItem("sessionCreatedTime");
    const sessionDurationInSeconds = sessionStorage.getItem(
      "sessionDurationInSeconds"
    );

    if (sessionCreatedTime && sessionDurationInSeconds) {
      const sessionStartTime = moment(sessionCreatedTime);
      const sessionExpirationTime = sessionStartTime.add(
        parseInt(sessionDurationInSeconds, 10),
        "seconds"
      );

      const checkSessionExpiration = () => {
        const currentTime = moment();
        if (currentTime.isAfter(sessionExpirationTime)) {
          setIsSessionExpired(true);
        }
      };

      checkSessionExpiration();

      const interval = setInterval(checkSessionExpiration, 1000);

      return () => clearInterval(interval);
    }
  }, [sessionReady]);

  const ADULT = paxDetails?.ADULT || 0;
  const CHILD = paxDetails?.CHILD || 0;
  const INFANT = paxDetails?.INFANT || 0;

  const initialValues: IReviewBookingDetails = reviewBookingDetails;

  const travellerSchema = Yup.object().shape({
    initial: Yup.string().when("isSelected", {
      is: (isSelected: boolean) => isSelected,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    first_name: Yup.string().when("isSelected", {
      is: (isSelected: boolean) => isSelected,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    last_name: Yup.string().when("isSelected", {
      is: (isSelected: boolean) => isSelected,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    passport_no: Yup.string().when("isSelected", {
      is: (isSelected: boolean) => isSelected && isPNRequired,
      then: (schema) =>
        schema
          .required("Required")
          .matches(/^[A-Z]{1}[0-9]{7}$/, "Invalid passport number"),
      otherwise: (schema) => schema.nullable(),
    }),
    expire_date: Yup.string().when("isSelected", {
      is: (isSelected: boolean) => isSelected && isPPEDRequired,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    issue_date: Yup.string().when("isSelected", {
      is: (isSelected: boolean) => isSelected && isPIDRequired,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    issue_country_code: Yup.string().when("isSelected", {
      is: (isSelected: boolean) => isSelected && isPNRequired,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const adultSchema = Yup.object().shape({
    ...travellerSchema.fields,
    date_of_birth: Yup.string().when("isSelected", {
      is: (isSelected: boolean) =>
        isSelected && (isADOBRRequired || !isDomestic),
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });
  const childSchema = Yup.object().shape({
    ...travellerSchema.fields,
    date_of_birth: Yup.string().when("isSelected", {
      is: (isSelected: boolean) =>
        isSelected && (isCDOBRRequired || !isDomestic),
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const infantSchema = Yup.object().shape({
    ...travellerSchema.fields,
    date_of_birth: Yup.string().when("isSelected", {
      is: (isSelected: boolean) =>
        isSelected && (isIDOBRRequired || !isDomestic),
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const validationSchema = Yup.object().shape({
    adults: Yup.array().when("ADULT", {
      is: () => ADULT > 0,
      then: (schema) => schema.of(adultSchema),
      otherwise: (schema) => schema.nullable(),
    }),
    children: Yup.array().when("ADULT", {
      is: () => CHILD > 0,
      then: (schema) => schema.of(childSchema),
      otherwise: (schema) => schema.nullable(),
    }),
    infants: Yup.array().when("ADULT", {
      is: () => INFANT > 0,
      then: (schema) => schema.of(infantSchema),
      otherwise: (schema) => schema.nullable(),
    }),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile_no: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile number is required"),
    gst_number: Yup.string().when("isGST", {
      is: (isGST: boolean) => isGST || isGSTRequired,
      then: (schema) =>
        schema
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            "Invalid GST number"
          )
          .required("GST number is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    gst_mobile: Yup.string().when("isGST", {
      is: (isGST: boolean) => isGST || isGSTRequired,
      then: (schema) =>
        schema
          .matches(/^[0-9]{10}$/, "Invalid mobile number")
          .required("Mobile number is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    gst_registered_name: Yup.string().when("isGST", {
      is: (isGST: boolean) => isGST || isGSTRequired,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    gst_address: Yup.string().when("isGST", {
      is: (isGST: boolean) => isGST || isGSTRequired,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
    gst_email: Yup.string().when("isGST", {
      is: (isGST: boolean) => isGST,
      then: (schema) =>
        schema.email("Invalid email address").required("Email is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const requiredItems = [
    {
      type: "adults",
      key: "ADULT",
      label: "Adult",
      count: ADULT,
    },
    {
      type: "children",
      key: "CHILD",
      label: "Child",
      count: CHILD,
    },
    {
      type: "infants",
      key: "INFANT",
      label: "Infant",
      count: INFANT,
    },
  ];

  const formik = useFormik<IReviewBookingDetails>({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  const handleSubmit = async (values: FormikValues) => {
    let errorMessages: string[] = [];

    requiredItems.forEach((item) => {
      const selectedItems = getSelectedItems(
        values[item.type as keyof IReviewBookingKeys]
      )?.length;
      if (selectedItems < item.count) {
        errorMessages.push(`${item.count} ${item.label}`);
      }
    });

    if (errorMessages.length > 0) {
      return ErrorToast(`You must have to select ${errorMessages.join(", ")}.`);
    }

    const travellerInfo = [
      ...values.adults
        .filter((e: any) => e.isSelected && ADULT > 0)
        .map((adult: any) => formatTraveller(adult, "ADULT")),
      ...values.children
        .filter((e: any) => e.isSelected && CHILD > 0)
        .map((child: any) => formatTraveller(child, "CHILD")),
      ...values.infants
        .filter((e: any) => e.isSelected && INFANT > 0)
        .map((infant: any) => formatTraveller(infant, "INFANT")),
    ];

    const tempFormatedPaylod = await formatTempBookingData({
      ...flightReviewDetails,
      travellerInfos: travellerInfo,
      deliveryInfo: {
        emails: [values?.email_id, values?.alt_email_id].filter(Boolean),
        contacts: [values?.mobile_no, values?.alt_mobile_no]
          .filter(Boolean)
          ?.map((e: any) => `${e}`),
      },
      ...(values?.isGST && {
        gstInfo: {
          gstNumber: values?.gst_number,
          email: values?.gst_email,
          registeredName: values?.gst_registered_name,
          mobile: values?.gst_mobile,
          address: values?.gst_address,
        },
      }),
    });

    const tempMainPayload = {
      ...tempFormatedPaylod,
      from_location: flightSearchDetails?.from,
      to_location: flightSearchDetails?.to,
    };

    dispatch(saveTempFlightBooking(tempMainPayload));

    const orderResponse: any = await dispatch(
      cretePaymentOrder({
        Amount:
          flightReviewDetails?.totalPriceInfo?.totalFareDetail?.fC?.TF * 100,
        Currency: "INR",
      })
    );

    const orderDetails = orderResponse?.payload?.Data;
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: orderDetails?.Amount,
      currency: orderDetails?.Currency,
      name: "BIGSMILE HOLIDAYS PRIVATE LIMITED",
      description: `transction_${flightReviewDetails?.bookingId}`,
      order_id: orderDetails?.Orderid,
      handler: async function (response: any) {
        const orderDetails: any = await dispatch(
          verifyPayment({
            RazorpayOrderId: response.razorpay_order_id,
            RazorpayPaymentId: response.razorpay_payment_id,
            RazorpaySignature: response.razorpay_signature,
            booking_no: flightReviewDetails?.bookingId,
          })
        );

        if (orderDetails?.meta?.requestStatus === "fulfilled") {
          const bookingPayload = {
            bookingId: flightReviewDetails?.bookingId,
            paymentInfos: [
              {
                amount:
                  flightReviewDetails?.totalPriceInfo?.totalFareDetail?.fC?.TF,
              },
            ],
            travellerInfo: travellerInfo,
            deliveryInfo: {
              emails: [values?.email_id, values?.alt_email_id].filter(Boolean),
              contacts: [values?.mobile_no, values?.alt_mobile_no]
                .filter(Boolean)
                ?.map((e: any) => `${e}`),
            },
            ...(values?.isGST && {
              gstInfo: {
                gstNumber: values?.gst_number,
                email: values?.gst_email,
                registeredName: values?.gst_registered_name,
                mobile: values?.gst_mobile,
                address: values?.gst_address,
              },
            }),
          };

          const bookingRes = await dispatch(
            confirmFlightBooking(bookingPayload)
          );

          if (bookingRes.meta.requestStatus === "fulfilled") {
            const getbookingRes = await dispatch(
              getConfirmFlightBooking({
                bookingId: flightReviewDetails?.bookingId,
                requirePaxPricing: true,
              })
            );
            if (getbookingRes.meta.requestStatus === "fulfilled") {
              const formatedPaylod = await formatBookingData({
                ...getbookingRes.payload,
                searchType: flightReviewDetails?.searchQuery?.searchType,
              });
              const mainPayload = {
                ...formatedPaylod,
                from_location: flightSearchDetails?.from,
                to_location: flightSearchDetails?.to,
              };
              const lastRes = await dispatch(saveFlightBooking(mainPayload));
              if (lastRes.meta.requestStatus === "fulfilled") {
                navigate(
                  `/flights/confirmed-trip-details?bookingId=${mainPayload?.booking_no}`
                );
              }
            }
          }
        } else {
          console.error("Payment verification failed:", orderDetails);
        }
      },
      theme: {
        color: "green",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();

    rzp.on("payment.failed", function (response: any) {
      ErrorToast(response.error.description);
      console.error("Payment failed", response);
    });
  };

  const handleCheckboxChange = (
    type: "adults" | "children" | "infants",
    flightDetailsKey: "ADULT" | "CHILD" | "INFANT",
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedItems = getSelectedItems(formik.values[type])?.length;
    const maxItems = paxDetails[flightDetailsKey];

    if (e.target.checked && selectedItems >= maxItems) {
      formik.setFieldError(
        `${type}Limit`,
        `You have already selected ${paxDetails[flightDetailsKey]} ${flightDetailsKey}. Remove before adding a new one.`
      );
    } else {
      formik.handleChange(e);
    }
  };

  const handleSearchAgain = () => {
    const session_id = sessionStorage.getItem("session_id");
    sessionStorage.clear();
    sessionStorage.setItem("session_id", session_id || "");
    const flightDetails = JSON.parse(
      localStorage.getItem("flightDetails") || "{}"
    );
    window.location.assign(
      `${flightDetails?.lastFlightSearchUrl || window.location.origin}`
    );
  };

  return (
    <>
      <Loading
        loading={
          travellerLoading || loading || bookingLoading || paymentLoading
        }
        backgroundColor="white"
      />
      {!(travellerLoading || loading || bookingLoading || paymentLoading) && (
        <div className="Booking-page">
          <div className="container">
            <>
              {tripInfos?.length > 0 ? (
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-lg-8 col-md-7 col-sm-12">
                        <div className="booking-page-right">
                          <h2>Complete your booking</h2>
                          {flightReviewDetails?.tripInfos?.length > 0 &&
                            flightReviewDetails?.tripInfos?.map(
                              (route: any, index: number) => {
                                const fareRuleInfo =
                                  flightReviewDetails?.tripInfos[index]
                                    ?.totalPriceList[0]?.fareRuleInformation
                                    ?.tfr;
                                return (
                                  <ReviewFlightRouteCard
                                    route={route}
                                    fareRule={fareRuleInfo}
                                  />
                                );
                              }
                            )}
                          <IMPInfoCard />
                          <div className="traveller-detail">
                            <h4>Traveller Details</h4>
                            {ADULT > 0 && (
                              <AdultForm
                                formik={formik}
                                handleCheckboxChange={handleCheckboxChange}
                                isADOBRRequired={isADOBRRequired || !isDomestic}
                                isPNRequired={isPNRequired}
                                isPPEDRequired={isPPEDRequired}
                                isPIDRequired={isPIDRequired}
                              />
                            )}
                            {CHILD > 0 && (
                              <ChildForm
                                formik={formik}
                                handleCheckboxChange={handleCheckboxChange}
                                isCDOBRRequired={isCDOBRRequired || !isDomestic}
                                isPNRequired={isPNRequired}
                                isPPEDRequired={isPPEDRequired}
                                isPIDRequired={isPIDRequired}
                              />
                            )}
                            {INFANT > 0 && (
                              <InfantForm
                                formik={formik}
                                handleCheckboxChange={handleCheckboxChange}
                                isIDOBRRequired={isIDOBRRequired || !isDomestic}
                                isPNRequired={isPNRequired}
                                isPPEDRequired={isPPEDRequired}
                                isPIDRequired={isPIDRequired}
                              />
                            )}
                            <ContactDetailsForm
                              formik={formik}
                              isGSTRequired={isGSTRequired}
                              isGSTApplicable={isGSTApplicable}
                            />
                          </div>
                          <span className="agree-note">
                            By clicking on the Continue button below to proceed
                            with the booking, <br />I confirm that I have read
                            and I accept the&nbsp;Fare Rules, the&nbsp;
                            <a href="#">Privacy Policy</a>&nbsp;, the&nbsp;
                            <a href="#">
                              User Agreement&nbsp;and&nbsp;Terms of Service
                            </a>
                          </span>
                          <div className="traveller-detail travrller-btn">
                            <a
                              className="fill-btn"
                              href="javascript:void(0)"
                              onClick={() => {
                                if (localStorage.getItem("user_guid")) {
                                  formik.handleSubmit();
                                } else {
                                  ErrorToast(
                                    "Please login to book a flight!!!"
                                  );
                                }
                              }}
                            >
                              Proceed to Pay
                            </a>
                          </div>
                        </div>
                      </div>
                      <ReviewPriceCard
                        tripInfos={tripInfos}
                        paxDetails={paxDetails}
                        handleSubmit={() => formik.handleSubmit()}
                      />
                    </div>
                  </form>
                </FormikProvider>
              ) : (
                <div className="reviewbooking-something-wrong">
                  <h1>Something went Wrong!</h1>
                  <button
                    className="fill-btn fw-600"
                    onClick={() => handleSearchAgain()}
                  >
                    Search Again
                  </button>
                </div>
              )}
            </>
          </div>
          {isAlertAvailabel && !isSessionExpired && (
            <>
              <span className="overlay" style={{ display: "block" }}></span>
              <div
                className="modal fade show"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="session-expired"
                aria-modal="true"
                style={{ display: "block" }}
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  style={{ maxWidth: 400 }}
                >
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <h4 className="modal-title fs-5" id="session-expired">
                        Fare Price Updated!
                      </h4>
                    </div>
                    <div className="modal-body text-center">
                      <span className="fareupdate-text">
                        The fare for your flight has been changed from{" "}
                        <b>₹{formatIndianNumber(alerts?.oldFare)}</b> to{" "}
                        <b>₹{formatIndianNumber(alerts?.newFare)}</b>. Do you
                        want to continue?
                      </span>
                      <div className="fareupdate-btn-list">
                        <button
                          className="fill-btn fill-btn-disabled fw-600"
                          onClick={() => handleSearchAgain()}
                        >
                          Back to Flight List
                        </button>
                        <button
                          className="fill-btn fw-600"
                          onClick={() => setIsAlertAvaialbel(false)}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {isSessionExpired && (
            <>
              <span className="overlay" style={{ display: "block" }}></span>
              <div
                className="modal fade show"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="session-expired"
                aria-modal="true"
                style={{ display: "block" }}
                role="dialog"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  style={{ maxWidth: 350 }}
                >
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <h4 className="modal-title fs-5" id="session-expired">
                        Your session is expired!
                      </h4>
                    </div>
                    <div className="modal-body text-center">
                      <button
                        className="fill-btn fw-600"
                        onClick={() => handleSearchAgain()}
                      >
                        Search Again
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ReviewBookingNew;
