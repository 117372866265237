import React, { useState } from "react";
import { FormikProps } from "formik";
import PLAN_TAKEOFF from "../../assets/images/plane-takeoff.jpg";
import SWITCH_IMAGE from "../../assets/images/swith.png";
import PLANE_LANDING from "../../assets/images/plane-landing.png";
import CALENDER_DAYS from "../../assets/images/calendar-days.png";
import SEARCH from "../../assets/images/search.png";
import { Select } from "antd";
import { ErrorToast } from "../../toast/Toasts";
import { useAppDispatch } from "../../app/hooks";
import {
  DateRangePicker,
  FocusedInputShape,
  SingleDatePicker,
} from "react-dates";
import moment from "moment";
import useWindowSize from "../../_hooks/useWindowSize";
import { useLocation } from "react-router";
import { filterOutRecentSearches } from "../../_helpers/helper";

interface IORTripSearch {
  formik: FormikProps<any>;
  onSearchLocation: (e: string, options: string) => void;
  recentToOptions: any[];
  recentFromOptions: any[];
}

const ORTripSearch = ({
  formik,
  onSearchLocation,
  recentToOptions,
  recentFromOptions,
}: IORTripSearch) => {
  const { width } = useWindowSize();
  const location = useLocation();

  const [dateInput, setDateInput] = useState<FocusedInputShape | null>(null);
  const [dateFocused, setDateFocused] = useState(false);
  const [search, setSearch] = useState("");
  const isBannerSearch =
    location.pathname === "/" || location.pathname === "/flights";

  return (
    <div className="tabcontent-ow tabiingcontent">
      <div className="row">
        <div className="col-lg-7 col-md-12 col-sm-12">
          <div className="place-field">
            <span className="field-top">From</span>
            <span className="field-top field-right">To</span>
            <span
              className="switch"
              onClick={() => {
                const from = formik.values.from;
                const to = formik.values.to;
                const fromOptions = formik.values.fromOptions;
                const toOptions = formik.values.toOptions;

                formik.setFieldValue("from", to);
                formik.setFieldValue("to", from);
                formik.setFieldValue("fromOptions", toOptions);
                formik.setFieldValue("toOptions", fromOptions);
              }}
            >
              <img src={SWITCH_IMAGE} alt="" />
            </span>
            <div
              className="place-field-dropdown place-field-1"
              style={{
                padding: isBannerSearch ? "14px 0" : "7px 0",
                justifyContent: "start",
              }}
            >
              <div className="place-field-pic">
                <img src={PLAN_TAKEOFF} alt="" />
              </div>
              <div className="select-div">
                <Select
                  className="select-control"
                  onChange={(value) => {
                    if (formik.values.to !== value) {
                      formik.setFieldValue("from", value);
                    } else {
                      ErrorToast("");
                    }
                  }}
                  showSearch
                  onSearch={(e) => {
                    setSearch(e);
                    onSearchLocation(e, "fromOptions");
                  }}
                  filterOption={false}
                  value={formik.values.from}
                  // options={[
                  //   {
                  //     label: (
                  //       <p className="option-group-title">Recent Searches</p>
                  //     ),
                  //     title: "Recent Searches",
                  //     options: recentFromOptions.map((e: any) => ({
                  //       label: (
                  //         <div className="location-field-option">
                  //           <div
                  //             style={{
                  //               display: "flex",
                  //               justifyContent: "space-between",
                  //               gap: 5,
                  //             }}
                  //           >
                  //             <p className="city-option">{e?.city_name}</p>
                  //             <span className="code-option m-0">
                  //               {e.flightplace_code}
                  //             </span>
                  //           </div>
                  //           <p className="m-0 airport-name-option">
                  //             {e.flightplace_name}
                  //           </p>
                  //         </div>
                  //       ),
                  //       value: e.flightplace_code,
                  //     })),
                  //   },
                  //   {
                  //     label: (
                  //       <p className="option-group-title">Popular Cities</p>
                  //     ),
                  //     title: "Popular Cities",
                  //     options: filterOutRecentSearches(
                  //       formik?.values?.fromOptions,
                  //       recentFromOptions
                  //     )?.map((e: any) => ({
                  //       label: (
                  //         <div className="location-field-option">
                  //           <div className="option-field">
                  //             <p className="city-option">{e?.city_name}</p>
                  //             <span className="code-option m-0">
                  //               {e.flightplace_code}
                  //             </span>
                  //           </div>
                  //           <p className="m-0 airport-name-option">
                  //             {e.flightplace_name}
                  //           </p>
                  //         </div>
                  //       ),
                  //       value: e.flightplace_code,
                  //     })),
                  //   },
                  // ]}
                >
                  {formik?.values?.fromOptions?.map((e: any) => (
                    <option value={e?.flightplace_code} label={e?.city_name}>
                      <div className="location-field-option">
                        <div className="option-field">
                          <p className="city-option">{e?.city_name}</p>
                          <span className="code-option m-0">
                            {e.flightplace_code}
                          </span>
                        </div>
                        <p className="m-0 airport-name-option">
                          {e.flightplace_name}
                        </p>
                      </div>
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              className="place-field-dropdown place-field-2"
              style={{ padding: isBannerSearch ? "14px 0" : "7px 0" }}
            >
              <div className="place-field-pic">
                <img src={PLANE_LANDING} alt="" />
              </div>
              <div className="select-div">
                <Select
                  className="select-control"
                  onChange={(value) => {
                    if (formik.values.from !== value) {
                      formik.setFieldValue("to", value);
                    } else {
                      ErrorToast("From & To airports cannot be the same");
                    }
                  }}
                  showSearch
                  onSearch={(e) => onSearchLocation(e, "toOptions")}
                  filterOption={false}
                  value={formik.values.to}
                >
                  {/* {recentToOptions?.map((e: any) => (
                          <option
                            value={e?.flightplace_code}
                            label={e?.city_name}
                          >
                            <div className="place-field-text">
                              {location?.pathname === "/flights" ||
                              location?.pathname === "/" ? (
                                <h6>{e?.city_name}</h6>
                              ) : (
                                <h3
                                  className={`${
                                    location?.pathname === "/flights" ||
                                    location?.pathname === "/"
                                      ? ""
                                      : "searchitem"
                                  }`}
                                >
                                  {e?.city_name}
                                </h3>
                              )}
                              <div>
                                {e?.flightplace_code} - {e?.city_name}
                              </div>
                            </div>
                          </option>
                        ))} */}
                  {formik?.values?.toOptions?.map((e: any) => (
                    <option value={e?.flightplace_code} label={e?.city_name}>
                      <div className="location-field-option">
                        <div className="option-field">
                          <p className="city-option">{e?.city_name}</p>
                          <span className="code-option m-0">
                            {e.flightplace_code}
                          </span>
                        </div>
                        <p className="m-0 airport-name-option">
                          {e.flightplace_name}
                        </p>
                      </div>
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-lg-5 col-md-12 col-sm-12 ${
            isBannerSearch ? "" : "list-top-right"
          }`}
        >
          <div className="place-field position-relative">
            {formik.values.triptype === "Round trip" && (
              <DateRangePicker
                startDate={
                  formik.values.departureDate
                    ? moment(formik.values.departureDate)
                    : null
                }
                startDateId="your_unique_start_date_id"
                endDate={
                  formik.values.returnDate
                    ? moment(formik.values.returnDate)
                    : null
                }
                endDateId="your_unique_end_date_id"
                onDatesChange={({ startDate, endDate }) => {
                  formik.setFieldValue("departureDate", startDate);
                  formik.setFieldValue("returnDate", endDate);
                }}
                focusedInput={dateInput}
                onFocusChange={(focusedInput: any) => {
                  setDateInput(focusedInput);
                }}
                anchorDirection="right"
                hideKeyboardShortcutsPanel={true}
                minimumNights={0}
                orientation={width <= 768 ? "vertical" : "horizontal"}
                renderMonthElement={({
                  month,
                  onMonthSelect,
                  onYearSelect,
                }) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <select
                      style={{ border: "1px solid lightgray" }}
                      value={month.month()}
                      onChange={(e) => onMonthSelect(month, e.target.value)}
                    >
                      {moment.months().map((label, value) => (
                        <option value={value} key={`month-${value}`}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <select
                      style={{ border: "1px solid lightgray" }}
                      value={month.year()}
                      onChange={(e) => onYearSelect(month, e.target.value)}
                    >
                      {Array.from(new Array(100), (e, i) => (
                        <option
                          value={i + moment().year() - 50}
                          key={`year-${i}`}
                        >
                          {i + moment().year() - 50}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              />
            )}
            <span className="field-top">Departure</span>
            <span className="field-top field-right">Return</span>
            <div className="place-field-dropdown place-field-3">
              <div className="place-field-pic">
                <img src={CALENDER_DAYS} alt="" />
              </div>
              <div className="custom-select">
                <button
                  className="select-button"
                  onClick={(e: any) => e.preventDefault()}
                >
                  <span
                    className="selected-value"
                    onClick={() => {
                      if (formik.values.triptype === "Round trip") {
                        setDateInput("startDate");
                      } else {
                        setDateFocused(true);
                      }
                    }}
                  >
                    <div className="place-field-text formattedDate">
                      <h3>
                        {moment(formik.values.departureDate).format(
                          "DD MMM'YY"
                        )}
                      </h3>
                      <p>
                        {moment(formik.values.departureDate).format("dddd")}
                      </p>
                    </div>
                  </span>
                </button>
                {formik.values.triptype === "One Way" && (
                  <SingleDatePicker
                    id="fromdate"
                    date={
                      formik.values.departureDate
                        ? moment(formik.values.departureDate)
                        : null
                    }
                    onDateChange={(date) => {
                      formik.setFieldValue("departureDate", date?.toDate());
                      const returnDate = moment(formik.values.returnDate);
                      if (moment(returnDate).isBefore(moment(date?.toDate()))) {
                        formik.setFieldValue(
                          "returnDate",
                          moment(date?.toDate()).toDate()
                        );
                      }
                    }}
                    renderMonthElement={({
                      month,
                      onMonthSelect,
                      onYearSelect,
                    }) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          style={{ border: "1px solid lightgray" }}
                          value={month.month()}
                          onChange={(e) => onMonthSelect(month, e.target.value)}
                        >
                          {moment.months().map((label, value) => (
                            <option value={value} key={`month-${value}`}>
                              {label}
                            </option>
                          ))}
                        </select>
                        <select
                          style={{ border: "1px solid lightgray" }}
                          value={month.year()}
                          onChange={(e) => onYearSelect(month, e.target.value)}
                        >
                          {Array.from(new Array(100), (e, i) => (
                            <option
                              value={i + moment().year() - 50}
                              key={`year-${i}`}
                            >
                              {i + moment().year() - 50}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    displayFormat={"DD/MM/YYYY"}
                    focused={dateFocused}
                    openDirection="down"
                    anchorDirection="left"
                    numberOfMonths={1}
                    onFocusChange={({ focused }) => {
                      setDateFocused(focused);
                    }}
                    hideKeyboardShortcutsPanel={true}
                  />
                )}
              </div>
            </div>
            <div className="place-field-dropdown place-field-4">
              <div className="place-field-pic">
                <img src={CALENDER_DAYS} alt="" />
              </div>
              <div className="custom-select">
                <button
                  className="select-button"
                  onClick={(e: any) => e.preventDefault()}
                >
                  <span
                    className="selected-value"
                    onClick={() => setDateInput("endDate")}
                  >
                    <div
                      className="place-field-text formattedDate"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.setFieldValue("triptype", "Round trip");
                      }}
                    >
                      <h3>
                        {formik.values.triptype === "Round trip"
                          ? formik.values.returnDate
                            ? moment(formik.values.returnDate).format(
                                "DD MMM'YY"
                              )
                            : ""
                          : ""}
                      </h3>
                      <p>
                        {formik.values.triptype === "Round trip"
                          ? formik.values.returnDate
                            ? moment(formik.values.returnDate).format("dddd")
                            : "Select Date"
                          : "Select Date"}
                      </p>
                    </div>
                  </span>
                </button>
              </div>
            </div>
          </div>
          {!isBannerSearch && (
            <a
              onClick={() => {
                const newFormKey = (formik.values.formKey || 0) + 1;
                formik.setFieldValue("formKey", newFormKey);
                formik.handleSubmit();
              }}
              className="fill-btn"
            >
              <img src={SEARCH} alt="" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ORTripSearch;
