import React from "react";
import WEKAND from "../../assets/images/wekand-1.jpg";
import WEKAND2 from "../../assets/images/wekand-2.jpg";
import WEKAND3 from "../../assets/images/wekand-3.jpg";
import WEKAND4 from "../../assets/images/wekand-4.jpg";
import Slider from "react-slick";
import WeekendCard from "./WeekendCard";

const weekendDeals = [
  {
    image: WEKAND,
    review: "8.4/10 Very good (202 reviews)",
    name: "The Roseate New Delhi",
    location: "New Delhi",
    price: "₹41,533",
    oldPrice: "₹63,897",
    nights: 2,
    perNight: "₹20,767",
    discount: "35% off",
    memberPrice: false,
  },
  {
    image: WEKAND2,
    review: "8.4/10 Very good (202 reviews)",
    name: "The Roseate New Delhi",
    location: "New Delhi",
    price: "₹41,533",
    oldPrice: "₹63,897",
    nights: 2,
    perNight: "₹20,767",
    discount: "35% off",
    memberPrice: true,
  },
  {
    image: WEKAND3,
    review: "8.4/10 Very good (202 reviews)",
    name: "The Roseate New Delhi",
    location: "New Delhi",
    price: "₹41,533",
    oldPrice: "₹63,897",
    nights: 2,
    perNight: "₹20,767",
    discount: "35% off",
    memberPrice: false,
  },
  {
    image: WEKAND4,
    review: "8.4/10 Very good (202 reviews)",
    name: "The Roseate New Delhi",
    location: "New Delhi",
    price: "₹41,533",
    oldPrice: "₹63,897",
    nights: 2,
    perNight: "₹20,767",
    discount: "35% off",
    memberPrice: false,
  },
];

const WeekendDeals = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="lastdeal-section mt-5 mb-5">
      <div className="container">
        <h2 className="text-start mb-3 mt-2 ps-2">Last-minute weekend deals</h2>
        <p className="ps-2">Showing deals for: 23 Feb - 25 Feb</p>
        <Slider {...settings}>
          {weekendDeals.map((deal, index) => (
            <WeekendCard key={index} deal={deal} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default WeekendDeals;
