import { createSlice } from "@reduxjs/toolkit";
import { IReviewBooking } from "./reviewbookingModel";
import {
  confirmFlightBooking,
  cretePaymentOrder,
  getConfirmFlightBooking,
  getGroupwiseTravellerByUserID,
  getReviewFlightBooking,
  saveFlightBooking,
  saveTempFlightBooking,
  verifyPayment,
} from "./services/reviewbooking.services";
import { DUMMY_REVIEWFLIGHTDETAILS } from "../../_constants/constant";

const initialState: IReviewBooking = {
  loading: false,
  travellerLoading: false,
  paymentLoading: false,
  bookingLoading: false,
  error: "",
  flightReviewDetails: null,
  reviewBookingDetails: {
    adults: [
      {
        initial: "",
        first_name: "",
        last_name: "",
        gender: "",
        mobile_no: "",
        email_id: "",
        isSelected: true,
        date_of_birth: "",
        passport_no: "",
        issue_country_code: "",
        issue_date: "",
        expire_date: "",
      },
    ],
    children: [
      {
        initial: "",
        first_name: "",
        last_name: "",
        gender: "",
        isSelected: true,
        date_of_birth: "",
        passport_no: "",
        issue_country_code: "",
        issue_date: "",
        expire_date: "",
      },
    ],
    infants: [
      {
        initial: "",
        isSelected: true,
        first_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        passport_no: "",
        issue_country_code: "",
        issue_date: "",
        expire_date: "",
      },
    ],
    mobile_no: "",
    email_id: "",
    alt_mobile_no: "",
    alt_email_id: "",
    isGST: false,
    gst_number: "",
    gst_email: "",
    gst_registered_name: "",
    gst_mobile: "",
    gst_address: "",
  },
};

const reviewbookingSlice = createSlice({
  name: "reviewbooking",
  initialState,
  reducers: {
    setFlightReviewDetails: (state, action) => {
      state.flightReviewDetails = action.payload;
    },
    clearFlightReviewDetails: (state) => {
      state.flightReviewDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReviewFlightBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getReviewFlightBooking.fulfilled, (state, action) => {
      state.loading = false;
      state.flightReviewDetails = action.payload;
      // state.flightReviewDetails = DUMMY_REVIEWFLIGHTDETAILS;
    });
    builder.addCase(getReviewFlightBooking.rejected, (state, action) => {
      state.loading = false;
      state.flightReviewDetails = initialState.flightReviewDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(confirmFlightBooking.pending, (state) => {
      state.bookingLoading = true;
      state.error = "";
    });
    builder.addCase(confirmFlightBooking.fulfilled, (state, action) => {
      state.bookingLoading = false;
    });
    builder.addCase(confirmFlightBooking.rejected, (state, action) => {
      state.bookingLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getConfirmFlightBooking.pending, (state) => {
      state.bookingLoading = true;
      state.error = "";
    });
    builder.addCase(getConfirmFlightBooking.fulfilled, (state, action) => {
      state.bookingLoading = false;
    });
    builder.addCase(getConfirmFlightBooking.rejected, (state, action) => {
      state.bookingLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(saveFlightBooking.pending, (state) => {
      state.bookingLoading = true;
      state.error = "";
    });
    builder.addCase(saveFlightBooking.fulfilled, (state, action) => {
      state.bookingLoading = false;
    });
    builder.addCase(saveFlightBooking.rejected, (state, action) => {
      state.bookingLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(saveTempFlightBooking.pending, (state) => {
      state.bookingLoading = true;
      state.error = "";
    });
    builder.addCase(saveTempFlightBooking.fulfilled, (state, action) => {
      state.bookingLoading = false;
    });
    builder.addCase(saveTempFlightBooking.rejected, (state, action) => {
      state.bookingLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(cretePaymentOrder.pending, (state) => {
      state.paymentLoading = true;
      state.error = "";
    });
    builder.addCase(cretePaymentOrder.fulfilled, (state, action) => {
      state.paymentLoading = false;
    });
    builder.addCase(cretePaymentOrder.rejected, (state, action) => {
      state.paymentLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(verifyPayment.pending, (state) => {
      state.paymentLoading = true;
      state.error = "";
    });
    builder.addCase(verifyPayment.fulfilled, (state, action) => {
      state.paymentLoading = false;
    });
    builder.addCase(verifyPayment.rejected, (state, action) => {
      state.paymentLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getGroupwiseTravellerByUserID.pending, (state) => {
      state.travellerLoading = true;
      state.error = "";
    });
    builder.addCase(
      getGroupwiseTravellerByUserID.fulfilled,
      (state, action) => {
        state.travellerLoading = false;
        state.reviewBookingDetails = {
          ...state.reviewBookingDetails,
          adults:
            action.payload?.adults?.length > 0
              ? action.payload?.adults
              : state.reviewBookingDetails?.adults,
          children:
            action.payload?.children?.length > 0
              ? action.payload?.children
              : state.reviewBookingDetails?.children,
          infants:
            action.payload?.infants?.length > 0
              ? action.payload?.infants
              : state.reviewBookingDetails?.infants,
        };
      }
    );
    builder.addCase(getGroupwiseTravellerByUserID.rejected, (state, action) => {
      state.travellerLoading = false;
      state.reviewBookingDetails = initialState.reviewBookingDetails;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const { setFlightReviewDetails, clearFlightReviewDetails } =
  reviewbookingSlice.actions;
export default reviewbookingSlice.reducer;
