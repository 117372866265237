import { FormikProps } from "formik";
import { IReviewBookingDetails } from "./reviewbookingModel";

interface ContactDetailsFormProps {
  formik: FormikProps<IReviewBookingDetails>;
  isGSTApplicable: Boolean;
  isGSTRequired: Boolean;
}

const ContactDetailsForm = ({
  formik,
  isGSTApplicable,
  isGSTRequired,
}: ContactDetailsFormProps) => {
  return (
    <div className="adult-form">
      <div className="child-form">
        <h5>Booking details will be sent to</h5>
        <div className="form-1 form">
          <form>
            <div className="field-set">
              <div className="fields number-field">
                <label
                  className={
                    formik?.touched?.mobile_no && formik.errors.mobile_no
                      ? "error-label"
                      : ""
                  }
                >
                  Mobile No <code>*</code>
                </label>
                <input
                  type="number"
                  className={
                    formik?.touched?.mobile_no && formik.errors.mobile_no
                      ? "error-border"
                      : ""
                  }
                  placeholder="Mobile No"
                  name={`mobile_no`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile_no}
                />
                {formik?.touched?.mobile_no && formik.errors.mobile_no && (
                  <div className="error-label">{formik.errors.mobile_no}</div>
                )}
              </div>
              <div className="fields number-field">
                <label>Alternate Mobile No</label>
                <input
                  type="number"
                  placeholder="Mobile No"
                  name={`alt_mobile_no`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.alt_mobile_no}
                />
              </div>
            </div>
            <div className="field-set">
              <div className="fields email_id-field">
                <label
                  className={
                    formik?.touched?.email_id && formik.errors.email_id
                      ? "error-label"
                      : ""
                  }
                >
                  Email <code>*</code>
                </label>
                <input
                  type="email"
                  className={
                    formik?.touched?.email_id && formik.errors.email_id
                      ? "error-border"
                      : ""
                  }
                  placeholder="Email Address"
                  name={`email_id`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email_id}
                />
                {formik?.touched?.email_id && formik.errors.email_id && (
                  <div className="error-label">{formik.errors.email_id}</div>
                )}
              </div>
              <div className="fields email_id-field">
                <label>Alternate Email</label>
                <input
                  type="email"
                  placeholder="Email Address"
                  name={`alt_email_id`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.alt_email_id}
                />
              </div>
            </div>
            <div className="chaeckbox">
              <input
                type="checkbox"
                name={`isGST`}
                checked={
                  isGSTRequired
                    ? true
                    : isGSTApplicable
                    ? formik.values.isGST
                    : false
                }
                onChange={formik.handleChange}
              />
              <label>
                <strong>I have a GST number</strong>
              </label>
            </div>
            {formik.values.isGST && (
              <div className="form">
                <div className="field-set align-end">
                  <div className="fields">
                    <label
                      className={
                        formik?.touched?.gst_number && formik.errors.gst_number
                          ? "error-label"
                          : ""
                      }
                    >
                      GST No
                    </label>
                    <input
                      type="text"
                      className={
                        formik?.touched?.gst_number && formik.errors.gst_number
                          ? "error-border"
                          : ""
                      }
                      name={`gst_number`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gst_number}
                    />
                    {formik?.touched?.gst_number &&
                      formik.errors.gst_number && (
                        <div className="error-label">
                          {formik.errors.gst_number}
                        </div>
                      )}
                  </div>
                  <div className="fields">
                    <label
                      className={
                        formik?.touched?.gst_registered_name &&
                        formik.errors.gst_registered_name
                          ? "error-label"
                          : ""
                      }
                    >
                      Registered Name
                    </label>
                    <input
                      type="text"
                      className={
                        formik?.touched?.gst_registered_name &&
                        formik.errors.gst_registered_name
                          ? "error-border"
                          : ""
                      }
                      name={`gst_registered_name`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gst_registered_name}
                    />
                    {formik?.touched?.gst_registered_name &&
                      formik.errors.gst_registered_name && (
                        <div className="error-label">
                          {formik.errors.gst_registered_name}
                        </div>
                      )}
                  </div>
                  <div className="fields">
                    <label
                      className={
                        formik?.touched?.gst_mobile && formik.errors.gst_mobile
                          ? "error-label"
                          : ""
                      }
                    >
                      Mobile No
                    </label>
                    <input
                      type="text"
                      className={
                        formik?.touched?.gst_mobile && formik.errors.gst_mobile
                          ? "error-border"
                          : ""
                      }
                      name={`gst_mobile`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gst_mobile}
                    />
                    {formik?.touched?.gst_mobile &&
                      formik.errors.gst_mobile && (
                        <div className="error-label">
                          {formik.errors.gst_mobile}
                        </div>
                      )}
                  </div>
                </div>
                <div className="field-set align-end mb-3">
                  <div className="fields">
                    <label
                      className={
                        formik?.touched?.gst_email && formik.errors.gst_email
                          ? "error-label"
                          : ""
                      }
                    >
                      Email ID
                    </label>
                    <input
                      type="text"
                      className={
                        formik?.touched?.gst_email && formik.errors.gst_email
                          ? "error-border"
                          : ""
                      }
                      name={`gst_email`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gst_email}
                    />
                    {formik?.touched?.gst_email && formik.errors.gst_email && (
                      <div className="error-label">
                        {formik.errors.gst_email}
                      </div>
                    )}
                  </div>
                  <div className="fields">
                    <label
                      className={
                        formik?.touched?.gst_address &&
                        formik.errors.gst_address
                          ? "error-label"
                          : ""
                      }
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      className={
                        formik?.touched?.gst_address &&
                        formik.errors.gst_address
                          ? "error-border"
                          : ""
                      }
                      name={`gst_address`}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gst_address}
                    />
                    {formik?.touched?.gst_address &&
                      formik.errors.gst_address && (
                        <div className="error-label">
                          {formik.errors.gst_address}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsForm;
