import React, { useMemo, useState } from "react";
import moment from "moment";
import PLANE from "../../assets/images/plane.png";
import { formatMinToHRAndMin, getFlightImage } from "../../_helpers/helper";
import Loading from "../../components/spinner/Loading";
import { useAppSelector } from "../../app/hooks";
import { axiosApiInstance } from "../../app/axios";
import { TRIPJACK_API_URL } from "../../app/config";

interface ComboFlightDetailsTabProps {
  flightGroup: any[];
  priceDetails: any;
  openFightDetail: boolean;
  flightGroupIndex: number;
}

const ComboFlightDetails = ({
  flightGroup = [],
  priceDetails,
  openFightDetail,
  flightGroupIndex,
}: ComboFlightDetailsTabProps) => {
  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const [fareRule, setFareRule] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const departureCode = useMemo(
    () => flightGroup[0]?.sI[0]?.da?.code,
    [flightGroup[0]]
  );
  const arivalCode = useMemo(
    () =>
      flightGroup[flightGroup?.length - 1]?.sI[
        flightGroup[flightGroup?.length - 1]?.sI?.length - 1
      ]?.aa?.code,
    [flightGroup[flightGroup?.length - 1]]
  );

  const cancellationPolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.BEFORE_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.AFTER_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION?.DEFAULT
      ?.policyInfo ||
    "Airline Cancellation Charges + Taxes + Surcharges";
  const cancellationAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.BEFORE_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.AFTER_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION?.DEFAULT
      ?.amount ||
    "";
  const datechangePolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.BEFORE_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.AFTER_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.DEFAULT
      ?.policyInfo ||
    "";
  const datechangeAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.BEFORE_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.AFTER_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.DEFAULT
      ?.amount ||
    "";
  const noshowPolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.BEFORE_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.AFTER_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.DEFAULT
      ?.policyInfo ||
    "Fees As Per Airline Rule + Surcharges";
  const seatschargePolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.BEFORE_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.AFTER_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE?.DEFAULT
      ?.policyInfo ||
    "As Per Airline";
  const seatschargeAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.BEFORE_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.AFTER_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE?.DEFAULT
      ?.amount ||
    "";
  const noshowAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.BEFORE_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.AFTER_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.DEFAULT?.amount ||
    "";

  const classOfBook = priceDetails?.fd?.ADULT?.cB;

  const { ADULT, CHILD, INFANT } = flightSearchDetails;

  const adultBaseRate = priceDetails?.fd?.ADULT?.fC?.BF || 0;
  const childBaseRate = priceDetails?.fd?.CHILD?.fC?.BF || 0;
  const infantBaseRate = priceDetails?.fd?.INFANT?.fC?.BF || 0;

  const adultTaxRate = priceDetails?.fd?.ADULT?.fC?.TAF || 0;
  const childTaxRate = priceDetails?.fd?.CHILD?.fC?.TAF || 0;
  const infantTaxRate = priceDetails?.fd?.INFANT?.fC?.TAF || 0;

  const totalPrice = useMemo(() => {
    const totalAdultPrice = (priceDetails?.fd?.ADULT?.fC?.TF || 0) * ADULT;
    const totalChildPrice = (priceDetails?.fd?.CHILD?.fC?.TF || 0) * CHILD;
    const totalInfantPrice = (priceDetails?.fd?.INFANT?.fC?.TF || 0) * INFANT;
    return totalAdultPrice + totalChildPrice + totalInfantPrice;
  }, [priceDetails, ADULT, CHILD, INFANT]);

  const flights: any = useMemo(() => {
    const flightPriceDetails = priceDetails;
    return flightGroup?.map((route: any) => {
      const startTime = moment(route?.sI[0]?.dt);
      const endTime = moment(route?.sI[route?.sI?.length - 1]?.at);
      const departureCode = route?.sI[0]?.da?.code;
      const arrivalCode = route?.sI[route?.sI?.length - 1]?.aa?.code;

      const remainingSeats = flightPriceDetails?.fd?.ADULT?.sR;
      const cabinClass = flightPriceDetails?.fd?.ADULT?.cc;
      const classOfBook = flightPriceDetails?.fd?.ADULT?.cB;

      const adultCabbinBaggage = flightPriceDetails?.fd?.ADULT?.bI?.cB;
      const childCabbinBaggage = flightPriceDetails?.fd?.CHILD?.bI?.cB;
      const infantCabbinBaggage = flightPriceDetails?.fd?.INFANT?.bI?.cB;

      const adultCheckingBaggage = flightPriceDetails?.fd?.ADULT?.bI?.iB;
      const childCheckingBaggage = flightPriceDetails?.fd?.CHILD?.bI?.iB;
      const infantCheckingBaggage = flightPriceDetails?.fd?.INFANT?.bI?.iB;

      return {
        route,
        priceDetails: flightPriceDetails,
        startTime,
        endTime,
        departureCode,
        arrivalCode,
        remainingSeats,
        cabinClass,
        classOfBook,
        adultCabbinBaggage,
        childCabbinBaggage,
        infantCabbinBaggage,
        adultCheckingBaggage,
        childCheckingBaggage,
        infantCheckingBaggage,
      };
    });
  }, [flightGroup]);

  const formatContent = (content: any, additionalText: any) => {
    const formattedContent = content?.replace(/__nls__/g, "<br />");
    return `${
      additionalText ? additionalText + " + " : ""
    } ${formattedContent}`;
  };
  const getFareRules = async (id: any) => {
    try {
      setFareRule({});
      setLoading(true);
      const response = await axiosApiInstance.post(
        `${TRIPJACK_API_URL}/fms/v1/farerule`,
        {
          id: id,
          flowType: "SEARCH",
        }
      );
      setFareRule(response.data?.fareRule);
    } catch (error) {
      console.error("Error fetching fare rules results:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`flight-tabing-2 ${
        openFightDetail ? "flight-outer-open" : ""
      }`}
    >
      <ul className="nav nav-pills mb-3 gap-2" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id={`pills-tab-flight-${flightGroupIndex}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-flight-details-${flightGroupIndex}`}
            type="button"
            role="tab"
            aria-controls={`pills-flight-details-${flightGroupIndex}`}
            aria-selected="true"
          >
            Flight Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-fare-details-${flightGroupIndex}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-fare-details-${flightGroupIndex}`}
            type="button"
            role="tab"
            aria-controls={`pills-fare-details-${flightGroupIndex}`}
            aria-selected="false"
            tabIndex={-1}
          >
            Fare Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-fare-rules-${flightGroupIndex}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-fare-rules-${flightGroupIndex}`}
            type="button"
            role="tab"
            aria-controls={`pills-fare-rules-${flightGroupIndex}`}
            aria-selected="false"
            tabIndex={-1}
            onClick={() => {
              getFareRules(priceDetails?.id);
            }}
          >
            Fare Rules
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-buggage-details-${flightGroupIndex}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-buggage-details-${flightGroupIndex}`}
            type="button"
            role="tab"
            aria-controls={`pills-buggage-details-${flightGroupIndex}`}
            aria-selected="false"
            tabIndex={-1}
          >
            Baggage Details
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade active show"
          id={`pills-flight-details-${flightGroupIndex}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-flight-${flightGroupIndex}`}
        >
          {flights?.map((flight: any) => (
            <div className="fligt-confirm">
              {flight?.route?.sI?.map((e: any, index: number) => {
                const flightNo = e?.fD?.fN;
                const flightCode = e?.fD?.aI?.code;
                const equipmentType = e?.fD?.eT;
                const departureTime = e?.dt;
                const arivalTime = e?.at;
                const layoverTime = e?.cT;
                const duration = e?.duration;
                const arivalCity = e?.aa?.city;
                const departureCity = e?.da?.city;
                const arivalAirport = e?.aa?.name;
                const departureAirport = e?.da?.name;
                const stops = e?.stops;

                const isPlaneChange =
                  flightNo !== flight?.route?.sI[index + 1]?.fD?.fN;

                if (index === 0) {
                  return (
                    <>
                      <div className="flight-indcator">
                        <span>
                          <h5>
                            {flight?.route?.sI[0]?.da?.city} →{" "}
                            {
                              flight?.route?.sI[flight?.route?.sI?.length - 1]
                                ?.aa?.city
                            }
                          </h5>
                          {moment(flight?.route?.sI[0]?.dt)?.format(
                            "ddd, MMM Do YYYY"
                          )}
                        </span>
                      </div>
                      <div className="flight-choose">
                        <div className="fight-name">
                          <img src={getFlightImage(e.fD?.aI?.code)} alt="" />
                          {/* <img src={getFlightImage(e.fD?.aI?.name)} alt="" /> */}
                          <span>
                            <h5>{e.fD?.aI?.name}</h5>
                            <p>
                              {flightCode} - {flightNo} {equipmentType}
                            </p>
                          </span>
                        </div>
                        <div className="route-timing">
                          <div className="route-strating">
                            {moment(departureTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{departureCity},</strong>{" "}
                              {departureAirport}
                            </p>
                          </div>
                          <div className="cunsume-time">
                            <p>{stops === 0 ? "Non Stop" : `${stops} Stop`}</p>
                            <div className="cunsume-pic">
                              <img src={PLANE} alt="" />
                            </div>
                          </div>
                          <div className="route-strating route-arrival">
                            {moment(arivalTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{arivalCity},</strong> {arivalAirport}
                            </p>
                          </div>
                        </div>
                        <div className="flight-duration">
                          <strong>{formatMinToHRAndMin(duration)}</strong>
                          <span>{flight?.cabinClass}</span>
                          <span className="highlight"></span>
                          CB.{flight?.classOfBook}{" "}
                          {flight?.remainingSeats && (
                            <strong
                              className={`${
                                flight?.remainingSeats <= 5
                                  ? "text-danger"
                                  : flight?.remainingSeats > 5 &&
                                    flight?.remainingSeats <= 10
                                  ? "text-warning"
                                  : ""
                              }`}
                            >
                              {`Seats left : ${flight?.remainingSeats}`}
                            </strong>
                          )}
                        </div>
                      </div>
                      {layoverTime && (
                        <div className="require">
                          <span>
                            {isPlaneChange ? "Require to change Plane " : ""}
                            layover Time - {formatMinToHRAndMin(layoverTime)}
                          </span>
                        </div>
                      )}
                    </>
                  );
                } else {
                  return (
                    <div className="fligt-confir">
                      <div className="flight-choose">
                        <div className="fight-name">
                          <img src={getFlightImage(e.fD?.aI?.code)} alt="" />
                          {/* <img src={getFlightImage(e.fD?.aI?.name)} alt="" /> */}
                          <span>
                            <h5>{e.fD?.aI?.name}</h5>
                            <p>
                              {flightCode} - {flightNo} {equipmentType}
                            </p>
                          </span>
                        </div>
                        <div className="route-timing">
                          <div className="route-strating">
                            {moment(departureTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{departureCity},</strong>{" "}
                              {departureAirport}
                            </p>
                          </div>
                          <div className="cunsume-time">
                            <p>{stops === 0 ? "Non Stop" : `${stops} Stop`}</p>
                            <div className="cunsume-pic">
                              <img src={PLANE} alt="" />
                            </div>
                          </div>
                          <div className="route-strating route-arrival">
                            {moment(arivalTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{arivalCity},</strong> {arivalAirport}
                            </p>
                          </div>
                        </div>
                        <div className="flight-duration">
                          <strong>{formatMinToHRAndMin(duration)}</strong>
                          <span>{flight?.cabinClass}</span>
                          <span className="highlight"></span>
                          CB.{flight?.classOfBook}{" "}
                          {flight?.remainingSeats && (
                            <strong
                              className={`${
                                flight?.remainingSeats <= 5
                                  ? "text-danger"
                                  : flight?.remainingSeats > 5 &&
                                    flight?.remainingSeats <= 10
                                  ? "text-warning"
                                  : ""
                              }`}
                            >{`Seats left : ${flight?.remainingSeats}`}</strong>
                          )}
                        </div>
                      </div>
                      {layoverTime && (
                        <div className="require">
                          <span>
                            {isPlaneChange ? "Require to change Plane " : ""}
                            layover Time - {formatMinToHRAndMin(layoverTime)}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          ))}
        </div>
        <div
          className="tab-pane fade"
          id={`pills-fare-details-${flightGroupIndex}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-fare-details-${flightGroupIndex}`}
        >
          <div className="flight-summary flight-detail fare-detail">
            <table>
              <tbody>
                <tr>
                  <th>Type</th>
                  <th>Fare</th>
                  <th>Total</th>
                </tr>
                {/* {flights?.map((flight: any) => (
                  <> */}
                <div className="flight-indcator p-0">
                  <span>
                    <h5 className="border-0 pb-0 my-0 pt-3">
                      {flightGroup[0]?.sI[0]?.da?.city} →{" "}
                      {
                        flightGroup[flightGroup?.length - 1]?.sI[
                          flightGroup[flightGroup?.length - 1]?.sI?.length - 1
                        ]?.aa?.city
                      }
                    </h5>
                  </span>
                </div>
                {adultBaseRate && ADULT && (
                  <>
                    <tr>
                      <td colSpan={3}>
                        <span>Fare Details for Adult(CB:{classOfBook})</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Base Price</td>
                      <td>
                        ₹{adultBaseRate} x {ADULT}
                      </td>
                      <td>₹{adultBaseRate * ADULT}</td>
                    </tr>
                    <tr>
                      <td>Taxes and fee</td>
                      <td>
                        ₹{adultTaxRate} x {ADULT}
                      </td>
                      <td>₹{adultTaxRate * ADULT}</td>
                    </tr>
                  </>
                )}
                {childBaseRate > 0 && CHILD > 0 && (
                  <>
                    <tr>
                      <td colSpan={3}>
                        <span>Fare Details for Child(CB:{classOfBook})</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Base Price</td>
                      <td>
                        {" "}
                        ₹{childBaseRate} x {CHILD}
                      </td>
                      <td>₹{childBaseRate * CHILD}</td>
                    </tr>
                    <tr>
                      <td>Taxes and fee</td>
                      <td>
                        {" "}
                        ₹{childTaxRate} x {CHILD}
                      </td>
                      <td>₹{childTaxRate * CHILD}</td>
                    </tr>
                  </>
                )}
                {infantBaseRate > 0 && INFANT > 0 && (
                  <>
                    <tr>
                      <td colSpan={3}>
                        <span>Fare Details for Infant(CB:{classOfBook})</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Base Price</td>
                      <td>
                        {" "}
                        ₹{infantBaseRate} x {INFANT}
                      </td>
                      <td>₹{infantBaseRate * INFANT}</td>
                    </tr>
                    <tr>
                      <td>Taxes and fee</td>
                      <td>
                        {" "}
                        ₹{infantTaxRate} x {INFANT}
                      </td>
                      <td>₹{infantTaxRate * INFANT}</td>
                    </tr>
                  </>
                )}
                <tr>
                  <td colSpan={2}>
                    <strong className="fw-600">Total</strong>
                  </td>
                  <td>
                    <strong>₹{totalPrice}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade position-relative min-height-150"
          id={`pills-fare-rules-${flightGroupIndex}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-fare-rules-${flightGroupIndex}`}
        >
          <Loading loading={loading} />
          {!loading && (
            <>
              {Object.keys(fareRule).length === 0 ? (
                <h4 className="text-center">
                  No Fare Rule Found. Please contact Customer Care!!!
                </h4>
              ) : (
                <div className="flight-cancel flight-detail flight-table">
                  <h5>
                    {flightGroup[0]?.sI[0]?.da?.city} {" to "}
                    {
                      flightGroup[flightGroup?.length - 1]?.sI[
                        flightGroup[flightGroup?.length - 1]?.sI?.length - 1
                      ]?.aa?.city
                    }
                  </h5>
                  <span className="mention-tag">
                    Mentioned fees are Per Pax Per Sector <br /> Apart from
                    airline charges, GST + RAF + applicable charges if any, will
                    be charged.
                  </span>
                  <table>
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Cancellation Fee</th>
                        <th>Date Change Fee</th>
                        <th>No Show</th>
                        <th>Seat Chargeable</th>
                      </tr>
                      <tr>
                        <td>ALL</td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              cancellationPolicyInfo,
                              cancellationAmount
                            ),
                          }}
                        />
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              datechangePolicyInfo,
                              datechangeAmount
                            ),
                          }}
                        />
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              noshowPolicyInfo,
                              noshowAmount
                            ),
                          }}
                        />
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              seatschargePolicyInfo,
                              seatschargeAmount
                            ),
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                  <span> *From the Time of Departure</span>
                </div>
              )}
            </>
          )}
        </div>
        <div
          className="tab-pane fade"
          id={`pills-buggage-details-${flightGroupIndex}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-buggage-details-${flightGroupIndex}`}
        >
          <div className="flight-cancel flight-detail">
            <table>
              <tbody>
                <tr>
                  <th>Sector</th>
                  <th>Cheking</th>
                  <th>Cabin</th>
                </tr>
                {flights?.map((flight: any) => (
                  <>
                    {flight?.route?.sI?.map((e: any) => {
                      const departureCode = e?.da?.code;
                      const arivalCode = e?.aa?.code;
                      return (
                        <tr>
                          <td>
                            {departureCode}-{arivalCode}
                          </td>
                          <td>
                            {flight?.adultCheckingBaggage
                              ? "Adult: " + flight?.adultCheckingBaggage
                              : ""}
                            {flight?.childCheckingBaggage
                              ? ", Child: " + flight?.childCheckingBaggage
                              : ""}
                            {flight?.infantCheckingBaggage
                              ? ", Infant: " + flight?.infantCheckingBaggage
                              : ""}
                          </td>
                          <td>
                            {flight?.adultCabbinBaggage
                              ? "Adult: " + flight?.adultCabbinBaggage
                              : ""}
                            {flight?.childCabbinBaggage
                              ? ", Child: " + flight?.childCabbinBaggage
                              : ""}
                            {flight?.infantCabbinBaggage
                              ? ", Infant: " + flight?.infantCabbinBaggage
                              : ""}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ))}
              </tbody>
            </table>
            <span> *From the Time of Departure</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComboFlightDetails;
