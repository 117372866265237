import React from "react";
import {
  OneWayListingSkeleton,
  TwoWayListingSkeleton,
} from "../../components/skeletons/Skeletons";
import ComboFlightMainCard from "./ComboFlightMainCard";
import FilghtRMainCard from "./FilghtRMainCard";
import moment from "moment";
import {
  getCurrentFlightSearchFromLocalStorage,
  getFlightTimes,
  groupComboFlights,
} from "../../_helpers/helper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { sortingOptions } from "../../_constants/constant";
import { setMulticityActiveIndex } from "./flightSlice";
import FlightMainCard from "./FlightMainCard";
import ARROW_DOWN from "../../assets/images/arrow-down.png";

const FlightListingPart = ({
  selectedMultiCity,
  handleChange,
  selectedOnward,
  handleSelectFlight,
  selectedReturn,
}: any) => {
  const dispatch = useAppDispatch();

  const multicityActiveIndex: any = useAppSelector(
    (state) => state.flight.multicityActiveIndex
  );

  const multicities = getCurrentFlightSearchFromLocalStorage("multicities");

  const flightTimes = getFlightTimes(selectedMultiCity || []);

  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const filteredReturnFlights: any = useAppSelector(
    (state) => state.flight.filteredReturnFlights
  );

  const filteredMulticityFlights: any = useAppSelector(
    (state) => state.flight.filteredMulticityFlights
  );

  const multicitySortTypes: any = useAppSelector(
    (state) => state.flight.multicitySortTypes
  );

  const filteredComboFlights: any = useAppSelector(
    (state) => state.flight.filteredComboFlights
  );

  const comboSortType: any = useAppSelector(
    (state) => state.flight.comboSortType
  );

  const comboActiveIndex: any = useAppSelector(
    (state) => state.flight.comboActiveIndex
  );

  const onwardSortType: any = useAppSelector(
    (state) => state.flight.onwardSortType
  );

  const filteredOnwardFlights: any = useAppSelector(
    (state) => state.flight.filteredOnwardFlights
  );

  const flightData: any = useAppSelector((state) => state.flight.flightData);
  const comboFlights = flightData?.searchResult?.tripInfos?.COMBO || [];
  const groupedFlights = groupComboFlights(comboFlights);

  const loading = useAppSelector((state) => state.flight.loading);

  return (
    <div
      className="col-lg-9 col-md-7 col-sm-12 position-relative"
      style={{ minHeight: "calc(100vh - 388px" }}
    >
      <div className="listing-page-right">
        <div className="row multicity-tabbing">
          <div className="col-12">
            <ul className="nav nav-pills nav-justified">
              {selectedMultiCity?.map((e: any, index: number) => (
                <li
                  style={{ flex: 1 }}
                  className="nav-item"
                  onClick={() => dispatch(setMulticityActiveIndex(index))}
                  key={index}
                >
                  <a
                    className={`nav-link ${
                      multicityActiveIndex === index ? "active" : ""
                    }`}
                    aria-current="page"
                    href="javascript:void(0)"
                  >
                    <p
                      className="navlinkflights p-2 m-0 fw-600"
                      style={{ fontSize: 17 }}
                    >
                      {multicities[index]?.fromCity}
                      {" - "}
                      {multicities[index]?.toCity}
                    </p>
                    <span>
                      {flightTimes[index]?.startTime &&
                        flightTimes[index]?.startTime?.format("ddd, DD MMM YY")}
                      <br />
                      {flightTimes[index]?.startTime &&
                        flightTimes[index]?.startTime?.format("HH:mm")}{" "}
                      →{" "}
                      {flightTimes[index]?.endTime &&
                        flightTimes[index]?.endTime?.format("HH:mm")}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="listing-page-right">
        {flightSearchDetails?.triptype !== "Multicity" && (
          <h2 className="mt-2">
            {getCurrentFlightSearchFromLocalStorage("fromCity")
              ? "Flights from"
              : ""}{" "}
            {getCurrentFlightSearchFromLocalStorage("fromCity")}{" "}
            {getCurrentFlightSearchFromLocalStorage("toCity") ? "to " : ""}
            {getCurrentFlightSearchFromLocalStorage("toCity")}
            {flightSearchDetails.triptype === "Round trip" ? (
              <>
                , and <span>back</span>
              </>
            ) : (
              ""
            )}
          </h2>
        )}
        <div className="fight-detail">
          {filteredReturnFlights?.length <= 0 ? (
            <>
              {filteredMulticityFlights[0] ||
              filteredMulticityFlights[0]?.length > 0 ? (
                <div className="fight-detail-top">
                  <h5>
                    Flights sorted by{" "}
                    {multicitySortTypes[multicityActiveIndex]?.order === "asc"
                      ? "Lowest"
                      : "Highest"}{" "}
                    {multicitySortTypes[multicityActiveIndex]?.field} on this
                    route
                  </h5>
                  <span>
                    <label htmlFor="">Sort By:&nbsp;</label>
                    <select
                      name="flight-sort"
                      id="flight-sort"
                      value={JSON.stringify(
                        multicitySortTypes[multicityActiveIndex]
                      )}
                      onChange={(e) =>
                        handleChange(e, "multicity", multicityActiveIndex)
                      }
                    >
                      {sortingOptions.map((option, index) => (
                        <option
                          key={index}
                          value={JSON.stringify({
                            field: option.field,
                            order: option.order,
                          })}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              ) : filteredComboFlights?.length > 0 ? (
                <div className="fight-detail-top">
                  <h5>
                    Flights sorted by{" "}
                    {comboSortType?.order === "asc" ? "Lowest" : "Highest"}{" "}
                    {comboSortType?.field} on this route
                  </h5>
                  <span>
                    <label htmlFor="">Sort By:&nbsp;</label>
                    <select
                      name="flight-sort"
                      id="flight-sort"
                      value={JSON.stringify(comboSortType)}
                      onChange={(e) =>
                        handleChange(e, "combo", comboActiveIndex)
                      }
                    >
                      {sortingOptions.map((option, index) => (
                        <option
                          key={index}
                          value={JSON.stringify({
                            field: option.field,
                            order: option.order,
                          })}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              ) : (
                <div className="fight-detail-top">
                  <h5>
                    Flights sorted by{" "}
                    {onwardSortType?.order === "asc" ? "Lowest" : "Highest"}{" "}
                    {onwardSortType?.field} on this route
                  </h5>
                  <span>
                    <label htmlFor="">Sort By:&nbsp;</label>
                    <select
                      name="flight-sort"
                      id="flight-sort"
                      onChange={(e) => handleChange(e)}
                    >
                      {sortingOptions.map((option, index) => (
                        <option
                          key={index}
                          value={JSON.stringify({
                            field: option.field,
                            order: option.order,
                          })}
                        >
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
              )}
            </>
          ) : (
            <div className="fight-detail-top-round-trip">
              <div className="w-50 text-end me-3">
                <span>
                  <label htmlFor="">Sort By:&nbsp;</label>
                  <select
                    name="flight-sort"
                    id="flight-sort"
                    onChange={(e) => handleChange(e)}
                  >
                    {sortingOptions.map((option, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          field: option.field,
                          order: option.order,
                        })}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="w-50 text-end">
                <span>
                  <label htmlFor="">Sort By:&nbsp;</label>
                  <select
                    name="flight-sort"
                    id="flight-sort"
                    onChange={(e) => handleChange(e, "return")}
                  >
                    {sortingOptions.map((option, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          field: option.field,
                          order: option.order,
                        })}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
            </div>
          )}
          {flightSearchDetails?.triptype === "One Way" && (
            <>
              {!loading ? (
                !filteredOnwardFlights || filteredOnwardFlights?.length <= 0 ? (
                  <h2 style={{ textAlign: "center" }}>No Data Found!</h2>
                ) : (
                  filteredOnwardFlights?.map(
                    (route: any, routeIndex: number) => {
                      return (
                        <FlightMainCard
                          key={`oneway-${routeIndex}`}
                          route={route}
                          routeIndex={routeIndex}
                        />
                      );
                    }
                  )
                )
              ) : (
                <>
                  <OneWayListingSkeleton />
                  <OneWayListingSkeleton />
                </>
              )}
            </>
          )}
          {flightSearchDetails?.triptype === "Round trip" &&
            (groupedFlights?.length <= 0 ? (
              <>
                {!loading ? (
                  <div className="flight-detail-tab-main">
                    <div className="row listing-row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="indicatior">
                              <span>
                                <a>
                                  <img
                                    src={ARROW_DOWN}
                                    alt=""
                                    style={{
                                      transform: "rotate(180deg)",
                                    }}
                                  />
                                </a>
                                <h5>
                                  {getCurrentFlightSearchFromLocalStorage(
                                    "fromCity"
                                  )}
                                  {getCurrentFlightSearchFromLocalStorage(
                                    "toCity"
                                  )
                                    ? " - "
                                    : ""}
                                  {getCurrentFlightSearchFromLocalStorage(
                                    "toCity"
                                  )}
                                </h5>
                              </span>
                              <p>
                                {moment(
                                  flightSearchDetails.departureDate
                                )?.format("ddd, Do MMM YYYY")}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 Detail-tab mb-2 mb-lg-0">
                            {filteredOnwardFlights?.length <= 0 ? (
                              <h3 style={{ textAlign: "center" }}>
                                No Data Found!
                              </h3>
                            ) : (
                              <>
                                {filteredOnwardFlights?.map(
                                  (route: any, routeIndex: number) => {
                                    return (
                                      <FilghtRMainCard
                                        key={`onward-${routeIndex}`}
                                        route={route}
                                        prefix="onward"
                                        routeIndex={routeIndex}
                                        selectedFlightPriceId={
                                          selectedOnward?.priceDetails?.id
                                        }
                                        handleSelectFlight={handleSelectFlight}
                                      />
                                    );
                                  }
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="row">
                          <div className="col-12">
                            <div className="indicatior">
                              <span>
                                <a>
                                  <img src={ARROW_DOWN} alt="" />
                                </a>
                                <h5>
                                  {getCurrentFlightSearchFromLocalStorage(
                                    "fromCity"
                                  )}{" "}
                                  {getCurrentFlightSearchFromLocalStorage(
                                    "toCity"
                                  )
                                    ? " - "
                                    : ""}
                                  {getCurrentFlightSearchFromLocalStorage(
                                    "toCity"
                                  )}
                                </h5>
                              </span>
                              <p>
                                {moment(flightSearchDetails.returnDate)?.format(
                                  "ddd, Do MMM YYYY"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="col-12 Detail-tab">
                            {filteredReturnFlights?.length <= 0 ? (
                              <h3 style={{ textAlign: "center" }}>
                                No Data Found!
                              </h3>
                            ) : (
                              <>
                                {filteredReturnFlights?.map(
                                  (route: any, routeIndex: number) => {
                                    return (
                                      <FilghtRMainCard
                                        key={`return-${routeIndex}`}
                                        route={route}
                                        prefix="return"
                                        selectedFlightPriceId={
                                          selectedReturn?.priceDetails?.id
                                        }
                                        routeIndex={routeIndex}
                                        handleSelectFlight={handleSelectFlight}
                                      />
                                    );
                                  }
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <TwoWayListingSkeleton />
                )}
              </>
            ) : (
              <>
                {!loading ? (
                  !filteredComboFlights || filteredComboFlights?.length <= 0 ? (
                    <h2 style={{ textAlign: "center" }}>No Data Found!</h2>
                  ) : (
                    filteredComboFlights?.map(
                      (flightGroup: any, flightGroupIndex: number) => (
                        <ComboFlightMainCard
                          flightGroup={flightGroup}
                          flightGroupIndex={flightGroupIndex}
                        />
                      )
                    )
                  )
                ) : (
                  <OneWayListingSkeleton />
                )}
              </>
            ))}
          {flightSearchDetails?.triptype === "Multicity" &&
            (groupedFlights?.length <= 0 ? (
              <>
                {!loading ? (
                  !filteredMulticityFlights[multicityActiveIndex] ||
                  filteredMulticityFlights[multicityActiveIndex]?.length <=
                    0 ? (
                    <h2 style={{ textAlign: "center" }}>No Data Found!</h2>
                  ) : (
                    filteredMulticityFlights[multicityActiveIndex]?.map(
                      (route: any, routeIndex: number) => {
                        return (
                          <FlightMainCard
                            key={`multicity-${routeIndex}`}
                            route={route}
                            routeIndex={routeIndex}
                            prefix="multicity"
                            index={multicityActiveIndex}
                            handleSelectFlight={handleSelectFlight}
                            selectedFlightPriceId={
                              selectedMultiCity[multicityActiveIndex]
                                ?.priceDetails?.id
                            }
                          />
                        );
                      }
                    )
                  )
                ) : (
                  <>
                    <OneWayListingSkeleton />
                    <OneWayListingSkeleton />
                  </>
                )}
              </>
            ) : (
              <>
                {!loading ? (
                  !filteredComboFlights || filteredComboFlights?.length <= 0 ? (
                    <h2 style={{ textAlign: "center" }}>No Data Found!</h2>
                  ) : (
                    filteredComboFlights?.map(
                      (flightGroup: any, flightGroupIndex: number) => (
                        <ComboFlightMainCard
                          flightGroup={flightGroup}
                          flightGroupIndex={flightGroupIndex}
                        />
                      )
                    )
                  )
                ) : (
                  <OneWayListingSkeleton />
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FlightListingPart;
