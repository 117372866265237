import React from "react";
import moment from "moment";
import {
  getArrivalDayMessage,
  getDiffInHRAndMin,
  getFlightImage,
} from "../../_helpers/helper";
import PLANE from "../../assets/images/plane.png";

interface FlightRouteProps {
  route: any;
  startTime: moment.Moment;
  endTime: moment.Moment;
  remainingSeats: number;
  openFightDetail: boolean;
  setOpenFlightDetail: (openflightDetail: boolean) => void;
}

const FlightRoute = ({
  route,
  startTime,
  endTime,
  setOpenFlightDetail,
  openFightDetail,
  remainingSeats,
}: FlightRouteProps) => {
  const flightNamesArray = route?.sI?.map((sI: any) => sI?.fD?.aI?.name) || [];
  const uniqueFlightNames = Array.from(new Set(flightNamesArray));
  const flightCodesArray = route?.sI?.map((sI: any) => sI?.fD?.aI?.code) || [];
  const uniqueFlightCodes = Array.from(new Set(flightCodesArray));
  const flightDetails = route?.sI?.reduce(
    (acc: string[], sI: any, index: number, array: any[]) => {
      const flightNo = sI?.fD?.fN;
      const flightCode = sI?.fD?.aI?.code;

      // Add flight details if not already added
      if (!acc.some((detail) => detail.includes(flightNo))) {
        acc.push(`${flightCode} - ${flightNo}`);
      }

      // Always add the last item
      if (
        index === array.length - 1 &&
        !acc[acc.length - 1].includes(flightNo)
      ) {
        acc.push(`${flightCode} - ${flightNo}`);
      }

      return acc;
    },
    []
  );
  return (
    <div className="flight-mobile">
      <div className="flight-flex">
        <div className="fight-name">
          {uniqueFlightCodes?.length > 1 ? (
            <div style={{ height: 40, width: 40, display: "flex" }}>
              {uniqueFlightCodes?.map((code: any, index: number) => {
                if (index < 2) {
                  return (
                    <img
                      src={getFlightImage(code)}
                      alt=""
                      style={{
                        height: 20,
                        width: 20,
                        marginBottom: index === 0 ? 20 : 0,
                        marginTop: index === 0 ? 0 : 20,
                      }}
                    />
                  );
                }
              })}
            </div>
          ) : (
            uniqueFlightCodes?.map((code: any) => {
              return <img src={getFlightImage(code)} alt="" />;
            })
          )}
          <span>
            <h5>{uniqueFlightNames.join(", ")}</h5>
            <p>{flightDetails.join(", ")}</p>
          </span>
        </div>
        <div className="route-timing">
          <div className="route-strating">
            <p>{route?.sI[0]?.da?.city}</p>
            {moment(startTime)?.format("HH:mm")}
            <p>{moment(startTime)?.format("MMM DD")}</p>
          </div>
          <div className="cunsume-time">
            <span>
              {route?.sI?.length - 1 === 0
                ? "Non-Stop"
                : `${route?.sI?.length - 1} Stop`}
            </span>
            <div className="cunsume-pic">
              <img src={PLANE} alt="" />
            </div>
            <p>{getDiffInHRAndMin(startTime, endTime)}</p>
          </div>
          <div className="route-strating">
            <p>{route?.sI[route?.sI?.length - 1]?.aa?.city}</p>
            {moment(endTime)?.format("HH:mm")}
            <p>{moment(endTime)?.format("MMM DD")}</p>
          </div>
        </div>
      </div>
      {/* need to change here onclick and make the content dynamic*/}
      <a
        data-rid="3"
        className="view-detail"
        onClick={() => {
          setOpenFlightDetail(!openFightDetail);
        }}
      >
        {"View Detail"}
        <span className="plus-button">{openFightDetail ? "-" : "+"}</span>
      </a>
      <div className="flight-left">
        <span>
          {getArrivalDayMessage(startTime, endTime) && (
            <img src={PLANE} alt="" />
          )}
          {getArrivalDayMessage(startTime, endTime)}
          {remainingSeats && (
            <span
              className={`${
                remainingSeats <= 5
                  ? "text-danger"
                  : remainingSeats > 5 && remainingSeats <= 10
                  ? "text-warning"
                  : ""
              }`}
            >
              {`Seats left : ${remainingSeats}`}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default FlightRoute;
