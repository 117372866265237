import moment from "moment";

export const useFlightTimes = (route: any) => {
  let startTime: any = "";
  let endTime: any = "";
  if (route?.sI) {
    startTime = moment(route?.sI[0]?.dt) || "";
    endTime = moment(route?.sI[route?.sI?.length - 1]?.at) || "";
  }
  return { startTime, endTime };
};
