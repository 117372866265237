import { useAppSelector } from "../../app/hooks";
import { useEffect, useMemo, useState } from "react";
import { axiosApiInstance } from "../../app/axios";
import { TRIPJACK_API_URL } from "../../app/config";
import FlightDetailsTabs from "./FlightDetailsTabs";
import { useNavigate } from "react-router";
import ComboFlightRoute from "./ComboFlightRoute";
import ComboFlightPrice from "./ComboFlightPrice";
import ComboFlightDetails from "./ComboFlightDetails";

interface ComboFlightMainCardProps {
  flightGroup: any;
  flightGroupIndex: number;
  prefix?: string;
  index?: number;
}

const ComboFlightMainCard = ({
  flightGroup,
  flightGroupIndex,
  prefix,
  index,
}: ComboFlightMainCardProps) => {
  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );
  const sortedPriceList = useMemo(
    () =>
      [...flightGroup[0]?.totalPriceList].sort(
        (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
      ),
    [flightGroup[0]?.totalPriceList]
  );
  const [fareRulesLoading, setFareRulesLoading] = useState(false);
  const [openFlightRate, setOpenFlightRate] = useState<boolean>(false);
  const [openFightDetail, setOpenFlightDetail] = useState<boolean>(false);
  const [priceDetails, setPriceDetails] = useState<any>(null);
  const [fareRule, setFareRule] = useState<any>({});

  const getFareRules = async (id: any) => {
    try {
      setFareRule({});
      setFareRulesLoading(true);
      const response = await axiosApiInstance.post(
        `${TRIPJACK_API_URL}/fms/v1/farerule`,
        {
          id: id,
          flowType: "SEARCH",
        }
      );
      setFareRule(response.data?.fareRule);
    } catch (error) {
      console.error("Error fetching fare rules results:", error);
    } finally {
      setFareRulesLoading(false);
    }
  };

  useEffect(() => {
    if (sortedPriceList && flightGroup?.length > 0) {
      let minPriceOption = flightGroup[0].totalPriceList[0];
      let minPrice = calculateTotalPrice(minPriceOption);

      flightGroup[0].totalPriceList.forEach((tp: any) => {
        const totalPrice = calculateTotalPrice(tp);
        if (totalPrice < minPrice) {
          minPrice = totalPrice;
          minPriceOption = tp;
        }
      });

      setPriceDetails(minPriceOption);
    }
  }, [sortedPriceList]);

  const calculateTotalPrice = (tp: any) => {
    const totalAdultPrice =
      (tp?.fd?.ADULT?.fC?.TF || 0) * flightSearchDetails.ADULT;
    const totalChildPrice =
      (tp?.fd?.CHILD?.fC?.TF || 0) * flightSearchDetails.CHILD;
    const totalInfantPrice =
      (tp?.fd?.INFANT?.fC?.TF || 0) * flightSearchDetails.INFANT;
    return totalAdultPrice + totalChildPrice + totalInfantPrice;
  };

  const handleRadioButtonChange = (id: any) => {
    const selectedTp = sortedPriceList.find((tp: any) => tp?.id === id);
    setPriceDetails(selectedTp);
    if (openFightDetail) {
      getFareRules(id);
    }
  };

  const handleBookNowClick = (priceDetails: any) => {
    let flightDetails = JSON.parse(
      localStorage.getItem("flightDetails") || "{}"
    );

    flightDetails = {
      ...flightDetails,
      lastFlightSearchUrl: window.location.href,
    };
    localStorage.setItem("flightDetails", JSON.stringify(flightDetails));

    const baseUrl = window.location.origin;
    const newTabUrl = `${baseUrl}/flights/reviewbooking?priceIds=${priceDetails?.id}`;

    window.open(newTabUrl, "_blank");
  };

  return (
    <div className="flight-outer">
      <div className={`flight-route d-block`}>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: 20,
          }}
        >
          <div className="flight-mobile" style={{ width: "100%" }}>
            {flightGroup?.map((route: any) => (
              <ComboFlightRoute route={route} />
            ))}
          </div>
          {flightGroup?.length > 0 && (
            <ComboFlightPrice
              type={prefix}
              index={index}
              flightSearchDetails={flightSearchDetails}
              openFlightRate={openFlightRate}
              routeIndex={flightGroupIndex}
              setOpenFlightRate={setOpenFlightRate}
              priceDetails={priceDetails}
              sortedPriceList={sortedPriceList}
              handleRadioButtonChange={handleRadioButtonChange}
            />
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a
            data-rid="3"
            className="view-detail"
            onClick={() => {
              setOpenFlightDetail(!openFightDetail);
            }}
          >
            {"View Detail"}
            <span className="plus-button">{openFightDetail ? "-" : "+"}</span>
          </a>
          <div className="flight-btn">
            <a
              className="fill-btn"
              href="javascript:void(0)"
              onClick={() => handleBookNowClick(priceDetails)}
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
      {/* flight-outer-open */}
      <ComboFlightDetails
        flightGroup={flightGroup}
        priceDetails={priceDetails}
        openFightDetail={openFightDetail}
        flightGroupIndex={flightGroupIndex}
      />
    </div>
  );
};

export default ComboFlightMainCard;
