import React, { useMemo, useState } from "react";
import moment from "moment";
import PLANE from "../../assets/images/plane.png";
import { formatMinToHRAndMin, getFlightImage } from "../../_helpers/helper";
import Loading from "../../components/spinner/Loading";
import { useAppSelector } from "../../app/hooks";
import { axiosApiInstance } from "../../app/axios";
import { TRIPJACK_API_URL } from "../../app/config";

interface FlightMulticityDetailsTabProps {
  //   route: any;
  //   priceDetails: any;
  //   classOfBook: string;
  //   cabinClass: string;
  //   remainingSeats: number;
  //   getFareRules: (id: string) => void;
  //   ADULT: number;
  //   adultTaxRate: number;
  //   adultBaseRate: number;
  //   CHILD: number;
  //   childTaxRate: number;
  //   childBaseRate: number;
  //   INFANT: number;
  //   infantTaxRate: number;
  //   infantBaseRate: number;
  //   totalPrice: number;
  //   fareRulesLoading: boolean;
  //   fareRule: any;
  //   cancellationPolicyInfo: string;
  //   cancellationAmount: number;
  //   datechangePolicyInfo: string;
  //   datechangeAmount: number;
  //   noshowPolicyInfo: string;
  //   noshowAmount: number;
  //   seatschargePolicyInfo: string;
  //   seatschargeAmount: number;
  //   adultCheckingBaggage: string;
  //   childCheckingBaggage: string;
  //   infantCheckingBaggage: string;
  //   adultCabbinBaggage: string;
  //   childCabbinBaggage: string;
  //   infantCabbinBaggage: string;
}

const FlightMulticityDetailsTab = ({ selectedMultiCity = [] }: any) => {
  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const [fareRules, setFareRules] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const flights: any = useMemo(() => {
    return selectedMultiCity?.map((flight: any) => {
      const { route, priceDetails: flightPriceDetails } = flight;

      const startTime = moment(route?.sI[0]?.dt);
      const endTime = moment(route?.sI[route?.sI?.length - 1]?.at);
      const departureCode = route?.sI[0]?.da?.code;
      const arrivalCode = route?.sI[route?.sI?.length - 1]?.aa?.code;

      const remainingSeats = flightPriceDetails?.fd?.ADULT?.sR;
      const cabinClass = flightPriceDetails?.fd?.ADULT?.cc;
      const classOfBook = flightPriceDetails?.fd?.ADULT?.cB;

      const { ADULT, CHILD, INFANT } = flightSearchDetails;

      const adultBaseRate = flightPriceDetails?.fd?.ADULT?.fC?.BF || 0;
      const childBaseRate = flightPriceDetails?.fd?.CHILD?.fC?.BF || 0;
      const infantBaseRate = flightPriceDetails?.fd?.INFANT?.fC?.BF || 0;

      const adultCabbinBaggage = flightPriceDetails?.fd?.ADULT?.bI?.cB;
      const childCabbinBaggage = flightPriceDetails?.fd?.CHILD?.bI?.cB;
      const infantCabbinBaggage = flightPriceDetails?.fd?.INFANT?.bI?.cB;

      const adultCheckingBaggage = flightPriceDetails?.fd?.ADULT?.bI?.iB;
      const childCheckingBaggage = flightPriceDetails?.fd?.CHILD?.bI?.iB;
      const infantCheckingBaggage = flightPriceDetails?.fd?.INFANT?.bI?.iB;

      const adultTaxRate = flightPriceDetails?.fd?.ADULT?.fC?.TAF || 0;
      const childTaxRate = flightPriceDetails?.fd?.CHILD?.fC?.TAF || 0;
      const infantTaxRate = flightPriceDetails?.fd?.INFANT?.fC?.TAF || 0;

      const totalPrice =
        (flightPriceDetails?.fd?.ADULT?.fC?.TF || 0) * ADULT +
        (flightPriceDetails?.fd?.CHILD?.fC?.TF || 0) * CHILD +
        (flightPriceDetails?.fd?.INFANT?.fC?.TF || 0) * INFANT;

      return {
        route,
        priceDetails: flightPriceDetails,
        startTime,
        endTime,
        departureCode,
        arrivalCode,
        ADULT,
        CHILD,
        INFANT,
        remainingSeats,
        cabinClass,
        classOfBook,
        adultBaseRate,
        childBaseRate,
        infantBaseRate,
        adultCabbinBaggage,
        childCabbinBaggage,
        infantCabbinBaggage,
        adultCheckingBaggage,
        childCheckingBaggage,
        infantCheckingBaggage,
        adultTaxRate,
        childTaxRate,
        infantTaxRate,
        totalPrice,
      };
    });
  }, [selectedMultiCity]);

  const getFareRules = async (
    index: number,
    id: any,
    departureCode: string,
    arrivalCode: string
  ) => {
    try {
      const response = await axiosApiInstance.post(
        `${TRIPJACK_API_URL}/fms/v1/farerule`,
        {
          id: id,
          flowType: "SEARCH",
        }
      );

      const fareRule = response.data?.fareRule;

      // Update the fare rules state for the specific flight by index
      setFareRules((prev: any[]) => {
        const updatedFareRules = [...prev];
        updatedFareRules[index] = {
          cancellationPolicyInfo:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.CANCELLATION
              ?.DEFAULT?.policyInfo ||
            "Airline Cancellation Charges + Taxes + Surcharges",
          cancellationAmount:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.CANCELLATION
              ?.DEFAULT?.amount || "",
          datechangePolicyInfo:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.DATECHANGE
              ?.DEFAULT?.policyInfo || "",
          datechangeAmount:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.DATECHANGE
              ?.DEFAULT?.amount || "",
          noshowPolicyInfo:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.NO_SHOW?.DEFAULT
              ?.policyInfo || "Fees As Per Airline Rule + Surcharges",
          noshowAmount:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.NO_SHOW?.DEFAULT
              ?.amount || "",
          seatschargePolicyInfo:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.SEAT_CHARGEABLE
              ?.DEFAULT?.policyInfo || "As Per Airline",
          seatschargeAmount:
            fareRule?.[`${departureCode}-${arrivalCode}`]?.fr?.SEAT_CHARGEABLE
              ?.DEFAULT?.amount || "",
          arrivalCode,
          departureCode,
        };
        return updatedFareRules;
      });
    } catch (error) {
      setFareRules((prev: any[]) => {
        const updatedFareRules = [...prev];
        updatedFareRules[index] = {};
        return updatedFareRules;
      });
      console.error("Error fetching fare rules:", error);
    }
  };

  // Function to get fare rules for all flights
  const getFareRulesForAllFlights = async () => {
    setLoading(true);
    await Promise.all(
      flights.map((flight: any, index: number) =>
        getFareRules(
          index,
          flight.priceDetails?.id,
          flight.departureCode,
          flight.arrivalCode
        )
      )
    );
    setLoading(false);
  };

  const formatContent = (content: any, additionalText: any) => {
    const formattedContent = content?.replace(/__nls__/g, "<br />");
    return `${
      additionalText ? additionalText + " + " : ""
    } ${formattedContent}`;
  };

  return (
    <div className={`flight-tabing-2 flight-outer-open`}>
      <ul className="nav nav-pills mb-3 gap-2" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id={`pills-tab-flight`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-flight-details`}
            type="button"
            role="tab"
            aria-controls={`pills-flight-details`}
            aria-selected="true"
          >
            Flight Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-fare-details`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-fare-details`}
            type="button"
            role="tab"
            aria-controls={`pills-fare-details`}
            aria-selected="false"
            tabIndex={-1}
          >
            Fare Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-fare-rules`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-fare-rules`}
            type="button"
            role="tab"
            aria-controls={`pills-fare-rules`}
            aria-selected="false"
            tabIndex={-1}
            onClick={getFareRulesForAllFlights}
          >
            Fare Rules
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-buggage-details`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-buggage-details`}
            type="button"
            role="tab"
            aria-controls={`pills-buggage-details`}
            aria-selected="false"
            tabIndex={-1}
          >
            Baggage Details
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade active show"
          id={`pills-flight-details`}
          role="tabpanel"
          aria-labelledby={`pills-tab-flight`}
        >
          {flights?.map((flight: any) => (
            <div className="fligt-confirm">
              {flight?.route?.sI?.map((e: any, index: number) => {
                const flightNo = e?.fD?.fN;
                const flightCode = e?.fD?.aI?.code;
                const equipmentType = e?.fD?.eT;
                const departureTime = e?.dt;
                const arivalTime = e?.at;
                const layoverTime = e?.cT;
                const duration = e?.duration;
                const arivalCity = e?.aa?.city;
                const departureCity = e?.da?.city;
                const arivalAirport = e?.aa?.name;
                const departureAirport = e?.da?.name;
                const stops = e?.stops;

                const isPlaneChange =
                  flightNo !== flight?.route?.sI[index + 1]?.fD?.fN;

                if (index === 0) {
                  return (
                    <>
                      <div className="flight-indcator">
                        <span>
                          <h5>
                            {flight?.route?.sI[0]?.da?.city} →{" "}
                            {
                              flight?.route?.sI[flight?.route?.sI?.length - 1]
                                ?.aa?.city
                            }
                          </h5>
                          {moment(flight?.route?.sI[0]?.dt)?.format(
                            "ddd, MMM Do YYYY"
                          )}
                        </span>
                      </div>
                      <div className="flight-choose">
                        <div className="fight-name">
                          <img src={getFlightImage(e.fD?.aI?.code)} alt="" />
                          {/* <img src={getFlightImage(e.fD?.aI?.name)} alt="" /> */}
                          <span>
                            <h5>{e.fD?.aI?.name}</h5>
                            <p>
                              {flightCode} - {flightNo} {equipmentType}
                            </p>
                          </span>
                        </div>
                        <div className="route-timing">
                          <div className="route-strating">
                            {moment(departureTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{departureCity},</strong>{" "}
                              {departureAirport}
                            </p>
                          </div>
                          <div className="cunsume-time">
                            <p>{stops === 0 ? "Non Stop" : `${stops} Stop`}</p>
                            <div className="cunsume-pic">
                              <img src={PLANE} alt="" />
                            </div>
                          </div>
                          <div className="route-strating route-arrival">
                            {moment(arivalTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{arivalCity},</strong> {arivalAirport}
                            </p>
                          </div>
                        </div>
                        <div className="flight-duration">
                          <strong>{formatMinToHRAndMin(duration)}</strong>
                          <span>{flight?.cabinClass}</span>
                          <span className="highlight"></span>
                          CB.{flight?.classOfBook}{" "}
                          {flight?.remainingSeats && (
                            <strong
                              className={`${
                                flight?.remainingSeats <= 5
                                  ? "text-danger"
                                  : flight?.remainingSeats > 5 &&
                                    flight?.remainingSeats <= 10
                                  ? "text-warning"
                                  : ""
                              }`}
                            >
                              {`Seats left : ${flight?.remainingSeats}`}
                            </strong>
                          )}
                        </div>
                      </div>
                      {layoverTime && (
                        <div className="require">
                          <span>
                            {isPlaneChange ? "Require to change Plane " : ""}
                            layover Time - {formatMinToHRAndMin(layoverTime)}
                          </span>
                        </div>
                      )}
                    </>
                  );
                } else {
                  return (
                    <div className="fligt-confir">
                      <div className="flight-choose">
                        <div className="fight-name">
                          <img src={getFlightImage(e.fD?.aI?.code)} alt="" />
                          {/* <img src={getFlightImage(e.fD?.aI?.name)} alt="" /> */}
                          <span>
                            <h5>{e.fD?.aI?.name}</h5>
                            <p>
                              {flightCode} - {flightNo} {equipmentType}
                            </p>
                          </span>
                        </div>
                        <div className="route-timing">
                          <div className="route-strating">
                            {moment(departureTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{departureCity},</strong>{" "}
                              {departureAirport}
                            </p>
                          </div>
                          <div className="cunsume-time">
                            <p>{stops === 0 ? "Non Stop" : `${stops} Stop`}</p>
                            <div className="cunsume-pic">
                              <img src={PLANE} alt="" />
                            </div>
                          </div>
                          <div className="route-strating route-arrival">
                            {moment(arivalTime).format("MMM D ddd HH:mm")}
                            <p>
                              <strong>{arivalCity},</strong> {arivalAirport}
                            </p>
                          </div>
                        </div>
                        <div className="flight-duration">
                          <strong>{formatMinToHRAndMin(duration)}</strong>
                          <span>{flight?.cabinClass}</span>
                          <span className="highlight"></span>
                          CB.{flight?.classOfBook}{" "}
                          {flight?.remainingSeats && (
                            <strong
                              className={`${
                                flight?.remainingSeats <= 5
                                  ? "text-danger"
                                  : flight?.remainingSeats > 5 &&
                                    flight?.remainingSeats <= 10
                                  ? "text-warning"
                                  : ""
                              }`}
                            >{`Seats left : ${flight?.remainingSeats}`}</strong>
                          )}
                        </div>
                      </div>
                      {layoverTime && (
                        <div className="require">
                          <span>
                            {isPlaneChange ? "Require to change Plane " : ""}
                            layover Time - {formatMinToHRAndMin(layoverTime)}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          ))}
        </div>
        <div
          className="tab-pane fade"
          id={`pills-fare-details`}
          role="tabpanel"
          aria-labelledby={`pills-tab-fare-details`}
        >
          <div className="flight-summary flight-detail fare-detail">
            <table>
              <tbody>
                <tr>
                  <th>Type</th>
                  <th>Fare</th>
                  <th>Total</th>
                </tr>
                {flights?.map((flight: any) => (
                  <>
                    <div className="flight-indcator p-0">
                      <span>
                        <h5 className="border-0 pb-0 my-0 pt-3">
                          {flight?.route?.sI[0]?.da?.city} →{" "}
                          {
                            flight?.route?.sI[flight?.route?.sI?.length - 1]?.aa
                              ?.city
                          }
                        </h5>
                      </span>
                    </div>
                    {flight?.adultBaseRate && flight?.ADULT && (
                      <>
                        <tr>
                          <td colSpan={3}>
                            <span>
                              Fare Details for Adult(CB:{flight?.classOfBook})
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Base Price</td>
                          <td>
                            ₹{flight?.adultBaseRate} x {flight?.ADULT}
                          </td>
                          <td>₹{flight?.adultBaseRate * flight?.ADULT}</td>
                        </tr>
                        <tr>
                          <td>Taxes and fee</td>
                          <td>
                            ₹{flight?.adultTaxRate} x {flight?.ADULT}
                          </td>
                          <td>₹{flight?.adultTaxRate * flight?.ADULT}</td>
                        </tr>
                      </>
                    )}
                    {flight?.childBaseRate > 0 && flight?.CHILD > 0 && (
                      <>
                        <tr>
                          <td colSpan={3}>
                            <span>
                              Fare Details for Child(CB:{flight?.classOfBook})
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Base Price</td>
                          <td>
                            {" "}
                            ₹{flight?.childBaseRate} x {flight?.CHILD}
                          </td>
                          <td>₹{flight?.childBaseRate * flight?.CHILD}</td>
                        </tr>
                        <tr>
                          <td>Taxes and fee</td>
                          <td>
                            {" "}
                            ₹{flight?.childTaxRate} x {flight?.CHILD}
                          </td>
                          <td>₹{flight?.childTaxRate * flight?.CHILD}</td>
                        </tr>
                      </>
                    )}
                    {flight?.infantBaseRate > 0 && flight?.INFANT > 0 && (
                      <>
                        <tr>
                          <td colSpan={3}>
                            <span>
                              Fare Details for Infant(CB:{flight?.classOfBook})
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Base Price</td>
                          <td>
                            {" "}
                            ₹{flight?.infantBaseRate} x {flight?.INFANT}
                          </td>
                          <td>₹{flight?.infantBaseRate * flight?.INFANT}</td>
                        </tr>
                        <tr>
                          <td>Taxes and fee</td>
                          <td>
                            {" "}
                            ₹{flight?.infantTaxRate} x {flight?.INFANT}
                          </td>
                          <td>₹{flight?.infantTaxRate * flight?.INFANT}</td>
                        </tr>
                      </>
                    )}
                    <tr>
                      <td colSpan={2}>
                        <strong className="fw-600">Total</strong>
                      </td>
                      <td>
                        <strong>₹{flight?.totalPrice}</strong>
                      </td>
                    </tr>
                  </>
                ))}
                {flights?.length > 0 && (
                  <tr>
                    <td colSpan={2}>
                      <strong>Grand Total</strong>
                    </td>
                    <td>
                      <strong>
                        ₹
                        {flights?.reduce(
                          (acc: number, flight: any) =>
                            acc + flight?.totalPrice,
                          0
                        )}
                      </strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade position-relative min-height-150"
          id={`pills-fare-rules`}
          role="tabpanel"
          aria-labelledby={`pills-tab-fare-rules`}
        >
          {/* {fareRulesLoading && <h5 className="text-center">Loading...</h5>} */}
          <Loading loading={loading} />
          {!loading && (
            <>
              {fareRules?.length > 0 && (
                <>
                  {/* Common Header */}
                  <div className="flight-cancel flight-detail flight-table">
                    <span className="mention-tag">
                      Mentioned fees are Per Pax Per Sector <br />
                      Apart from airline charges, GST + RAF + applicable
                      charges, if any, will be charged.
                    </span>

                    {/* Common Table Header */}
                    <table>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Cancellation Fee</th>
                          <th>Date Change Fee</th>
                          <th>No Show</th>
                          <th>Seat Chargeable</th>
                        </tr>
                      </thead>
                    </table>
                  </div>

                  {/* Fare Rules for Each Flight */}
                  {fareRules?.map((fareRule: any, index) => (
                    <div
                      key={index}
                      className="flight-cancel flight-detail flight-table"
                    >
                      <h5 className="border-0 pb-0 my-0 pt-3">
                        {flights[index]?.route?.sI[0]?.da?.city} {" to "}
                        {
                          flights[index]?.route?.sI[
                            flights[index]?.route?.sI?.length - 1
                          ]?.aa?.city
                        }
                      </h5>
                      {Object.keys(fareRule).length === 0 ? (
                        <h4 className="text-center">
                          No Fare Rule Found. Please contact Customer Care!!!
                        </h4>
                      ) : (
                        <table>
                          <tbody>
                            <tr>
                              <td>ALL</td>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: formatContent(
                                    fareRule?.cancellationPolicyInfo,
                                    fareRule?.cancellationAmount
                                  ),
                                }}
                              />
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: formatContent(
                                    fareRule?.datechangePolicyInfo,
                                    fareRule?.datechangeAmount
                                  ),
                                }}
                              />
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: formatContent(
                                    fareRule?.noshowPolicyInfo,
                                    fareRule?.noshowAmount
                                  ),
                                }}
                              />
                              <td
                                dangerouslySetInnerHTML={{
                                  __html: formatContent(
                                    fareRule?.seatschargePolicyInfo,
                                    fareRule?.seatschargeAmount
                                  ),
                                }}
                              />
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  ))}
                </>
              )}
            </>
          )}
          <span> *From the Time of Departure</span>
        </div>
        <div
          className="tab-pane fade"
          id={`pills-buggage-details`}
          role="tabpanel"
          aria-labelledby={`pills-tab-buggage-details`}
        >
          <div className="flight-cancel flight-detail">
            <table>
              <tbody>
                <tr>
                  <th>Sector</th>
                  <th>Cheking</th>
                  <th>Cabin</th>
                </tr>
                {flights?.map((flight: any) => (
                  <>
                    {flight?.route?.sI?.map((e: any) => {
                      const departureCode = e?.da?.code;
                      const arivalCode = e?.aa?.code;
                      return (
                        <tr>
                          <td>
                            {departureCode}-{arivalCode}
                          </td>
                          <td>
                            {flight?.adultCheckingBaggage
                              ? "Adult: " + flight?.adultCheckingBaggage
                              : ""}
                            {flight?.childCheckingBaggage
                              ? ", Child: " + flight?.childCheckingBaggage
                              : ""}
                            {flight?.infantCheckingBaggage
                              ? ", Infant: " + flight?.infantCheckingBaggage
                              : ""}
                          </td>
                          <td>
                            {flight?.adultCabbinBaggage
                              ? "Adult: " + flight?.adultCabbinBaggage
                              : ""}
                            {flight?.childCabbinBaggage
                              ? ", Child: " + flight?.childCabbinBaggage
                              : ""}
                            {flight?.infantCabbinBaggage
                              ? ", Infant: " + flight?.infantCabbinBaggage
                              : ""}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ))}
              </tbody>
            </table>
            <span> *From the Time of Departure</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightMulticityDetailsTab;
