import { createSlice } from "@reduxjs/toolkit";
import {
  emailVerification,
} from "./services/verifyemail.services";

interface IVerifyEmailState {
  loading: boolean;
  error: string;
}

const initialState: IVerifyEmailState = {
  loading: false,
  error: "",
};

const userSlice = createSlice({
  name: "verifyemail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(emailVerification.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(emailVerification.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(emailVerification.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export default userSlice.reducer;
