import React, { useState } from "react";
import moment from "moment";
import PLANE from "../../assets/images/plane.png";
import { formatMinToHRAndMin, getFlightImage } from "../../_helpers/helper";
import Loading from "../../components/spinner/Loading";

interface FlightRDetailsTabsProps {
  route: any;
  routeIndex: number;
  openFightDetail: boolean;
  priceDetails: any;
  classOfBook: string;
  cabinClass: string;
  remainingSeats: number;
  getFareRules: (id: string) => void;
  ADULT: number;
  adultTaxRate: number;
  adultBaseRate: number;
  CHILD: number;
  childTaxRate: number;
  childBaseRate: number;
  INFANT: number;
  infantTaxRate: number;
  infantBaseRate: number;
  totalPrice: number;
  fareRulesLoading: boolean;
  fareRule: any;
  cancellationPolicyInfo: string;
  cancellationAmount: number;
  datechangePolicyInfo: string;
  datechangeAmount: number;
  noshowPolicyInfo: string;
  noshowAmount: number;
  seatschargePolicyInfo: string;
  seatschargeAmount: number;
  adultCheckingBaggage: string;
  childCheckingBaggage: string;
  infantCheckingBaggage: string;
  adultCabbinBaggage: string;
  childCabbinBaggage: string;
  infantCabbinBaggage: string;
  prefix: string;
}

const FlightRDetailsTabs = ({
  openFightDetail,
  routeIndex,
  route,
  priceDetails,
  classOfBook,
  cabinClass,
  remainingSeats,
  getFareRules,
  ADULT,
  adultTaxRate,
  adultBaseRate,
  CHILD,
  childTaxRate,
  childBaseRate,
  INFANT,
  infantTaxRate,
  infantBaseRate,
  totalPrice,
  fareRule,
  fareRulesLoading,
  seatschargeAmount,
  seatschargePolicyInfo,
  noshowAmount,
  noshowPolicyInfo,
  datechangeAmount,
  datechangePolicyInfo,
  cancellationAmount,
  cancellationPolicyInfo,
  adultCheckingBaggage,
  childCheckingBaggage,
  infantCheckingBaggage,
  adultCabbinBaggage,
  childCabbinBaggage,
  infantCabbinBaggage,
  prefix,
}: FlightRDetailsTabsProps) => {
  const formatContent = (content: any, additionalText: any) => {
    const formattedContent = content?.replace(/__nls__/g, "<br />");
    return `${
      additionalText ? additionalText + " + " : ""
    } ${formattedContent}`;
  };

  return (
    <div
      className={`flight-tabing-3 ${
        openFightDetail ? "flight-outer-open" : ""
      }`}
    >
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id={`pills-tab-flight-${routeIndex}-${prefix}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-flight-details-${routeIndex}-${prefix}`}
            type="button"
            role="tab"
            aria-controls={`pills-flight-details-${routeIndex}-${prefix}`}
            aria-selected="true"
          >
            Flight Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-fare-details-${routeIndex}-${prefix}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-fare-details-${routeIndex}-${prefix}`}
            type="button"
            role="tab"
            aria-controls={`pills-fare-details-${routeIndex}-${prefix}`}
            aria-selected="false"
            tabIndex={-1}
          >
            Fare Details
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-fare-rules-${routeIndex}-${prefix}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-fare-rules-${routeIndex}-${prefix}`}
            type="button"
            role="tab"
            aria-controls={`pills-fare-rules-${routeIndex}-${prefix}`}
            aria-selected="false"
            tabIndex={-1}
            onClick={() => {
              getFareRules(priceDetails?.id);
            }}
          >
            Fare Rules
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id={`pills-tab-buggage-details-${routeIndex}-${prefix}`}
            data-bs-toggle="pill"
            data-bs-target={`#pills-buggage-details-${routeIndex}-${prefix}`}
            type="button"
            role="tab"
            aria-controls={`pills-buggage-details-${routeIndex}-${prefix}`}
            aria-selected="false"
            tabIndex={-1}
          >
            Baggage Details
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade active show"
          id={`pills-flight-details-${routeIndex}-${prefix}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-flight-${routeIndex}-${prefix}`}
        >
          <div className="fligt-confirm">
            {route?.sI?.map((e: any, index: number) => {
              const flightNo = e?.fD?.fN;
              const flightCode = e?.fD?.aI?.code;
              const equipmentType = e?.fD?.eT;
              const departureTime = e?.dt;
              const arivalTime = e?.at;
              const layoverTime = e?.cT;
              const duration = e?.duration;
              const arivalCity = e?.aa?.city;
              const departureCity = e?.da?.city;
              const arivalAirport = e?.aa?.name;
              const departureAirport = e?.da?.name;
              const stops = e?.stops;

              const isPlaneChange = flightNo !== route?.sI[index + 1]?.fD?.fN;

              if (index === 0) {
                return (
                  <>
                    <div className="flight-indcator">
                      <span>
                        <h5>
                          {route?.sI[0]?.da?.city} →{" "}
                          {route?.sI[route?.sI?.length - 1]?.aa?.city}
                        </h5>
                        {moment(route?.sI[0]?.dt)?.format("ddd, MMM Do YYYY")}
                      </span>
                    </div>
                    <div className="flight-choose">
                      <div className="fight-name">
                        <img src={getFlightImage(e.fD?.aI?.code)} alt="" />
                        {/* <img src={getFlightImage(e.fD?.aI?.name)} alt="" /> */}
                        <span>
                          <h5>{e.fD?.aI?.name}</h5>
                          <p>
                            {flightCode} - {flightNo} {equipmentType}
                          </p>
                        </span>
                      </div>
                      <div className="flight-duration">
                        <strong>{formatMinToHRAndMin(duration)}</strong>
                        <span>{cabinClass}</span>
                        <span className="highlight"></span>
                        CB.{classOfBook}{" "}
                        {remainingSeats && (
                          <strong
                            className={`${
                              remainingSeats <= 5
                                ? "text-danger"
                                : remainingSeats > 5 && remainingSeats <= 10
                                ? "text-warning"
                                : ""
                            }`}
                          >{`Seats left : ${remainingSeats}`}</strong>
                        )}
                      </div>
                    </div>
                    <div className="route-timing">
                      <div className="route-strating">
                        {moment(departureTime).format("MMM D ddd HH:mm")}
                        <p>
                          <strong>{departureCity},</strong> {departureAirport}
                        </p>
                      </div>
                      <div className="cunsume-time">
                        <p>{stops === 0 ? "Non Stop" : `${stops} Stop`}</p>
                        <div className="cunsume-pic">
                          <img src={PLANE} alt="" />
                        </div>
                      </div>
                      <div className="route-strating route-arrival">
                        {moment(arivalTime).format("MMM D ddd HH:mm")}
                        <p>
                          <strong>{arivalCity},</strong> {arivalAirport}
                        </p>
                      </div>
                    </div>
                    {layoverTime && (
                      <div className="require">
                        <span>
                          {isPlaneChange ? "Require to change Plane " : ""}
                          layover Time - {formatMinToHRAndMin(layoverTime)}
                        </span>
                      </div>
                    )}
                  </>
                );
              } else {
                return (
                  <div className="fligt-confir">
                    <div className="flight-choose">
                      <div className="fight-name">
                        <img src={getFlightImage(e.fD?.aI?.code)} alt="" />
                        {/* <img src={getFlightImage(e.fD?.aI?.name)} alt="" /> */}
                        <span>
                          <h5>{e.fD?.aI?.name}</h5>
                          <p>
                            {flightCode} - {flightNo} {equipmentType}
                          </p>
                        </span>
                      </div>
                      <div className="flight-duration">
                        <strong>{formatMinToHRAndMin(duration)}</strong>
                        <span>{cabinClass}</span>
                        <span className="highlight"></span>
                        CB.{classOfBook}{" "}
                        {remainingSeats && (
                          <strong
                            className={`${
                              remainingSeats <= 5
                                ? "text-danger"
                                : remainingSeats > 5 && remainingSeats <= 10
                                ? "text-warning"
                                : ""
                            }`}
                          >{`Seats left : ${remainingSeats}`}</strong>
                        )}
                      </div>
                    </div>
                    <div className="route-timing">
                      <div className="route-strating">
                        {moment(departureTime).format("MMM D ddd HH:mm")}
                        <p>
                          <strong>{departureCity},</strong> {departureAirport}
                        </p>
                      </div>
                      <div className="cunsume-time">
                        <p>{stops === 0 ? "Non Stop" : `${stops} Stop`}</p>
                        <div className="cunsume-pic">
                          <img src={PLANE} alt="" />
                        </div>
                      </div>
                      <div className="route-strating route-arrival">
                        {moment(arivalTime).format("MMM D ddd HH:mm")}
                        <p>
                          <strong>{arivalCity},</strong> {arivalAirport}
                        </p>
                      </div>
                    </div>
                    {layoverTime && (
                      <div className="require">
                        <span>
                          {isPlaneChange ? "Require to change Plane " : ""}
                          layover Time - {formatMinToHRAndMin(layoverTime)}
                        </span>
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div
          className="tab-pane fade"
          id={`pills-fare-details-${routeIndex}-${prefix}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-fare-details-${routeIndex}-${prefix}`}
        >
          <div className="flight-summary flight-detail fare-detail">
            <table>
              <tbody>
                <tr>
                  <th>Type</th>
                  <th>Fare</th>
                  <th>Total</th>
                </tr>
                <>
                  {adultBaseRate && ADULT && (
                    <>
                      <tr>
                        <td colSpan={3}>
                          <span>Fare Details for Adult(CB:{classOfBook})</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Base Price</td>
                        <td>
                          ₹{adultBaseRate} x {ADULT}
                        </td>
                        <td>₹{adultBaseRate * ADULT}</td>
                      </tr>
                      <tr>
                        <td>Taxes and fee</td>
                        <td>
                          ₹{adultTaxRate} x {ADULT}
                        </td>
                        <td>₹{adultTaxRate * ADULT}</td>
                      </tr>
                    </>
                  )}
                  {childBaseRate > 0 && CHILD > 0 && (
                    <>
                      <tr>
                        <td colSpan={3}>
                          <span>Fare Details for Child(CB:{classOfBook})</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Base Price</td>
                        <td>
                          {" "}
                          ₹{childBaseRate} x {CHILD}
                        </td>
                        <td>₹{childBaseRate * CHILD}</td>
                      </tr>
                      <tr>
                        <td>Taxes and fee</td>
                        <td>
                          {" "}
                          ₹{childTaxRate} x {CHILD}
                        </td>
                        <td>₹{childTaxRate * CHILD}</td>
                      </tr>
                    </>
                  )}
                  {infantBaseRate > 0 && INFANT > 0 && (
                    <>
                      <tr>
                        <td colSpan={3}>
                          <span>Fare Details for Infant(CB:{classOfBook})</span>
                        </td>
                      </tr>
                      <tr>
                        <td>Base Price</td>
                        <td>
                          {" "}
                          ₹{infantBaseRate} x {INFANT}
                        </td>
                        <td>₹{infantBaseRate * INFANT}</td>
                      </tr>
                      <tr>
                        <td>Taxes and fee</td>
                        <td>
                          {" "}
                          ₹{infantTaxRate} x {INFANT}
                        </td>
                        <td>₹{infantTaxRate * INFANT}</td>
                      </tr>
                    </>
                  )}
                </>
                <tr>
                  <td colSpan={2}>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>₹{totalPrice}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="tab-pane fade position-relative min-height-150"
          id={`pills-fare-rules-${routeIndex}-${prefix}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-fare-rulesd-${routeIndex}-${prefix}`}
        >
          {/* {fareRulesLoading && <h5 className="text-center">Loading...</h5>} */}
          <Loading loading={fareRulesLoading} />
          {!fareRulesLoading && (
            <>
              {Object.keys(fareRule).length === 0 ? (
                <h4 className="text-center">
                  No Fare Rule Found. Please contact Customer Care!!!
                </h4>
              ) : (
                <div className="flight-cancel flight-detail flight-table">
                  <h5>
                    {route?.sI[0]?.da?.city} {" to "}
                    {route?.sI[route?.sI?.length - 1]?.aa?.city}
                  </h5>
                  <span className="mention-tag">
                    Mentioned fees are Per Pax Per Sector <br /> Apart from
                    airline charges, GST + RAF + applicable charges if any, will
                    be charged.
                  </span>
                  <table>
                    <tbody>
                      <tr>
                        <th>Type</th>
                        <th>Cancellation Fee</th>
                        <th>Date Change Fee</th>
                        <th>No Show</th>
                        <th>Seat Chargeable</th>
                      </tr>
                      <tr>
                        <td>ALL</td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              cancellationPolicyInfo,
                              cancellationAmount
                            ),
                          }}
                        />
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              datechangePolicyInfo,
                              datechangeAmount
                            ),
                          }}
                        />
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              noshowPolicyInfo,
                              noshowAmount
                            ),
                          }}
                        />
                        <td
                          dangerouslySetInnerHTML={{
                            __html: formatContent(
                              seatschargePolicyInfo,
                              seatschargeAmount
                            ),
                          }}
                        />
                      </tr>
                    </tbody>
                  </table>
                  <span> *From the Time of Departure</span>
                </div>
              )}
            </>
          )}
        </div>
        <div
          className="tab-pane fade"
          id={`pills-buggage-details-${routeIndex}-${prefix}`}
          role="tabpanel"
          aria-labelledby={`pills-tab-buggage-details-${routeIndex}-${prefix}`}
        >
          <div className="flight-cancel flight-detail">
            <table>
              <tbody>
                <tr>
                  <th>Sector</th>
                  <th>Cheking</th>
                  <th>Cabin</th>
                </tr>
                {route?.sI?.map((e: any) => {
                  const departureCode = e?.da?.code;
                  const arivalCode = e?.aa?.code;
                  return (
                    <tr>
                      <td>
                        {departureCode}-{arivalCode}
                      </td>
                      <td>
                        {adultCheckingBaggage
                          ? "Adult: " + adultCheckingBaggage
                          : ""}
                        {childCheckingBaggage
                          ? ", Child: " + childCheckingBaggage
                          : ""}
                        {infantCheckingBaggage
                          ? ", Infant: " + infantCheckingBaggage
                          : ""}
                      </td>
                      <td>
                        {adultCabbinBaggage
                          ? "Adult: " + adultCabbinBaggage
                          : ""}
                        {childCabbinBaggage
                          ? ", Child: " + childCabbinBaggage
                          : ""}
                        {infantCabbinBaggage
                          ? ", Infant: " + infantCabbinBaggage
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <span> *From the Time of Departure</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightRDetailsTabs;
