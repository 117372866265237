import React from "react";

export const OneWayListingSkeleton = () => {
  return (
    <div className="flight-route flight-skeleton">
      <div className="flight-mobile">
        <div className="flight-flex">
          <div className="fight-name">
            <div className="flight-skeleton-img"></div>
            <span>
              <h5>IndiGo</h5>
              <p>6E 6116, 6E 361</p>
            </span>
          </div>
          <div className="route-price">
            ₹ 9,999
            <span>per adult</span>
          </div>
          <div className="route-timing">
            <div className="route-strating">
              <p>Bangalore</p>
              21:10
              <p>Abc 20</p>
            </div>
            <div className="cunsume-time">
              <span>Non-Stop</span>
              <div className="cunsume-pic">
                <img
                  src="/noimage"
                  alt=""
                  style={{ background: "lightgray" }}
                />
              </div>
              <p>01&nbsp;h&nbsp;50&nbsp;min</p>
            </div>
            <div className="route-strating">
              <p>Hyderabad</p>
              23:00
              <p>Abc 21</p>
            </div>
          </div>
        </div>
        <a data-rid={3} className="view-detail" href="javascript:void(0)">
          View Detail
        </a>
        <div className="flight-left">
          <span>
            <img src="./image/plane.png" alt="" />
            Flight Arrives after 1 Day(s)
            <span>Seats left:1</span>
          </span>
        </div>
      </div>
      <div className="route-price-right">
        <div className="route-price-col">
          <div className="route-price">
            <input type="radio" />
            <label />
            ₹9,999
            <a href="javascript:void(0)">
              <span>Published</span> Economy,Free Meal, Refundable
            </a>
          </div>
          <div className="route-price">
            <input type="radio" />
            <label />
            ₹9,999
            <a href="javascript:void(0)">
              <span>Published</span>Premium, Free Meal, Refundable
            </a>
          </div>
          <div className="route-price">
            <input type="radio" />
            <label />
            ₹9,999
            <a href="javascript:void(0)">
              <span>Published</span>Business,Free Meal, Refundable
            </a>
          </div>
        </div>
        <div className="flight-btn">
          <a
            className="fill-btn"
            style={{
              background:
                "linear-gradient(to right,  rgba(130, 130, 130, 0.1) 8%,    rgba(130, 130, 130, 0.2) 18%,    rgba(130, 130, 130, 0.1) 33%  )",
              backgroundSize: "800px 100px",
            }}
            href="javascript:void(0)"
          >
            Book Now
          </a>
        </div>
      </div>
    </div>
  );
};

export const TwoWayListingSkeleton = () => {
  return (
    <div className="flight-detail-tab-main flight-skeleton">
      <div className="row listing-row">
        <div className="col-lg-6 col-md-12 col-sm-12 Detail-tab">
          <div className="indicatior">
            <span>
              <a href="">
                <img src="" alt="" />
              </a>
              <h5>Bangalore - Hyderabad</h5>
            </span>
            <p>Sunday, 20 Feb 2024</p>
          </div>
          <div className="flight-detail-tab">
            <div className="flight-route">
              <div className="flight-route-top">
                <div className="flight-route-full">
                  <div className="flight-route-name">
                    <div className="fight-name">
                      <div className="flight-skeleton-img"></div>
                      <span>
                        <h5>IndiGo</h5>
                        <p>6E 6116, 6E 361</p>
                      </span>
                    </div>
                    <div className="route-timing">
                      <div className="route-strating">
                        <p>BLR</p>
                        16:40
                        <p>May 20</p>
                      </div>
                      <div className="cunsume-time">
                        <span>Non-Stop</span>
                        <div className="cunsume-pic">
                          <img src="./image/plane.png" alt="" />
                        </div>
                        <p>03h&nbsp;50min</p>
                      </div>
                      <div className="route-strating">
                        <p>HYD</p>
                        20:30
                        <p>May 20</p>
                      </div>
                    </div>
                  </div>
                  <a
                    data-rid={3}
                    className="view-detail"
                    href="javascript:void(0)"
                  >
                    View Detail
                  </a>
                  <div className="flight-left">
                    <span>
                      <img src="./image/plane.png" alt="" />
                      Flight Arrives after 1 Day(s) <span>Seats left:5</span>
                    </span>
                  </div>
                </div>
                <div className="route-price-col">
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹8,946
                    <a href="javascript:void(0)">
                      <span>Published</span> Economy,Free Meal, Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹14,159
                    <a href="javascript:void(0)">
                      <span>Published</span>Premium Economy,Free Meal,
                      Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹29,459
                    <a href="javascript:void(0)">
                      <span>Published</span>Business,Free Meal, Refundable
                    </a>
                  </div>
                </div>
              </div>
              <div className="detail-btn">
                <a
                  className="fill-btn"
                  style={{
                    background:
                      "linear-gradient(to right,  rgba(130, 130, 130, 0.1) 8%,    rgba(130, 130, 130, 0.2) 18%,    rgba(130, 130, 130, 0.1) 33%  )",
                    backgroundSize: "800px 100px",
                  }}
                  href="javascript:void(0)"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
          <div className="flight-detail-tab">
            <div className="flight-route">
              <div className="flight-route-top">
                <div className="flight-route-full">
                  <div className="flight-route-name">
                    <div className="fight-name">
                      <div className="flight-skeleton-img"></div>
                      <span>
                        <h5>IndiGo</h5>
                        <p>6E 6116, 6E 361</p>
                      </span>
                    </div>
                    <div className="route-timing">
                      <div className="route-strating">
                        <p>BLR</p>
                        16:40
                        <p>May 20</p>
                      </div>
                      <div className="cunsume-time">
                        <span>Non-Stop</span>
                        <div className="cunsume-pic">
                          <img src="./image/plane.png" alt="" />
                        </div>
                        <p>03h&nbsp;50min</p>
                      </div>
                      <div className="route-strating">
                        <p>HYD</p>
                        20:30
                        <p>May 20</p>
                      </div>
                    </div>
                  </div>
                  <a
                    data-rid={3}
                    className="view-detail"
                    href="javascript:void(0)"
                  >
                    View Detail
                  </a>
                  <div className="flight-left">
                    <span>
                      <img src="./image/plane.png" alt="" />
                      Flight Arrives after 1 Day(s) <span>Seats left:5</span>
                    </span>
                  </div>
                </div>
                <div className="route-price-col">
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹8,946
                    <a href="javascript:void(0)">
                      <span>Published</span> Economy,Free Meal, Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹14,159
                    <a href="javascript:void(0)">
                      <span>Published</span>Premium Economy,Free Meal,
                      Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹29,459
                    <a href="javascript:void(0)">
                      <span>Published</span>Business,Free Meal, Refundable
                    </a>
                  </div>
                </div>
              </div>
              <div className="detail-btn">
                <a
                  className="fill-btn"
                  style={{
                    background:
                      "linear-gradient(to right,  rgba(130, 130, 130, 0.1) 8%,    rgba(130, 130, 130, 0.2) 18%,    rgba(130, 130, 130, 0.1) 33%  )",
                    backgroundSize: "800px 100px",
                  }}
                  href="javascript:void(0)"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 Detail-tab">
          <div className="indicatior">
            <span>
              <a href="">
                <img src="" alt="" />
              </a>
              <h5>Hyderabad - Bangalore</h5>
            </span>
            <p>Monday, 21 Feb 2024</p>
          </div>
          <div className="flight-detail-tab">
            <div className="flight-route">
              <div className="flight-route-top">
                <div className="flight-route-full">
                  <div className="flight-route-name">
                    <div className="fight-name">
                      <div className="flight-skeleton-img"></div>
                      <span>
                        <h5>IndiGo</h5>
                        <p>6E 6116, 6E 361</p>
                      </span>
                    </div>
                    <div className="route-timing">
                      <div className="route-strating">
                        <p>BLR</p>
                        16:40
                        <p>May 20</p>
                      </div>
                      <div className="cunsume-time">
                        <span>Non-Stop</span>
                        <div className="cunsume-pic">
                          <img src="./image/plane.png" alt="" />
                        </div>
                        <p>03h&nbsp;50min</p>
                      </div>
                      <div className="route-strating">
                        <p>HYD</p>
                        20:30
                        <p>May 20</p>
                      </div>
                    </div>
                  </div>
                  <a
                    data-rid={3}
                    className="view-detail"
                    href="javascript:void(0)"
                  >
                    View Detail
                  </a>
                  <div className="flight-left">
                    <span>
                      <img src="./image/plane.png" alt="" />
                      Flight Arrives after 1 Day(s) <span>Seats left:5</span>
                    </span>
                  </div>
                </div>
                <div className="route-price-col">
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹8,946
                    <a href="javascript:void(0)">
                      <span>Published</span> Economy,Free Meal, Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹14,159
                    <a href="javascript:void(0)">
                      <span>Published</span>Premium Economy,Free Meal,
                      Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹29,459
                    <a href="javascript:void(0)">
                      <span>Published</span>Business,Free Meal, Refundable
                    </a>
                  </div>
                </div>
              </div>
              <div className="detail-btn">
                <a
                  className="fill-btn"
                  style={{
                    background:
                      "linear-gradient(to right,  rgba(130, 130, 130, 0.1) 8%,    rgba(130, 130, 130, 0.2) 18%,    rgba(130, 130, 130, 0.1) 33%  )",
                    backgroundSize: "800px 100px",
                  }}
                  href="javascript:void(0)"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
          <div className="flight-detail-tab">
            <div className="flight-route">
              <div className="flight-route-top">
                <div className="flight-route-full">
                  <div className="flight-route-name">
                    <div className="fight-name">
                      <div className="flight-skeleton-img"></div>
                      <span>
                        <h5>IndiGo</h5>
                        <p>6E 6116, 6E 361</p>
                      </span>
                    </div>
                    <div className="route-timing">
                      <div className="route-strating">
                        <p>BLR</p>
                        16:40
                        <p>May 20</p>
                      </div>
                      <div className="cunsume-time">
                        <span>Non-Stop</span>
                        <div className="cunsume-pic">
                          <img src="./image/plane.png" alt="" />
                        </div>
                        <p>03h&nbsp;50min</p>
                      </div>
                      <div className="route-strating">
                        <p>HYD</p>
                        20:30
                        <p>May 20</p>
                      </div>
                    </div>
                  </div>
                  <a
                    data-rid={3}
                    className="view-detail"
                    href="javascript:void(0)"
                  >
                    View Detail
                  </a>
                  <div className="flight-left">
                    <span>
                      <img src="./image/plane.png" alt="" />
                      Flight Arrives after 1 Day(s) <span>Seats left:5</span>
                    </span>
                  </div>
                </div>
                <div className="route-price-col">
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹8,946
                    <a href="javascript:void(0)">
                      <span>Published</span> Economy,Free Meal, Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹14,159
                    <a href="javascript:void(0)">
                      <span>Published</span>Premium Economy,Free Meal,
                      Refundable
                    </a>
                  </div>
                  <div className="route-price">
                    <input type="radio" />
                    <label />
                    ₹29,459
                    <a href="javascript:void(0)">
                      <span>Published</span>Business,Free Meal, Refundable
                    </a>
                  </div>
                </div>
              </div>
              <div className="detail-btn">
                <a
                  className="fill-btn"
                  style={{
                    background:
                      "linear-gradient(to right,  rgba(130, 130, 130, 0.1) 8%,    rgba(130, 130, 130, 0.2) 18%,    rgba(130, 130, 130, 0.1) 33%  )",
                    backgroundSize: "800px 100px",
                  }}
                  href="javascript:void(0)"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FilterSkeleton = () => {
  return (
    <div
      className="col-lg-3 col-md-5 col-sm-12"
      style={{ position: "sticky", top: 0, zIndex: 2 }}
    >
      <div className="filter-overlay flight-skeleton">
        <div className="listing-sidebar">
          <span className="close-btn" />
          <div className="sidebar-title">
            <h5>Popular Filters</h5>
          </div>
          <div className="sidebar-content">
            <div className="sidebar-left">
              <h5>Stops</h5>
              <div className="sidebar-field">
                <div className="radio-div-small-skeleton"></div>
                <label>Direct (4)</label>
              </div>
              <div className="sidebar-field">
                <div className="radio-div-small-skeleton"></div>
                <label>1 stop (20)</label>
              </div>
            </div>
            <div className="sidebar-right">
              <h5>From</h5>
              <span>₹9,999</span>
              <span>₹9,999</span>
            </div>
          </div>
          <div className="sidebar-content">
            <div className="sidebar-left">
              <h5>Airlines</h5>
              <div className="sidebar-field">
                <div className="radio-div-small-skeleton"></div>
                <label>IndiGo (12)</label>
              </div>
              <div className="sidebar-field">
                <div className="radio-div-small-skeleton"></div>
                <label>Air India (9)</label>
              </div>
            </div>
            <div className="sidebar-right">
              <h5>From</h5>
              <span>₹9,999</span>
              <span>₹9,999</span>
            </div>
          </div>
          <div className="sidebar-timing">
            <h5>Departure time in Bangalore</h5>
            <div className="sider-bar-depar">
              <div className="timimg-departure">
                <input type="radio" />
                <div className="timing-radio">
                  <img src="" alt="" />
                  <p>Morning</p>
                  <span>(05:00 - 11:59)</span>
                </div>
              </div>
              <div className="timimg-departure">
                <input type="radio" />
                <div className="timing-radio">
                  <img src="" alt="" />
                  <p>Afternoon</p>
                  <span>(05:00 - 11:59)</span>
                </div>
              </div>
              <div className="timimg-departure">
                <input type="radio" />
                <div className="timing-radio">
                  <img src="" alt="" />
                  <p>Morning</p>
                  <span>(05:00 - 11:59)</span>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar-timing">
            <h5>Arrival time in Hyderabad</h5>
            <div className="sider-bar-depar">
              <div className="timimg-departure">
                <input type="radio" />
                <div className="timing-radio">
                  <img src="" alt="" />
                  <p>Morning</p>
                  <span>(05:00 - 11:59)</span>
                </div>
              </div>
              <div className="timimg-departure">
                <input type="radio" />
                <div className="timing-radio">
                  <img src="" alt="" />
                  <p>Afternoon</p>
                  <span>(05:00 - 11:59)</span>
                </div>
              </div>
              <div className="timimg-departure">
                <input type="radio" />
                <div className="timing-radio">
                  <img src="" alt="" />
                  <p>Morning</p>
                  <span>(05:00 - 11:59)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
