import React from "react";
import LOGO from "../../../assets/images/logo.png";
import MAP_PIN from "../../../assets/images/map-pin.png";
import MAILPNG from "../../../assets/images/mail.png";
import PHONEPNG from "../../../assets/images/phone.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-top">
          <a className="footer-logo">
            <img src={LOGO} alt="" />
          </a>
          <a>
            <div className="social-pic">
              <img src={MAP_PIN} alt="" />
            </div>
            <div className="social-text">
              <span>Our Location</span>
              <br />
              2, Shreeji Complex, Ghodasar Highway, Ahmedabad - 380050
            </div>
          </a>
          <a href="mailto:info@bigsmileholidays.com">
            <div className="social-pic">
              <img src={MAILPNG} alt="" />
            </div>
            <div className="social-text text-underline">
              <span>Mail Us</span>
              <br />
              <strong>info@bigsmileholidays.com</strong>
            </div>
          </a>
          <a href="tel:+ 91 9377876677">
            <div className="social-pic">
              <img src={PHONEPNG} alt="" />
            </div>
            <div className="social-text">
              <span>Call</span>
              <br />
              +91 9377876677
            </div>
          </a>
        </div>
        <div className="footer-middle">
          <div className="row">
            <div className="col-md-3 col-sm-12 footmtogglep">
              <h5 className="footmtoggle">Our Products</h5>
              <ul>
                <li>
                  <a> Domestic Hotels</a>
                </li>
                <li>
                  <a> International Hotels</a>
                </li>
                <li>
                  <a>Domestic Flights</a>
                </li>
                <li>
                  <a>International Flights</a>
                </li>
                <li>
                  <a>Multi-City Flights</a>
                </li>
                <li>
                  <a>Couple Friendly Hotels</a>
                </li>
                <li>
                  <a> Nearby Getaways</a>
                </li>
                <li>
                  <a>Bus Booking</a>
                </li>
                <li>
                  <a>Cab Booking</a>
                </li>
                <li>
                  <a>Airport Cabs Booking</a>
                </li>
                <li>
                  <a>Outstation Cabs Booking</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-12 footmtogglep">
              <h5 className="footmtoggle">About us</h5>
              <ul>
                <li>
                  <a> About Us</a>
                </li>
                <li>
                  <a> Investor Relations</a>
                </li>
                <li>
                  <a>Management</a>
                </li>
                <li>
                  <a>Terms of Services</a>
                </li>
                <li>
                  <a>User Agreement</a>
                </li>
                <li>
                  <a>Privacy</a>
                </li>
                <li>
                  <a> Careers</a>
                </li>
                <li>
                  <a>YouTube Channel</a>
                </li>
                <li>
                  <a>Customer Support</a>
                </li>
                <li>
                  <a>Facebook Page</a>
                </li>
                <li>
                  <a>Twitter Handle</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-12 footmtogglep">
              <h5 className="footmtoggle">Information</h5>
              <ul>
                <li>
                  <a>Cheap Flights</a>
                </li>
                <li>
                  <a>Hotels Near Me</a>
                </li>
                <li>
                  <a>My Bookings</a>
                </li>
                <li>
                  <a>Cancellation</a>
                </li>
                <li>
                  <a>My Account</a>
                </li>
                <li>
                  <a>Wallet</a>
                </li>
                <li>
                  <a>Advertise with Us</a>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-sm-12 footmtogglep">
              <h5 className="footmtoggle">Quick links</h5>
              <ul>
                <li>
                  <a>Cheap Flights</a>
                </li>
                <li>
                  <a>Hotels Near Me</a>
                </li>
                <li>
                  <a>My Bookings</a>
                </li>
                <li>Cancellation</li>
                <li>
                  <a>My Account</a>
                </li>
                <li>
                  <a>Wallet</a>
                </li>
                <li>
                  <a>Advertise with Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2014-2024 Bigsmile Holidays Pvt Ltd.</p>
          <a>
            <img src="./image/payment-icons.png" alt="" />
          </a>
        </div>
      </div>

      {/* <script src="./js/custom.js"></script>

      <script type="text/javascript">
        jQuery(document).ready(function () {
          jQuery(".select-dropdown li").click(function () {
            var droplist = jQuery(this).html();
            jQuery(this)
              .parent(".select-dropdown.showdropdown")
              .parent(".custom-select")
              .children(".select-button")
              .html(droplist);
          });
          jQuery(".select-button").click(function () {
            var droplist = jQuery(this).html();
            jQuery(this).toggleClass("active");
            jQuery(this)
              .parent(".custom-select")
              .children(".select-dropdown")
              .toggleClass("showdropdown");
          });
        });
        const customSelect = document.querySelector(".custom-select");
        const selectBtn = document.querySelector(".select-button");

        const selectedValue = document.querySelector(".selected-value");
        const optionsList = document.querySelectorAll(".select-dropdown li");
      </script> */}
    </footer>
  );
};

export default Footer;
