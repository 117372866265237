import React from "react";

const FilterLoading = () => {
  return (
    <div className="filter-loading-container">
      <div className="filter-loader">
        <div></div> <div></div>
        <div></div> <div></div>
      </div>
    </div>
  );
};

export default FilterLoading;
