import React from "react";
import { Link } from "react-router-dom";
import CANCEL from "../../assets/images/cancel-trip.png";
import { useAppSelector } from "../../app/hooks";
import moment from "moment";

const CancelledTrips = () => {
  const MytripsList = useAppSelector((state) => state.mytrips.MytripsList);
  return (
    <div
      className="tab-pane fade"
      id="pills-hotel1"
      role="tabpanel"
      aria-labelledby="pills-hotel-tab1"
    >
      {MytripsList && MytripsList.cancelled?.length > 0 ? (
        <>
          <table>
            <tbody>
              <tr>
                <th>Sr No.</th>
                <th>Booking ID</th>
                <th>From date</th>
                <th>To date</th>
                <th>From location</th>
                <th>To location</th>
                <th>Total amount</th>
                <th>Status</th>
                <th />
              </tr>
              {MytripsList.cancelled.map((item: any, index: any) => (
                <tr key={item.booking_no}>
                  <td>{item.sr_no}</td>
                  <td>{item.booking_no}</td>
                  <td>
                    {moment(item?.from_date_time, "MMM DD YYYY hh:mma").format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td>
                    {" "}
                    {moment(item?.to_date_time, "MMM DD YYYY hh:mma").format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                  <td>{item.from_place}</td>
                  <td>{item?.to_place}</td>
                  <td>{item.amount}</td>
                  <td className="red-text">Cancelled</td>
                  <td>
                    <Link
                      to={`/flights/trip-details?bookingId=${item?.booking_no}`}
                      target="_blank"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="upcoming-trips">
          <img src={CANCEL} alt="cancel-trip" />
          <h3>Looks empty, you've no cancelled bookings.</h3>
          <p>When you cancel a trip, you will se your itinerary here.</p>
        </div>
      )}
    </div>
  );
};

export default CancelledTrips;
