import React from "react";

const Loading = ({
  loading,
  backgroundColor = "rgba(255, 255, 255, 0.8)",
}: {
  loading: boolean;
  backgroundColor?: string;
}) => {
  return (
    <>
      {loading && (
        <div className="loading-spinner-overlay" style={{ backgroundColor }}>
          <div className="loading-spinner"></div>
        </div>
      )}
    </>
  );
};

export default Loading;
