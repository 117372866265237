import moment from "moment";
import {
  formatMinToHRAndMin,
  getDiffInHRAndMin,
  getFlightImage,
} from "../../_helpers/helper";
import PLANE from "../../assets/images/plane.png";
import BRIEFCASE from "../../assets/images/briefcase.png";
import BACKPACK from "../../assets/images/backpack.png";
import { useMemo } from "react";

const ReviewFlightRouteCard = ({
  route,
  fareRule,
}: {
  route: any;
  fareRule: any;
}) => {
  const startTime = useMemo(() => moment(route?.sI[0]?.dt), [route]);
  const endTime = useMemo(
    () => moment(route?.sI[route?.sI?.length - 1]?.at),
    [route]
  );
  const priceDetails = route?.totalPriceList && route?.totalPriceList[0];
  const cabinClass = priceDetails?.fd?.ADULT?.cc;

  const adultCheckingBaggage = priceDetails?.fd?.ADULT?.bI?.iB;
  const adultCabbinBaggage = priceDetails?.fd?.ADULT?.bI?.cB;

  const formatContent = (content: any, additionalText: any) => {
    const formattedContent = content?.replace(/__nls__/g, "<br />");
    return `${
      additionalText ? additionalText + " + " : ""
    } ${formattedContent}`;
  };

  return (
    <div className="fligt-confirm">
      <div className="flight-indcator">
        <span>
          {/* <h5>Ahmedabad → Leh</h5> */}
          <h5>
            {route?.sI[0]?.da?.city} →{" "}
            {route?.sI[route?.sI?.length - 1]?.aa?.city}
          </h5>
        </span>
        <p>All departure/arrival times are in local time</p>
      </div>
      <div className="fare-rule">
        <span>
          {route?.sI?.length - 1 === 0
            ? "Non-Stop"
            : `${route?.sI?.length - 1} Stop`}
          {" - "}
          {getDiffInHRAndMin(startTime, endTime)}
        </span>
        {/* <span>1 stop.7h 10m</span> */}
        <a
          href="javascript:void(0)"
          data-bs-toggle="modal"
          data-bs-target="#viewfarerule"
        >
          View fare rule
        </a>
        <div
          className="modal fade"
          id="viewfarerule"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="view-farerule"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered farerule-model">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title fs-5" id="edit-profile">
                  Fare Rule Information
                </h4>
              </div>
              <div className="modal-body pb-0 ">
                <div className="body-content mb-3" id="pills-tabContent">
                  <div className=" position-relative min-height-150">
                    <div className="flight-cancel flight-detail flight-table">
                      <span className="mention-tag">
                        Mentioned fees are Per Pax Per Sector <br /> Apart from
                        airline charges, GST + RAF + applicable charges if any,
                        will be charged.
                      </span>
                      <table>
                        <tbody>
                          <tr>
                            <th>Type</th>
                            <th>Cancellation Fee</th>
                            <th>Date Change Fee</th>
                            <th>No Show</th>
                            <th>Seat Chargeable</th>
                          </tr>
                          <tr>
                            <td>ALL</td>
                            <td
                              dangerouslySetInnerHTML={{
                                __html: formatContent(
                                  fareRule?.CANCELLATION &&
                                    fareRule?.CANCELLATION[0]?.policyInfo,
                                  (fareRule?.CANCELLATION &&
                                    fareRule?.CANCELLATION[0]?.amount) ||
                                    0
                                ),
                              }}
                            />
                            <td
                              dangerouslySetInnerHTML={{
                                __html: formatContent(
                                  fareRule?.DATECHANGE &&
                                    fareRule?.DATECHANGE[0]?.policyInfo,
                                  (fareRule?.DATECHANGE &&
                                    fareRule?.DATECHANGE[0]?.amount) ||
                                    0
                                ),
                              }}
                            />
                            <td
                              dangerouslySetInnerHTML={{
                                __html: formatContent(
                                  fareRule?.NO_SHOW &&
                                    fareRule?.NO_SHOW[0]?.policyInfo,
                                  (fareRule?.NO_SHOW &&
                                    fareRule?.NO_SHOW[0]?.amount) ||
                                    0
                                ),
                              }}
                            />
                            <td
                              dangerouslySetInnerHTML={{
                                __html: formatContent(
                                  fareRule?.SEAT_CHARGEABLE &&
                                    fareRule?.SEAT_CHARGEABLE[0]?.policyInfo,
                                  (fareRule?.SEAT_CHARGEABLE &&
                                    fareRule?.SEAT_CHARGEABLE[0]?.amount) ||
                                    0
                                ),
                              }}
                            />
                          </tr>
                        </tbody>
                      </table>
                      <span> *From the Time of Departure</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal-footer justify-content-end border-0 pt-0 pe-0"
                style={{
                  marginTop: 28,
                }}
              >
                <div className="travel-detail-btns mt-2">
                  <a
                    className="disable"
                    style={{
                      background: "#cdffe4",
                      color: "#08a34e",
                      border: "1px solid #08a34e",
                      borderRadius: 30,
                      padding: "5px 10px",
                      minWidth: 73,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                    href="javascript:void(0)"
                    data-bs-dismiss="modal"
                    // ref={closeBtnRef}
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="booking-timing">
        {route?.sI?.map((e: any, index: number) => {
          const flightNo = e?.fD?.fN;
          const flightCode = e?.fD?.aI?.code;
          const equipmentType = e?.fD?.eT;
          const departureTime = e?.dt;
          const arivalTime = e?.at;
          const layoverTime = e?.cT;
          const duration = e?.duration;
          const arivalCity = e?.aa?.city;
          const departureCity = e?.da?.city;
          const arivalAirport = e?.aa?.name;
          const departureAirport = e?.da?.name;
          const stops = e?.stops;

          const isPlaneChange = flightNo !== route?.sI[index + 1]?.fD?.fN;

          return (
            <>
              <div className="booking-flight-half">
                <div className="flight-choose">
                  <div className="fight-name">
                    <img src={getFlightImage(e.fD?.aI?.code)} alt="" />
                    {/* <img src={getFlightImage(e.fD?.aI?.name)} alt="" /> */}
                    <span>
                      <h5>{e.fD?.aI?.name}</h5>
                      <p>
                        {flightCode} - {flightNo} {equipmentType}
                      </p>
                    </span>
                  </div>
                  <div className="flight-className">
                    <span>
                      <strong>{cabinClass}</strong>
                    </span>
                  </div>
                </div>
                <div className="booking-timimg-main">
                  <div className="route-timing">
                    <div className="route-strating">
                      {moment(departureTime).format("HH:mm")}
                      <p>
                        <strong>{departureCity},</strong> {departureAirport}
                      </p>
                    </div>
                    <div className="cunsume-time">
                      <p>
                        {stops === 0 ? "Non Stop" : `${stops} Stop`} |{" "}
                        <strong>{formatMinToHRAndMin(duration)}</strong>
                      </p>
                      <div className="cunsume-pic">
                        <img src={PLANE} />
                      </div>
                      <p>{moment(departureTime).format("dddd, DD MMM’YY")}</p>
                    </div>
                    <div className="route-strating route-arrival">
                      {moment(arivalTime).format("HH:mm")}
                      <p>
                        <strong>{arivalCity},</strong> {arivalAirport}
                      </p>
                    </div>
                  </div>
                  <div className="mobile-timimg">
                    <div className="cunsume-time">
                      <p>
                        {stops === 0 ? "Non Stop" : `${stops} Stop`} |{" "}
                        <strong>{formatMinToHRAndMin(duration)}</strong>
                      </p>
                      <p>{moment(departureTime).format("dddd, DD MMM’YY")}</p>
                    </div>
                  </div>
                  <div className="fligt-parcel">
                    <div className="low-parcel">
                      <img src={BRIEFCASE} />
                      <span>
                        <strong>Cabin Baggage: </strong>
                        {adultCabbinBaggage || ""} / Adult
                      </span>
                    </div>
                    <div className="low-parcel">
                      <img src={BACKPACK} />
                      <span>
                        <strong>Check-In Baggage: </strong>
                        {adultCheckingBaggage} / Adult
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {index < route?.sI?.length - 1 && (
                <div className="terminal-change">
                  <span>Change of Terminal</span>
                  <p>
                    {isPlaneChange ? "Require to change Plane, " : ""}
                    {formatMinToHRAndMin(layoverTime)} Layover in {arivalCity}
                  </p>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ReviewFlightRouteCard;
