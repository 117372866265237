import React from "react";

const flightToCities = [
  "New Delhi",
  "Mumbai",
  "Bangalore",
  "Kolkata",
  "Hyderabad",
  "Srinagar",
  "Chennai",
  "Patna",
  "Ahmedabad",
];

const flightToCountries = [
  "United Arab Emirates",
  "Saudi Arabia",
  "Kuwait",
  "Thailand",
  "Oman",
  "Qatar",
  "Canada",
  "Bahrain",
  "United States",
  "Singapore",
];

const hotelsInCities = [
  "Goa",
  "Mumbai",
  "New Delhi",
  "Jaipur",
  "Hyderabad",
  "Bangalore",
  "Kolkata",
  "Manali",
  "Varanasi",
  "Udaipur",
  "Dubai",
  "Pattaya",
  "Bangkok",
  "Singapore",
];

const trendingResortCities = [
  "Mahabaleshwar",
  "Agra",
  "Bhimtal",
  "Bordi",
  "Br Hills",
  "Chikmagalur",
  "Cochin",
  "Darjeeling",
  "Dehradun",
  "Dharamshala",
  "Gorai",
  "Jaipur",
  "Jaisalmer",
  "Jodhpur",
  "Kanakapura",
  "Kollam",
  "Kotagiri",
  "Lucknow",
  "Madikeri",
  "Mahabaleshwar",
  "Masinagudi",
  "Matheran",
  "Mount Abu",
  "Mumbai",
  "Munnar",
  "Mussoorie",
  "Mysore",
  "Nainital",
  "Neemrana",
  "Kodaikanal",
];

const PopularItems = () => {
  return (
    <div className="container">
      <div className="listing">
        <CategorySection
          title="Flights To Top Cities from India"
          items={flightToCities}
        />
        <CategorySection
          title="Flights To Top Countries from India"
          items={flightToCountries}
        />
        <CategorySection title="Hotels In Top Cities" items={hotelsInCities} />
        <CategorySection
          title="Trending resorts cities"
          items={trendingResortCities}
        />
      </div>
    </div>
  );
};

const CategorySection = ({ title, items }: { title: string; items: any }) => (
  <div className="footmtogglep">
    <h5 className="footmtoggle">{title}</h5>
    <ul>
      {items.map((item: any, index: number) => (
        <li key={index}>
          <a href="#">{item}</a> {index < items.length - 1 ? "|" : ""}
        </li>
      ))}
    </ul>
  </div>
);

export default PopularItems;
