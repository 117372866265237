import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router";
import {
  getBrowserName,
  getDeviceId,
  getDeviceType,
  getSessionId,
} from "../../_helpers/helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateOTP, userLogin } from "./services/login.services";
import { FaEdit } from "react-icons/fa";

const Login = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.login.loading);
  const [showOTP, setShowOTP] = useState(false);
  const [isOTPButtonClicked, setIsOTPButtonClicked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(0);

  const deviceId = getDeviceId();
  const deviceType = getDeviceType();
  const sessionId = getSessionId();
  const browser = getBrowserName();

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timer]);

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const inputRef = useRef<any>([]);
  const closeBtnRef = useRef<any>(null);

  useEffect(() => {
    // Focus the first input on mount
    inputRef?.current[0]?.focus();
  }, []);

  const handleChange = (element: any, index: number) => {
    if (isNaN(element.value)) return false;

    const newOtp = [
      ...otp.map((d, idx) => (idx === index ? element.value : d)),
    ];
    setOtp(newOtp);

    // Focus next input if the current input is not empty
    if (element.value !== "" && element.nextSibling) {
      element.nextSibling.focus();
    }

    if (newOtp.every((char) => char !== "")) {
      formik.setFieldValue("otp", newOtp.join(""));
      formik.handleSubmit();
    }
  };

  const handleKeyDown = (element: any, index: number, e: any) => {
    if (e.key === "Backspace") {
      if (element.value === "" && index > 0) {
        // Move focus to previous input
        inputRef.current[index - 1].focus();
        e.preventDefault();
      } else {
        setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);
      }
    } else if (e.key === "ArrowLeft") {
      if (index > 0) {
        // Move focus to previous input
        inputRef.current[index - 1].focus();
      }
    } else if (e.key === "ArrowRight") {
      if (index < otp.length - 1) {
        // Move focus to next input
        inputRef.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e: any) => {
    const pasteData = e.clipboardData.getData("text").split("");

    // Ensure pasted data is numeric and within the OTP length
    if (pasteData.every((char: any) => !isNaN(char))) {
      const newOtp = [...otp];
      pasteData.slice(0, otp.length).forEach((char: any, i: number) => {
        newOtp[i] = char;
      });
      setOtp(newOtp);

      // Focus on the last pasted input field
      inputRef.current[Math.min(pasteData.length, otp.length) - 1].focus();
      if (newOtp.every((char) => char !== "")) {
        formik.setFieldValue("otp", newOtp.join(""));
        formik.handleSubmit();
      }
    }
  };

  const formik: any = useFormik({
    initialValues: {
      mobile_no: "",
      otp: "",
    },
    validateOnBlur: true,
    validationSchema: Yup.object({
      mobile_no: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Mobile number is not valid"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (isOTPButtonClicked) {
        setIsOTPButtonClicked(false);
        const otpResponse = await dispatch(generateOTP(`${values.mobile_no}`));
        if (otpResponse.meta.requestStatus === "fulfilled") {
          setShowOTP(true);
          setTimer(30);
          setIsButtonDisabled(true);
        }
      } else {
        const payload = {
          mobile_no: `${values?.mobile_no}`,
          otp: values?.otp,
          device_id: deviceId,
          device_type: deviceType,
          session_id: sessionId,
          browser_version: browser,
        };
        const response = await dispatch(userLogin(payload));
        if (response.meta.requestStatus === "fulfilled") {
          if (closeBtnRef.current) {
            closeBtnRef.current.click();
          }
          setSubmitting(false);
          resetForm();
        }
      }
    },
  });

  const handleGenerateOTP = async (mobileno: any) => {
    const response = await dispatch(generateOTP(`${mobileno}`));
    if (response.meta.requestStatus === "fulfilled") {
      setShowOTP(true);
      setTimer(30);
      setIsButtonDisabled(true);
    }
  };

  return (
    <div
      className="modal fade login-modal"
      id="loginModal"
      tabIndex={-1}
      aria-labelledby="loginModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-left">
          <div className="sc-dtInlm eCzTrX">
            <p className="sc-kOPcWz fCDhNF">FLAT 12% OFF*</p>
            <p className="sc-cWSHoV bsLsTf">on your first Flight booking</p>
            <p className="sc-eBMEME rhNIm">
              <span className="empty">Use Coupon</span>
              <span className="fill">BIGSMILE</span>
            </p>
          </div>
        </div>
        <form
          onSubmit={(e) => formik.handleSubmit(e)}
          className={`modal-content  ${showOTP ? "open" : ""}`}
        >
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref={closeBtnRef}
            onClick={() => {
              setOtp(new Array(4).fill(""));
              formik.resetForm();
              setShowOTP(false);
            }}
          ></button>
          <div className="model-inner-1">
            <div className="modal-header">
              <h3>Login/Signup</h3>
            </div>
            <div className="half-modal">
              <div className="modal-form">
                <div className={`modal-body`}>
                  <span className={`loginCont__label`}>
                    Enter your Mobile Number
                  </span>
                  <span className="loginCont__code">+91</span>
                  <input
                    className="loginCont__input"
                    type="number"
                    name="mobile_no"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.mobile_no}
                    onInput={(e: any) => {
                      return (e.target.value = Math.max(
                        0,
                        parseInt(e.target.value)
                      )
                        .toString()
                        .slice(0, 10));
                    }}
                  />
                </div>
                {/* {formik.errors.mobile_no && formik.touched.mobile_no && (
                  <p className="text-end pe-2 text-white pt-1 mb-0">
                    <span className="bg-danger ps-2 pe-2 rounded-pill">
                      {formik?.errors?.mobile_no}
                    </span>
                  </p>
                )} */}
                <button
                  className="sc-dhKdcB cYwnDu disabled"
                  disabled={
                    formik.errors.mobile_no ||
                    formik.values.mobile_no?.length < 10
                  }
                  type="submit"
                  onClick={() => setIsOTPButtonClicked(true)}
                >
                  Continue
                </button>
              </div>
              <p data-id="terms-condition" className="sc-kpDqfm jFZtLs">
                By proceeding, you agree to Bigsmile’s
                <br />
                <a href="#">Privacy Policy</a>, <a href="#">User Agreement </a>
                and
                <a href="#"> Terms of Service</a>
              </p>
            </div>
          </div>
          <div className="modal-inner-2">
            <div className="modal-header">
              <h3>Verify OTP</h3>
            </div>
            <div className="gxPBXm">
              <span>{`Enter 4 digit OTP sent to ${formik.values.mobile_no}`}</span>
              <FaEdit
                className="btn-edit"
                onClick={() => {
                  setShowOTP(false);
                  setOtp(new Array(4).fill(""));
                }}
              />
            </div>
            <div className="half-modal">
              <div className="modal-form">
                <div className="verifyOtpCont__otpCont">
                  {otp.map((e, index) => {
                    return (
                      <input
                        className="verifyOtpCont__otpFiled "
                        type="text"
                        name="otp"
                        maxLength={1}
                        key={index}
                        onPaste={handlePaste}
                        style={{ width: "1em", textAlign: "center" }}
                        value={e}
                        ref={(el) => (inputRef.current[index] = el)}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e.target, index, e)}
                        disabled={loading}
                      />
                    );
                  })}
                </div>
                <div className="verifyOtpCont__message"></div>
                {showOTP && (
                  <div className="sc-dLMFU rClTI">
                    <a
                      className={`forget-btn ${
                        isButtonDisabled ? "forget-btn-disabled" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() =>
                        !isButtonDisabled &&
                        handleGenerateOTP(formik.values.mobileno)
                      }
                    >
                      Didn't Receive OTP?{" "}
                      {isButtonDisabled
                        ? `Wait ${timer} seconds`
                        : "Resend OTP"}
                    </a>
                  </div>
                )}
                <p data-id="terms-condition" className="sc-kpDqfm jFZtLs">
                  I agree to receive critical messages such as OTP, booking
                  details on WhatsApp.
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
