import React, { useState } from "react";
import HOTEL from "../../assets/images/hotel.png";
import FLIGHT from "../../assets/images/flight.png";
import CARD from "../../assets/images/card.png";
import CUPON from "../../assets/images/cupon.png";
import INSURANCE from "../../assets/images/insurance.png";
import OfferCard from "./OfferCard";

const offersData: any = {
  bestOffers: [
    {
      id: 1,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: HOTEL,
    },
    {
      id: 2,
      title: "Flight Book",
      description: "New User Offer on",
      code: "Travelfirst",
      image: FLIGHT,
      backgroundClass: "red-bg-liner",
    },
    {
      id: 3,
      title: "ICICI Bank",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: CARD,
      backgroundClass: "blue-bg",
    },
    {
      id: 4,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "blue-bg",
    },
    {
      id: 5,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "green-bg",
    },
    {
      id: 6,
      title: "Insurance",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: INSURANCE,
      backgroundClass: "red-light-bg",
    },
  ],
  flights: [
    {
      id: 1,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: HOTEL,
    },
    {
      id: 2,
      title: "Flight Book",
      description: "New User Offer on",
      code: "Travelfirst",
      image: FLIGHT,
      backgroundClass: "red-bg-liner",
    },
    {
      id: 3,
      title: "ICICI Bank",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: CARD,
      backgroundClass: "blue-bg",
    },
    {
      id: 4,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "blue-bg",
    },
    {
      id: 5,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "green-bg",
    },
    {
      id: 6,
      title: "Insurance",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: INSURANCE,
      backgroundClass: "red-light-bg",
    },
  ],
  hotels: [
    {
      id: 1,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: HOTEL,
    },
    {
      id: 2,
      title: "Flight Book",
      description: "New User Offer on",
      code: "Travelfirst",
      image: FLIGHT,
      backgroundClass: "red-bg-liner",
    },
    {
      id: 3,
      title: "ICICI Bank",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: CARD,
      backgroundClass: "blue-bg",
    },
    {
      id: 4,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "blue-bg",
    },
    {
      id: 5,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "green-bg",
    },
    {
      id: 6,
      title: "Insurance",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: INSURANCE,
      backgroundClass: "red-light-bg",
    },
  ],
  holidays: [
    {
      id: 1,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: HOTEL,
    },
    {
      id: 2,
      title: "Flight Book",
      description: "New User Offer on",
      code: "Travelfirst",
      image: FLIGHT,
      backgroundClass: "red-bg-liner",
    },
    {
      id: 3,
      title: "ICICI Bank",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: CARD,
      backgroundClass: "blue-bg",
    },
    {
      id: 4,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "blue-bg",
    },
    {
      id: 5,
      title: "First Hotel",
      description: "New User Offer on",
      code: "Travelfirst",
      image: CUPON,
      backgroundClass: "green-bg",
    },
    {
      id: 6,
      title: "Insurance",
      description: "Get Discounts with",
      code: "Travelfirst",
      image: INSURANCE,
      backgroundClass: "red-light-bg",
    },
  ],
};

const ExclusiveOfferSection = () => {
  const [activeTab, setActiveTab] = useState("bestOffers");

  const renderOffers = (offers: any) => {
    return offers.map((offer: any) => (
      <div className="col-lg-4 col-md-6 col-sm-12" key={offer.id}>
        <OfferCard offer={offer} />
      </div>
    ));
  };

  return (
    <div className="exclusive-offer">
      <div className="container">
        <h2>
          Exclusive <span>Offers</span>
        </h2>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {Object.keys(offersData).map((tab) => (
            <li className="nav-item" role="presentation" key={tab}>
              <button
                className={`nav-link ${activeTab === tab ? "active" : ""}`}
                id={`pills-${tab}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#pills-${tab}`}
                type="button"
                role="tab"
                aria-controls={`pills-${tab}`}
                aria-selected={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              >
                {tab
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {Object.keys(offersData).map((tab) => (
            <div
              className={`tab-pane fade ${
                activeTab === tab ? "show active" : ""
              }`}
              id={`pills-${tab}`}
              role="tabpanel"
              aria-labelledby={`pills-${tab}-tab`}
              key={tab}
            >
              <div className="row">{renderOffers(offersData[tab])}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExclusiveOfferSection;
