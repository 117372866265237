import React from "react";
import {
  ADULTARRAY,
  CABBINCLASSARRAY,
  CHILDARRAY,
  INFANTARRAY,
} from "../../_constants/constant";
import { FormikProps } from "formik";

interface ITravellerSearch {
  formik: FormikProps<any>;
  handleClose: () => void;
}

const TravellerSearchPopup = ({ formik, handleClose }: ITravellerSearch) => {
  const handleTravelerChange = (
    type: "ADULT" | "CHILD" | "INFANT",
    value: number
  ) => {
    let updatedValues = { ...formik.values, [type]: value };

    if (type === "ADULT" && updatedValues.INFANT > value) {
      formik.setFieldError(
        "traveller",
        "Number of Adults cannot be less than Infants"
      );
    } else if (type === "INFANT" && updatedValues.ADULT < value) {
      formik.setFieldError(
        "traveller",
        "Number of Infants cannot be more than Adults"
      );
    } else if (
      updatedValues.ADULT + updatedValues.CHILD + updatedValues.INFANT >
      9
    ) {
      formik.setFieldError(
        "traveller",
        "Total number of travelers cannot exceed 9"
      );
    } else {
      formik.setFieldValue(type, value);
    }
  };

  return (
    <div className="select-ppoup">
      <div className="cloe-btn" onClick={handleClose}></div>
      <div className="select-ppoup-top">
        <h4>ADULTS(12Y +)</h4>
        <p>on the day of travel</p>
        <div className="select-ppoup-list">
          {ADULTARRAY.map((adult) => (
            <div className="fareCardItem" key={adult}>
              <input
                type="radio"
                name="ADULT"
                value={adult}
                checked={formik.values.ADULT === adult}
                onChange={() => handleTravelerChange("ADULT", adult)}
              />
              <span
                style={{
                  background:
                    formik.values.ADULT === adult ? "#cdffe4" : undefined,
                  borderColor:
                    formik.values.ADULT === adult ? "#08a34e" : undefined,
                }}
              ></span>
              <label
                htmlFor=""
                style={{
                  color: formik.values.ADULT === adult ? "#08a34e" : undefined,
                }}
              >
                {adult}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="select-ppoup-middle">
        <div className="select-ppoup-top">
          <h4>CHILDREN(2Y - 12Y)</h4>
          <p>on the day of travel</p>
          <div className="select-ppoup-list">
            {CHILDARRAY.map((child) => (
              <div className="fareCardItem" key={child}>
                <input
                  type="radio"
                  name="radio05"
                  value={child}
                  checked={formik.values.CHILD === child}
                  onChange={() => handleTravelerChange("CHILD", child)}
                />
                <span
                  style={{
                    background:
                      formik.values.CHILD === child ? "#cdffe4" : undefined,
                    borderColor:
                      formik.values.CHILD === child ? "#08a34e" : undefined,
                  }}
                ></span>
                <label
                  htmlFor=""
                  style={{
                    color:
                      formik.values.CHILD === child ? "#08a34e" : undefined,
                  }}
                >
                  {child}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="select-ppoup-top">
          <h4>INFANT(below 2Y)</h4>
          <p>on the day of travel</p>
          <div className="select-ppoup-list">
            {INFANTARRAY.map((INFANT) => (
              <div className="fareCardItem" key={INFANT}>
                <input
                  type="radio"
                  name="radio06"
                  value={INFANT}
                  checked={formik.values.INFANT === INFANT}
                  onChange={() => {
                    handleTravelerChange("INFANT", INFANT);
                  }}
                />
                <span
                  style={{
                    background:
                      formik.values.INFANT === INFANT ? "#cdffe4" : undefined,
                    borderColor:
                      formik.values.INFANT === INFANT ? "#08a34e" : undefined,
                  }}
                ></span>
                <label
                  htmlFor=""
                  style={{
                    color:
                      formik.values.INFANT === INFANT ? "#08a34e" : undefined,
                  }}
                >
                  {INFANT}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="select-ppoup-bottom">
        <div className="select-ppoup-top">
          <h4 className="mb-2">CHOOSE TRAVELER CLASS NAME</h4>
          {/* <p>on the day of travel</p> */}
          <div className="select-ppoup-list">
            {CABBINCLASSARRAY.map((cabinClass) => (
              <div className="fareCardItem" key={cabinClass?.class}>
                <input
                  type="radio"
                  name="radio07"
                  value={cabinClass?.class}
                  onChange={() =>
                    formik.setFieldValue("cabinClass", cabinClass?.class)
                  }
                />
                <span
                  style={{
                    background:
                      formik.values.cabinClass === cabinClass?.class
                        ? "#cdffe4"
                        : undefined,
                    borderColor:
                      formik.values.cabinClass === cabinClass?.class
                        ? "#08a34e"
                        : undefined,
                  }}
                ></span>
                <label
                  htmlFor=""
                  style={{
                    color:
                      formik.values.cabinClass === cabinClass?.class
                        ? "#08a34e"
                        : undefined,
                  }}
                >
                  {cabinClass?.class}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="popup-btn">
        <h6 className="m-0 error-label">
          {formik.errors?.traveller &&
            typeof formik.errors?.traveller === "string" &&
            formik.errors?.traveller}
        </h6>
        <a onClick={handleClose}>Apply</a>
      </div>
    </div>
  );
};

export default TravellerSearchPopup;
