import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useAppSelector } from "../../app/hooks";
import { axiosApiInstance } from "../../app/axios";
import { TRIPJACK_API_URL } from "../../app/config";
import FlightRRoute from "./FlightRRoute";
import FlightRPrice from "./FlightRPrice";
import FlightRDetailsTabs from "./FlightRDetailsTabs";

interface FlightCardProps {
  route: any;
  selectedFlightPriceId: string;
  routeIndex: number;
  prefix: string;
  handleSelectFlight: (priceDetails: any, route: any, type: string) => void;
}

// this is card is for Round trip
const FilghtRMainCard = ({
  route,
  routeIndex,
  prefix,
  handleSelectFlight,
  selectedFlightPriceId,
}: FlightCardProps) => {
  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const sortedPriceList = useMemo(
    () =>
      [...route?.totalPriceList].sort(
        (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
      ),
    [route?.totalPriceList]
  );
  const [fareRulesLoading, setFareRulesLoading] = useState(false);
  const [openFlightRate, setOpenFlightRate] = useState<boolean>(false);
  const [openFightDetail, setOpenFlightDetail] = useState<boolean>(false);
  const [priceDetails, setPriceDetails] = useState<any>(null);
  const [fareRule, setFareRule] = useState<any>({});

  const getFareRules = async (id: any) => {
    try {
      setFareRule({});
      setFareRulesLoading(true);
      const response = await axiosApiInstance.post(
        `${TRIPJACK_API_URL}/fms/v1/farerule`,
        {
          id: id,
          flowType: "SEARCH",
        }
      );
      setFareRule(response.data?.fareRule);
    } catch (error) {
      console.error("Error fetching fare rules results:", error);
    } finally {
      setFareRulesLoading(false);
    }
  };

  useEffect(() => {
    if (sortedPriceList) {
      let minPriceOption = route.totalPriceList[0];
      let minPrice = calculateTotalPrice(minPriceOption);

      route.totalPriceList.forEach((tp: any) => {
        const totalPrice = calculateTotalPrice(tp);
        if (totalPrice < minPrice) {
          minPrice = totalPrice;
          minPriceOption = tp;
        }
      });

      setPriceDetails(minPriceOption);
    }
  }, [sortedPriceList]);

  const calculateTotalPrice = (tp: any) => {
    const totalAdultPrice =
      (tp?.fd?.ADULT?.fC?.TF || 0) * flightSearchDetails.ADULT;
    const totalChildPrice =
      (tp?.fd?.CHILD?.fC?.TF || 0) * flightSearchDetails.CHILD;
    const totalInfantPrice =
      (tp?.fd?.INFANT?.fC?.TF || 0) * flightSearchDetails.INFANT;
    return totalAdultPrice + totalChildPrice + totalInfantPrice;
  };

  const handleRadioButtonChange = (id: any) => {
    const selectedTp = sortedPriceList.find((tp: any) => tp?.id === id);
    setPriceDetails(selectedTp);
    if (openFightDetail) {
      getFareRules(id);
    }
  };

  const handleSelectFlightClick = (
    priceDetails: any,
    route: any,
    prefix: any
  ) => {
    handleSelectFlight(priceDetails, route, prefix);
  };

  const startTime = useMemo(() => moment(route?.sI[0]?.dt), [route]);
  const endTime = useMemo(
    () => moment(route?.sI[route?.sI?.length - 1]?.at),
    [route]
  );
  const departureCode = useMemo(() => route?.sI[0]?.da?.code, [route]);
  const arivalCode = useMemo(
    () => route?.sI[route?.sI?.length - 1]?.aa?.code,
    [route]
  );

  const cancellationPolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.BEFORE_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.AFTER_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION?.DEFAULT
      ?.policyInfo ||
    "Airline Cancellation Charges + Taxes + Surcharges";
  const cancellationAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.BEFORE_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION
      ?.AFTER_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.CANCELLATION?.DEFAULT
      ?.amount ||
    "";
  const datechangePolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.BEFORE_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.AFTER_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.DEFAULT
      ?.policyInfo ||
    "";
  const datechangeAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.BEFORE_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.AFTER_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.DATECHANGE?.DEFAULT
      ?.amount ||
    "";
  const noshowPolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.BEFORE_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.AFTER_DEPARTURE
      ?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.DEFAULT
      ?.policyInfo ||
    "Fees As Per Airline Rule + Surcharges";
  const seatschargePolicyInfo =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.BEFORE_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.AFTER_DEPARTURE?.policyInfo ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE?.DEFAULT
      ?.policyInfo ||
    "As Per Airline";
  const seatschargeAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.BEFORE_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE
      ?.AFTER_DEPARTURE?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.SEAT_CHARGEABLE?.DEFAULT
      ?.amount ||
    "";
  const noshowAmount =
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.BEFORE_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.AFTER_DEPARTURE
      ?.amount ||
    fareRule[`${departureCode}-${arivalCode}`]?.fr?.NO_SHOW?.DEFAULT?.amount ||
    "";

  const remainingSeats = priceDetails?.fd?.ADULT?.sR;
  const cabinClass = priceDetails?.fd?.ADULT?.cc;
  const classOfBook = priceDetails?.fd?.ADULT?.cB;

  const { ADULT, CHILD, INFANT } = flightSearchDetails;

  const adultBaseRate = priceDetails?.fd?.ADULT?.fC?.BF || 0;
  const childBaseRate = priceDetails?.fd?.CHILD?.fC?.BF || 0;
  const infantBaseRate = priceDetails?.fd?.INFANT?.fC?.BF || 0;

  const adultCabbinBaggage = priceDetails?.fd?.ADULT?.bI?.cB;
  const childCabbinBaggage = priceDetails?.fd?.CHILD?.bI?.cB;
  const infantCabbinBaggage = priceDetails?.fd?.INFANT?.bI?.cB;

  const adultCheckingBaggage = priceDetails?.fd?.ADULT?.bI?.iB;
  const childCheckingBaggage = priceDetails?.fd?.CHILD?.bI?.iB;
  const infantCheckingBaggage = priceDetails?.fd?.INFANT?.bI?.iB;

  const adultTaxRate = priceDetails?.fd?.ADULT?.fC?.TAF || 0;
  const childTaxRate = priceDetails?.fd?.CHILD?.fC?.TAF || 0;
  const infantTaxRate = priceDetails?.fd?.INFANT?.fC?.TAF || 0;

  const totalPrice = useMemo(() => {
    const totalAdultPrice = (priceDetails?.fd?.ADULT?.fC?.TF || 0) * ADULT;
    const totalChildPrice = (priceDetails?.fd?.CHILD?.fC?.TF || 0) * CHILD;
    const totalInfantPrice = (priceDetails?.fd?.INFANT?.fC?.TF || 0) * INFANT;
    return totalAdultPrice + totalChildPrice + totalInfantPrice;
  }, [priceDetails, ADULT, CHILD, INFANT]);

  return (
    <div className="flight-detail-tab">
      <div
        className={`flight-route ${
          selectedFlightPriceId === priceDetails?.id
            ? "selected-flight-border"
            : ""
        }`}
      >
        <div className="flight-route-top">
          <FlightRRoute
            route={route}
            startTime={startTime}
            endTime={endTime}
            remainingSeats={remainingSeats}
            openFightDetail={openFightDetail}
            setOpenFlightDetail={setOpenFlightDetail}
          />
          <FlightRPrice
            prefix={prefix}
            flightSearchDetails={flightSearchDetails}
            openFlightRate={openFlightRate}
            routeIndex={routeIndex}
            setOpenFlightRate={setOpenFlightRate}
            priceDetails={priceDetails}
            sortedPriceList={sortedPriceList}
            handleRadioButtonChange={handleRadioButtonChange}
          />
        </div>
        <div className="detail-btn">
          <a
            className="fill-btn"
            onClick={() => handleSelectFlightClick(priceDetails, route, prefix)}
          >
            {selectedFlightPriceId === priceDetails?.id
              ? "Selected"
              : "Select Flight"}
          </a>
        </div>
      </div>
      {/* flight-outer-open */}
      <FlightRDetailsTabs
        openFightDetail={openFightDetail}
        routeIndex={routeIndex}
        prefix={prefix}
        route={route}
        priceDetails={priceDetails}
        classOfBook={classOfBook}
        cabinClass={cabinClass}
        remainingSeats={remainingSeats}
        getFareRules={getFareRules}
        ADULT={ADULT}
        adultTaxRate={adultTaxRate}
        adultBaseRate={adultBaseRate}
        CHILD={CHILD}
        childTaxRate={childTaxRate}
        childBaseRate={childBaseRate}
        INFANT={INFANT}
        infantTaxRate={infantTaxRate}
        infantBaseRate={infantBaseRate}
        totalPrice={totalPrice}
        fareRule={fareRule}
        fareRulesLoading={fareRulesLoading}
        seatschargeAmount={seatschargeAmount}
        seatschargePolicyInfo={seatschargePolicyInfo}
        noshowAmount={noshowAmount}
        noshowPolicyInfo={noshowPolicyInfo}
        datechangeAmount={datechangeAmount}
        datechangePolicyInfo={datechangePolicyInfo}
        cancellationAmount={cancellationAmount}
        cancellationPolicyInfo={cancellationPolicyInfo}
        adultCheckingBaggage={adultCheckingBaggage}
        childCheckingBaggage={childCheckingBaggage}
        infantCheckingBaggage={infantCheckingBaggage}
        adultCabbinBaggage={adultCabbinBaggage}
        childCabbinBaggage={childCabbinBaggage}
        infantCabbinBaggage={infantCabbinBaggage}
      />
    </div>
  );
};

export default FilghtRMainCard;
