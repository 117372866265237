import React from "react";
import GOA from "../../assets/images/goa.jpg";
import BANGLORE from "../../assets/images/banglore.jpg";
import DEKHI from "../../assets/images/dekhi.jpg";
import PATTYA from "../../assets/images/pattya.jpg";
import UDAIPUR from "../../assets/images/udaipur.jpg";
import MOUNT from "../../assets/images/mount.jpg";
import Slider from "react-slick";
import DestinationCard from "./DestinationCard";

const destinations = [
  { image: GOA, name: "Goa", propertiesCount: 3051 },
  { image: BANGLORE, name: "Bangalore", propertiesCount: 3051 },
  { image: DEKHI, name: "Delhi", propertiesCount: 3051 },
  { image: PATTYA, name: "Pattaya", propertiesCount: 3051 },
  { image: UDAIPUR, name: "Udaipur", propertiesCount: 3051 },
  { image: MOUNT, name: "Mount Abu", propertiesCount: 3051 },
  { image: BANGLORE, name: "Bangalore", propertiesCount: 3051 },
  { image: DEKHI, name: "Delhi", propertiesCount: 3051 },
];

const DestinationSection = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="destination-section">
      <div className="container">
        <h2>
          Popular <span>Destinations</span>
        </h2>
        <Slider {...settings}>
          {destinations.map((destination, index) => (
            <DestinationCard destination={destination} key={index} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default DestinationSection;
