import React from "react";
import FlightMulticityDetailsTab from "./FlightMulticityDetailsTab";
import { formatIndianNumber, getFlightImage } from "../../_helpers/helper";
import { useAppSelector } from "../../app/hooks";

const MulticityFlightsFooter = ({
  selectedMultiCity,
  grandMulticityTotal,
  handleMulticityBookNowClick,
}: any) => {
  const flightSearchDetails = useAppSelector(
    (state) => state.home.flightSearchDetails
  );

  const multicityActiveIndex: any = useAppSelector(
    (state) => state.flight.multicityActiveIndex
  );

  return (
    <div
      className="container"
      style={{
        position: "fixed",
        bottom: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 9,
      }}
    >
      <div className="row">
        <div className="col-3"></div>
        <div className="col-9">
          <div className="accordion multicity-accordian" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header" id="headingOne">
                {selectedMultiCity?.map((flight: any, index: number) => {
                  const totalAdultPrice =
                    (selectedMultiCity[index]?.priceDetails?.fd?.ADULT?.fC
                      ?.TF || 0) * flightSearchDetails.ADULT;
                  const totalChildPrice =
                    (selectedMultiCity[index]?.priceDetails?.fd?.CHILD?.fC
                      ?.TF || 0) * flightSearchDetails.CHILD;
                  const totalInfantPrice =
                    (selectedMultiCity[index]?.priceDetails?.fd?.INFANT?.fC
                      ?.TF || 0) * flightSearchDetails.INFANT;
                  const totalPrice =
                    totalAdultPrice + totalChildPrice + totalInfantPrice;
                  return (
                    <div className="accordian-flight-container">
                      <div className="accordian-flight">
                        <img
                          className="accordian-flight-image"
                          src={getFlightImage(
                            selectedMultiCity[index]?.route?.sI[0]?.fD?.aI?.code
                            // selectedMultiCity[index]?.route?.sI[0]?.fD?.aI
                            //   ?.name
                          )}
                        />
                        <div className="place-code">
                          <p>
                            {selectedMultiCity[index]?.route?.sI[0]?.da?.code}
                            {" - "}
                            {
                              selectedMultiCity[index]?.route?.sI[
                                selectedMultiCity[index]?.route?.sI?.length - 1
                              ]?.aa?.code
                            }
                          </p>
                        </div>
                      </div>
                      <div className="flight-price">
                        ₹ {formatIndianNumber(totalPrice)}
                      </div>
                    </div>
                  );
                })}
                <div className="accordian-flight-cost">
                  <p className="cost-total">Total Trip Cost</p>
                  <p className="cost">
                    ₹ {formatIndianNumber(grandMulticityTotal)}
                  </p>
                </div>
                <div className="accordian-flight-btn text-white">
                  <button
                    className="fill-btn btn-right"
                    onClick={() => handleMulticityBookNowClick()}
                  >
                    {selectedMultiCity?.length - 1 <= multicityActiveIndex
                      ? "Continue"
                      : "Next Flight"}
                  </button>
                  <p
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Flight details
                  </p>
                </div>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                ></button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <FlightMulticityDetailsTab
                    selectedMultiCity={selectedMultiCity}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MulticityFlightsFooter;
