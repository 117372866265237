import axios from "axios";

export const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  (config) => {
    config.headers["apikey"] = "6122967fb0fcbd-9d8f-4336-8bfc-16f5615c0f27";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors here
    return Promise.reject(error);
  }
);
