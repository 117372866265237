import { useMemo, useState } from "react";
import { formatIndianNumber } from "../../_helpers/helper";
import { ErrorToast } from "../../toast/Toasts";
import useWindowSize from "../../_hooks/useWindowSize";

const ReviewPriceCard = ({
  paxDetails,
  tripInfos,
  handleSubmit,
}: {
  paxDetails: any;
  tripInfos: any;
  handleSubmit: () => void;
}) => {
  const [activeTabs, setActiveTabs] = useState<any>([]);
  const { width } = useWindowSize();

  const ADULT = paxDetails?.ADULT || 0;
  const CHILD = paxDetails?.CHILD || 0;
  const INFANT = paxDetails?.INFANT || 0;

  const totalAdultBaseRate = useMemo(() => {
    return tripInfos?.reduce(
      (sum: any, route: any) =>
        sum + (route?.totalPriceList?.[0]?.fd?.ADULT?.fC?.BF || 0),
      0
    );
  }, [tripInfos]);

  const totalChildBaseRate = useMemo(() => {
    return tripInfos?.reduce(
      (sum: any, route: any) =>
        sum + (route?.totalPriceList?.[0]?.fd?.CHILD?.fC?.BF || 0),
      0
    );
  }, [tripInfos]);

  const totalInfantBaseRate = useMemo(() => {
    return tripInfos?.reduce(
      (sum: any, route: any) =>
        sum + (route?.totalPriceList?.[0]?.fd?.INFANT?.fC?.BF || 0),
      0
    );
  }, [tripInfos]);

  const totalAdultTaxRate = useMemo(() => {
    return tripInfos?.reduce(
      (sum: any, route: any) =>
        sum + (route?.totalPriceList?.[0]?.fd?.ADULT?.fC?.TAF || 0),
      0
    );
  }, [tripInfos]);

  const totalChildTaxRate = useMemo(() => {
    return tripInfos?.reduce(
      (sum: any, route: any) =>
        sum + (route?.totalPriceList?.[0]?.fd?.CHILD?.fC?.TAF || 0),
      0
    );
  }, [tripInfos]);

  const totalInfantTaxRate = useMemo(() => {
    return tripInfos?.reduce(
      (sum: any, route: any) =>
        sum + (route?.totalPriceList?.[0]?.fd?.INFANT?.fC?.TAF || 0),
      0
    );
  }, [tripInfos]);

  const totalBasePrice = useMemo(() => {
    const totalAdultPrice = (totalAdultBaseRate || 0) * ADULT;
    const totalChildPrice = (totalChildBaseRate || 0) * CHILD;
    const totalInfantPrice = (totalInfantBaseRate || 0) * INFANT;
    return totalAdultPrice + totalChildPrice + totalInfantPrice;
  }, [tripInfos, ADULT, CHILD, INFANT]);

  const totalTaxandFarePrice = useMemo(() => {
    const totalAdultPrice = (totalAdultTaxRate || 0) * ADULT;
    const totalChildPrice = (totalChildTaxRate || 0) * CHILD;
    const totalInfantPrice = (totalInfantTaxRate || 0) * INFANT;
    return totalAdultPrice + totalChildPrice + totalInfantPrice;
  }, [tripInfos, ADULT, CHILD, INFANT]);

  // const RefundableType =
  //   priceDetails?.fd?.ADULT?.rT === 1
  //     ? "Refundable"
  //     : priceDetails?.fd?.ADULT?.rT === 2
  //     ? "Partial Refundable"
  //     : "Non Refundable";

  const toggleTab = (index: number) => {
    setActiveTabs((prev: any) => {
      if (prev.includes(index)) {
        return prev.filter((i: number) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  return (
    <div className="col-lg-4 col-md-5 col-sm-12">
      <div
        className="listing-sidebar booking-sidebar"
        style={{ top: width <= 991 ? 0 : 80 }}
      >
        <div className="d-flex-space-between mb-3">
          <h4>Fare Summary</h4>
          <h4>{ADULT + CHILD + INFANT} Traveller</h4>
        </div>
        {/* <div className="d-flex-space-between mb-3">
          <h5>Fare Type</h5>
          <h5>{RefundableType}</h5>
        </div> */}
        <div
          className={`sidebar-tab ${
            activeTabs.includes(1) ? "menu-active" : ""
          }`}
        >
          <div className="d-flex-space-between">
            <h5 onClick={() => toggleTab(1)}>{"Base Fare"}</h5>
            <h6>₹{formatIndianNumber(totalBasePrice)}</h6>
          </div>
          {activeTabs.includes(1) && (
            <div className="sidebar-content flex-column">
              {totalAdultBaseRate > 0 && ADULT > 0 && (
                <div className="sidebar-row">
                  <span>
                    Adult(s) ({totalAdultBaseRate} x {ADULT})
                  </span>
                  <span>₹{formatIndianNumber(totalAdultBaseRate * ADULT)}</span>
                </div>
              )}
              {totalChildBaseRate > 0 && CHILD > 0 && (
                <div className="sidebar-row">
                  <span>
                    Children ({totalChildBaseRate} x {CHILD})
                  </span>
                  <span>₹{formatIndianNumber(totalChildBaseRate * CHILD)}</span>
                </div>
              )}
              {totalInfantBaseRate > 0 && INFANT > 0 && (
                <div className="sidebar-row">
                  <span>
                    Infant(s) ({totalInfantBaseRate} x {INFANT})
                  </span>
                  <span>
                    ₹{formatIndianNumber(totalInfantBaseRate * INFANT)}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={`sidebar-tab ${
            activeTabs.includes(2) ? "menu-active" : ""
          }`}
        >
          <div className="d-flex-space-between">
            <h5 onClick={() => toggleTab(2)}>{"Taxes & Fees"}</h5>
            <h6>₹{formatIndianNumber(totalTaxandFarePrice)}</h6>
          </div>
          {activeTabs.includes(2) && (
            <div className="sidebar-content flex-column">
              {totalAdultTaxRate > 0 && ADULT > 0 && (
                <div className="sidebar-row">
                  <span>
                    Adult(s) ({totalAdultTaxRate} x {ADULT})
                  </span>
                  <span>₹{formatIndianNumber(totalAdultTaxRate * ADULT)}</span>
                </div>
              )}
              {totalChildTaxRate > 0 && CHILD > 0 && (
                <div className="sidebar-row">
                  <span>
                    Children ({totalChildTaxRate} x {CHILD})
                  </span>
                  <span>₹{formatIndianNumber(totalChildTaxRate * CHILD)}</span>
                </div>
              )}
              {totalInfantTaxRate > 0 && INFANT > 0 && (
                <div className="sidebar-row">
                  <span>
                    Infant(s) ({totalInfantTaxRate} x {INFANT})
                  </span>
                  <span>
                    ₹{formatIndianNumber(totalInfantTaxRate * INFANT)}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="sidebar-content siderbar-total">
          <div className="sidebar-left">
            <h5>Total Amount</h5>
          </div>
          <div className="sidebar-right">
            <h5>
              ₹{formatIndianNumber(totalBasePrice + totalTaxandFarePrice)}
            </h5>
          </div>
        </div>
        <a
          className="fill-btn"
          href="javascript:void(0)"
          onClick={() => {
            if (localStorage.getItem("user_guid")) {
              handleSubmit();
            } else {
              ErrorToast("Please login to book a flight!!!");
            }
          }}
        >
          Proceed to Pay
        </a>
      </div>
    </div>
  );
};

export default ReviewPriceCard;
