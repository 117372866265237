import { createSlice } from "@reduxjs/toolkit";
import { INationalityState } from "./nationalityModel";
import { getAllActiveNationalities } from "./services/nationality.services";

const initialState: INationalityState = {
  loading: false,
  error: "",
  NationalityList: [],
  NationalityID: null,
};

const userSlice = createSlice({
  name: "nationality",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllActiveNationalities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAllActiveNationalities.fulfilled, (state, action) => {
      state.loading = true;
      state.NationalityList = action.payload;
    });
    builder.addCase(getAllActiveNationalities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default userSlice.reducer;
