import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL, TRIPJACK_API_URL } from "../../../app/config";
import axios from "axios";

export const getAllActiveFlightPlaces = createAsyncThunk(
  "FlightPlaces/FindAllActiveFlightPlaces",
  async () => {
    const response = await axiosApiInstance.get(
      `${API_URL}/FlightPlaces/FindAllActiveFlightPlaces`
    );
    return response.data?.Data?.map((item: any) => {
      return {
        ID: item?.ID,
        flightplace_name: item?.flightplace_name,
        flightplace_code: item?.flightplace_code,
        city_name: item?.city_name,
        country_name: item?.country_name,
        state_name: item?.state_name,
        state_id: item?.state_id,
        country_id: item?.country_id,
        city_id: item?.city_id,
        isactive: item?.isactive,
      };
    });
  }
);

export const getAllFlightPlaces = createAsyncThunk(
  "FlightPlaces/FromFindAllFlightPlaces",
  async (placeName: string) => {
    try {
      const response = await axios.get(
        `${TRIPJACK_API_URL}/fms/v1/airport/${placeName}`
      );

      return (
        response.data?.suggestions?.map((item: any) => {
          return {
            ID: item?.id,
            flightplace_name: item?.name,
            flightplace_code: item?.code,
            city_name: item?.city,
            country_name: item?.country,
          };
        }) || []
      );
    } catch (error: any) {
      return [];
    }
  }
);

// export const getAllFlightPlaces = createAsyncThunk(
//   "FlightPlaces/FromFindAllFlightPlaces",
//   async (placeName: string) => {
//     try {
//       const response = await axiosApiInstance.post(
//         `${API_URL}/FlightPlaces/FindAllFlightPlaces`,
//         { flightplace_name: placeName }
//       );

//       return (
//         response.data?.Data?.map((item: any) => {
//           return {
//             ID: item?.ID,
//             flightplace_name: item?.flightplace_name,
//             flightplace_code: item?.flightplace_code,
//             city_name: item?.city_name,
//             country_name: item?.country_name,
//             state_name: item?.state_name,
//             state_id: item?.state_id,
//             country_id: item?.country_id,
//             city_id: item?.city_id,
//             isactive: item?.isactive,
//           };
//         }) || []
//       );
//     } catch (error: any) {
//       return [];
//     }
//   }
// );
