import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllMyTrips } from "./services/mytrips.services";
import UpcomingTrips from "./UpcomingTrips";
import CancelledTrips from "./CancelledTrips";
import CompletedTrips from "./CompletedTrips";
import Loading from "../../components/spinner/Loading";
import { getLocalItem } from "../../_helpers/helper";
const MyTrips = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.mytrips.loading);

  const user_guid = getLocalItem("user_guid");

  useEffect(() => {
    dispatch(
      getAllMyTrips({
        user_guid: user_guid,
      })
    );
  }, []);

  return (
    <>
      <Loading backgroundColor="white" loading={loading} />
      <div className="trip-detail">
        <div className="container">
          <h2>My Trips</h2>
          <div className="trip-detail-tabbing flight-detail table">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-flight-tab1"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-flight1"
                  type="button"
                  role="tab"
                  aria-controls="pills-flight1"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 15H15.0125M20 7.5V5C20 4.33696 19.7366 3.70107 19.2678 3.23223C18.7989 2.76339 18.163 2.5 17.5 2.5H12.5C11.837 2.5 11.2011 2.76339 10.7322 3.23223C10.2634 3.70107 10 4.33696 10 5V7.5M27.5 16.25C23.791 18.6987 19.4444 20.0041 15 20.0041C10.5556 20.0041 6.20901 18.6987 2.5 16.25M5 7.5H25C26.3807 7.5 27.5 8.61929 27.5 10V22.5C27.5 23.8807 26.3807 25 25 25H5C3.61929 25 2.5 23.8807 2.5 22.5V10C2.5 8.61929 3.61929 7.5 5 7.5Z"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Upcoming
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-hotel-tab1"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-hotel1"
                  type="button"
                  role="tab"
                  aria-controls="pills-hotel1"
                  aria-selected="false"
                  tabIndex={-1}
                >
                  <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.875 18.125L18.125 11.875M11.875 11.875L18.125 18.125M2.5 11.25C3.49456 11.25 4.44839 11.6451 5.15165 12.3483C5.85491 13.0516 6.25 14.0054 6.25 15C6.25 15.9946 5.85491 16.9484 5.15165 17.6517C4.44839 18.3549 3.49456 18.75 2.5 18.75V21.25C2.5 21.913 2.76339 22.5489 3.23223 23.0178C3.70107 23.4866 4.33696 23.75 5 23.75H25C25.663 23.75 26.2989 23.4866 26.7678 23.0178C27.2366 22.5489 27.5 21.913 27.5 21.25V18.75C26.5054 18.75 25.5516 18.3549 24.8484 17.6517C24.1451 16.9484 23.75 15.9946 23.75 15C23.75 14.0054 24.1451 13.0516 24.8484 12.3483C25.5516 11.6451 26.5054 11.25 27.5 11.25V8.75C27.5 8.08696 27.2366 7.45107 26.7678 6.98223C26.2989 6.51339 25.663 6.25 25 6.25H5C4.33696 6.25 3.70107 6.51339 3.23223 6.98223C2.76339 7.45107 2.5 8.08696 2.5 8.75V11.25Z"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Cancelled
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-package-tab1"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-package1"
                  type="button"
                  role="tab"
                  aria-controls="pills-package1"
                  aria-selected="true"
                >
                  <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 5H22.5C23.163 5 23.7989 5.26339 24.2678 5.73223C24.7366 6.20107 25 6.83696 25 7.5V25C25 25.663 24.7366 26.2989 24.2678 26.7678C23.7989 27.2366 23.163 27.5 22.5 27.5H7.5C6.83696 27.5 6.20107 27.2366 5.73223 26.7678C5.26339 26.2989 5 25.663 5 25V7.5C5 6.83696 5.26339 6.20107 5.73223 5.73223C6.20107 5.26339 6.83696 5 7.5 5H10M11.25 17.5L13.75 20L18.75 15M11.25 2.5H18.75C19.4404 2.5 20 3.05964 20 3.75V6.25C20 6.94036 19.4404 7.5 18.75 7.5H11.25C10.5596 7.5 10 6.94036 10 6.25V3.75C10 3.05964 10.5596 2.5 11.25 2.5Z"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Completed
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <UpcomingTrips />
              <CancelledTrips />
              <CompletedTrips />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyTrips;
