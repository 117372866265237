import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL, TRIPJACK_API_URL } from "../../../app/config";

export const getAllMyTrips = createAsyncThunk(
  "FlightBooking/MyTrips",
  async (user_guid: any) => {
    const response = await axiosApiInstance.post(
      `${API_URL}/FlightBooking/MyTrips`,
      user_guid
    );
    return response.data?.Data;
  }
);

export const getBookingDetails = createAsyncThunk(
  "oms/v1/booking-details",
  async (payload: any) => {
    const response = await axiosApiInstance.post(
      `${TRIPJACK_API_URL}/oms/v1/booking-details`,
      payload
    );
    return response.data;
  }
);
