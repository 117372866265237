import React from "react";
import PRIVACY from "../../assets/images/privacy.png";

const IMPInfoCard = () => {
  return (
    <div className="Important-information">
      <h4>Important Information</h4>
      <h6>
        <img src={PRIVACY} />
        Check travel guidelines and baggage information below:
      </h6>
      <ul>
        <li>
          Carry no more than 1 check-in baggage and 1 hand baggage per
          passenger. If violated, airline may levy extra charges.
        </li>
      </ul>
      <h6>
        <img src={PRIVACY} />
        Unaccompanied Minors Travelling:
      </h6>
      <ul>
        <li>
          An unaccompanied minor usually refers to a child traveling without an
          adult aged 18 or older.
        </li>
        <li>
          Please check with the airline for their rules and regulations
          regarding unaccompanied minors, as these can differ between airlines.
        </li>
      </ul>
    </div>
  );
};

export default IMPInfoCard;
